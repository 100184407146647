import React, { useRef, useState } from 'react';
import colors from '../../assets/colors';
import Gifs from '../Gifs';

import "../../styles/FilterStyle.css";

interface Props {
    tableData: any
    componentsState: any
    componentsStateSetter: Function
    paginationEndPoint?: number
    paginationStartPoint?: number
};

const Filter = (props: Props) => {
    let filter = [] as any[];
    const handleFilterInput = (input: string) => {
        props.tableData.map((object:any) => {
            const keys = Object.keys(object);
            const requiredKeys = keys.filter(key => !key.includes("_") && !key.includes('date'))
            // console.log("\n\t requiredKeys: ", requiredKeys)
            requiredKeys.map(key => {
                const value = object[key];
                if(String(value).toLowerCase().includes(input.toLowerCase())){
                    !filter.includes(object) && filter.push(object);
                };
            });
        });
        props.componentsStateSetter({
            ...props.componentsState,
            paginatedTableData: input ? filter : props.tableData.slice(
                props.paginationStartPoint ? props.paginationStartPoint : 0, 
                props.paginationEndPoint ? props.paginationEndPoint : undefined
            )
        });
        filter = [];
    };
    return (
        <div style={{width: "100%", height: "5vh", marginRight: "-.6rem", outline: "none"}} className='filter row d-flex justify-content-center'>
            <div className='float-right row form-group align-items-center span-gif-container '>
                <span style={{backgroundColor: colors.sideNav, zIndex: 1}} className='p-2 px-3 justify-content-center'>
                    <Gifs gifName='search' />
                </span>
                <span style={{width: "18.9rem"}} className=''>
                    <input placeholder='Search' onChange={e => handleFilterInput(e.target.value)} type="text" className='form-control input-search' />
                </span>
            </div>
        </div>
    )
};

export default Filter;
