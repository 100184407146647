import { useLocation } from 'react-router-dom';
import ModuleSummary from '../ModuleSummary/ModuleSummary';
import SettingsTable from '../Tables/SettingsTable';

interface Props {
    summaryHeader: any[]
};



const SettingsDashboard = (props: Props) => {
    const location = useLocation()
    const pathname = location.pathname;
    // console.log("\n\t SettingsDashboard-summaryHeader: ", props.summaryHeader)
    // console.log("\n\t SettingsDashboard-pathname includes settings: ", pathname.toLowerCase().includes("settings"))
    return (
        <div>
            {
                pathname ===  "/settings" ?
                <ModuleSummary dashboardName="Settings"  />
                :
                <div style={{marginLeft: "2rem"}} className="row d-flex">
                    <SettingsTable />
                </div>
            }
        </div>
    )
}

export default SettingsDashboard
