

const actualHour = (time: string[]) => {
    if(time[0] === "12"){
        if(time[2].includes("AM")){
            return 1
        }else{
            return 12+1
        }
    }else if(time[2].includes("PM")){
        return (+time[0])+12
    }else{
        return +time[0];
    }
}


export const todaysDateBoolean = (date: string | Date, pathname: string): boolean => {
    if(pathname.includes('backtrack')){
        return false
    }
    const todaysFullDate = new Date();
    const currentYear = todaysFullDate.getFullYear();
    const currentMonth = todaysFullDate.getMonth();
    const currentDate = todaysFullDate.getDate();
    const requiredDate = new Date(currentYear, currentMonth, currentDate);

    const enteredDate = new Date(date)
    // console.log("\n\t todaysDate: ", requiredDate.getDate()+1);
    // console.log("\n\t tomorrowsDate: ", tomorrowsDate());
    if(enteredDate.toLocaleDateString() === requiredDate.toLocaleDateString()){
        return false
    };
    return true;
};



export const dateMaker = (year:number, month:number, day:number) => {
    const date = new Date(year, month, day);
    const madeDate = `${date.getFullYear()}-${
    date.getMonth() + 1 >= 10
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)
    }-${date.getDate() >= 10 ? date.getDate() : "0" + (date.getDate())}`;
    return madeDate;
}

export const dateConstrainers = (date: Date | string) => {
    let dateData: any; 
    if(typeof(date) === "string"){
        dateData = givenDateAndCurrentTime(date)
    }else{
        dateData = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    const madeDate = `${dateData.getFullYear()}-${
    dateData.getMonth() + 1 >= 10
        ? dateData.getMonth() + 1
        : "0" + (dateData.getMonth() + 1)
    }-${dateData.getDate() >= 10 ? dateData.getDate() : "0" + (dateData.getDate())}`;
    return madeDate;
};

// const permissibleMinWelcomeDate = givenDateAndCurrentTime("2022-08-24")
// const ttt = dateConstrainers(permissibleMinWelcomeDate as Date)
// console.log("\n\t")

export const givenDateAndCurrentTime = (date?: string | Date) => {
    const formedDate = new Date();
    const time = formedDate.toLocaleTimeString().split(":");
    let requiredDate: Date;
    if(typeof(date) === "string"){
        const splittedStringDate = date.includes("-") ? date.split("-") : date.split("/");
        requiredDate = new Date(+splittedStringDate[0], +splittedStringDate[1]-1, +splittedStringDate[2], actualHour(time), +time[1], +time[2].split(" ")[0]);
        // console.log("\n\t givenDateAndCurrentTime-date-string: ", requiredDate)
        return requiredDate;
    }else if(date){
        const splittedStringDate = date.toLocaleDateString().split("/");
        requiredDate = new Date(+splittedStringDate[2], +splittedStringDate[0]-1, +splittedStringDate[1], actualHour(time), +time[1], +time[2].split(" ")[0]);
        return requiredDate;
    }
    requiredDate = new Date(formedDate.getFullYear(), formedDate.getMonth(), formedDate.getDate(), actualHour(time), +time[1], +time[2].split(" ")[0]);
    // console.log("\n\t yesterdaysDate-no-date-given: ", yesterdaysDate())
    // console.log("\n\t givenDateAndCurrentTime-requiredDate: ", requiredDate)
    return requiredDate;
};

export const todaysDate = () => {
    return givenDateAndCurrentTime();
};