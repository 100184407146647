


export const taxComputation = (total: number, taxDetails: any) => {
    console.log("\n\t taxComputation: ", total, taxDetails)
    try {
        const { vat, serviceCharge, consumptionCharge } = taxDetails;
        const totalTaxCharges = vat + serviceCharge + consumptionCharge;
        const totalTaxInPercentage = totalTaxCharges/100;
        const netCharge = total/(1 + totalTaxInPercentage)
        const vatCharge = (vat/100)*netCharge;
        const serviceChargeOnTotalWorth = (serviceCharge/100)*netCharge;
        const consumptionChargeOnTotalWorth = (consumptionCharge/100)*netCharge;
    
        const requiredNet = netCharge + serviceChargeOnTotalWorth + consumptionChargeOnTotalWorth;
        return {netCharge: requiredNet.toFixed(2), vatCharge: vatCharge.toFixed(2)}
    } catch (error) {
        console.log("\n\t taxComputation-error: ", error)
        return {netCharge: 0, vatCharge: 0}
    }
}