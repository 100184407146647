import { ActionTypes, CallBackendInterface, ShowConfirmModalInterface, ShowFooterInterface, ShowFormModalInterface, ShowProcessingGifInterface, ToggleSidebarInterface } from "../actions/actionTypes";
import { StoreInterface } from "../store";


export const toggleSidebarReducer = (store: StoreInterface, action: ToggleSidebarInterface) => {
    switch(action.type){
        case ActionTypes.TOGGLE_SIDEBAR:
            // console.log("\n\t toggleSidebarReducer: ", action.payload)
            store.showSidebar = action.payload;
            return({
                ...store
            });
        default:
            return({
                ...store
            }) 
    }
};

export const toggleFooterReducer = (store: StoreInterface, action: ShowFooterInterface) => {
    switch(action.type){
        case ActionTypes.SHOW_FOOTER:
            // console.log("\n\t toggleSidebarReducer: ", action.payload)
            store.showFooter = action.payload;
            return({
                ...store
            });
        default:
            return({
                ...store
            }) 
    }
};

export const toggleNavbarReducer = (store: StoreInterface, action: ToggleSidebarInterface) => {
    switch(action.type){
        case ActionTypes.TOGGLE_NAVBAR:
            // console.log("\n\t toggleSidebarReducer: ", action.payload)
            store.showNavbar = action.payload;
            return({
                ...store
            });
        default:
            return({
                ...store
            }) 
    }
};


export const callBackendReducer = (store: StoreInterface, action: CallBackendInterface) => {
    switch(action.type){
        case ActionTypes.CALL_BACKEND:
            // console.log("\n\t callBackendReducer: ", action.payload)
            store.callBackend = action.payload;
            return({
                ...store
            });
        default:
            return({
                ...store
            }) 
    }

};

export const showProcessingGifReducer = (store: StoreInterface, action: ShowProcessingGifInterface) => {
    switch(action.type){
        case ActionTypes.SHOW_PROCESSING_GIF:
            // console.log("\n\t showProcessingGifReducer: ", action.payload)
            store.showProcessingGif = action.payload;
            return({
                ...store
            });
        default:
            return({
                ...store
            }) 
    }

}

export const showFormModalReducer = (store: StoreInterface, action: ShowFormModalInterface) => {
    switch(action.type){
        case ActionTypes.SHOW_MODAL:
            // console.log("\n\t showFormModalReducer: ", action.payload)
            store.showFormModal = action.payload;
            return({
                ...store
            });
        default:
            return({
                ...store
            }) 
    }

}

export const showConfirmModalReducer = (store: StoreInterface, action: ShowConfirmModalInterface) => {
    switch(action.type){
        case ActionTypes.SHOW_CONFIRM_MODAL:
            // console.log("\n\t showConfirmModalReducer: ", action.payload)
            store.showConfirm = action.payload;
            return({
                ...store
            });
        default:
            return({
                ...store
            }) 
    }

}