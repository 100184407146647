import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import logouGif from "../../assets/img/logout-gif.gif";
import userDetailsAction from '../../redux/actions/storeActions/userDetailsAction';
import { clearStorageItem } from '../../utilities/localStorage';
interface Props {
    
}

const Logout = (props: Props) => {
    const [logoutMessage, setLogoutMessage] = useState("Logging out...");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        (async() => {
            await clearStorageItem()
        })();
        dispatch(userDetailsAction({
            userName: "", 
            shiftStatus: "",
            userType: "", 
            firstName: "", 
            lastName: "", 
            hotelDate: "",
            licenseDetails: {} as any, 
            email: "",
            companysLogo: "",
            companysAddress: "",
            companysEmail: ""
        }));
        setTimeout(() => {
            navigate('/');
        }, 7000);
    }, []);
    return (
        <div style={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center", flexDirection: "column"}} className="animate__animated animate__bounce second_gif_active_in_home_body">
            <img className="gif_style" src={logouGif} />
            <p className="text-white animate__animated animate__headShake animate__infinite">{logoutMessage}</p>
        </div>
    )
}

export default Logout;
