import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';

import "../../../../styles/RegCardStyles.css";
import { Link } from 'react-router-dom';
import IndividualRegCard from './IndividualRegCard';
import GroupRegCard from './GroupRegCard';
import { floorNumberHelper, getFrontdeskDetails, getReservationDetailsFromHistory } from '../../../../utilities/databaseHelpers';
import Gifs from '../../../Gifs';
import { FaChevronCircleLeft } from 'react-icons/fa';

interface Props {
    reservationDetails: any
    groupCheckInData: any
    setPrintRegCard: Function
    // hideRegCard: Function
    // setGroupCheckInData: Function
    // printGroupRegCard: boolean
    // setPrintGroupRegCard: Function
}

const RegCard = (props: Props) => {
    const reservationDetails = props.reservationDetails ? props.reservationDetails : "";
    const location = useLocation();
    const pathname = location.pathname;

    // const modulesData = useSelector((store: any) => store.modulesData.modulesData);
    const [regState, setRegState] = useState({
        reRender: false,
        emptyReg: false
    })
    useEffect(() => {
        if(pathname.includes("history") || pathname.includes("individual")){
            (async () => {
                // console.log("\n\t RegCard-reservationDetails: ", reservationDetails && reservationDetails)
                if(props.reservationDetails){
                    if(pathname.includes("history")){
                        const response = await getReservationDetailsFromHistory(props.reservationDetails._id)
                        // console.log("\n\t RegCard-reservationDetails-response: ", response)
                    }
                    const { roomNumber } = props.reservationDetails;
                    const floorNumber = await floorNumberHelper(roomNumber) as any;
                    // inject floor number into individuals' reservationdetails
                    // console.log("\n\t floorNumber: ", floorNumber)
                    props.reservationDetails['floorNumber'] = floorNumber;
                    setRegState({
                        ...regState,
                        reRender: true
                    })
                }
            })();
        };
    }, [regState.reRender]);
    const handleRegPrint = (action: string) => {
        if(action.toLowerCase().includes('empty')){
            setRegState({
                ...regState,
                emptyReg: true
            })
        }else{
            props.setPrintRegCard()
        }
       
    }
    return (
        <div style={{flexDirection: "column", marginTop: ".7rem"}} className="reg-body">
            {
                ["Close Reg. Card", "Empty"].map(action => (
                    <Link 
                        onClick={() => handleRegPrint(action)} 
                        to="#" 
                        key={action}
                        className={`text-center mx-2 mt-5 btn btn-${action.toLowerCase().includes('close') ? 'secondary' : 'danger'} print`}>
                            <FaChevronCircleLeft size={23} className="mx-2" />
                            {action}
                    </Link>
                ))
            }
            {
               ( pathname.includes('history') || pathname.includes('individual')) &&
                <div className="reg-header">
                    <div style={{flexDirection: "column"}} className="row d-flex align-items-center justify-content-center my-3">
                        <Gifs width={150} height={150} gifName='hotel-logo' />
                        <Gifs gifName='company-details' />
                        <p className="text-center font-weight-bold reg-card-title">Registration Card</p>
                    </div>
                </div>
            }
            {
               ( pathname.includes('history') || pathname.includes('individual')) ?
                <IndividualRegCard
                    reservationDetails={reservationDetails && reservationDetails}
                    emptyReg={regState.emptyReg}
                />
                :
                <GroupRegCard 
                    reservationDetails={reservationDetails && reservationDetails}
                    groupCheckInData={props.groupCheckInData && props.groupCheckInData}
                    setPrintRegCard={props.setPrintRegCard}
                    // setGroupCheckInData={props.setGroupCheckInData}
                />
            }
            <div className="text-center">
                <Link 
                    onClick={() =>  window.print()} 
                    to="#" 
                    className="text-center mx-2 btn btn-secondary print">
                        Print
                </Link>
            </div>

        </div>
    )
}

export default RegCard
