import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { toggleNavbarAction } from '../../../../../../redux/actions/storeActions/appActionsUtilities';
import Modal from '../../../../Modal';

import "../../../../../../styles/EndOfDayStyles.css";
import axiosHelper from '../../../../../../utilities/axiosHelper';
import { useLocation } from 'react-router';
import EndOfDayComputations from './EndOfDayComputations';
interface Props {

}

const EndOfDay = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;

    const [endOfDayState, setEndOfDayState] = useState({
        runningTimeWarning: "",
        processMessage: "",
        finishStatus: "",
        serverResponse: {}as any
    });
    useEffect(() => {
        (async () => {
            try {
                const endOfDayResponse = await axiosHelper({
                    routeName: pathname,
                    httpVerb: 'get'
                });
                console.log("\n\t useEffect-EndOfDay-endOfDayResponse-data: ", endOfDayResponse);
                if(endOfDayResponse){
                    endOfDayState.serverResponse = endOfDayResponse;
                    // dispatch(toggleNavbarAction(false)); 
                    setEndOfDayState({
                        ...endOfDayState,
                        serverResponse: endOfDayResponse,
                        runningTimeWarning: "",
                    });
                } 
                // setTimeout(() => {
                // }, 10000);
            } catch (error) {
                console.log("\n\t EndOfDay-error: ", error);
                setEndOfDayState({
                    ...endOfDayState,
                    runningTimeWarning: ''
                });
            }
        })();
    }, []);
    // console.log("\n\t EndOfDay-endOfDayState: ", endOfDayState);
    return (
        <div className="end-of-day-container bg-black">
            <div>
                {
                    endOfDayState.runningTimeWarning ?
                    <div style={{height: "100vh", width: "100%", backgroundColor: "#000"}} className="gif-container">
                        <div className="row mt-5"></div>
                        <div className="row mt-2"></div>
                        <div className="d-flex row justify-content-center align-items">
                            <img src={require("../../../../../../assets/img/end-of-day3.gif")} />
                            <span className="text-center text-white loading-text">{endOfDayState.runningTimeWarning}</span>
                        </div>
                    </div>
                    :
                    <EndOfDayComputations
                        serverResponse={endOfDayState.serverResponse}
                    />
                }
            </div>
        </div>
    )
}

export default EndOfDay