import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import GroupReservation from '../../Forms/GroupReservation';
import IndividualReservation from '../../Forms/IndividualReservation';
import ModuleSummary from '../ModuleSummary/ModuleSummary';
import { validate } from 'node-cron';
import FrontdeskTable from '../Tables/FrontdeskTable';
import { titleCase } from '../../../../utilities/strings';
import StoreTable from '../Tables/StoreTable';
import RecordsTable from '../Tables/RecordsTable';

interface Props {
    summaryHeader: any[]
    tableData: []
};



const StoreDashboard = (props: Props) => {
    const location = useLocation()
    const pathname = location.pathname;
    console.log("\n\t StoreDashboard-summaryHeader: ", props.summaryHeader)
    return (
        <div>
            {
                pathname === "/store" ?
                <div>
                    <ModuleSummary dashboardName="Store" />
                </div>
                :
                pathname.includes('records') ?
                <div style={{flexDirection: "column", width: "85vw"}} className='d-flex'>
                    <RecordsTable />
                </div>
                :
                <StoreTable />
            }
        </div>
    )
}

export default StoreDashboard
