

/**
 * set up groups room types and rates
 * @param groupValues 
 * @param groupReservationDetails 
 * @param groupRateValues 
 * @param roomTypes 
 * @returns 
 */
const groupReservationDataCleaner = (groupValues: any, groupRateValues: any, roomTypes: any, inputtedGroupName?: string) => {
    const groupNames: string[] = []
    for(let group of groupValues){
        groupNames.push((group as any).groupName)
    };
    console.log("\n\t groupNames: ", groupNames)
    console.log("\n\t inputtedGroupName: ", inputtedGroupName)
    const inputtedGroup = inputtedGroupName ? inputtedGroupName : (document as any).querySelector('.group').value;
    if(!groupNames.includes(inputtedGroup)){ 
        // (document as any).querySelector('.number-of-guests').innerHTML = "";
        return 'The inputted group is not registerd on the system. Either register the group or enter a recognised group name, before selecting the number of guests.' 
    }
    let index = 0
    let inputtedGroupProps: any = '';
    for(let group of groupValues){
        // console.log("\n\t GroupRt: ", inputtedGroup, group)
        if((group as any).groupName === inputtedGroup){
            inputtedGroupProps = group
        }
    };
    // console.log("\n\t roomTypes: ", roomTypes)
    const reqData = [];
    for(let gRate of groupRateValues){
        if((gRate as any).groupId === inputtedGroupProps._id){
            const roomTypeProps = Object.values(roomTypes).filter((roomType: any) => roomType._id === (gRate as any).roomTypeId)
            // console.log(`\n\t roomTypeName:${index} `, roomTypeProps);
            if(roomTypeProps.length === 0) {
                return "You need to set room type rates for this group.";
            }
            let data= {
                roomTypeName: (roomTypeProps as any)[0].roomTypeName,
                groupRate: (gRate as any).rate + (gRate as any).breakfast
            }
            reqData.push(data);
        }
        index++
    }
    return reqData;
}


export default groupReservationDataCleaner;