
const greeter = () => {
    const currentTime = new Date().toTimeString();
    const stringifiedTime = currentTime.split(" ")[0];
    const hourTime = stringifiedTime.split(":")[0];
    // console.log("\n\t currentTime: ", typeof(hourTime))
    if(+hourTime < 12){
        return "Good morning";
    }else if(+hourTime >= 12 && +hourTime <= 16){
        return "Good afternoon";
    }
    return "Good evening";
}


export default greeter;
