import { reservationSidebarLinks, frontdeskSidebarNavlinks, reportsSidebarNavlinks, settingsSidebarNavlinks, backTrackNavlinks } from "../components/containers/Modules/ModuleSidebar/SidebarNavLinks"


const pathResolver = (pathSegments: string[], pathname: string, navigate: Function, mainModule: string) => {
    const splittedPathname = pathname.split("/");
    const splittedSidebarLink = [];
    if(pathname.includes("settings")){
        for(let path of settingsSidebarNavlinks){
            splittedSidebarLink.push(path.toLowerCase().replaceAll(" ", "-"))
        }
        if(splittedSidebarLink.includes(splittedPathname[splittedPathname.length-1])){
            navigate(`settings/${splittedPathname[splittedPathname.length-1]}`)
        }else if(splittedPathname.length > 2){
            navigate(`settings/${splittedPathname[splittedPathname.length-2]}/${splittedPathname[splittedPathname.length-1]}`)
        }
    }else if(pathname.split("/")[1].includes("reservations")){
        for(let path of reservationSidebarLinks){
            splittedSidebarLink.push(path.toLowerCase().replaceAll(" ", "-"))
        }
        if(splittedSidebarLink.includes(splittedPathname[splittedPathname.length-1])){
            navigate(`reservations/${splittedPathname[splittedPathname.length-1]}`)
        }else if(splittedPathname.length > 2){
            navigate(`reservations/${splittedPathname[splittedPathname.length-2]}/${splittedPathname[splittedPathname.length-1]}`)
        }
    }else if(pathname.includes("audit-reports")){
        for(let path of reportsSidebarNavlinks){
            if(path.includes("'")){
                const newPath = path.replace("'", "")
                splittedSidebarLink.push(newPath.toLowerCase().replaceAll(" ", "-"))
            }else{
                splittedSidebarLink.push(path.toLowerCase().replaceAll(" ", "-"))
            }
        }
        if(splittedSidebarLink.includes(splittedPathname[splittedPathname.length-1])){
            navigate(`audit-reports/${splittedPathname[splittedPathname.length-1]}`)
        }else if(splittedPathname.length > 2){
            navigate(`audit-reports/${splittedPathname[splittedPathname.length-2]}/${splittedPathname[splittedPathname.length-1]}`)
        }
    }else if(pathname.includes("frontdesk")){
        // console.log("\n\t Fdesk:splittedPathname ", splittedPathname)
        // console.log("\n\t Fdesk:pathSegments ", pathSegments)
        for(let path of frontdeskSidebarNavlinks){
            splittedSidebarLink.push(path.toLowerCase().replaceAll(" ", "-"))
        }
        if(splittedSidebarLink.includes(splittedPathname[splittedPathname.length-1])){
            navigate(`frontdesk/${splittedPathname[splittedPathname.length-1]}`)
        }else if(splittedPathname.length > 2){
            navigate(`frontdesk/${splittedPathname[splittedPathname.length-2]}/${splittedPathname[splittedPathname.length-1]}`)
        }
        // console.log("\n\t Fdesk: splittedSidebarLink", splittedSidebarLink)
    }else if(pathname.includes("backtrack")){
        // console.log("\n\t Fdesk:splittedPathname ", splittedPathname)
        // console.log("\n\t Fdesk:pathSegments ", pathSegments)
        for(let path of backTrackNavlinks){
            splittedSidebarLink.push(path.toLowerCase().replaceAll(" ", "-"))
        }
        if(splittedSidebarLink.includes(splittedPathname[splittedPathname.length-1])){
            navigate(`backtrack/${splittedPathname[splittedPathname.length-1]}`)
        }else if(splittedPathname.length > 2){
            navigate(`backtrack/${splittedPathname[splittedPathname.length-2]}/${splittedPathname[splittedPathname.length-1]}`)
        }
        // console.log("\n\t Fdesk: splittedSidebarLink", splittedSidebarLink)
    }
    // console.log("\n\t splittedSidebarLink: ", splittedSidebarLink as any);
}

export default pathResolver;