import RecordsTable from '../Tables/RecordsTable';

interface Props {
    summaryHeader: any[]
    tableData: any[]
};



const RecordsDashboard = (props: Props) => {
    // console.log("\n\t RecordsDashboard-pathname includes records: ", pathname.toLowerCase().includes("records"))
    return (
        <div style={{marginLeft: "2rem"}} className="row d-flex">
            <RecordsTable />
        </div>
    )
}

export default RecordsDashboard;
