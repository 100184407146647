import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import "../../../../styles/RegCardStyles.css";
import { givenDateAndCurrentTime } from 'date-fran';

interface Props {
    reservationDetails?: any
    emptyReg: boolean
}

const IndividualRegCard = (props: Props) => {
    const paymentMethod = ["Cash", "Transfer", "Card", "Company"];
    const userDetails = useSelector((store: any) => store.userDetails)
    const { firstName, lastName } = userDetails && userDetails.userDetails;
    // const pathname = useLocation().pathname;
    console.log("\n\t Ind-Reg-reservationDetails: ", props.reservationDetails)
    return (
        <div className="row d-flex justify-content-center individual-reg-container">
            <div style={{ width: "100%"}} className="">
                <table className="text-center table table-responsive reg-table" id="" >
                    {
                        props.reservationDetails &&
                        <tbody className="">
                            <tr className="">
                                <th className="header-titles py-3" colSpan={8}>Guest Details</th>
                            </tr>
                            <tr className="text-center">
                                <td colSpan={1} className="table-title">
                                    Title
                                </td>
                                <td colSpan={1}>
                                    {props.emptyReg ? "" : props.reservationDetails.title || props.reservationDetails.guestName ? props.reservationDetails.guestName.split(" ")[0] : props.reservationDetails.fullname.split(" ")[0]} 
                                </td>
                                <td className="table-title">
                                    Firstname
                                </td>
                                <td colSpan={1}>
                                {props.emptyReg ? "" : props.reservationDetails["Firstname"] || props.reservationDetails.guestName ? props.reservationDetails.guestName.split(" ")[1] : props.reservationDetails.firstName || props.reservationDetails.fullname.split(" ")[1]}
                                </td>
                                <td className="table-title">
                                    Lastname
                                </td>
                                <td colSpan={2}>
                                    {props.emptyReg ? "" : props.reservationDetails["Lastname"] || props.reservationDetails.guestName ? props.reservationDetails.guestName.split(" ")[2] : props.reservationDetails.fullname.split(" ")[2]}
                                </td>
                            </tr>
                            <tr>
                                <td className="table-title">
                                    Phone
                                </td>
                                <td colSpan={1}>
                                    {props.emptyReg ? "" : props.reservationDetails.guestPhone || props.reservationDetails.phoneNumber}
                                </td>
                                <td className="table-title">
                                    Membership Type
                                </td>
                                <td colSpan={1}>
                                    {props.emptyReg ? "Ignore" : props.reservationDetails.membershipType}
                                </td>
                                <td className="table-title">
                                    Nationality
                                </td>
                                <td colSpan={2}>
                                    {props.emptyReg ? "" : props.reservationDetails.nationality}
                                </td>
                            </tr>
                            <tr>
                                <th className="header-titles text-center" colSpan={8}>Dates</th>
                            </tr>
                            <tr className="text-center">
                                <td colSpan={0} className="table-title">
                                    Arrival Date
                                </td>
                                <td colSpan={1}>
                                    {props.emptyReg ? "" : new Date(props.reservationDetails.checkedInDate || props.reservationDetails.arrivalDate).toDateString()}, {(givenDateAndCurrentTime()as any)['currentTime']} 
                                </td>
                                <td colSpan={0} className="table-title">
                                    Departure Date
                                </td>
                                <td colSpan={1}>
                                    {props.emptyReg ? "" : new Date(props.reservationDetails.checkOutDate || props.reservationDetails.departureDate).toDateString()}, {(givenDateAndCurrentTime()as any)['currentTime']} 
                                </td>
                                <td colSpan={0} className="table-title">
                                    Nights
                                </td>
                                <td colSpan={1}>
                                    {props.emptyReg ? "Ignore" : props.reservationDetails.nights} 
                                </td>
                            </tr>
                            <tr>
                                <th className="header-titles text-center" colSpan={8}>Room Details</th>
                            </tr>
                            <tr className="text-center">
                                <td className="table-title">
                                    Room Type
                                </td>
                                <td colSpan={2}>
                                    {props.emptyReg ? "Ignore" : props.reservationDetails.roomType || "--"}
                                </td>
                                <td className="table-title">
                                    Room Rate
                                </td>
                                <td colSpan={2}>
                                    {props.emptyReg ? "Ignore" : props.reservationDetails.totalCost ? props.reservationDetails.totalCost.toLocaleString() : "--"}
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className="table-title">
                                    Floor Number
                                </td>
                                <td colSpan={2}>
                                    {props.emptyReg ? "Ignore" : props.reservationDetails.floor || props.reservationDetails.floorNumber || "--"}
                                </td>
                                <td className="table-title">
                                    Room Number
                                </td>
                                <td colSpan={2}>
                                    {props.emptyReg ? "Ignore" : props.reservationDetails.roomNumber || "--"}
                                </td>

                            </tr>
                            <tr>
                                <th className="header-titles text-center" colSpan={8}>Other Details</th>
                            </tr>
                            <tr className="text-center">
                                <td className="table-title">
                                    E-mail
                                </td>
                                <td colSpan={3}>
                                    {props.reservationDetails.email || "--"}
                                </td>
                                <td className="table-title">
                                    Passport Number
                                </td>
                                <td colSpan={0}>
                                    {props.reservationDetails.passportNumber || "--"}
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className="table-title">
                                    Country
                                </td>
                                <td colSpan={2}>
                                    {props.reservationDetails.country || "--"}
                                </td>
                                <td className="table-title">
                                    Company
                                </td>
                                <td colSpan={2}>
                                    {props.reservationDetails.company || "--"}
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td className="table-title">
                                    Deposit
                                </td>
                                <td colSpan={6}>
                                    {props.emptyReg ? "Ignore" : props.reservationDetails.deposit ? props.reservationDetails.deposit.toLocaleString() : "--"}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center"  colSpan={6}> <strong>N.B: checkout time is 11:00 a.m</strong> </td>
                            </tr> 
                            
                        </tbody>
                    }
                    
                </table>
                <div className="" id="" >
                    <section style={{flexDirection: "column", width: "100%"}} className="d-flex align-items-center justify-content-center">
                        <article style={{flexDirection: "row", display: "flex", alignItems: "center"}}>
                            <span className="table-title mx-3">Payment Method:</span>
                            <span className="row">
                                {
                                    paymentMethod.map(method => (
                                        <span className="mx-3" key={method}>
                                            <input style={{display: "flex", justifyContent: "center", width: "100%"}} type="checkbox" /> 
                                            {method}
                                        </span>
                                    ))
                                }
                            </span>
                        </article>
                    </section>
                    
                    <section>
                        <article className="row justify-content-center mt-4">
                            <span className="col-md-4 text-center">
                                <span className="text-center">Frontdesk Name:</span> <br />
                                <span style={{textDecoration: "underline", }} className='mt-2'>{firstName && firstName.concat(" ", lastName)}</span>
                            </span>
                        </article>
                        <article className="row justify-content-center mt-4">
                            <span className="col-md-4">
                                <p className="text-center">Guest Signature:</p>
                                <hr className="confirm_modal_hr mt-5" />
                            </span>

                            <span className="col-md-4">
                                <p className="text-center">Frontdesk Signature:</p>
                                <hr className="confirm_modal_hr mt-5" />
                            </span>
                        </article>
                            <article className="my-3 text-center mt-3">
                                <span >Date: {new Date().toDateString()}</span> <br />
                                <span >Time: {new Date().toLocaleTimeString()}</span>
                            </article>
                            <article className="my-3 text-center mt-3">
                                <span>
                                    <h6>Enjoy Your Stay with us!</h6>
                                </span>
                            </article>
                                <h6>
                                    {/* <%= data.companyDetails.companyName %> */}
                                </h6>
                                <span>
                                    {/* <%= data.companyDetails.companyAddress %> */}
                                </span>
                                <span> 
                                    {/* Tel: */}
                                    {/* <%= data.companyDetails.companyPhoneNumberOne %>,
                                        <%= data.companyDetails.companyPhoneNumberTwo %> &nbsp; Email:
                                            <%= data.companyDetails.companyEmail %> */}
                                </span>
                                <span>
                                    {/* <%= data.companyDetails.companyWebsite %> */}
                                </span>

                        {/* </tfoot> */}
                    </section>
                </div>
            </div>
        </div>
    )
}

export default IndividualRegCard
