import { useLocation } from 'react-router-dom';
import ModuleSummary from '../ModuleSummary/ModuleSummary';
import HouseKeepingTable from '../Tables/HouseKeepingTable';

interface Props {
    summaryHeader: any[]
    tableData: any[]
};



const HouseKeepingDashboard = (props: Props) => {
    const location = useLocation()
    const pathname = location.pathname;
    // console.log("\n\t HouseKeepingDashboard-summaryHeader: ", props.summaryHeader)
    console.log("\n\t HouseKeepingDashboard-pathname includes HouseKeeping: ", pathname.toLowerCase().includes("HouseKeeping"))
    return (
        <div>
            {
                pathname ===  "/house-keeping" ?
                <ModuleSummary dashboardName="House-Keeping"  />
                :
                <div style={{marginLeft: "2rem"}} className="row d-flex">
                    <HouseKeepingTable />
                </div>
            }
        </div>
    )
}

export default HouseKeepingDashboard
