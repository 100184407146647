import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import colors from '../../../../assets/colors'
import { callBackendAction } from '../../../../redux/actions/storeActions/appActionsUtilities'
import { handleSubMenus, settingsSubMenus } from './SidebarNavLinks'

interface Props {
    navLinks: string[]
}

export const settingsNonHiddenOptions = ["licenses", "communications", "facilities", "companies",]
const SettingsSidebar = (props: Props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const pathname = location.pathname;
    const store = useSelector((store: any) => store);
    const userDetails = store.userDetails;
    const userData = userDetails ? userDetails.userDetails : undefined;
    const userType = userData ? userData.userType : "";

    // const origin = window.location.origin;
    // const applicationDomain = origin.split("//")[1];
    const restrict = !userType.includes(process.env.REACT_APP_COMPANY_DEVELOPER_USER_TYPE) ? true : false;
    // console.log("\n\t userData: ", userData)
    // console.log("\n\t process.env.REACT_APP_COMPANY_DEVELOPER_USER_TYPE: ", process.env.REACT_APP_COMPANY_DEVELOPER_USER_TYPE)
    // const restrictedLinks = [ "Companies", "Licenses"];
    const [settingsMenu, setSettingsMenu] = useState({
        toggleMenuState: {}as any
    })
    useEffect(() => {
        pathname !== "/settings" && dispatch(callBackendAction(false));
        props.navLinks.map(link => {
            settingsMenu.toggleMenuState[link] = false;
        })
        setSettingsMenu({...settingsMenu})
    }, []);
    const handleStateSubMenus = (toggledLink: string) => {
        handleSubMenus({
            state: settingsMenu,
            stateSetter: setSettingsMenu,
            toggledLink
        })
    };
    return (
        <div style={{height: "auto"}} className=''>
            <NavLink style={{color: colors.ash}} className={`nav-link d-flex`} to="/settings">
                <strong>Settings</strong>
            </NavLink>
            <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />

            {
                userData != undefined &&
                props.navLinks && props.navLinks.slice(userType.toLowerCase().includes("f&b manager") ? 2 : 0, userType.toLowerCase().includes("f&b manager") ? 4 : restrict ? 11 : undefined).map((link, index) => (
                    <div className="" key={link}>
                        <NavLink 
                            style={{flexDirection: "column"}}  
                            className={`nav-link collapsed d-flex`} data-toggle="collapse" data-target={`#${link.toLowerCase().replaceAll(" ", "-")}`} aria-expanded="false" aria-controls="accounts"
                            to={settingsNonHiddenOptions.includes(link.toLowerCase()) ? `${pathname}/${link.toLowerCase().replaceAll(" ", "-")}` : "#"}
                            key={link}
                            onClick={() => handleStateSubMenus(link)}
                            >
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}} >
                                <span style={{letterSpacing: 2}} className="link_hover">{link}</span>
                                {
                                    !settingsNonHiddenOptions.includes(link.toLowerCase()) &&
                                    <span>
                                        {
                                            !settingsMenu.toggleMenuState[link] ?
                                            <FaAngleRight size={25} color={colors.appYellow}  />
                                            :
                                            <FaAngleDown size={25} color={colors.appYellow}  />
                                        }
                                    </span>
                                }
                            </div>
                        </NavLink>
                        <div className={`${!settingsMenu.toggleMenuState[link] ? "collapse" : undefined} ml-3`} id={`${link.toLowerCase().replaceAll(" ", "-")}`} aria-labelledby="headingOne" >
                            <nav className="sb-sidenav-menu-nested nav">
                                {
                                    Object.entries(settingsSubMenus).map((entery, secIndex: number) => (
                                        <span className="px-2" style={{width: "100%"}} key={secIndex}>
                                            {
                                                entery[0].toLowerCase() === link.toLowerCase() && 
                                                <span>
                                                    {
                                                        entery[1].map(value => (
                                                            <Link 
                                                                key={value}
                                                                style={{color: colors.appYellow, flexDirection: "column", textDecoration: "none", fontSize: value.toLowerCase().includes('licenses') ? "15px" : undefined}} 
                                                                className="" 
                                                                to={`${pathname}/${link.toLowerCase().replaceAll(" ", "-")}/${value.toLowerCase().replaceAll(" ", "-")}`}>
                                                                {value}
                                                                <hr style={{width: "100%", backgroundColor: colors.warning}} />
                                                            </Link>
                                                        ))
                                                    }
                                                </span>
                                            }
                                        </span>
                                    ))
                                }
                            </nav>
                        </div>
                        <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
                    </div>
                ))
            }
        </div>
    )
}

export default SettingsSidebar;
