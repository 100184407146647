import { givenDateAndCurrentTime } from "date-fran";
import { showConfirmModalAction, showFormModalAction } from "../../../../../redux/actions/storeActions/appActionsUtilities";
import axiosHelper from "../../../../../utilities/axiosHelper";
import { todaysDate } from "../../../../../utilities/dateHelper";
import { removeItemFromArray } from "../../../../../utilities/strings";
import { auditReceiptsTableHeader, depositsTableHeader, endOfShiftTableHeader, financialTransactionsTableHeader, foliosTableHeader, groupReservationTableHeader, individualReservationTableHeader, individualsCheckedInTableHeader, restaurantTableHeader, roomStatusTableHeader, storeItemsTableHeader, storeOutletsTableHeader, userLogsTableHeader } from "../tableData";

interface DetailsActionInterface {
    menu: string,
    dispatch: Function
    detailsState: any
    setDetailsState: Function
}

export const detailsActionsHandler = (detailsProps: DetailsActionInterface) => {
    switch(detailsProps.menu){
        case detailsProps.menu = "Check-Out":
            detailsProps.dispatch(showConfirmModalAction(true));
            detailsProps.dispatch(showFormModalAction(false));
            detailsProps.setDetailsState({
                ...detailsProps.detailsState,
                confirmModalTitle: "Proceed with checking guest out?"
            })    
            console.log("\n\t Choice: detailsActionsHandler-check-out: ", detailsProps.detailsState)
                return 
        case detailsProps.menu = "Change Room":
            console.log("\n\t Choice: detailsActionsHandler-Change Room: ", detailsProps.detailsState)
            detailsProps.dispatch(showConfirmModalAction(true));
            detailsProps.dispatch(showFormModalAction(false));
            detailsProps.setDetailsState({
                ...detailsProps.detailsState,
                confirmModalTitle: "Proceed with checking guest out?"
            })    
                return 
        default:
            return detailsProps.setDetailsState({
                ...detailsProps.detailsState,
            })  
    }
}


export const houseKeepingStylist = (pathname: string, dataObject: any, propIndex: number) => {
    // console.log("\n\t houseKeepingStylist..............")
    if(pathname.toLowerCase().includes("room")){
        let hKStatus = dataObject['houseKeepingStatus'];
        let occupancyStatus = dataObject['occupancyStatus'];
        if(roomStatusTableHeader[propIndex].toLowerCase().includes('occupancy') || roomStatusTableHeader[propIndex].toLowerCase().includes('house keeping')){
            if(hKStatus === "Clean" && occupancyStatus === "Occupied"){
                return 'bg-success';
            }
        }
        if(roomStatusTableHeader[propIndex].toLowerCase().includes('house keeping')){
            if(hKStatus === "Dirty"){
                return 'bg-danger';
            }if(hKStatus === "Clean"){
                return 'bg-success';
            }if(hKStatus === "Out-of-order"){
                return 'bg-dark';
            }
        }if(roomStatusTableHeader[propIndex].toLowerCase().includes('occupancy')){
            if(hKStatus === "Dirty" && occupancyStatus === "Occupied"){
                return 'bg-success';
            }else{
                return 'bg-white'
            }
        }
    }else if(pathname.includes('shifts')){
        const shiftDate = dataObject["shiftDate"];
        const status = dataObject["status"];
        // console.log("\n\t shiftDate: ", shiftDate)
        if(shiftDate === todaysDate().toDateString() && status.toLowerCase() === "completed"){
            return 'bg-success text-light'
        }else if(shiftDate === todaysDate().toDateString() && status.toLowerCase() === "on-going"){
            return 'bg-warning text-black'
        }
    }
};



export const classNameForDepartures = (data: any) => {
    const stringifiedDataCheckOutDate = data["checkOutDate"];
    // console.log("\n\t stringifiedDataCheckOutDate: ", stringifiedDataCheckOutDate)
    const dateifiedDataCheckOutDate = stringifiedDataCheckOutDate ? givenDateAndCurrentTime(stringifiedDataCheckOutDate.split("T")[0]) : todaysDate();
    // console.log("\n\t dateifiedDataCheckOutDate: ", dateifiedDataCheckOutDate)
    // console.log("\n\t todaysDate: ", todaysDate())
    // console.log("\n\t dateifiedDataCheckOutDate.toDateString() === todaysDate().toDateString(): ", dateifiedDataCheckOutDate.toDateString() === todaysDate().toDateString())
    if(dateifiedDataCheckOutDate.toDateString() === todaysDate().toDateString()){
        return `animate__animated animate__infinite animate__flash animate__slower 5s text-dark bg-white`
    }
}



interface ReportsInterface {
    setReportsData: Function,
    data: any
    reportsData: any
    pathname: string
}

export const showReportDetails = async(props: ReportsInterface) => {
    // console.log("\n\t Details data: ", props.data)
    let requiredData = [] as any[]
    if(props.pathname.includes('guest-ledger')){
        const folioDetails = await axiosHelper({
            httpVerb: 'get',
            routeName: `${props.pathname}/get-folios/${props.data['_id']}`,
        });
        folioDetails.data.forEach((data: any) => {
            console.log("\n\t Data: ", data);
            requiredData.push(data)
        })
        // console.log("\n\t Folio details data: ", folioDetails.data)
    }
    // console.log("\n\t requiredData: ", requiredData)
    props.setReportsData({
        ...props.reportsData,
        detailsData: requiredData,
        showRowDetails: true
    })
    return requiredData
};


interface StoreActionInterface {
    action: string
    storeState: any, 
    setStoreState: Function
    dataToModify: any
    pathname: string
}

export const handleStoreActions = (props: StoreActionInterface) => {
    console.log("\n\t handleStoreActions: ", props.action)
    let action = props.action
    props.storeState.dataToModifyId = props.dataToModify._id;
    // props.storeState.formInputs = props.dataToModify || [];
    if(action.toLowerCase().includes("delete")){
        props.storeState.formFields = ["Username", "Password"];
        props.storeState.formFieldType = ["text", "password"];
        props.storeState.previewData = false;
        props.storeState.formTitle = `Deleting this store section will remove all store-items under this section. Are you sure about deleting this store ${props.pathname.includes("sections") ? "section" : "item"}?`
    }else if(props.pathname.includes("sections")){
        props.storeState.formFields = ["Section Code", "Description"];
        props.storeState.formFieldType = ["number", "text"];
        props.storeState.placeholders = ["Four digit code (Begin all codes with 19)", "Toiletries"];
        props.storeState.formTitle = (action.toLowerCase().includes('edit') ? "Edit" : "Create").concat(" ", "Store Section");
       
    }else if(props.pathname.includes('items')){
        props.storeState.formTitle = action.toLowerCase().includes('stock') ? "Stock Outlet" : (action.toLowerCase().includes('edit') ? "Edit" : "Create").concat(" ", "Store Item");
        if(action.toLowerCase().includes("stock")){
            // props.storeState.formInputs = props.dataToModify || [];
            props.storeState.formFields = ["Outlet", "Quantity", "Collected By"];
            props.storeState.formFieldType = ["select", "number", "select"];
            props.storeState.placeholders = ["", `How many pieces of ${props.dataToModify.itemName} are you sending to the outlet?`];
        }else{
            const formFields = ["Section", "Item Name", "Price-per unit", "Restock Limit", "Quantity", "Signature Of Acquisition"];
            props.storeState.formFields = formFields.slice(0, action.toLowerCase().includes('edit') ? 5 : undefined)
            props.storeState.formFieldType = ["select", "text", 'number', "number", "number", "text"];
            props.storeState.placeholders = ["", "Toiletries", "", "", "", "Who authorized the stock?"];
        }
    };
    return props.setStoreState({
        ...props.storeState
    })
}




interface PaginatorInterface {
    state: any
    stateHandler: Function
    paginationBtnName: string
}

export const handleDataPagination = async(props: PaginatorInterface) => {
    switch(props.paginationBtnName){
        case "Next":
            const start = props.state.paginationEndPoint;
            const end = props.state.paginationEndPoint += props.state.paginationLength;
            return props.stateHandler({
                ...props.state, 
                paginatedTableData: props.state.tableData.slice(start, end), 
                paginationStartPoint: start, 
                paginationEndPoint: end
            })
        case "Previous":
            const startPrevious = props.state.paginationStartPoint -= props.state.paginationLength;
            const endPrevious = props.state.paginationEndPoint -= props.state.paginationLength;
            return props.stateHandler({
                ...props.state, 
                paginatedTableData: props.state.tableData.slice(startPrevious, endPrevious), 
                paginationStartPoint: startPrevious, 
                paginationEndPoint: endPrevious
            })
        }
};


export const financialTransactionsRecords = (allFinancialTransactions: any[], pathname: string) => {
    // for(let dailyTransactions of allFinancialTransactions.slice(3, 9)){
    const transactionInDailyTransactions = []as any[]
    for(let dailyTransactions of allFinancialTransactions){
        // console.log("\n\t transactionInDailyTransactions: ", transactionInDailyTransactions)
        for(let transaction of dailyTransactions){
            if(transaction){
                // console.log("\n\t transactionInDailyTransactions: ", transaction)
                const { credit, debit } = transaction
                if((pathname.includes("credits") || pathname.includes("cashiers")) && credit > 0){
                    transactionInDailyTransactions.push(transaction);
                }else if(pathname.includes("charges") && debit > 0){
                    transactionInDailyTransactions.push(transaction);
                }else if(pathname.includes("transactions")){
                    transactionInDailyTransactions.push(transaction);
                }
            }
        };
    };
    // console.log("\n\t Trans: ", trans)
    return transactionInDailyTransactions;
};

export const trialBalanceRecords = (allTrialBalance: any[]) => {
    const trialBalanceData = []as any[]
    for(let dailyTrialBalance of allTrialBalance){
        console.log("\n\t dailyTrialBalance: ", dailyTrialBalance)
        if(dailyTrialBalance.length > 0){
            // console.log("\n\t dailyTrialBalance: ", dailyTrialBalance.trialBalance)
            trialBalanceData.push(dailyTrialBalance[0])
        }
    };
    return trialBalanceData;
}

export const managersFlashRecord = (endOfDayManagersFlash: any[]) => {
    const requiredManagersFlash = [] as any;
    for(let flash of endOfDayManagersFlash){
        console.log("\n\t flash.managersFlash...", flash)
        if(flash.length > 0){
            requiredManagersFlash.push(flash[0])
        }
    };
    console.log("\n\t requiredManagersFlash: ", requiredManagersFlash)
    return requiredManagersFlash
};


interface RecordsFunctionInterface {
    pathname: string
    recordsState: any
    // tableData: any
    // allRecords: any
    // dataIndex: number, 
    data: any
    recordDate: string
    setRecordsState: Function
}
export const handleRecordDetails = (props: RecordsFunctionInterface) => {
    // console.log("\n\t tableData: ", props.tableData)
    // console.log("\n\t data: ", props.data)
    // console.log("\n\t recordsState: ", props.recordsState)

    try{
        const detailsHeader = 
        (props.pathname.includes('transactions') || props.pathname.includes('charges') || props.pathname.includes('credits')) ? removeItemFromArray(financialTransactionsTableHeader, props.pathname.includes('charges') ? 4 : props.pathname.includes('credits') ? 3 : 0) :
        props.pathname.includes('folios') ? foliosTableHeader :
        props.pathname.includes('receipts') ? auditReceiptsTableHeader :
        props.pathname.includes('deposits') ? depositsTableHeader :
        props.pathname.includes('individual-reservations') ? individualReservationTableHeader.slice(0, 13) :
        props.pathname.includes('individual-in-house') ? [...individualsCheckedInTableHeader.slice(0, 14), "Balance"] :
        props.pathname.includes('store/records/items') ? storeItemsTableHeader.slice(0, 12) :
        props.pathname.includes('store/records/outlets') ? storeOutletsTableHeader :
        props.pathname.includes('logs') ? userLogsTableHeader :
        groupReservationTableHeader.slice(0, 9);
        // props.pathname.includes('shift-sales') ? endOfShiftTableHeader :
        // props.pathname.includes('daily-sales') ? restaurantTableHeader.slice(0, 11) :[""];
        
        props.recordsState.summedDebit =0;
        props.recordsState.summedCredit =0;
        props.recordsState.summedVAT =0;
        props.recordsState.summedServiceCharge =0;
        props.recordsState.summedConsumptionCharge =0;
        props.recordsState.summedDepositAmount =0;
        props.recordsState.summedDepositAmount = 0;
        props.recordsState.summedReceiptTotalAmount = 0;
        props.recordsState.summedFAndBTotalAmount = 0;
        props.recordsState.summedFAndBBalanceAmount = 0;
        props.recordsState.summedReceiptActualAmountPaid = 0;
        props.recordsState.summedNetCharge = 0;
        const detailsDataForManagersFlash = [] as any;
        const detailsDataForTrialBalance = [] as any;

        if(props.pathname.includes('folios') || props.pathname.includes('credits') || props.pathname.includes('charges') || props.pathname.includes('transaction')){
            (props.data as any).map((data: any) => {
                // console.log("\n\t Data: ", data)
                props.recordsState.summedDebit += data.debit;
                props.recordsState.summedCredit += data.credit;
                props.recordsState.summedVAT += data.vat;
                props.recordsState.summedServiceCharge += data.serviceCharge;
                props.recordsState.summedConsumptionCharge += data.consumptionCharge;
                props.recordsState.summedNetCharge += data.netCharge;
                props.recordsState.tableTitle = `${props.pathname.includes('folios') ? "Genrated Folios" : props.pathname.includes('charges') ? "Charges (Revenues)" : props.pathname.includes('transaction') ? "Financial Transactions" : "Credits (Income)"} For: ${props.recordDate}`
            });
        }else if(props.pathname.includes('deposits')){
            (props.data as any).map((data: any, index:number) => {
                props.recordsState.summedDepositAmount += data.amount;
                if(index == 0){
                    props.recordsState.tableTitle = `Deposits for: ${props.recordDate}`
                }
            });
        }else if(props.pathname.includes('receipts')){
            (props.data as any).map((data: any, index:number) => {
                props.recordsState.summedReceiptTotalAmount += data.totalCost;
                props.recordsState.summedReceiptActualAmountPaid += data.amountPaid;
                if(index == 0){
                    props.recordsState.tableTitle = `Receipts for: ${props.recordDate}`
                }
            });
        }else if(props.pathname.includes('flash')){
            const managersFlashData = (props.data as any).managersFlashData;
            const referenceDate = managersFlashData.referenceDate;
            // console.log("\n\t props.dataIndex: ", props.dataIndex)
            detailsDataForManagersFlash.push(
                managersFlashData.variables,
                referenceDate,
                managersFlashData.lastYearsArray,
                managersFlashData.thisYearsArray,
            )
        };
        if(props.pathname.includes('trial')){
            const trialBalanceData = props.data;
            // console.log("\n\t trialBalanceData: ", trialBalanceData)
            const referenceDate = props.recordDate;
            const variables = Object.keys(trialBalanceData) 
            detailsDataForTrialBalance.push(
                variables,
                referenceDate,
            )
        }else if(props.pathname.includes('reservations')){
            (props.data as any).map((data: any, index:number) => {
                if(props.pathname.includes('individual')){
                    props.recordsState.individualReservationSummedAdult += data.numberOfAdult;
                    props.recordsState.individualReservationSummedRoomRate += data.roomRate;
                    props.recordsState.individualReservationSummedTotalCost += data.totalCost;
                }else{
                    props.recordsState.groupReservationSummedGuest += data.numberOfGuests;
                    props.recordsState.groupReservationSummedTotalCost += data.totalCost;
                }
                props.recordsState.tableTitle = `${props.pathname.includes('group') ? "Group" : "Individual"} Reservations: ${props.recordDate}`
            });
        }else if(props.pathname.includes("individual-in-house")){
            props.recordsState.tableTitle = `Individuals In-House: ${props.recordDate}`
        }else if(props.pathname.includes("store/records")){
            props.recordsState.tableTitle = `${props.pathname.includes("outlets") ? "Store Outlet" : "Store Items"} for: ${props.recordDate}`
        }
        props.setRecordsState({
            ...props.recordsState,
            showDetails: true,
            detailsTableData: props.data,
            detailsTableHeader: [...detailsHeader]
        });
    }catch(error){
        console.log("\n\t Records error: ", error)
    }
};

