import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useLocation } from 'react-router';
import "../../../../styles/ReportsStyles.css";

import { BsArrowRightCircle } from "react-icons/bs";
import axiosHelper from '../../../../utilities/axiosHelper';
// import { managersFlashRecord } from './tableActions/tableActionsThree';
import colors from '../../../../assets/colors';
import Gifs from '../../../Gifs';
import { tableDataCleaner, titleCase } from '../../../../utilities/strings';

interface Props {
    dataToDetail: any
};

const MarketWorthDetails = (props: Props) => {
    const store = useSelector((store: any) => store);
    const pathname = useLocation().pathname;
    const { sidebarStatus } = store;
    const { showSidebar } = sidebarStatus;

    const location = useLocation();
    const subRecord = ["Day", "Month", "Year"]
    const [segmentsState, setSegmentsState] = useState({
        tableData: []as any[],
        rowVariables: []as string[],
        referenceDate: "",
        loadingText: `Please wait; Hotel-Pro is fetching ${titleCase(pathname.split("/")[2])} records...`,
        actualLengthOfTableData: 0
    })
    const definitions = {
        "Available rooms": "Signifies the number of rooms available for reservation.",
        "Dirty rooms": "Signifies the number of rooms checked-out from, but requires clean-up.",

    };
    useEffect(() => {
        (async () => {
            console.log("\n\t props.tableData: ", props.dataToDetail);
            // await serverPaginator();
            console.log("\n\t dataToDetail: ", props.dataToDetail)
            const dataToDetail = props.dataToDetail;
            const recordResponse = await axiosHelper({
                httpVerb: "get",
                routeName: `${pathname}/${dataToDetail.recordDate}`
            });
            console.log("\n\t recordResponse: ", recordResponse)
            const data = recordResponse ? recordResponse.data : {data: []}
            console.log("\n\t data: ", data)
            setSegmentsState({
                ...segmentsState,
                tableData: data,
                loadingText: "",
                rowVariables: data[0].variables,
                referenceDate: dataToDetail.recordDate,
            });
        })()
        
    }, []);
    const header = ["S/N", "Count", "Actual Rate", "Actual Total", "Discount Rate", "Discount Total", "Nights"];
    // console.log("\n\t segmentsState: ", segmentsState )
    return (
        <React.Fragment>
            {
                segmentsState.loadingText ?
                <div style={{height: "100vh", width: "100vw", marginLeft: "-4rem", flexDirection: "column"}} className='bg-black d-flex justify-content-center align-items-center'>
                    <Gifs width={150} height={150} gifName='fandb' />
                    <p className='font-italic animate__animated animate__headShake animate__infinite'>{segmentsState.loadingText}</p>
                </div>
                :
                <div className="managers-flash-table-container" style={{
                                                    // overflow: "hidden", 
                                                    marginLeft: showSidebar ? "-25px" : "2rem", 
                                                    width: "80vw"
                                                }}>
                    <span className="managers-flash-header">
                        <span className='text-center'>
                            <Gifs gifName='hotel-logo' />
                        </span>
                        <h4 className="text-center mt-2">{"Market Segmentation (By Worth)"}</h4>
                        <h5 style={{fontSize: "15px"}} className="text-center font-weight-bold">{segmentsState.referenceDate && `Reference Date: ${segmentsState.referenceDate}`}</h5>
                        <hr />
                    </span>
                    <div >
                        {
                            segmentsState.tableData.length > 0 &&
                            segmentsState.tableData.map((segment:any, index:number) => (
                                <table className="managers-flash-table-table table bg-light table-striped table-hover table-bordered">
                                    <thead style={{backgroundColor: colors.sideNav}} className="text-white">
                                        <tr>
                                            <th colSpan={21} className="text-center">Membership Type: {segment.membershipTypeName}</th>
                                        </tr>
                                        
                                    </thead>
                                    {
                                        <tbody className='managers-flash-body'>
                                            <tr>
                                                {
                                                    header.map(hd => (
                                                        <td colSpan={3} key={hd}>{hd}</td>
                                                    ))
                                                }
                                            </tr>
                                            {/* {
                                                segment.checkedInData.length > 0 &&
                                                segment.checkedInData.map((data:any, dataIndex:number) => (
                                                    <tr>
                                                        {
                                                            Object.keys(data).map((innerData:any, innerDataIndex:number) => (
                                                                <td colSpan={3}>{tableDataCleaner(header[innerDataIndex], data)}</td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            } */}
                                            <tr>
                                                {
                                                    segment.totals.map((total:any) => (
                                                        <td key={total} colSpan={3}>{total}</td>
                                                    ))
                                                }
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            ))
                        }
                    </div>
                    {
                        !pathname.includes('market') &&
                        <div className="managers-flash-notes">
                            <section>
                                <p className="mx-2 font-weight-bold" style={{textDecoration: "underline", fontSize: "16px"}}>Definitions:</p>
                                {
                                    Object.keys(definitions).map((variable) => (
                                        <article key={variable} className="definitions">
                                            <p style={{fontSize: "14px"}} className="d-flex align-items-center">
                                                <span><BsArrowRightCircle /></span>
                                                <span className="mx-2 mt-1 font-weight-bold">{variable}:</span> 
                                                <span className="mt-1">{(definitions as any)[variable]}</span>
                                            </p>
                                        </article>
                                    ))
                                }
                            </section>
                        </div>
                    }
                    <section style={{justifyContent: "space-between"}} className="row">
                        <span className="mx-3 print-btn-container">
                            <button className="btn btn-secondary text-center" onClick={e => window.print()}>
                                Print 
                            </button>
                        </span>
                    </section>
                </div>
            }
        </React.Fragment>
    )
};

export default MarketWorthDetails;
