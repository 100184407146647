import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { showConfirmModalAction, showProcessingGifAction } from '../../redux/actions/storeActions/appActionsUtilities'
import { customAlert } from '../../utilities/customAlert'
import { pascalCaseSeparator } from '../../utilities/strings'
import FAndBSummary from './restaurantData/FAndBSummary'

interface Props {
    message: string
    actionForContinue: () => any,
    formFields?: string[]
    fieldValues?: any[]
    cancelButtonText?: string
    continueButtonText?: string
    fAndBData?: any
    previewData: boolean
    hideConfirmModalButtons?: boolean
    // pathnameForContinueAction: string
}


const Confirm = (props: Props) => {
    console.log("\n\t  props.previewData: ", props.previewData)
    // console.log("\n\t Confirm... Modal: ", props.fieldValues, props.formFields)
    const dispatch = useDispatch();
    const buttonAction = [`${props.cancelButtonText ? props.cancelButtonText : 'Cancel'}`, `${props.continueButtonText ? props.continueButtonText : "Continue"}`];
    // console.log("\n\t Confirm... Modal-buttonAction: ", buttonAction)

    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();
    const handleConfirmModalAction = () => {
        // console.log("\n\t handleConfirmModalAction....");
        dispatch(showProcessingGifAction(true));
        setTimeout(async () => {
            const serverResponse = await props.actionForContinue();
            // console.log("\n\t Modal-serverResponse: ", serverResponse)
            if(typeof(serverResponse) === "string"){
                customAlert("error", serverResponse, 7000);
            }else{
                customAlert("success", serverResponse.message, 5000);
                setTimeout(() => {
                    pathname.includes('arrivals/individual') && navigate('frontdesk/checked-in/individual')
                    pathname.includes('arrivals/group') && navigate('frontdesk/checked-in/group')
                    // window.location.reload();
                }, 3000);
            };
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        }, 4000);
    };
    return (
        <div style={{borderRadius: "7px"}} className="bg-black mt-5">
            <div className="modal-content bg-dark">
                <h4 style={{fontSize: "20px"}} className="m-3 mt-3 text-white text-center">{props.message}</h4>
                {/* <hr className="confirm_modal_hr" /> */}
                {
                    props.previewData &&
                    <div className="modal-body">
                        {
                            props.fAndBData ?
                            <FAndBSummary fAndBData={props.fAndBData} />
                            :
                            props.formFields && props.formFields.map((fieldName, index) => (
                                <div key={fieldName}>
                                    {
                                        fieldName !== "roomTypeAndNumberOfRooms" ?
                                        <div className="">
                                            <article className="text-white form-group">
                                                <span style={{color: "tomato"}} className="pr-3 text-white font-weight-bold">{pascalCaseSeparator(fieldName)}:</span>
                                                {
                                                    props.fieldValues &&
                                                    <span style={{color: "#00ff00",}}>{parseInt(props.fieldValues[index]) && !fieldName.toLowerCase().includes("guest") && !fieldName.toLowerCase().includes("date") && !fieldName.toLowerCase().includes("phone") ? parseInt(props.fieldValues[index]).toLocaleString() : props.fieldValues[index] || "--"}</span>
                                                }
                                            </article>
                                            <hr className="confirm_modal_hr" />
                                        </div>
                                        :
                                        <table style={{borderColor: "#fff", color: "#fff", borderStyle: "solid", borderWidth: "3px"}} className="table table-sm table-hover table-bordered text-white table-striped">
                                            <h6 className="text-center my-2 " >Room types and number of rooms</h6>
                                            {
                                                props.fieldValues &&
                                                (props.fieldValues[index] as any[]).map((item: any, tIndex: number) => (
                                                    <tr style={{color: "#fff",}} key={tIndex}>
                                                        <td>{item.roomTypeName}</td>
                                                        <td>{item.numberOfRooms}</td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                        // <span>{pascalCaseSeparator(fieldName)}: {props.fieldValues && props.fieldValues[index]}</span>
                                    }
                                </div>
                            ))
                        }
                    </div>
                }
                <div className="modal-footer btn-container">
                    {
                        !props.hideConfirmModalButtons &&
                        buttonAction.map(action => (
                            <button
                                key={action}
                                data-toggle="modal"
                                data-target="#modal"
                                data-dismiss="modal"
                                className={`btn ${action.toLowerCase() === "cancel" || action.toLowerCase() === "no" ? 'btn-info' : 'btn-danger'}`}
                                onClick={(action.toLowerCase() !== "cancel" && action.toLowerCase() !== "no") ? () => handleConfirmModalAction() : () => dispatch(showConfirmModalAction(false))}>
                                {action}
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Confirm
