import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { individualFrontdeskActions } from './FrontdeskTable'

interface Props {
    pathname: string
    handleActions: Function
    data: any
    details?: boolean
}
export const fAndBTableActions = ["Print Consent Folio", "Sign Consent Folio", "Unsign Consent Folio", "Modify Order", 'Pay Balance', "Order Details", "Cancel Order", "Assign to PM-Account", "Backtrack Order"]
const reservationButtons = ['Edit', "Cancel Reservation", "Move to PM", "Reg. Card", 'Update Profile', "Create Reservation", "Delete Profile", "Details"]

// const individualExpectedChecOutnActions = ["Pay Balance", "Extend Stay", "Check-Out", "Change Room"];

const individualArrivalActionsMenu = ["Guest Bill", "Move In-House", "Backtrack Guest", "Reg. card", "Check-in", "Cancel Reservation"]
const groupArrivalActionsMenu = ["Check-in", "Close Checking-in", "Cancel Reservation"]
const groupCheckedInActionsMenu = ["Group Details", "Group Bill", "Add Guest", "Check-Out", "Extend Stay", "Add Charges", "Change Room"];
const frontdeskGuestBills = ["Print Bill", "Bill Details"];
const auditReportActions = ["Folios", "Details", "Add Credits", "Add Charges", "Reverse Credits", "Reverse Charges", "Move In-House", "Guest Bill", "Transfer Bill", "Transfer Balance"];




const TableActionButtons = (props: Props) => {
    // console.log("\n\t Props.Data: ", props.data)
    const backtrackGuestActions = ["Add Charges", "Add Credits", "Reverse Charges", "Reverse Credits", `${props.pathname.includes("group") ? "Group" : "Guest"} Bill`, `${!props.pathname.includes("group") ? "Reg. Card": ""}`, "Extend Stay", "Room Billing", "Close Backtrack", `${!props.pathname.includes("group") ? "Cancel Backtrack" : ""}`, "End Backtrack"];
    const facilitiesButton = ["Add Event", "Completed Events", "Cancel Event", "Update Event", "End Event"]
    // console.log("\n\t TableActionButtons: ", props.pathname === 'shift-sales-details')
    // const navigate = useNavigate();
    return (
        <React.Fragment>
            {
                (props.pathname.includes('bar') || props.pathname.includes('restaurant')) && !props.pathname.includes('receipts') ?
                <span>
                    {
                        props.pathname.includes('daily-sales') || props.pathname.includes('shift-sales') ?  
                        <Link to="#" onClick={() => props.handleActions('shift-or-daily-sales', 'shift-or-daily-sales', props.data)} className="dropdown-item button">
                            Sales Details
                        </Link>
                        :
                        !props.pathname.includes('receipts') && !props.pathname.includes('frontdesk') &&
                        [...fAndBTableActions.slice(0, props.pathname.includes("backtrack") ? 8 : undefined), "Bill Backtrack Guest"].slice(
                            props.pathname.includes('folios') ? 5 : 
                            0, props.pathname.includes('point') ? 9 :
                            undefined
                        ).map(action => (
                            <Link 
                                onClick={
                                    action.toLowerCase().includes('modify') ?
                                    () => props.handleActions('modify-order', 'modify-order', props.data)
                                    :
                                    action.toLowerCase().includes('cancel') ?
                                    () => props.handleActions('cancel-order', 'cancel-order', props.data)
                                    :
                                    () => props.handleActions(action.toLowerCase().replaceAll(" ", "-"), action.toLowerCase().replaceAll(" ", "-"), props.data)
                                }
                                data-target={`
                                                ${action.toLowerCase().includes('modify') ? 
                                                '#restaurantTray' : 
                                                action.toLowerCase().includes('pay') ? "#paymentTray" :
                                                (action.toLowerCase().includes('backtrack') || action.toLowerCase().includes('pm-account') || action.toLowerCase().includes('cancel') || action.toLowerCase().includes('sign')) ? '#modal' 
                                                : undefined }
                                            `}
                                data-toggle={`${action.toLowerCase().includes('backtrack') || action.toLowerCase().includes('cancel') || action.toLowerCase().includes('modify') || action.toLowerCase().includes('pay') || action.toLowerCase().includes('sign') ? 'modal' : undefined }`}
                                className="dropdown-item button"
                                key={action}
                                to="#"
                                >
                                {action}
                            </Link>
                        ))
                    }
                </span>
                :
                props.pathname.includes('receipt') ?
                <Link 
                    onClick={() => props.handleActions('print-receipt', "print-receipt", props.data)}
                    className="dropdown-item button"
                    to="#"
                >
                    Print Receipt
                </Link>
                :
                props.pathname.includes('reservations') ?
                <div>
                    {
                        props.pathname === "/reservations" ?
                        <span></span>
                        :
                        reservationButtons.slice(
                            props.pathname.includes('calendar') ? 7 : props.pathname.includes('profiles') ? 4 : 0, 
                            props.pathname.includes('group') ? 3 : props.pathname.includes('calendar') ? 9 : props.pathname.includes('profiles') ? 7 : props.pathname.includes('group') ? 4 : !props.pathname.includes('profiles') ? 4
                            : undefined
                        ).map(button => (
                            <Link
                                data-toggle={(((button.toLowerCase().includes('details') || button.toLowerCase().includes('move')) && !props.details) || button.toLowerCase().includes('create')) ? undefined : "modal"}
                                data-target={(((button.toLowerCase().includes('details') || button.toLowerCase().includes('move')) && !props.details) || button.toLowerCase().includes('create')) ? undefined : "#modal"}
                                className="dropdown-item button"
                                to={button.toLowerCase() === 'edit' ? `reservations///update-${props.pathname.includes('group') ? 'group' : 'individual'}-reservations/${props.data._id}` : "#"} 
                                onClick={() => props.handleActions(button, "", props.data)}>
                                    {button} 
                            </Link>
                        ))
                    }
                </div>                
                :
                props.pathname.includes('audit-reports') ?
                <div>
                    {
                        props.pathname.includes("pm-account") ?
                        auditReportActions.slice(2).map(action => (
                            <Link 
                                key={action}
                                data-target="#modal"
                                data-toggle="modal"
                                to="#"
                                onClick={() => props.handleActions(action, props.data)}
                                className="dropdown-item button" >
                                {action}
                            </Link>
                        ))
                        :
                        props.pathname.includes('city') ?
                        [props.pathname.includes('individual') ? auditReportActions.slice(7, 8) : [...groupCheckedInActionsMenu.slice(0, 2), ...auditReportActions.slice(2, 6)]].map(action => (
                            <Link 
                                data-target={!props.details ? "#modal" : undefined}
                                data-toggle={!props.details ? "modal" : undefined}
                                to="#"
                                onClick={() => props.handleActions(action, props.data)}
                                className="dropdown-item button" >
                                {action}
                            </Link>
                        ))
                        :
                        auditReportActions.slice(props.pathname.includes("bills") ? 7 : 5).map(action => (
                            <Link 
                                data-target="#modal"
                                data-toggle="modal"
                                to="#"
                                onClick={() => props.handleActions(action, props.data)}
                                className="dropdown-item button" >
                                {action}
                            </Link>
                        ))
                    }
                </div>
                :
                props.pathname.includes("backtrack") ?
                <div>
                    {
                        props.pathname.includes("account") ?
                        auditReportActions.slice(2).map(menu => (
                            <Link
                                data-target={menu.toLowerCase().includes("card") ? undefined : "#modal"}
                                data-toggle={menu.toLowerCase().includes("card") ? undefined : "modal"}
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                                // target={menu.toLowerCase().includes("card") ? "_blank" : undefined}
                                to={"#"}>
                                {menu}
                            </Link>
                        ))
                        :
                        props.pathname.includes("facilities") ?
                        facilitiesButton.slice(
                            props.pathname.includes("facility") ? 0 : 2, 
                            props.pathname.includes("facility") ? 2 : undefined
                        ).map(menu => (
                            <Link
                                data-target={menu.toLowerCase().includes("card") ? undefined : "#modal"}
                                data-toggle={menu.toLowerCase().includes("card") ? undefined : "modal"}
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                                // target={menu.toLowerCase().includes("card") ? "_blank" : undefined}
                                to={"#"}>
                                {menu}
                            </Link>
                        ))
                        :
                        props.pathname.includes("no-eod") ?
                        ["Bill IHG", "Compute Transactions", "Run EOD"].map(menu => (
                            <Link
                                data-target={menu.toLowerCase().includes("card") ? undefined : "#modal"}
                                data-toggle={menu.toLowerCase().includes("card") ? undefined : "modal"}
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                                // target={menu.toLowerCase().includes("card") ? "_blank" : undefined}
                                to={"#"}>
                                {menu}
                            </Link>
                        ))
                        :
                        backtrackGuestActions.map(menu => (
                            <React.Fragment>
                                {
                                    menu && <Link
                                        data-target={menu.toLowerCase().includes("card") ? undefined : "#modal"}
                                        data-toggle={menu.toLowerCase().includes("card") ? undefined : "modal"}
                                        key={menu}
                                        onClick={() => props.handleActions(menu, props.data)}
                                        className="dropdown-item button"
                                        // target={menu.toLowerCase().includes("card") ? "_blank" : undefined}
                                        to={"#"}>
                                        {menu}
                                    </Link>
                                }
                            </React.Fragment>
                        ))
                    }
                </div>
                :
                props.pathname.includes('frontdesk') &&
                <div>
                    {
                        props.pathname.includes("arrivals/individual") ?
                        individualArrivalActionsMenu.slice(3).map(menu => (
                            <Link
                                data-target={menu.toLowerCase().includes("card") ? undefined : "#modal"}
                                data-toggle={menu.toLowerCase().includes("card") ? undefined : "modal"}
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                                // target={menu.toLowerCase().includes("card") ? "_blank" : undefined}
                                to={"#"}>
                                {menu}
                            </Link>
                        ))
                        :
                        props.pathname.includes("facilities") ?
                        facilitiesButton.slice(
                            props.pathname.includes("facility") ? 0 : 2, 
                            props.pathname.includes("facility") ? 2 : undefined
                        ).map(menu => (
                            <Link
                                data-target={menu.toLowerCase().includes("card") ? undefined : "#modal"}
                                data-toggle={menu.toLowerCase().includes("card") ? undefined : "modal"}
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                                // target={menu.toLowerCase().includes("card") ? "_blank" : undefined}
                                to={"#"}>
                                {menu}
                            </Link>
                        ))
                        :
                        props.pathname.includes("arrivals/group") ?
                        groupArrivalActionsMenu.map(menu => (
                            <Link
                                data-target="#modal"
                                data-toggle="modal"
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                                to={"#"}>
                                {menu}
                            </Link>
                        ))
                        :
                        props.pathname.includes('bills') ?
                        frontdeskGuestBills.map(action => (
                            <Link
                                // data-target={action.toLowerCase().includes('details') ? undefined : "#modal"}
                                // data-toggle={action.toLowerCase().includes('details') ? undefined : "modal"}
                                to="#"
                                key={action}
                                onClick={() => props.handleActions(action, props.data)}
                                className="dropdown-item button"
                            >
                                {action}
                            </Link>
                        ))
                        :
                        props.pathname.includes("individual") ? 
                        individualFrontdeskActions.slice(
                            props.pathname.includes("departures/individual") ? 8
                            :
                            0, props.pathname.includes("checked-in/individual") ? undefined
                            : undefined
                        ).map(menu => (
                            <Link
                                data-target="#modal"
                                data-toggle={!menu.includes('Bill') ? "modal" : undefined}
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                                target={menu.toLowerCase().includes("card") ? "_blank" : undefined}
                                to="#">
                                {menu}
                            </Link>
                        ))
                        :
                        props.pathname.includes('history') ?
                        (Object.keys(props.data).includes('recordDate') ? ["Details"] : individualArrivalActionsMenu).slice(0, 4).map(menu => (
                            <Link
                                data-target="#modal"
                                data-toggle="modal"
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                                target={menu.toLowerCase().includes("card") ? "_blank" : undefined}
                                to="#">
                                {menu}
                            </Link>
                        ))
                        :
                        props.pathname.includes('account') ?
                        auditReportActions.slice(
                            props.pathname.includes('out') ? 7 : props.pathname.includes('in') ? 2 : 0, 
                            props.pathname.includes('out') ? 8 : props.pathname.includes('in') ? undefined : 8
                        ).map(action => (
                            <Link 
                                key={action}
                                data-target="#modal"
                                data-toggle="modal"
                                to="#"
                                onClick={() => props.handleActions(action, props.data)}
                                className="dropdown-item button" >
                                {action}
                            </Link>
                        ))
                        :
                        groupCheckedInActionsMenu.slice(
                            0 + +`${props.details ? 2 : 0}`, !props.details ? groupCheckedInActionsMenu.length-4: 
                            groupCheckedInActionsMenu.length
                            ).map(menu => (
                            <Link
                                data-target={menu.toLowerCase().includes('details') ? undefined : "#modal"}
                                data-toggle={menu.toLowerCase().includes('details') ? undefined : "modal"}
                                to="#"
                                key={menu}
                                onClick={() => props.handleActions(menu, props.data)}
                                className="dropdown-item button"
                            >
                                {menu}
                            </Link>
                        ))
                    }
                </div>
            }
        </React.Fragment>
    )
}

export default TableActionButtons