
const colors = {
    ash: "#d6e1eba9",
    appYellow: "#dba620",
    error: "bg-danger",
    success: "bg-success",
    warning: "bg-warning",
    danger: "#ff0000",
    white: "#fff",
    sideNav: "#1f2d3d",
    black: "#000",
    restaurantTrayColor: `rgb(68, 68, 116), rgb(170, 89, 89))`
}


export default colors;