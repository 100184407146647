import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { yesterdaysFormDate } from '../../../assets/events/individualReservations';
import "../../../styles/RestaurantTrayStyles.css";
import axiosHelper from '../../../utilities/axiosHelper';
import { customAlert } from '../../../utilities/customAlert';
import { getDetailsForIndividualReservation } from '../../../utilities/databaseHelpers';
import { inputValidator } from '../../../utilities/security';
import { titledTextJoiner } from '../../../utilities/strings';
import Gifs from '../../Gifs';
import { dateConstrainer, givenDateAndCurrentTime } from "date-fran";


interface Props {
    paymentMethods: any
    formFields: string[]
    formFieldTypes: string[]
    selectOptions?: string[]
    placeholders?: string[]
    formModalTitle: string
    onChange: Function
    submitToServer: Function
    fAndBDiscounts: any[]
    orderTotal: number
    fAndBDiscountData: any[]
    order?: any
}

const FAndBPaymentTray = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;

    console.log("\n\t Payment tray-order...", props.order)
    console.log("\n\t Payment tray-paymentMethods...", props.paymentMethods);
    const payButtons = ["Pay", "Cancel"];
    const guestType = ["In-House", "Walk-In", "PM"];

    const [payState, setPayState] = useState({
        showPaymentButton: false,
        showRoomNumberInput: false,
        paymentType: "",
        guestType: "",
        roomNumberVerified: false,
        roomNumber: "", 
        verificationMessage: "",
        formInput: {} as any,
        showLoadingGif: false,
        guestsCheckInDetails: "",
        showPMAccountSelect: false,
        showPaymentMethodSelect: true,
        autoCreatedPMAccounts: []as any[],
        minDate: "",
        disableVerifyRoomButton: false,
    });
    const handlePaymentEvent = (fieldName: string, fieldValue: string) => {
        payState.verificationMessage = "";
        const newInput = payState.formInput;
        newInput[titledTextJoiner(fieldName)] = fieldValue;
        const amount = (document.getElementById('amount')as any).value
        payState.formInput = {
            ...newInput,
            amount
        }
        payState.showPaymentMethodSelect = +amount > 0 ? true : false;
        if(!fieldValue.toLowerCase().includes('select')){ 
            if(fieldName.toLowerCase().includes('payment')) {
                payState.paymentType = fieldValue;
            }else if(fieldName.toLowerCase() === "guest type") {
                payState.showPaymentButton = fieldValue.toLowerCase().includes('house') ? false : true;
                payState.showRoomNumberInput = fieldValue.toLowerCase().includes('house') ? true : false;
                payState.showPMAccountSelect = fieldValue.toLowerCase().includes('pm') ? true : false;
            }else if(fieldName.toLowerCase().includes('room')) {
                payState.roomNumber = fieldValue;
                payState.showRoomNumberInput = true;
            }
        };
        setPayState({...payState});
        props.onChange(fieldName, fieldValue);
       
    };
    // console.log("\n\t formInput: ", payState.formInput)
    const verifyRoomDetails = async() => {
        setPayState({
            ...payState,
            showLoadingGif: true,
            disableVerifyRoomButton: true,
            verificationMessage: "Hotel-Pro is verifying if this guest is in-house. Please wait.."
        });
        try {
            const roomDetailsResponse = await axiosHelper({
                httpVerb: 'get',
                routeName: `${pathname}/verify-in-house-guest-room-details/${payState.roomNumber}`
            });
            // console.log("\n\t roomDetailsResponse.data.[1]: ", roomDetailsResponse.data.split("-")[1])
            console.log("\n\t roomDetailsResponse: ", roomDetailsResponse)
            if(roomDetailsResponse.data){
                setTimeout(() => {
                    setPayState({
                        ...payState,
                        disableVerifyRoomButton: true,
                        verificationMessage: "Hotel-Pro found the guest.",
                    })
                }, 3000);
                setTimeout(() => {
                    return setPayState({
                        ...payState,
                        verificationMessage: roomDetailsResponse.data.split("__")[0],
                        showRoomNumberInput: false,
                        showPaymentButton: true,
                        showLoadingGif: false,
                        guestsCheckInDetails: roomDetailsResponse.data.split("__")[1],
                    })
                }, 5000);
            }else{
                setTimeout(() => {
                    setPayState({
                        ...payState,
                        verificationMessage: roomDetailsResponse,
                        disableVerifyRoomButton: false
                    });
                }, 5000);
            }
        } catch (error: any) {
            console.log("\n\t Error: ", error)
            setPayState({
                ...payState,
                verificationMessage: error.response ? error.response : undefined,
            });
        }
    };
    const validateInput = () => {
        const validation = inputValidator(payState.formInput, Object.keys(payState.formInput).length, !payState.paymentType.toLowerCase().includes('pos') ? Object.keys(payState.formInput).slice(0, 5) : Object.keys(payState.formInput))
        if(typeof(validation) == "string") return customAlert("error", validation, 6000)
        // console.log("\n\t payState.guestsCheckInDetails: ", payState.guestsCheckInDetails)
        props.onChange("amount", payState.formInput['amount']);
        props.submitToServer();
    };
    const appLocation = useLocation()
    useEffect(() => {
        (async () => {
            let minDate = ""
            if(appLocation.pathname.includes("backtrack")){
                const minBacktrackDate = props.order.dateCreated.split("T")[0].split("-");
                const date = givenDateAndCurrentTime(`${minBacktrackDate[0]}-${minBacktrackDate[1]}-${minBacktrackDate[2]}`)
                minDate = dateConstrainer(date);
            }
            const { autoCreatedPMAccounts } = await getDetailsForIndividualReservation() as any;
            setPayState({
                ...payState,
                autoCreatedPMAccounts,
                minDate
            })
        })();
    }, [props.paymentMethods, props.order]);
    console.log("\n\t showPaymentMethodSelect: ", payState.showPaymentMethodSelect)
    return (
        <div style={{width: "40vw"}} className="modal fade row d-flex justify-content-center payment-tray animate__animated animate__pulse" role="dialog" id="paymentTray">
            <h5>{props.formModalTitle}</h5>
            <hr className="ml-3" />
            <section className="row px-4">
                {
                    pathname.includes("backtrack") &&
                    <section style={{width: "96%", marginBottom: ".25em", flexDirection: "column"}} className='mx-2 d-flex'>
                        <label style={{marginLeft: "10px"}} className="" htmlFor="">Backtrack Date</label>
                        <input 
                            onChange={e => handlePaymentEvent("backtrackDate", e.target.value)} 
                            max={yesterdaysFormDate()} 
                            min={payState.minDate}
                            className="backtrack-date form-control mx-2" 
                            type="date"
                        />
                    </section>
                }
                {
                    props.formFieldTypes && props.formFieldTypes.map((fieldType, fieldIndex) => (
                        <span style={{width: "100%", marginBottom: ".25em"}} className="form-group row d-flex mx-2" key={fieldIndex}>
                            {
                                <label className="mx-2" htmlFor="">
                                    {
                                        props.formFields[fieldIndex].toLowerCase().includes("payment") && !payState.showPaymentMethodSelect ?
                                        () => props.formFields[fieldIndex] :
                                        (!props.formFields[fieldIndex].toLowerCase().includes("pm account") && !props.formFields[fieldIndex].includes('Room') && !props.formFields[fieldIndex].includes('POS') ) ?
                                        props.formFields[fieldIndex] :
                                        (props.formFields[fieldIndex].toLowerCase().includes("pm account") && payState.showPMAccountSelect)  ?
                                        props.formFields[fieldIndex] : 
                                        (props.formFields[fieldIndex].toLowerCase().includes('room') && payState.showRoomNumberInput) ?
                                        props.formFields[fieldIndex] : 
                                        (props.formFields[fieldIndex].includes('POS') && payState.paymentType.includes("POS")) &&
                                        props.formFields[fieldIndex] 
                                    }
                                </label>
                            }
                            {
                                (fieldType.toLowerCase() === "text" || fieldType.toLowerCase() === "number") ?
                                !props.formFields[fieldIndex].includes('Room') && !props.formFields[fieldIndex].includes('POS') ?
                                <input 
                                    onChange={e => handlePaymentEvent(props.formFields[fieldIndex], e.target.value)} 
                                    min={fieldType.toLowerCase() === "number" ? 1 : undefined} 
                                    className="form-control mx-2" 
                                    type={fieldType.toLowerCase()} 
                                    id={props.formFields[fieldIndex].toLowerCase()}
                                    placeholder={props.placeholders && props.placeholders[fieldIndex]}
                                    defaultValue={props.formFields[fieldIndex].toLowerCase().includes('amount') ? props.orderTotal : undefined}
                                    // readOnly={props.formFields[fieldIndex].toLowerCase().includes('amount') ? true : false}
                                />
                                :
                                (props.formFields[fieldIndex].includes('POS') && payState.paymentType.includes("POS")) ?
                                <input 
                                    onChange={e => handlePaymentEvent(props.formFields[fieldIndex], e.target.value)} 
                                    min={fieldType.toLowerCase() === "number" ? 1 : undefined} 
                                    className="form-control mx-2" 
                                    type={fieldType.toLowerCase()} 
                                    placeholder={props.placeholders && props.placeholders[fieldIndex]}
                                />
                                :
                                (props.formFields[fieldIndex].toLowerCase().includes('room') && payState.showRoomNumberInput) &&
                                <input 
                                    onChange={e => handlePaymentEvent(props.formFields[fieldIndex], e.target.value)} 
                                    min={fieldType.toLowerCase() === "number" ? 1 : undefined} 
                                    className="form-control mx-2" 
                                    type={fieldType.toLowerCase()} 
                                    placeholder={props.placeholders && props.placeholders[fieldIndex]}
                                />
                                :
                                fieldType.toLowerCase() === "select" && 
                                <React.Fragment>
                                    {
                                        ((props.formFields[fieldIndex].toLowerCase().includes("pm account") && payState.showPMAccountSelect) 
                                        || props.formFields[fieldIndex].toLowerCase() === 'guest type'
                                        || props.formFields[fieldIndex].toLowerCase() === 'payment method' && payState.showPaymentMethodSelect
                                        || props.formFields[fieldIndex].toLowerCase().includes('discount'))  &&
                                        <select style={{fontSize: "1.3em"}} onChange={e => handlePaymentEvent(props.formFields[fieldIndex], e.target.value)} className="mx-2 form-select form-control d-flex">
                                            <option>Select {props.formFields[fieldIndex]}</option>
                                            {
                                                props.paymentMethods && 
                                                (
                                                    props.formFields[fieldIndex].toLowerCase() === 'guest type' ? guestType.slice(pathname.includes("backtrack") ? 1 : 0,pathname.includes("backtrack") ? undefined : 2) 
                                                    : props.formFields[fieldIndex].toLowerCase().includes('discount') ? props.fAndBDiscountData 
                                                    :    (props.formFields[fieldIndex].toLowerCase().includes("pm account") && payState.showPMAccountSelect) ? payState.autoCreatedPMAccounts
                                                    : props.paymentMethods
                                                ).map((type: any) => (
                                                    <option 
                                                        key={props.formFields[fieldIndex].toLowerCase().includes('type') ? type : type.paymentMethodCode} 
                                                        value={ 
                                                            props.formFields[fieldIndex].toLowerCase().includes('type') ? type 
                                                            :    (props.formFields[fieldIndex].toLowerCase().includes("pm account") && payState.showPMAccountSelect) ? type.accountName
                                                            : type.description
                                                        }
                                                        
                                                        >
                                                        { 
                                                            props.formFields[fieldIndex].toLowerCase().includes('discount') ? type.description 
                                                            : props.formFields[fieldIndex].toLowerCase().includes('type') ? type 
                                                            :    props.formFields[fieldIndex].toLowerCase().includes("pm account") && payState.showPMAccountSelect ? type.accountName
                                                            : type.description
                                                        }
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    }
                                
                                </React.Fragment>
                            }
                        </span>
                    ))
                }
               
            </section>
            <section style={{width: "100%"}} className="animate__animated animate__heartBeat row text-center justify-content-center font-weight-bold d-flex mt-3">
                <span>
                    {
                        payState.showLoadingGif &&
                        <Gifs gifName='fandb' />
                    }
                    <h5 style={{fontSize: "18px"}} className='font-weight-bold'>{payState.verificationMessage}</h5>
                </span>
                <hr />
            </section>
            <section  className="">
                {
                    payButtons.slice(
                        !payState.showPaymentButton ? 1
                        : 0
                    ).map(btn => (
                        <button
                            key={btn}
                            onClick={
                                btn.toLowerCase() === "cancel" ? 
                                () => window.location.reload() : 
                                btn.toLowerCase().includes("verify") ?
                                () => verifyRoomDetails()
                                : () => validateInput()
                            } 
                            data-toggle="modal"
                            data-target="#modal"
                            className={`mx-1 mt-1 float-right mb-3 btn ${btn.toLowerCase() == "cancel" ? "btn-secondary" : "btn-primary"}`}>
                            {btn}
                        </button>
                    ))
                }
                {
                    payState.showRoomNumberInput && payState.roomNumber &&
                    <button
                        // disabled={payState.disableVerifyRoomButton}
                        style={{display: !payState.disableVerifyRoomButton ? undefined : 'none'}}
                        onClick={() => verifyRoomDetails()} 
                        className="mx-2 mt-1 float-right mb-3 btn btn-primary">
                        Verify Room Details
                    </button>
                }
            </section>
        </div>
    )
}

export default FAndBPaymentTray