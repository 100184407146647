import { ActionTypes, UserDetailsInterface } from "../../actions/actionTypes";
import { StoreInterface, InitialStore } from "../../store";


const userDetailsReducer =  (store: StoreInterface, action: UserDetailsInterface) => {
    switch(action.type){
        case ActionTypes.USER_DETAILS:
            // console.log("\n\t userDetailsReducer-payload: ", action.payload);
            store.userDetails = action.payload;
           return({
               ...store,
           });
        default:
            return({
                ...store
            })
    }
}


export default userDetailsReducer;

