import { lazy } from 'react';
import { useLocation } from 'react-router-dom';

// import GroupReservation from '../../Forms/GroupReservation';
// import IndividualReservation from '../../Forms/IndividualReservation';
// import DetailedReservationTable from '../Tables/ReservationTable';


const GroupReservation = lazy(() => import('../../Forms/GroupReservation'));
const IndividualReservation = lazy(() => import('../../Forms/IndividualReservation'));
const DetailedReservationTable = lazy(() => import('../Tables/ReservationTable'));
interface Props {
    summaryHeader: any[]
    tableData: []
};



const ReservationsDashbords = (props: Props) => {
    const location = useLocation()
    const pathname = location.pathname;
    console.log("\n\t ReservationsDashbords-: ", pathname)
    return (
        <div style={{flexDirection: "column",}} className="">
            {
                pathname.includes("new-reservation/individual") || pathname.includes("update-individual") ?
                <IndividualReservation  />
                :
                pathname.includes("new-reservation/group") || pathname.includes("update-group") ?
                <GroupReservation />
                :
                <DetailedReservationTable />
            }
        </div>
    )
}

export default ReservationsDashbords
