import React from 'react'
import colors from '../../assets/colors'
import "../../styles/HomeScreen.css";

interface Props  {
    warningMessage: string
}

const WarningModal = (props: Props) => {
    console.log("\n\t Warning modal....")
    return (
        <div role="dialog" id="warningModal" className=" modal fade d-flex justify-content-center box_shadow" style={{zIndex: 30, marginLeft: "0rem", marginRight: "0rem", marginTop: "0rem"}} >
            {/* <div className="mt-5"></div>
            <div className="mt-5"></div> */}
            


            <div role="" className=" mt-4" style={{alignSelf: "center", backgroundColor: colors.appYellow, height: "30vh", width: "40vw",}}>
                <p className="text-danger">{props.warningMessage}</p>
                <button
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#modal"
                >
                    Okay
                </button>
            </div>
        </div>
    )
}

export default WarningModal