import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import colors from '../../../../assets/colors'
import { callBackendAction } from '../../../../redux/actions/storeActions/appActionsUtilities'
import { backTrackSubMenus, handleSubMenus} from './SidebarNavLinks'

interface Props {
    navLinks: string[]
}

const BackTrackSidebar = (props: Props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const pathname = location.pathname;
    const [backtrackMenu, setbacktrackMenu] = useState({
        toggleMenuState: {}as any
    })
    useEffect(() => {
        !pathname.includes("audit-reports") && dispatch(callBackendAction(false));
        props.navLinks.map(link => {
            backtrackMenu.toggleMenuState[link] = false;
        })
        setbacktrackMenu({...backtrackMenu})
    }, []);
    const handleStateSubMenus = (toggledLink: string) => {
        handleSubMenus({
            state: backtrackMenu,
            stateSetter: setbacktrackMenu,
            toggledLink
        })
    };
    return (
        <div style={{}}>
            <NavLink style={{color: colors.ash}} className={`nav-link d-flex my-3`} to="/backtrack">
                <strong>Backtrack</strong>
            </NavLink>
            <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />

            {
                props.navLinks && props.navLinks.map((link, index) => (
                    <div className="" key={link}>
                        <NavLink 
                            data-toggle={"collapse"}
                            data-target={`#${link.toLowerCase().replaceAll(" ", "-")}`}
                            style={{flexDirection: "column"}}  
                            className={`nav-link collapsed d-flex my-3`} 
                            aria-expanded="false" aria-controls="accounts"
                            to={link.toLowerCase().includes("facilities") || link.toLowerCase().includes("guests") || link.toLowerCase() === "accommodation" ? "#" : `${link.toLowerCase().replaceAll(" ", "-")}`}
                            key={link}
                            onClick={() => handleStateSubMenus(link)}
                            >
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}} >
                                <span style={{letterSpacing: 2}} className="link_hover">{link}</span>
                                {
                                    (link.toLowerCase().includes("facilities") || link.toLowerCase().includes("guests") || link.toLowerCase().includes("accommodation")) && 
                                    backtrackMenu.toggleMenuState[link] ?
                                    <FaAngleRight size={25} color={colors.appYellow}  />
                                    :
                                    (link.toLowerCase().includes("facilities") || link.toLowerCase().includes("guests") || link.toLowerCase().includes("accommodation")) && 
                                    <FaAngleDown size={25} color={colors.appYellow}  />

                                }
                            </div>
                        </NavLink>
                        <div className={`${!backtrackMenu.toggleMenuState[link] ? "collapse" : undefined} ml-3`} id={`${link.toLowerCase().replaceAll(" ", "-")}`} aria-labelledby="headingOne" >
                            <nav  className="sb-sidenav-menu-nested nav row d-flex">
                                {
                                    Object.entries(backTrackSubMenus).map((entery, secIndex: number) => (
                                        <span className="px-2" style={{width: "100%"}} key={secIndex}>
                                            {
                                                entery[0].toLowerCase() === link.toLowerCase() && 
                                                <span >
                                                    {
                                                        entery[1].map(value => (
                                                            <Link 
                                                                // data-toggle={link.toLowerCase().includes("end-of-day") ? "modal" : "collapse"}
                                                                // data-target={link.toLowerCase().includes("end-of-day") ? "#modal" : `#${link.toLowerCase().replaceAll(" ", "-")}`}
                                                                key={value}
                                                                style={{color: colors.appYellow, flexDirection: "column", textDecoration: "none"}} 
                                                                to={`${pathname}/${link.toLowerCase().replaceAll(" ", "-")}/${value.toLowerCase().replaceAll(" ", "-")}`}>
                                                                {value}
                                                                <hr style={{width: "100%", backgroundColor: colors.warning}} />
                                                            </Link>
                                                        ))
                                                    }
                                                </span>
                                            }
                                        </span>
                                    ))
                                }
                            </nav>
                        </div>
                        <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
                    </div>
                ))
            }
        </div>
    )
}

export default BackTrackSidebar;
