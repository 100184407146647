import { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

// import FrontdeskTable from '../Tables/FrontdeskTable';
// import RegCard from '../../Forms/regCards/IndividualRegCard';
// import EndOfDay from '../Tables/auditReports/endoOfDayComponents/EndOfDay';

const FrontdeskTable = lazy(() => import('../Tables/FrontdeskTable'));
const RegCard = lazy(() => import('../../Forms/regCards/IndividualRegCard'));
const EndOfDay = lazy(() => import('../Tables/auditReports/endoOfDayComponents/EndOfDay'));
interface Props {
    summaryHeader: any[]
    tableData: []
};



const FrontdeskDashboard = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    // console.log("\n\t FrontdeskDashboard-pathname includes frontdesk: ", pathname.includes("frontdesk"))
    return (
        <div className={!pathname.includes("end") ? " ml-4" : undefined}>
            {
                pathname.toLowerCase().includes("reg-card") ?
                <div className="ml-5">
                    <RegCard emptyReg={false} />
                </div>
                :
                pathname.includes('end-of-day') ?
                <Suspense fallback={<p>HotelPro</p>}>
                    <EndOfDay  />
                </Suspense>
                :
                <FrontdeskTable />
            }
        </div>
    )
}

export default FrontdeskDashboard
