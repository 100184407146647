import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createGroupReservation, onArrivalDateChange, 
         onDepartureDateChange, 
         onNumberOfRoomsChange, 
         onGroupNameInput, 
         onGuaranteedChange, 
         todaysDate, 
         validateGroupReservationDetails 
} from '../../../assets/events/groupReservation';
import { yesterdaysFormDate } from '../../../assets/events/individualReservations';
import { showConfirmModalAction, showProcessingGifAction } from '../../../redux/actions/storeActions/appActionsUtilities';
import axiosHelper from '../../../utilities/axiosHelper';
import { getDetailsForGroupReservation } from '../../../utilities/databaseHelpers';
import Modal from '../Modal';
import groupReservationDataCleaner from './groupReservationDataCleaner';

import { dateConstrainers } from '../../../utilities/dateHelper';
import { dateConstrainer } from "date-fran";
interface Props {

}
interface ReservationFormInterface {
    "Group": string 
    "Group Email": string
    "Arrival Date": any
    "Departure Date": any
    "Days": number
    "Number of Guests": number
    "Agent": string, 
    "Source": string, 
    "Purpose": string, 
    "Is Reservation Guaranteed": string, 
    "Deposit": string, 
    "Total Cost": number, 
    [key: string]: string | number | Date
}


const GroupReservation = (props: Props) => {
    const dispatch = useDispatch();
    const store = useSelector((store: any) => store);
    const {confirmModal } = store;
    // const appModulesData = modulesData?.modulesData;
    const { showConfirm } = confirmModal;
    // console.log("\n\t appModulesData: ", appModulesData);
    const [formState, setFormState] = useState({
        showRoomTypeRatesContainer: false,
        loadingPrompt: "Please wait, form data is loading....",
        showPaymentMethod: false,
        validationData: {} as any,
        editingFlag: false,
        message: "",
        groupReservationDetails: {}as any,
        btnAction: ""
    });
    const [formData, setFormData] = useState({
        roomTypes: [] as any,
        groups: [] as any,
        sources: [] as any,
        groupRates: [] as any,    
        groupRatesAndRoomType: [] as any,
        paymentMethod: [] as any[],
    });
    const location = useLocation();
    const pathname = location.pathname;
    const groupReservationId = location.pathname.split("/")[3];

    const booleanOptions = ["Yes", "No"];
    const [groupReservationDetails, setGroupReservationDetails] = useState<ReservationFormInterface>({
        "Arrival Date": "",
        "Departure Date": "",
        "Days": 0,
        "Group": "",
        "Group Email": "",
        "Number of Guests": 0,
        "Source": "", 
        "Purpose": "", 
        "Agent": "", 
        "Is Reservation Guaranteed": "", 
        "Deposit": "", 
        "Total Cost": 0, 
    });

    const groupValues = [...Object.values(formData.groups)];
    const groupRateValues = [...Object.values(formData.groupRates)];
    const handleInputChange = async(field: string, value: string) => {
        formState.loadingPrompt = ""
        // console.log("\n\t input: ", field, value)
        if(!field.includes("number-of-rooms")){
            const newDetails = groupReservationDetails;
            newDetails[field] = value
        }
        if(field === "Arrival Date"){
            groupReservationDetails["Days"] = await onArrivalDateChange();
            if(groupReservationDetails['Group']){
                groupReservationDetails['Total Cost'] = onNumberOfRoomsChange(formData.groupRatesAndRoomType, groupReservationDetails["Days"]);
            }
        }else if(field === "Departure Date"){
            groupReservationDetails["Days"] = await onDepartureDateChange();
            if(groupReservationDetails['Group']){
                groupReservationDetails['Total Cost'] = onNumberOfRoomsChange(formData.groupRatesAndRoomType, groupReservationDetails["Days"]);
            }
        }else if(field.toLowerCase().includes('number-of-rooms')){
            groupReservationDetails['Total Cost'] = onNumberOfRoomsChange(formData.groupRatesAndRoomType, groupReservationDetails["Days"]);
        }else if(field.toLocaleLowerCase().includes('guaranteed')){
            onGuaranteedChange(value);
            if(value.toLowerCase() === 'no'){ 
                groupReservationDetails['Deposit'] = ""
                formState.showPaymentMethod = false;
            }
        }else if(field.toLocaleLowerCase() === "deposit"){
            formState.showPaymentMethod = true;
        }if(field === "Group"){
            onGroupNameInput({
                formData,
                setFormData, 
                setGroupReservationDetails, 
                setFormState, 
                formState, 
                groupReservationDetails, 
                groupReservationDataCleaner, 
                groupValues,
                groupRateValues,
                inputtedGroupName: value,
                roomTypes: formData.roomTypes
            });
            const groupEmail = (document as any).querySelector(".groupEmail").value;
            if(groupEmail){
                formState.showRoomTypeRatesContainer = true;
            }
            // formData.groupRatesAndRoomType = groupReservationDataCleaner(formData.groups, formData.groupRates, formData.roomTypes, value)
            // console.log("\n\t formData.groupRatesAndRoomType: ", formData.groupRatesAndRoomType)
            // setFormData({...formData})
            // formState.showRoomTypeRatesContainer = true;
        };
        setFormState({...formState})
        setGroupReservationDetails({...groupReservationDetails});
    }
    console.log("\n\t groupReservationDetails[Total Cost]: ", groupReservationDetails["Total Cost"])
    // const modulesData = 
    useEffect(() => {
        if(!pathname.includes("backtrack")){
            (document as any).querySelector(".arrival-date").min = todaysDate();
        }else if(pathname.includes("backtrack")){
            (document as any).querySelector(".arrival-date").max = yesterdaysFormDate();
        }
        (async () => {
            const { roomTypes, groupRates, paymentMethod, groups, sources} = await getDetailsForGroupReservation() as any;
            setFormData({
                roomTypes,
                groups,
                sources,
                groupRates,
                paymentMethod,
                groupRatesAndRoomType: groupRates
            });
            formState.loadingPrompt = ""
            console.log("\n\t paymentMethod: ", paymentMethod)
            roomTypes && setFormState({
                ...formState,
                loadingPrompt: ''
            });
            if(groupReservationId && groupReservationId !== "group"){
                formState.editingFlag = true;
                console.log("\n\t groupReservationId: ", groupReservationId)
                const reservationDetails = await axiosHelper({
                    httpVerb: "get",
                    routeName: `/reservations/get-group-reservation-details/${groupReservationId}`
                });
                console.log("\n\t reservationDetails: ", reservationDetails.data)
                const { arrivalDate, days, totalCost, deposit, groupName, departureDate, numberOfGuests, coperateEmail } = reservationDetails.data;
                // console.log("\n\t groupName: ", groupName)
                formData.groupRatesAndRoomType = groupReservationDataCleaner(groups, groupRates, roomTypes, groupName)
                formData.paymentMethod = paymentMethod;
                console.log("\n\t formData.groupRatesAndRoomType: ", formData.groupRatesAndRoomType)
                setFormData({...formData})
                formState.showRoomTypeRatesContainer = true;
                formState.message = "Enter the required number of rooms to be reserved for each room type.";
                setGroupReservationDetails({
                    ...groupReservationDetails,
                    "Arrival Date": (arrivalDate.split("T"))[0],
                    "Departure Date": (departureDate.split("T"))[0],
                    Group: groupName,
                    Deposit: deposit,
                    "Number of Guests": numberOfGuests,
                    Days: days,
                    "Group Email": coperateEmail,
                    "Total Cost": totalCost
                });
            }
           
            setFormState({
                ...formState
            });
        })();
    }, []);
    // console.log("\n\t formData.paymentMethod: ", formData.paymentMethod)
    const showSummary = (action: string) => {
        const validations = validateGroupReservationDetails(formData.groupRatesAndRoomType, groupReservationDetails)
        setFormState({
            ...formState,
            btnAction: action.toLowerCase(),
            validationData: validations
        });
        validations !== undefined && dispatch(showConfirmModalAction(true));
        // console.log("\n\t validateGroupReservationDetails: ", validations)
    }
    const handleReservation = async() => {
        dispatch(showProcessingGifAction(true));
        await createGroupReservation(formState.validationData, groupReservationId, pathname.includes("backtrack") ? "backtrack" : formState.btnAction)
        dispatch(showProcessingGifAction(false));
    };
    return (
        <div className="card shadow-lg border-0 rounded-lg mt-3">
            {
                showConfirm &&
                <Modal
                    title={"Group Reservation Summary"}
                    handleAction={() => handleReservation()}
                    formFields={[...Object.keys(formState.validationData)]}
                    fieldValues={[...Object.values(formState.validationData) as any]}
                    numberOfFields={0}
                    pathname={pathname}
                    previewData={true}
                    onChange={() => undefined}
                    // setPrintRegCard={() => undefined}
                    setGroupCheckInData={() => undefined}
                    type="confirm"
                />
            }
            <div className="card-header">
                <h3 className="text-center font-weight-light mt-3">{formState.editingFlag && 'Edit'} {pathname.includes("backtrack") && "Backtrack"} Group Reservation Form</h3>
                <p className="text-center text-secondary font-weight-bold">(Ensure that the group has been created as well as the associated group rates.)</p>
                {
                    !formState.editingFlag &&
                    <span className="font-weight-bolder small text-success animate__animated animate__pulse animate__infinite">{formState.loadingPrompt}</span>
                }
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-xl-6 col-md-6">
                        <div className="card bg-white text-dark mb-4">
                            <div className="card-body">
                                <h5 className="card-title"></h5>
                                <div className="form-row">
                                    {
                                        Object.keys(groupReservationDetails).slice(0, 6).map((field, index) => (
                                            <div key={index} className={ field.toLowerCase() === "group" || field.toLowerCase().includes("days") || field.toLowerCase().includes("type")  ? "col-md-12" : "col-md-6"}>
                                                <div className="form-group">
                                                    <label className="small mb-1">{field.toLowerCase() === "room type required" ? `${field} (How many distinct room types are required?)` : field}</label>
                                                    {
                                                        field.toLowerCase() !== "group" ?
                                                        <span>
                                                            <input 
                                                                className={`form-control ${field.toLowerCase().replaceAll(" ", "-")}`} 
                                                                name={`${field.replaceAll(" ", "-")}`}
                                                                type={
                                                                        field.toLowerCase().includes("date") ? "date" 
                                                                        : field.toLowerCase().includes("type") || field.toLowerCase().includes("rate") || field.toLowerCase().includes("guests") ? "number" 
                                                                        : field.toLowerCase().includes("email") ? 'email' 
                                                                        : field.toLowerCase().includes('phone') ? "tel"  
                                                                        : "text"
                                                                    }
                                                                required 
                                                                min={field.toLowerCase().includes("type") || field.toLowerCase().includes("guests") ? 1 : undefined}
                                                                readOnly={field.toLowerCase().includes("rate") || field.toLowerCase().includes("email") || field.toLowerCase().includes("days") ? true : false}
                                                                onChange={(e) => handleInputChange(field, e.target.value)} 
                                                                defaultValue={groupReservationDetails[field]as any || undefined }
                                                            />
                                                        </span>
                                                        :
                                                        field.toLowerCase() === "group" &&
                                                        <div className="form-group">
                                                            <input 
                                                                onChange={e => handleInputChange(field, e.target.value)} 
                                                                className="form-control group" 
                                                                list="countries" name="country" type="text" id="country" 
                                                                defaultValue={groupReservationDetails[field]as any || undefined }
                                                                // defaultValue={(formState.editingFlag ? groupReservationDetails["Group"] : '')as string}
                                                            />
                                                            <datalist id="countries">
                                                                {
                                                                    formData.groups && Object.values(formData.groups as string[]).map((group: any) => (
                                                                        <option key={group.groupName} value={group.groupName} ></option>
                                                                    ))
                                                                }
                                                            </datalist>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {
                                        <div style={{width: "100%"}} className='row d-flex' id="new-room-container">
                                            <div style={{width: "100%"}} className="row d-flex justify-content-center text-center">
                                                <strong className={`d-flex small font-weight-bold ${formState.message.includes('Unrecognised') ? "text-danger" : "text-success"} text-center`}>{formState.message} </strong>
                                            </div>
                                            {
                                                formState.showRoomTypeRatesContainer &&
                                                formData.groupRatesAndRoomType && Object.values(formData.groupRatesAndRoomType).map((rateAndRoomType: any, index) => (
                                                    <div key={index} className="form-row">
                                                        <div className="form-group col-md-4">
                                                            <label className="small mb-1">Room Type</label>
                                                            <input className={`form-control `} type="text" readOnly value={rateAndRoomType.roomTypeName} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="small mb-1">Number of Rooms</label>
                                                                <input onChange={(e)=> handleInputChange(`number-of-rooms-${index}`, `${e.target.value}-${rateAndRoomType.groupRate}`)} defaultValue={0} min={0} className={`form-control number-of-rooms-${index}`} type="number" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="small mb-1">Room Rate</label>
                                                                <input value={rateAndRoomType.groupRate} readOnly className={`form-control room-rate-${index}`} type="number" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-md-6">
                        <div className="card bg-white text-dark mb-4">
                            <div className="card-body">
                                <h5 className="card-title"></h5>
                                <div className="form-row">
                                    {
                                       Object.keys(groupReservationDetails).slice(6).map((field, index) => (
                                        <div key={field} className={ "col-md-6"}>
                                            <div className="form-group">
                                                {
                                                    !field.toLowerCase().includes("payment") &&
                                                    <label className="small mb-1">{field.toLowerCase().includes('guaranteed') ? field+'?' : field}</label>
                                                }
                                                {
                                                    !field.toLowerCase().includes("payment") && !field.toLowerCase().includes("type") && field.toLowerCase() !== "source" && !field.toLowerCase().includes("guaranteed")  ?
                                                    <section>
                                                        <input 
                                                            className={`form-control ${field.toLowerCase().replaceAll(" ", "-")}`} 
                                                            name={`${field.replaceAll(" ", "-")}`}
                                                            type={field.toLowerCase().includes("date") ? "date" : field.toLowerCase().includes("rate") || field.toLowerCase() === "deposit" ? "number" : "text"} 
                                                            required 
                                                            disabled={field.toLowerCase() === "deposit" ? true : false}
                                                            readOnly={field.toLowerCase().includes("total") ? true : false}
                                                            onChange={e => handleInputChange(field, e.target.value)} 
                                                            defaultValue={groupReservationDetails[field]as any || undefined }
                                                            min={field.toLowerCase() === "deposit" ? 0 : undefined}
                                                        />
                                                        {
                                                            field.toLowerCase() === 'deposit' && (groupReservationDetails['Is Reservation Guaranteed']as string).toLowerCase() === 'yes' &&
                                                            <span className='text-danger text-center d-flex row justify-content-center small'>Enter the amount being deposited.</span>
                                                        }
                                                        {
                                                            field.toLowerCase() === "deposit" && formState.showPaymentMethod &&
                                                            <article  className="d-flex text-center row justify-content-center small mt-2" style={{width: "100%", flexDirection: "column"}}>
                                                                <label className="mx-1 small ">Payment Method:</label>
                                                                <span className="row d-flex justify-content-center ">
                                                                    {
                                                                        formData.paymentMethod && formData.paymentMethod.map((method: any) => (
                                                                            <span className="align-items-center justify-content-center d-flex">
                                                                                {
                                                                                    !method.description.toLowerCase().includes('charge to') && !method.description.toLowerCase().includes('none') &&
                                                                                    <span className="mx-2" key={method.paymentMethodCode}>
                                                                                        <input onChange={e => handleInputChange('Payment Method', method.description)} name="paymentMethod" style={{display: "flex", justifyContent: "center", width: "100%"}} type="radio" /> 
                                                                                        {method.description}
                                                                                    </span>
                                                                                }
                                                                            </span>
                                                                        ))
                                                                    }
                                                                </span>
                                                            </article>
                                                        }
                                                    </section>
                                                    :
                                                    !field.toLowerCase().includes("payment") &&
                                                    <select  
                                                        onChange={e => handleInputChange(field, e.target.value)}
                                                        className={`form-select form-control ${field.toLowerCase().replaceAll(" ", "-")}`} >
                                                    <option value="">{`Select ${field.toLowerCase().includes('guaranteed') ? "" : field}`}</option>
                                                    {
                                                        field.toLowerCase().includes("guaranteed") ?
                                                        <>
                                                            {
                                                                booleanOptions.map((bool, index) => (
                                                                    <option key={index} value={bool}>{bool}</option>
                                                                ))
                                                            }
                                                        </>
                                                        :
                                                        field.toLowerCase() === "source" ?
                                                        formData.sources && Object.values(formData.sources as any).map((source: any) => (
                                                            <option key={source.sourceCode} value={source.sourceCode}>{source.sourceCode}</option>
                                                        ))
                                                        :
                                                        field.toLowerCase() === "membership type" &&
                                                        formData.groups && Object.values(formData.groups).map((group: any, index) => (
                                                            <option key={index} value={group.description}>{group.groupName}</option>
                                                        ))
                                                    }
                                                    </select>
                                                }
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                                    <div className="form-group mt-4 mb-0">
                                        <button 
                                            disabled={formState.message.includes('Unrecognised') ? true : false}
                                            data-toggle="modal" 
                                            data-target="#modal" 
                                            onClick={e => showSummary(formState.editingFlag ? "Update" : "Create")} 
                                            className="button create-group-reservation">
                                                {formState.editingFlag ? "Update" : "Create"}
                                                <span></span><span></span><span></span> <span></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default GroupReservation
