import { customAlert } from "../../../../../utilities/customAlert";
import { frontdeskState } from "../tableActions/tableActionsOne";
import { rowActionFunctions } from "../tableData";


interface FrontdeskHandleActionInterface {
    frontdesksState: any
    pathname: string
}

export const frontdeskTableActions = async(props: FrontdeskHandleActionInterface) => {
    let serverResponse = undefined
    if(props.frontdesksState.btnAction.includes("transfer-balance") || props.frontdesksState.btnAction.includes("transfer-bill") || props.frontdesksState.btnAction.includes("add-event") || props.frontdesksState.btnAction.toLowerCase().includes("create-pm-account") || props.frontdesksState.btnAction.toLowerCase().includes("check-in") || props.frontdesksState.btnAction.includes("assign-to-pm-account")){
        serverResponse = await rowActionFunctions.postAction(`/${props.pathname}/${props.frontdesksState.btnAction}`, {reservationData: props.frontdesksState.reservationData, formInputs: props.frontdesksState.formInputs })
    }else if(props.frontdesksState.btnAction.includes('reverse') || props.frontdesksState.btnAction.includes('backtrack-guest') || props.frontdesksState.btnAction.includes('cancel') || props.frontdesksState.btnAction.includes('refund') || props.frontdesksState.btnAction.includes('pay-debt')){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.frontdesksState.btnAction}`, props.frontdesksState.reservationData._id, {...props.frontdesksState.formInputs})
    }else if(props.frontdesksState.btnAction == "check-out" && props.pathname.includes('individual')){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.frontdesksState.btnAction}`, props.frontdesksState.reservationData._id, {})
    }else if(props.frontdesksState.btnAction == "check-out" && props.pathname.includes('group')){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/check-out`, props.frontdesksState.reservationData._id, props.frontdesksState.reservationData)
    }else if(props.frontdesksState.btnAction.includes('add-guest') || props.frontdesksState.btnAction.includes('end-event') || props.frontdesksState.btnAction.includes('edit-details') || props.frontdesksState.btnAction.includes("add-event") || props.frontdesksState.btnAction.includes("transfer-charges") || props.frontdesksState.btnAction == "pay-balance" || props.frontdesksState.btnAction == "change-room" || props.frontdesksState.btnAction == "add-credits" || props.frontdesksState.btnAction == "add-charges"){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.frontdesksState.btnAction}`, props.frontdesksState.reservationData._id, {reservationData: props.frontdesksState.reservationData, formInputs: props.frontdesksState.formInputs })
    }else if(props.frontdesksState.btnAction.toLowerCase().includes("move-in-house") || props.frontdesksState.btnAction.toLowerCase().includes("extend")){
        serverResponse = await rowActionFunctions.postAction(`/${props.pathname}/${props.frontdesksState.btnAction}`, {reservationData: props.frontdesksState.reservationData, formInputs: props.frontdesksState.formInputs })
    }else if(props.frontdesksState.btnAction.toLowerCase().includes("close")){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/close-group-check-in`, props.frontdesksState.reservationData._id, {reservationData: props.frontdesksState.reservationData})
    }else if(props.frontdesksState.btnAction.toLowerCase().includes("message")){
        serverResponse = await rowActionFunctions.postAction(`/${props.pathname}/send-message`, {formInputs: props.frontdesksState.formInputs})
    }else{
        serverResponse = await rowActionFunctions.deleteAction(`${props.pathname}`, props.frontdesksState.reservationData._id)
    };

    if(typeof(serverResponse) === "string"){
        customAlert("error", serverResponse, 5000);
    }else{
        const { status, message } = serverResponse;
        customAlert("success", message, 5000);
    };
    console.log("\n\t serverResponse: ", serverResponse)
    setTimeout(async() => {
        window.location.reload();
    }, 5200);
};



interface BacktrackHandleActionInterface {
    backtrackState: any
    pathname: string
    updateRoutes: string[]
}
export const backtrackTableActions = async(props: BacktrackHandleActionInterface) => {
    let serverResponse = undefined
    if(props.updateRoutes.includes(props.backtrackState.btnAction)){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.backtrackState.btnAction}`, (props.pathname.includes("restaurant") || props.pathname.includes("bar")) ? props.backtrackState.orderToUpdate._id : props.backtrackState.reservationData._id || props.backtrackState.dbIdOfDataToModify,  (!props.pathname.includes("restaurant")  && !props.pathname.includes("bar")) ? {reservationData: props.backtrackState.reservationData, formInputs: props.backtrackState.formInputs } : {orderDetails: props.backtrackState.orderDetails, orderToUpdate: props.backtrackState.orderToUpdate, formInput: props.backtrackState.formInputs} )
    }else if(props.backtrackState.btnAction.toLowerCase().includes("extend") || props.backtrackState.btnAction.includes("add-event") || props.backtrackState.btnAction.toLowerCase().includes("run") || props.backtrackState.btnAction.toLowerCase().includes("make-order") || props.backtrackState.btnAction.toLowerCase().includes("message")){
        serverResponse = await rowActionFunctions.postAction(`/${props.pathname}/${props.backtrackState.btnAction}`, (!props.pathname.includes("restaurant")  && !props.pathname.includes("bar")) ? {reservationData: props.backtrackState.reservationData, formInputs: props.backtrackState.formInputs} : {orderDetails: props.backtrackState.orderDetails, formInput: props.backtrackState.formInputs, barLocation: props.pathname.includes("bar") ? props.pathname.includes('pool') ? "Pool-Bar" : "Inner-Bar" : undefined})
    }else{
        serverResponse = await rowActionFunctions.deleteAction(`${props.pathname}`, props.backtrackState.reservationData._id);
    };
    if(typeof(serverResponse) === "string"){
        customAlert("error", serverResponse, 5000);
    }else{
        const { status, message } = serverResponse;
        customAlert("success", message, 5000);
    };
    console.log("\n\t serverResponse: ", serverResponse)
    setTimeout(() => {
        window.location.reload();
    }, 5200);
};


interface FAndBActionInterface {
    state: any
    pathname: string
    // updateRoutes: string[]
}
export const fAndBSubTableActions = async(props: FAndBActionInterface) => {
    let serverResponse = undefined
    if(props.state.btnAction.includes('message')){
        serverResponse  = await rowActionFunctions.postAction(`/${props.pathname}/${props.state.btnAction}`, {formInput: props.state.formInput})
    }else if(props.state.btnAction === 'make-order'){
        serverResponse = await rowActionFunctions.postAction(`/${props.pathname}/${props.state.btnAction}`, {orderDetails: props.state.orderDetails})
    }else if(props.state.btnAction === 'pay-balance' || props.state.btnAction.includes('pm-account')){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.state.btnAction}`, props.state.orderToUpdate._id, {orderToUpdate: props.state.orderToUpdate, formInput: props.state.formInput})
    }else if(props.state.btnAction === 'backtrack-order' || props.state.btnAction === 'modify-order'){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.state.btnAction}`, props.state.orderToUpdate._id, {orderDetails: props.state.orderDetails, orderToUpdate: props.state.orderToUpdate, formInput: props.state.formInput})
    }else if(props.state.btnAction === 'end-of-shift-details'){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.state.btnAction}`, props.state.orderToUpdate._id, {orderDetails: props.state.orderDetails, orderToUpdate: props.state.orderToUpdate, formInput: props.state.formInput})
    }else if(props.state.btnAction === "unsign-consent-folio" || props.state.btnAction === 'cancel-order'){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.state.btnAction}`, props.state.orderToUpdate._id, {orderToUpdate: props.state.orderToUpdate, formInput: props.state.formInput})
    }else if(props.state.btnAction === "sign-consent-folio" || props.state.btnAction === "sign-payment-folio"){
        serverResponse = await rowActionFunctions.updateAction(`/${props.pathname}/${props.state.btnAction}`, props.state.orderToUpdate._id, {orderToUpdate: props.state.orderToUpdate})
    };
    console.log("\n\t serverResponse: ", serverResponse)
    if(typeof(serverResponse) === "string"){
        customAlert("error", serverResponse, 5000);
    }else{
        const { status, message } = serverResponse;
        customAlert("success", message, 5000);
    };
    console.log("\n\t serverResponse: ", serverResponse)
    setTimeout(() => {
        window.location.reload();
    }, 5200);
};