import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import BONLogo from "../../../../assets/img/bonLogo.png";
import { showConfirmModalAction, showFormModalAction, showProcessingGifAction } from '../../../../redux/actions/storeActions/appActionsUtilities';

import "../../../../styles/RegCardStyles.css";
import Gifs from '../../../Gifs';
import Modal from '../../Modal';
import { rowActionFunctions } from '../../Modules/Tables/tableData';

interface Props {
    reservationDetails: any
    groupCheckInData?: any
    setPrintRegCard: Function
    // setGroupCheckInData: Function
}

const GroupRegCard = (props: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const pathname = location.pathname;
    const userDetails = useSelector((store: any) => store.userDetails)
    const { firstName, lastName } = userDetails && userDetails.userDetails;
    const { groupName } = props.reservationDetails;

    console.log("\n\t GroupRegCard-groupCheckInData: ", props.groupCheckInData)
    const store = useSelector((store: any) => store);
    const { confirmModal, } = store
    const { showConfirm } = confirmModal;
    const checkGroupIn = async (e: any) => {
        dispatch(showConfirmModalAction(true));
        dispatch(showFormModalAction(false));
    };
    return (
        <div className="container mt-4 justify-content-center reg-container">
            {
                showConfirm &&
                <Modal 
                    type="confirm"  
                    onChange={() => ""}
                    pathname={pathname}
                    // setPrintRegCard={() => ""}
                    numberOfFields={0}
                    handleAction={
                        () => rowActionFunctions.postAction(`/${pathname}/check-in`, {checkInData: props.groupCheckInData, reservationDetails: props.reservationDetails})
                    }
                    title={`${firstName.concat(" ", lastName)}, you're about checking ${groupName} in. Would you like to proceed?`}
                    cancelButtonText="No"
                    continueButtonText="Yes"
                    setGroupCheckInData={() => undefined}
                />
            }
            <div className="my-2">
                <button onClick={e => props.setPrintRegCard(false)} className="btn btn-secondary mx-2">
                    Back
                </button>
                <button 
                    data-target="#modal"
                    data-toggle="modal"
                    onClick={e => checkGroupIn(e)} className="btn btn-success mx-2">
                    Check-in
                </button>
            </div>
            {
                Object.values(props.groupCheckInData.formData).map((form: any, index) => (
                    <div key={index} style={{flexDirection: "column"}} className="justify-content-center individual-reg-container my-4">
                        <div className="mt-5 row"></div>

                        <div className="">
                            <div className="reg-header">
                                <div style={{flexDirection: "column"}} className="row d-flex align-items-center justify-content-center my-3">
                                    <Gifs width={150} height={150} gifName='hotel-logo' />
                                    <Gifs gifName='company-details' />
                                    <p className="text-center font-weight-bold reg-card-title">Registration Card</p>
                                </div>
                            </div>
                            <table className="text-center table table-responsive reg-table" id="" >
                                {
                                    props.groupCheckInData &&
                                    <tbody className="">
                                        <tr className="">
                                            <th className="header-titles py-3" colSpan={8}>Guest Details</th>
                                        </tr>
                                        <tr className="text-center">
                                            <td colSpan={1} className="table-title">
                                                Title
                                            </td>
                                            <td colSpan={1}>
                                                {form.title}
                                            </td>
                                            <td className="table-title">
                                                Firstname
                                            </td>
                                            <td colSpan={1}>
                                                {form.firstname}
                                            </td>
                                            <td className="table-title">
                                                Lastname
                                            </td>
                                            <td colSpan={2}>
                                                {form.lastname}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-title">
                                                Phone
                                            </td>
                                            <td colSpan={2}>
                                                {form.phone}
                                            </td>
                                            <td className="table-title">
                                                Nationality
                                            </td>
                                            <td colSpan={2}>
                                                {form.nationality}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="header-titles text-center" colSpan={8}>Dates</th>
                                        </tr>
                                        <tr className="text-center">
                                            <td colSpan={0} className="table-title">
                                                Arrival Date
                                            </td>
                                            <td colSpan={1}>
                                                {new Date(form.arrivalDate).toLocaleString()} 
                                            </td>
                                            <td colSpan={0} className="table-title">
                                                Departure Date
                                            </td>
                                            <td colSpan={1}>
                                                {new Date(form.departureDate).toLocaleString()} 
                                            </td>
                                            <td colSpan={0} className="table-title">
                                                Days
                                            </td>
                                            <td colSpan={1}>
                                                {form.days} 
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="header-titles text-center" colSpan={8}>Room Details</th>
                                        </tr>
                                        <tr className="text-center">
                                            <td className="table-title">
                                                Room Type
                                            </td>
                                            <td colSpan={2}>
                                                {form.roomType || "--"}
                                            </td>
                                            <td className="table-title">
                                                Room Rate
                                            </td>
                                            <td colSpan={2}>
                                                {form.roomRate.toLocaleString() || "--"}
                                            </td>
                                        </tr>
                                        <tr className="text-center">
                                            <td className="table-title">
                                                Floor Number
                                            </td>
                                            <td colSpan={2}>
                                                {form.floorNumber || "--"}
                                            </td>
                                            <td className="table-title">
                                                Room Number
                                            </td>
                                            <td colSpan={2}>
                                                {form.roomNumber || "--"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="header-titles text-center" colSpan={8}>Other Details</th>
                                        </tr>
                                        <tr className="text-center">
                                            <td className="table-title">
                                                E-mail
                                            </td>
                                            <td colSpan={3}>
                                                {form.email || "--"}
                                            </td>
                                            <td className="table-title">
                                                Passport-No
                                            </td>
                                            <td colSpan={0}>
                                                {form.passportNo || "--"}
                                            </td>
                                        </tr>
                                        <tr className="text-center">
                                            <td className="table-title">
                                                Country
                                            </td>
                                            <td colSpan={2}>
                                                {form.country || "--"}
                                            </td>
                                            <td className="table-title">
                                                Company
                                            </td>
                                            <td colSpan={2}>
                                                {form.company || "--"}
                                            </td>
                                        </tr>
                                        <tr className="text-center">
                                            <td className="table-title">
                                                Deposit
                                            </td>
                                            <td colSpan={6}>
                                                {form.deposit ? form.deposit.toLocaleString() : "--"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center"  colSpan={6}> <strong>N.B: checkout time is 11:00 a.m</strong> </td>
                                        </tr> 
                                        
                                    </tbody>
                                }
                                
                            </table>
                            <div className="row d-flex container other-group-reg-details" >
                                <section style={{flexDirection: "column"}} className="row">
                                    <article className="col-md-8 text-center mt-4">
                                        <span className="text-center">
                                            <span className="text-center">Frontdesk Name:</span> <br />
                                            <span style={{textDecoration: "underline", }} className='mt-2'>{firstName && firstName.concat(" ", lastName)}</span>
                                        </span>
                                    </article>
                                    <article className="col-md-8 mt-4 ">
                                        <span className="col-md-3">
                                            <p className="text-center">Guest Signature:</p>
                                            <hr className="confirm_modal_hr mt-5" />
                                        </span>

                                        <span className="col-md-3">
                                            <p className="text-center">Frontdesk Signature:</p>
                                            <hr className="confirm_modal_hr mt-5" />
                                        </span>
                                    </article>
                                    <article className="col-md-8 my-3 text-center mt-3">
                                        <span >Date: {new Date().toLocaleString()}</span>
                                    </article>
                                    <article className="col-md-8 my-3 text-center mt-3">
                                        <span>
                                            <h6>Enjoy Your Stay with us!</h6>
                                        </span>
                                    </article>
                                    <div className="mt-5 row"></div>
                                    <div className="mt-5 row"></div>
                                    <div className="mt-5 row"></div>
                                    <div className="mt-5 row"></div>
                                    <div className="mt-5 row"></div>
                                    <div className="mt-5 row"></div>

                                            <h6>
                                                {/* <%= data.companyDetails.companyName %> */}
                                            </h6>
                                            <span>
                                                {/* <%= data.companyDetails.companyAddress %> */}
                                            </span>
                                            <span> 
                                                {/* Tel: */}
                                                {/* <%= data.companyDetails.companyPhoneNumberOne %>,
                                                    <%= data.companyDetails.companyPhoneNumberTwo %> &nbsp; Email:
                                                        <%= data.companyDetails.companyEmail %> */}
                                            </span>
                                            <span>
                                                {/* <%= data.companyDetails.companyWebsite %> */}
                                            </span>

                                    {/* </tfoot> */}
                                </section>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default GroupRegCard
