import path from 'path';
import React, { useEffect, useState } from 'react'
import { FaChevronCircleLeft } from 'react-icons/fa';
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom';
import colors from '../../../../assets/colors';
import axiosHelper from '../../../../utilities/axiosHelper'
import { tableDataCleaner, titleCase } from '../../../../utilities/strings';
import Gifs from '../../../Gifs';
import Filter from '../../Filter';
import CashiersBreakdownReport from './auditReports/CashiersBreakdownReport';
import CashiersSummaryReport from './auditReports/CashiersSummaryReport';
import ManagersFlashReport from './auditReports/ManagersFlashReport';
import TrialBalanceReport from './auditReports/TrialBalanceReport';
import MarketWorthDetails from './MarketWorthDetails';
import RecordsDetails from './RecordsDetails';
import { handleDataPagination, } from './tableActions/tableActionsThree';



import { 
    endOfShiftTableHeader,  
    paginationButtons,
} from './tableData';


interface Props {

}

export const recordTableHeaders = ["S/N", "Record Date", "Number Of Rows", "Actions"];
const RecordsTable = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    const [recordsState, setRecordsState] = useState({
        tableData: []as any[],
        showDetails: false,
        tableTitle: "",
        detailsTableData: [],
        detailsTableHeader: [] as string[],
        allRecords: [],
        summedDebit: 0,
        summedCredit: 0,
        summedVAT: 0,
        summedServiceCharge: 0,
        summedConsumptionCharge: 0,
        summedDepositAmount: 0,
        summedLedgerAmount: 0,
        summedReceiptTotalAmount: 0,
        summedFAndBTotalAmount: 0,
        summedFAndBBalanceAmount: 0,
        loadingText: `Please wait; Hotel-Pro is fetching ${titleCase(pathname.split("/")[2])} records...`,
        summedNetCharge: 0,
        summedReceiptActualAmountPaid: 0,
        individualReservationSummedAdult: 0,
        individualReservationSummedRoomRate: 0,
        individualReservationSummedTotalCost: 0,
        groupReservationSummedGuest: 0,
        groupReservationSummedTotalCost: 0,
        paginationLength: 12,
        paginationStartPoint: 0,
        paginationEndPoint: 12,
        dataToDetail: {}as any,
        dataIndex: 0,
        paginatedTableData: [] as any[],
        referenceDate: "",
    });
    const recordsRouteNamer = () => {
        const routeName = pathname.split("/")[2];
        return `${pathname.includes('store') ? "Store" : "Records"} (${titleCase(routeName)})`
    };

 
    // console.log("\n\t recordsState.paginatedTableData: ", recordsState.paginatedTableData)
    const handlePagination = (btnName: string) => {
        handleDataPagination({
            paginationBtnName: btnName,
            state: recordsState,
            stateHandler: setRecordsState
        });
    };
    useEffect(() => {
        setRecordsState({
            ...recordsState,
            loadingText: `Please wait; Hotel-Pro is fetching ${titleCase(pathname.split("/")[2])} records...`,
        });
        (async () => {
            try {
                const recordResponse = await axiosHelper({
                    httpVerb: "get",
                    routeName: !recordsState.paginationStartPoint ? pathname : `/${pathname}/${recordsState.paginationStartPoint}/${recordsState.paginationEndPoint}`
                });
                // console.log("\n\t recordResponse-data: ", recordResponse.data)
                // console.log("\n\t recordResponse: ", recordResponse)
                const data = recordResponse ? recordResponse.data : []
                setRecordsState({
                    ...recordsState,
                    tableData: data,
                    paginatedTableData: recordResponse ? data.slice(0, recordsState.paginationLength) : data,
                    loadingText: ""
                });
            } catch (error) {
                console.log("\n\t RecordsTable-useEffect: ", error)
            }
        })();
    }, [pathname]);
    // console.log("\n\t recordsState.showDetails: ", recordsState.showDetails)
    const handleRecordDetailsAction = (dataIndex: number, data: any) => {
        setRecordsState({
            ...recordsState,
            showDetails: true,
            dataToDetail: data,
            dataIndex
        })
    }
    // console.log("\n\t recordsState: ", recordsState)
    // console.log("\n\t paginatedTableData: ", recordsState.paginatedTableData)
    return (
        <React.Fragment>
            {
                recordsState.loadingText ?
                <div style={{height: "100vh", width: "100vw", marginLeft: "-4rem", flexDirection: "column"}} className='bg-black d-flex justify-content-center align-items-center'>
                    <Gifs width={150} height={150} gifName='fandb' />
                    <p className='font-italic animate__animated animate__headShake animate__infinite'>{recordsState.loadingText}</p>
                </div>
                :
                <React.Fragment>
                    <section style={{justifyContent: "space-between", alignItems: "center", width: "95%"}} className='d-flex '>
                        <h4 className="mt-5">{recordsRouteNamer()}</h4>
                        <article style={{width: undefined}} className="mt-5">
                            {
                                !recordsState.showDetails &&
                                <Filter 
                                    tableData={recordsState.paginatedTableData}
                                    componentsState={recordsState}
                                    componentsStateSetter={setRecordsState}
                                    paginationStartPoint={recordsState.paginationStartPoint}
                                    paginationEndPoint={recordsState.paginationEndPoint}
                                />
                            }
                        </article>
                    </section>
                    <hr />
                    <section style={{justifyContent: "space-between", alignItems: "center", width: "95%", flexDirection: "column"}} className='d-flex '>
                        {
                            !recordsState.showDetails ?
                            <table className="table-table table bg-light table-striped table-hover table-bordered" id="example1" width="95%" cellSpacing="0">
                                <thead style={{ backgroundColor: colors.sideNav }} className="py-2">
                                    <tr className="text-center text-light">
                                        {
                                            (pathname.includes("shift-sales") ? endOfShiftTableHeader : recordTableHeaders).map((header:any) => (
                                                <th key={header} className="text-center">{header}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        recordsState.tableData.length > 0 && recordsState.paginatedTableData.map((data: any, index: number) => (
                                            <tr key={index}>
                                                {
                                                    (pathname.includes("shift-sales") ? endOfShiftTableHeader : recordTableHeaders).map((header:any, headerIndex: number) => (
                                                        <th key={header} className="text-center">
                                                            {
                                                                header.toLowerCase() !== 'actions' ?
                                                                tableDataCleaner(header, data, 'records')
                                                                :
                                                                <div className="dropdown show btn-container">
                                                                    <Link 
                                                                        className="btn button dropdown-toggle sb-sidenav-menu" 
                                                                        style={{ color: "#f4f4f4" }} 
                                                                        to="#" 
                                                                        onClick={() => handleRecordDetailsAction(index, data)}
                                                                        data-toggle="dropdown">
                                                                            Details
                                                                    </Link>
                                                                </div>
                                                            }
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            :
                            <div>
                                {
                                    (pathname.includes('segmentation') || pathname.includes('cashiers') || pathname.includes('trial') || pathname.includes('flash')) ?
                                    <div className="ml-5">
                                        <span className='back-btn'>
                                            <button
                                                className="btn btn-secondary row d-flex align-items-center"
                                                onClick={() => setRecordsState({
                                                    ...recordsState,
                                                    showDetails: false
                                                })}
                                            >
                                                <FaChevronCircleLeft size={25} className="mx-2" />
                                                Back
                                            </button>
                                        </span>
                                        {
                                            pathname.includes('segmentation') || pathname.includes('flash') ?
                                            <React.Fragment>
                                                {
                                                    pathname.includes('worth') ?
                                                    <MarketWorthDetails dataToDetail={recordsState.dataToDetail} />
                                                    :
                                                    <ManagersFlashReport dataToDetail={recordsState.dataToDetail} />
                                                }
                                            </React.Fragment>
                                            :
                                            pathname.includes("breakdown") ?
                                            <CashiersBreakdownReport dataToDetail={recordsState.dataToDetail} />
                                            :
                                            pathname.includes("summary") ?
                                            <CashiersSummaryReport dataToDetail={recordsState.dataToDetail} />
                                            :
                                            <TrialBalanceReport dataToDetail={recordsState.dataToDetail} />
                                        }
                                    </div>
                                    :
                                    <RecordsDetails 
                                        dataToDetail={recordsState.dataToDetail} 
                                        showRowDetails={recordsState.showDetails}
                                        setShowRowDetails={() => {
                                                setRecordsState({
                                                ...recordsState,
                                                showDetails: !recordsState.showDetails
                                            });
                                        }}
                                    />
                                }
                            </div>
                        }
                        {
                            !recordsState.showDetails &&
                            <section style={{}} className="">
                                <p>{recordsState.paginationStartPoint === 0 ? 1 : recordsState.paginationStartPoint} to {recordsState.paginationEndPoint > recordsState.tableData.length ? recordsState.tableData.length : recordsState.paginationEndPoint} of {recordsState.tableData.length}</p>
                                {
                                    recordsState.tableData &&
                                    !recordsState.showDetails && paginationButtons.slice(
                                        recordsState.paginationStartPoint <= 0 ? 1
                                        : 0, recordsState.paginationEndPoint >= recordsState.tableData.length ? 1
                                        : undefined
                                    ).map(btn => (
                                        <button
                                            onClick={e => handlePagination(btn)}
                                            key={btn}
                                            className={`btn ${btn.toLowerCase() === 'next' ? 'btn-primary' : 'btn-secondary'} mx-2 mb-4`}>
                                            {btn}
                                        </button>
                                    ))
                                }
                            </section>
                        }
                    </section>
                </React.Fragment>
            }
        </React.Fragment>
    )
};

export default RecordsTable