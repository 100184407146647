import { UserDetails } from "../types";
import axiosHelper from "./axiosHelper";
import { getStorageItem } from "./localStorage";


export const getReservationDetails = async (): Promise<string | object> => {
    try {
        const reservationDetails = await axiosHelper({
            routeName: '/reservations/details',
            httpVerb: "get"
        })
        // console.log("\n\t getReservationDetails: ", reservationDetails.data)
        return reservationDetails.data;
    } catch (error) {
        return "Network error"
    }
}

export const getFrontdeskDetails = async (): Promise<any[]> => {
    try {
        const frontdeskDetails = await axiosHelper({
            routeName: '/frontdesk/details',
            httpVerb: "get"
        })
        // console.log("\n\t getReservationDetails: ", frontdeskDetails.data)
        return frontdeskDetails.data;
    } catch (error) {
        return []
    }
}

export const getDetailsForIndividualReservation = async (): Promise<string | object> => {
    try {
        const individualReservationDetails = await axiosHelper({
            routeName: '/reservations/individual/details',
            httpVerb: "get"
        })
        console.log("\n\t getDetailsForIndividualReservation: ", individualReservationDetails.data)
        return individualReservationDetails.data;
    } catch (error) {
        return "Network error"
    }
};


export const getFAndBDiscounts = async (): Promise<string | object> => {
    try {
        const fAndBDiscounts = await axiosHelper({
            routeName: '/settings/charges/f-and-b-discounts',
            httpVerb: "get"
        })
        // console.log("\n\t fAndBDiscounts: ", fAndBDiscounts.data)
        return fAndBDiscounts.data;
    } catch (error) {
        return "Network error"
    }
};

export const getFAndBTableOrderData = async (): Promise<string | object> => {
    try {
        const fAndBTableOrderData = await axiosHelper({
            routeName: '/restaurant/order-helper-data',
            httpVerb: "get"
        })
        // console.log("\n\t fAndBTableOrderData: ", fAndBTableOrderData.data)
        return fAndBTableOrderData.data;
    } catch (error) {
        return "Network error"
    }
};

export const getDetailsForGroupReservation = async (): Promise<string | object> => {
    try {
        const individualReservationDetails = await axiosHelper({
            routeName: '/reservations/group/details',
            httpVerb: "get"
        })
        // console.log("\n\t individualReservationDetails: ", individualReservationDetails.data)
        return individualReservationDetails.data;
    } catch (error) {
        return "Network error"
    }
}

export const getDashboardDetails = async (dashboardName:string): Promise<string | object> => {
    try {
        const dashboardDetails = await axiosHelper({
            routeName: `/${dashboardName}`,
            httpVerb: "get"
        })
        // console.log("\n\t getDashboardDetails: ", dashboardDetails.data)
        return dashboardDetails.data;
    } catch (error) {
        return "Network error"
    }
};

export const getCheckInFormData = async (): Promise<string | object> => {
    try {
        const checkInFormData = await axiosHelper({
            routeName: "/frontdesk/arrivals/group/checkin-form-data",
            httpVerb: "get"
        });
        // console.log("\n\t getCheckInFormData: ", checkInFormData.data)
        return checkInFormData.data;
    } catch (error) {
        return "Network error"
    }
}

export const getGroupReservationFormData = async (): Promise<string | object> => {
    try {
        const groupReservationFormData = await axiosHelper({
            routeName: "/reservations/group/form-data",
            httpVerb: "get"
        });
        // console.log("\n\t groupReservationFormData: ", groupReservationFormData.data)
        return groupReservationFormData.data;
    } catch (error) {
        return "Network error"
    }
}

export const getUserDetails = async () => {
    try {
        const [usersId, companysId] = await Promise.all([
            getStorageItem("usersId"),
            getStorageItem("companysId")
        ]) 
        const detailsResponse = await axiosHelper({
            routeName: `/users/user-details/${usersId}/${companysId}`,
            httpVerb: "get",
        });
        // console.log("\n\t detailsResponse: ", detailsResponse)
        const licenseDetails = detailsResponse.licenseDetails;
        const companysLogo = detailsResponse.companysLogo;
        const companysAddress = detailsResponse.companysAddress;
        const companysEmail = detailsResponse.companysEmail
        const hotelDate = detailsResponse.hotelDate

        const { userType, userName, shiftStatus, firstName, lastName, email } = detailsResponse.data.user;
        // console.log("\n\t App.tsx-getUserDetails: userType, userName, firstName, lastName, licenseDetails: ", userType, userName, firstName, lastName, licenseDetails);
        const fullname = firstName.concat(" ", lastName);
        const details: UserDetails = {
            fullname,
            hotelDate,
            licenseDetails,
            shiftStatus,
            userType,
            email,
            firstName,
            lastName,
            userName,
            companysLogo,
            companysAddress,
            companysEmail
        }
        // console.log("\n\t globalState: ", globalState)
        return details;
    } catch (error) {
        return "Network error"
    }
};


export const paymentMethodsGetter = async() => {
    try {
        const response = await axiosHelper({
            httpVerb: 'get',
            routeName: "/settings/charges/payment-method"
        });
        return response.data
    } catch (error) {
        console.log("\n\t paymentMethodsGetter-error: ", error)
    }
};


export const floorNumberHelper = async(floorNumber: number | string) => {
    try {
        const response = await axiosHelper({
            httpVerb: 'get',
            routeName: `/settings/room-settings/room-and-rates/${floorNumber}`
        });
        return response.data
    } catch (error) {
        
    }
};


export const getAutoCreatedPMAccounts = async() => {
    try {
        const response = await axiosHelper({
            httpVerb: 'get',
            routeName: `/settings/pm-account/auto-created`
        });
        return response.data
    } catch (error) {
        
    }
}


export const getAllSystemCharges = async() => {
    try {
        const response = await axiosHelper({
            httpVerb: 'get',
            routeName: `/settings/charges/all-system-charges`
        });
        // console.log("\n\t getAllSystemCharges ", response.data)
        return response.data
    } catch (error) {
        
    }
};

export const getAllSystemCredits = async() => {
    try {
        const response = await axiosHelper({
            httpVerb: 'get',
            routeName: `/settings/credits/all-system-credits`
        });
        // console.log("\n\t getAllSystemCredits ", response.data)
        return response.data
    } catch (error) {
        
    }
};


export const getAllBacktrackGuest = async() => {
    try {
        const response = await axiosHelper({
            httpVerb: 'get',
            routeName: `/backtrack/guests/individual/f-and-b`
        });
        // console.log("\n\t getAllBacktrackGuest ", response.data)
        return response.data
    } catch (error) {
        
    }
}

export const getReservationDetailsFromHistory = async(_id: any) => {
    try {
        // "/arrivals/individual/:id",
        const response = await axiosHelper({
            httpVerb: 'get',
            routeName: `/frontdesk/arrivals/individual/${_id}`
        });
        // console.log("\n\t getReservationDetailsFromHistory ", response.data)
        return response.data
    } catch (error) {
        
    }
}