import { Console } from 'console';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BsArrowRightCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { 
    // onRoomTypeChange, 
    onRoomChange, 
    onArrivalDateChange, 
    onDepartureDateChange,
    onDiscountChange,
    todaysDate,
    validateIndividualReservationDetails,
    onGuaranteedChange,
    onNumberOfAdultChange,
    createIndividualReservation,
    updateIndividualReservation,
    onMemberShipTypeChange,
    totalCostCalculator,
    yesterdaysFormDate
} from '../../../assets/events/individualReservations';
import { showConfirmModalAction, showFormModalAction, showProcessingGifAction } from '../../../redux/actions/storeActions/appActionsUtilities';
import axiosHelper from '../../../utilities/axiosHelper';
import { customAlert } from '../../../utilities/customAlert';
import { floorNumberHelper, getDetailsForIndividualReservation } from '../../../utilities/databaseHelpers';
import { todaysDateBoolean } from '../../../utilities/dateHelper';
import { titledTextJoiner } from '../../../utilities/strings';
import Modal from '../Modal';
import { rowActionFunctions } from '../Modules/Tables/tableData';
import { handleReservation, showSummary } from './individualReservationsHandlers';
import RegCard from './regCards/RegCard';


interface Props {
    reservationsData?: any
}

const actionButtons = ["Create", "Print reg-card", "Check-in", "Membership-Type Calculator", "Update"];
interface ReservationFormInterface {
    "Arrival Date": Date | string, 
    "Departure Date": Date | string,
    "Nights": string | number,
    "Room Type": string,
    "Room Rate": string | number,
    "Room Number": string | number, 
    "Adults": number, 
    "Firstname": string, 
    "Lastname": string, 
    "Email": string, 
    "Title": string, 
    "Address": string, 
    "Phone": string, 
    "Date of Birth": Date | string, 
    "Country": string, 
    "Nationality": string, 
    "State": string, 
    "City": string,
    "Membership Type": string, 
    "Membership Number": number, 
    "Passport Number": string, 
    "Source": string, 
    "Traces": string, 
    "Purpose": string, 
    "Is Reservation Guaranteed": string, 
    "Deposit": string,
    "PM Account": string
    "Discount": string, 
    "Total Cost": number
    [key: string]: string | number | Date
}

const IndividualReservation = (props: Props) => {
    // const paymentMethod = ["Cash", "Transfer", "Card", "Company"];
    const location = useLocation();
    const pathname = location.pathname;
    const [individualReservationDetails, setIndividualReservationDetails] = useState<ReservationFormInterface>({
        "Arrival Date": "", 
        "Departure Date": "",
        "Nights": "",
        "Room Type": "",
        "Room Rate": "",
        "Room Number": 0, 
        "Adults": 1, 
        "Firstname": "", 
        "Lastname": "", 
        "Email": "", 
        "Title": "", 
        "Address": "", 
        "Phone": "", 
        "Date of Birth": "", 
        "Country": "", 
        "Nationality": "", 
        "State": "", 
        "City": "",
        "Membership Type": "", 
        "Membership Number": 0, 
        "Passport Number": "", 
        "Source": "", 
        "Purpose": "", 
        "Discount": "", 
        "Is Reservation Guaranteed": "", 
        "Deposit": "", 
        "Traces": "", 
        "PM Account": "",
        "Total Cost": 0
    });
    const defaultFormData = useRef(individualReservationDetails).current;
    const reservationId = pathname.includes("update") && pathname.split("/")[3]

    const booleanOptions = ["Yes", "No"];
    const dispatch = useDispatch();
    const store = useSelector((store: any) => store);
    const { confirmModal, userDetails } = store;
    const { showConfirm } = confirmModal;
    const userData = userDetails ? userDetails.userDetails : undefined;
    // console.log("\n\t userData: ", userData)
    const showSummaryHandler = (e: any, action: string) => {
        showSummary({
            action,
            e,
            dispatch,
            formState,
            individualReservationDetails,
            pathname,
            setFormState
        })
    };
    const handleReservationHandler = () => {
        handleReservation({
            dispatch,
            formState,
            pathname,
            reservationId: reservationId || "",
            setFormState
        })
    }
    const [roomsUnderSelectedRoomType, setRoomsUnderSelectedRoomType] = useState([] as any);
    const [formState, setFormState] = useState({
        showRoomTypeRatesContainer: false,
        loadingPrompt: "Please wait, form data is loading....",
        showPaymentMethod: false,
        validationData: {} as any,
        printRegCard: false,
        confirmModalTitle: "",
        btnAction: "",
        optionalFormText: "",
        formType: "",
        roomTypes: [] as any[],
        formFields: [] as string[],
        fieldType: [] as string[],
        titles: [] as any,
        membershipTypes: [] as any[],
        countries: [] as any,
        formModalTitle: "",
        fieldValues: []as any[],
        rooms: [] as any,
        formStateInputs: {}as any,
        sources: [] as any,
        numberOfFields: 0,
        paymentMethod: [] as any,
        autoCreatedPMAccounts: [] as any[],
    });
    console.log("\n\t Formdata-roomTypes: ", formState.roomTypes)
    console.log("\n\t Formstate: ", formState)
    const handleIputChangeForCalculator = (field: string, value: string) => {
        console.log("\n\t handleIputChangeForCalculator: ", field, value)
        formState.formStateInputs[titledTextJoiner(field)] = value;
        setFormState({
            ...formState
        })
    }
    const handleIndividualInputChange = (field: string, value: string) => {
        const newDetails = individualReservationDetails;
        // console.log("\n\t handleIndividualInputChange: ", field, value)
        let totalCost = (document as any).querySelector(".total-cost").value;
        const nights = (document as any).querySelector(".nights").value;
        let roomRate = (document as any).querySelector(".room-rate").value;
        const adults = (document as any).querySelector(".adults").value;

        let memebershipTypeRoomRate = 0
        if(field === "Arrival Date"){
            onArrivalDateChange();
            setIndividualReservationDetails({
                ...defaultFormData
            })
        }else if(field === "Departure Date"){
            onDepartureDateChange();
            setIndividualReservationDetails({
                ...defaultFormData
            })
        }else if(field === "membership-type-calculator"){
            onDepartureDateChange();
            setIndividualReservationDetails({
                ...defaultFormData
            })
        }else if(field === "PM Account"){
            onDepartureDateChange();
        }else if(field == "Discount"){
            onDiscountChange();
        }else if(field.toLowerCase().includes('guaranteed')){
            onGuaranteedChange(value);
        }else if(field.toLowerCase() === "membership type"){
            onMemberShipTypeChange(value);
            const selectedRoomNumber = (document as any).querySelector(".room-number").value;
            // console.log("\n\t selectedRoomNumber: ", selectedRoomNumber)
            const selectedMembershipType = formState.membershipTypes.find((type: any) => type.membershipType === value);
            // console.log("\n\t selectedMembershipType: ", selectedMembershipType)
            const selectedRoomType = formState.roomTypes.find((roomType: any) => individualReservationDetails["Room Type"] === roomType.roomTypeName);
            // console.log("\n\t selectedRoomType: ", selectedRoomType)
            const roomPropsUnderSelectedRoomType = formState.rooms.find((room: any) => room.roomNumber === +selectedRoomNumber);
            // console.log("\n\t roomPropsUnderSelectedRoomType: ", roomPropsUnderSelectedRoomType)
            const actualRoomRateForRoomsUnderSelectedRoomType = roomPropsUnderSelectedRoomType.breakfast + selectedRoomType.roomTypeRate;
            // console.log("\n\t actualRoomRateForRoomsUnderSelectedRoomType: ", actualRoomRateForRoomsUnderSelectedRoomType)
            const discount = +(selectedMembershipType.discount);
            // console.log("\n\t discount: ", discount)
            const noDiscountPrice = (Number(individualReservationDetails['Nights']) * actualRoomRateForRoomsUnderSelectedRoomType);
            // console.log("\n\t noDiscountPrice: ", noDiscountPrice)
            const discountPrice = noDiscountPrice * (discount <= 100 ? (1 - (discount/100)) : (discount/100));
            const nonDecimalDiscount = +String(discountPrice).split(".")[0]
            // console.log("\n\t nonDecimalDiscount: ", +String(discountPrice).split(".")[0]);
            totalCost = totalCostCalculator(discount > 0 ? nonDecimalDiscount : noDiscountPrice);
            // console.log("\n\t totalCost: ", totalCost);
            const newRoomRate = totalCost/(+individualReservationDetails['Nights']);
            // roomRate = newRoomRate;
            // console.log("\n\t newRoomRate: ", newRoomRate);
            newDetails['Room Rate'] = newRoomRate;
            setIndividualReservationDetails({
                ...individualReservationDetails,
                "Room Rate": newRoomRate
            });
            memebershipTypeRoomRate = newRoomRate;
            (document as any).querySelector(".total-cost").value = totalCost;
            (document as any).querySelector(".room-rate").value = newRoomRate;
        }else if(field.toLowerCase() === "deposit"){
            setFormState({
                ...formState,
                showPaymentMethod: true
            })
        }else if(field.toLowerCase() === "room type"){
            memebershipTypeRoomRate = 0;
            const selectedRoomType = formState.roomTypes.find((roomType: any) => roomType.roomTypeName === value);
            (document as any).querySelector('.room-rate').value = selectedRoomType.roomTypeRate;
            
            let roomNumbers: any[] = [];
            const unOccupiedRooms = pathname.includes("backtrack") ? formState.rooms : formState.rooms.filter((room: any) => room.occupancyStatus !== "Occupied")
            const roomsUnderSelectedRoomType = selectedRoomType.rooms;
            roomsUnderSelectedRoomType.forEach((roomId: any) => {
                const room = unOccupiedRooms.find((room: any) => room._id === roomId)
                room && roomNumbers.push(room.roomNumber)
            });
            setRoomsUnderSelectedRoomType([...roomNumbers]);
            const numberOfAdults = document.querySelector(".adults");
            numberOfAdults!.setAttribute("_singleRate", selectedRoomType.roomTypeRate);
        }else if(field.toLowerCase() === "room number"){
            const roomRateCost = onRoomChange(formState.rooms);
            (document as any).querySelector(".adults").value = 1;
            totalCost = totalCostCalculator(roomRateCost);
            roomRate = roomRateCost/nights;
        }else if(field.toLowerCase() === "adults"){
            const resp = onNumberOfAdultChange(formState.rooms);
            if(typeof(resp) === "string") return customAlert("error", resp, 7000);
            totalCost = totalCostCalculator(resp);
            roomRate = resp/nights;
        }
        newDetails[field] = value
        setIndividualReservationDetails({
            ...newDetails,
            "Room Rate": memebershipTypeRoomRate || roomRate,
            "Total Cost": totalCost,
            "Nights": nights,
            "Adults": adults
        });
        
    };
    // 

    useEffect(() => {
        if(!pathname.includes("backtrack")){
            (document as any).querySelector(".arrival-date").min = todaysDate();
        }else if(pathname.includes("backtrack")){
            (document as any).querySelector(".arrival-date").max = yesterdaysFormDate();
        }
        (document as any).querySelector(".departure-date").min = todaysDate();
        (async () => {
            const { roomTypes, membershipTypes, paymentMethod, titles, allCountries, rooms, sources, autoCreatedPMAccounts } = await getDetailsForIndividualReservation() as any;
            roomTypes && setFormState({
                ...formState,
                roomTypes,
                titles,
                membershipTypes,
                countries: allCountries,
                rooms,
                sources,
                paymentMethod,
                autoCreatedPMAccounts,
                loadingPrompt: ""
            });
            // console.log("\n\t paymentMethod: ", paymentMethod)
            // roomTypes && setFormState({
            //     ...formState,
            // }) 
        })();
        if(pathname.includes('profiles')){
            console.log("\n\t reservationsData: ", props.reservationsData)
            const { firstName, nationality, address, membershipType, phoneNumber, lastName, email, title, state, city, country, } = props.reservationsData;
            setIndividualReservationDetails({
                ...individualReservationDetails,
                "Firstname": firstName,
                "Lastname": lastName,
                "Email": email,
                "Title": title,
                "State": state,
                "City": city,
                // "Departure Date": (departureDate.split("T"))[0],
                "Country": country,
                "Phone": phoneNumber,
                "Address": address,
                "Nationality": nationality,
                "Membership Type": membershipType
            })
        };
        reservationId && (
            async () => {
                const response = await axiosHelper({
                    routeName: `/reservations/edit/individual/get-details/${reservationId}`,
                    httpVerb: "get"
                });
                const { firstName, departureDate, arrivalDate, totalCost, roomNumber, nationality, numberOfAdult, address, roomRate, phoneNumber, roomType, lastName, days, email, title, state, city, purpose, country, } = response.data;
                setIndividualReservationDetails({
                ...individualReservationDetails,
                "Firstname": firstName,
                "Lastname": lastName,
                "Email": email,
                "Title": title,
                "State": state,
                "City": city,
                "Purpose": purpose,
                "Arrival Date": (arrivalDate.split("T"))[0],
                "Departure Date": (departureDate.split("T"))[0],
                "Country": country,
                "Nights": days,
                "Room Type": roomType,
                "Room Rate": roomRate,
                "Phone": phoneNumber,
                "Address": address,
                "Adults": numberOfAdult,
                "Nationality": nationality,
                "Room Number": roomNumber,
                "Total Cost": totalCost,
            })
        })();
    }, []);
    // console.log("\n\t individual-formState: ", formState);
    return (
        <React.Fragment>
            {
                formState.printRegCard ?
                <React.Fragment>
                    <RegCard
                        reservationDetails={{
                            guestName: `${individualReservationDetails.Firstname} ${individualReservationDetails.Lastname}`,
                            totalCost: +individualReservationDetails['Total Cost'],
                            title: individualReservationDetails.Title,
                            arrivalDate: individualReservationDetails['Arrival Date'],
                            departureDate: individualReservationDetails['Departure Date'],
                            roomType: individualReservationDetails['Room Type'],
                            nights: individualReservationDetails.Nights,
                            roomNumber: individualReservationDetails['Room Number'],
                            phoneNumber: individualReservationDetails.Phone,
                            nationality: individualReservationDetails.Nationality,
                            country: individualReservationDetails.Country,
                            deposit: individualReservationDetails.Deposit,
                            email: individualReservationDetails.Email,
                            ...individualReservationDetails
                        }}
                        setPrintRegCard={() => {
                            setFormState({
                                ...formState,
                                printRegCard: false
                            })
                        }}
                        groupCheckInData={undefined}
                        // setGroupCheckInData={setGroupCheckInData}
                    />
                    <button 
                        className='btn btn-danger mx-4 my-2 '
                        onClick={() => {
                            setFormState({
                                ...formState,
                                printRegCard: false
                            })
                        }}>
                        Close
                    </button>
                </React.Fragment>
                :
                <div className="card shadow-lg border-0 rounded-lg mt-lg-4 mt-md-3">
                    {
                        showConfirm &&
                        <Modal 
                            type={formState.formType ? "form" : "confirm"}
                            formFields={formState.formFields}
                            fieldValues={formState.fieldValues}
                            formFieldType={formState.fieldType}
                            handleAction={() => handleReservationHandler()}
                            onChange={handleIputChangeForCalculator}
                            previewData={true}
                            formInput={formState.formStateInputs}
                            pathname={pathname}
                            serverSelectOption={{
                                roomType: Object.values(formState.roomTypes).map(data => data.roomTypeName).sort()
                            }}
                            optionalFormText={formState.optionalFormText}
                            setGroupCheckInData={() => undefined}
                            numberOfFields={formState.numberOfFields}
                            // stopFormModalSubmission={true}
                            title={formState.formType ? formState.formModalTitle : formState.confirmModalTitle}
                        />
                    }
                    <React.Fragment>
                        <div className="card-header">
                            <h3 className="text-center font-weight-light mt-2">
                                {pathname.includes('update') ? "Update Guest Reservation Details" : `${pathname.includes('backtrack') ? "Backtracking Reservation Form" : "Guest Reservation Form"}`}
                            </h3>
                            <p className="text-center text-secondary font-weight-bold">
                                {pathname.includes('update') ? 
                                `(Updating reservation details for ${individualReservationDetails['Firstname']} ${individualReservationDetails['Lastname']})` 
                                : pathname.includes('backtrack') ? 
                                <React.Fragment>
                                    {
                                        <p>
                                            {userData ? userData.firstName.concat(" ", userData.lastName) : ""} is bactracking a reservation.
                                            <p> Please verify all data before performing the intended action(s).</p>
                                        </p>
                                    }
                                </React.Fragment>
                                : "(This reservation form is for individual guests)"}
                            </p>
                            {
                                pathname.includes('update') &&
                                <p className="text-center text-secondary font-weight-bold">
                                    <strong className="text-danger">N.B:</strong> To correctly update the reservation details, <br /> you must re-select the room-type and room-number; irrespective of being pre-filled.
                                </p>
                            }
                            <span className="font-weight-bolder small text-success animate__animated animate__pulse animate__infinite">{formState.loadingPrompt}</span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-6 col-md-6">
                                    <div className="card bg-white text-dark mb-4">
                                        <div className="card-body">
                                            <h5 className="card-title"></h5>
                                            <div className="form-row">
                                            {
                                                Object.keys(individualReservationDetails).slice(0, 18).map((field, index) => (
                                                    <div key={field} className={field.toLowerCase() === "nights" || field.toLowerCase() === "email"  ? "col-md-12" : "col-md-6"}>
                                                        <div className="form-group">
                                                            <label className="small mb-1">{field}</label>
                                                            {
                                                                field.toLowerCase() === "country" ?
                                                                <div className="form-group">
                                                                    <input 
                                                                        value={individualReservationDetails[field] as string || undefined} 
                                                                        onChange={e => handleIndividualInputChange(field, e.target.value)} 
                                                                        className="form-control country" 
                                                                        list="countries" name="country" type="text" id="country" />
                                                                    <datalist id="countries">
                                                                        {
                                                                            formState.countries && Object.values(formState.countries as string[]).map(country => (
                                                                                <option key={country} value={country} ></option>
                                                                            ))
                                                                        }
                                                                    </datalist>
                                                                </div>
                                                                :
                                                                !field.toLowerCase().includes("type") && !field.toLowerCase().includes("room number") && field.toLowerCase() !== "title" ?
                                                                <input 
                                                                    className={`form-control ${field.toLowerCase().replaceAll(" ", "-")}`} 
                                                                    type={
                                                                        field.toLowerCase().includes("date") ? "date" 
                                                                        : field.toLowerCase().includes("rate") || field.toLowerCase().includes("adult") ? "number" 
                                                                        : field.toLowerCase() === "email" ? 'email' 
                                                                        : field.toLowerCase().includes('phone') ? "tel"  
                                                                        : "text"
                                                                    } 
                                                                    min={field.toLowerCase().includes("adult") ? 1 : undefined}
                                                                    required 
                                                                    readOnly={field.toLowerCase().includes("rate") || field.toLowerCase().includes("nights") ? true : false}
                                                                    onChange={(e) => handleIndividualInputChange(field, e.target.value)} 
                                                                    defaultValue={individualReservationDetails[field] as any || undefined} 
                                                                />
                                                                :
                                                                <select
                                                                    value={pathname.includes('update') ? individualReservationDetails[field] as any: undefined} 
                                                                    onChange={(e) => handleIndividualInputChange(field, e.target.value)} 
                                                                    defaultValue={individualReservationDetails[field] as any || undefined} 
                                                                    className={`form-select form-control ${field.toLowerCase().replaceAll(" ", "-")}`} >
                                                                    <option value="">{`Select ${field}`}</option>
                                                                    {
                                                                        field.toLowerCase() === "room type" ?
                                                                        formState.roomTypes && formState.roomTypes.map((roomType: any, index: number) => (
                                                                            <option 
                                                                                key={index} 
                                                                                // onClick={e => handleIndividualInputChange(field, e.target.va)} 
                                                                                value={roomType.roomTypeName}>
                                                                                    {roomType.roomTypeName}
                                                                            </option>
                                                                        ))
                                                                        :
                                                                        field.toLowerCase() === "title" ?
                                                                        formState.titles && Object.values(formState.titles as any).map((title: any) => (
                                                                            <option key={title.titleCode} value={title.titleCode}>{title.titleCode}</option>
                                                                        ))
                                                                        :
                                                                        field.toLowerCase() === 'room number' &&
                                                                        roomsUnderSelectedRoomType && Object.values(roomsUnderSelectedRoomType as any).map((roomNumber: any) => (
                                                                            <option key={roomNumber} value={roomNumber}>{roomNumber}</option>
                                                                        ))
                                                                        
                                                                    }
                                                                </select>
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-md-6">
                                <div className="card bg-white text-dark mb-4">
                                    <div className="card-body">
                                        <h5 className="card-title"></h5>
                                        <div className="form-row">
                                            {
                                                Object.keys(individualReservationDetails).slice(18).map((field, index) => (
                                                    <div key={field} className={field.toLowerCase() === "nights" || field.toLowerCase() === "email" || field.toLowerCase() === "total cost" || field.toLowerCase().includes('account') ? "col-md-12" : "col-md-6"}>
                                                        <div className="form-group">
                                                            {
                                                                !field.toLowerCase().includes("payment") &&
                                                                <label className="small mb-1">
                                                                    {
                                                                        field.toLowerCase() === "discount" ? field+'(%)' 
                                                                        : field.toLowerCase().includes('guaranteed') ? field+'?' 
                                                                        : field.toLowerCase().includes('account') ? <span>
                                                                            {field}: <span className="text-danger">(If this guest previously checked-out, prior to the expected checkout-date.)</span>
                                                                        </span>
                                                                        : field
                                                                    }
                                                                </label>
                                                            }
                                                            {
                                                                !field.toLowerCase().includes("traces") && !field.toLowerCase().includes("payment") && !field.toLowerCase().includes("account") && !field.toLowerCase().includes("type") && field.toLowerCase() !== "source" && !field.toLowerCase().includes("guaranteed")  ?
                                                                <section>
                                                                    <input
                                                                        value={pathname.includes('update') ? individualReservationDetails[field] as string : undefined} 
                                                                        className={`form-control ${field.toLowerCase().replaceAll(" ", "-")}`} 
                                                                        type={field.toLowerCase().includes("date") ? "date" : field.toLowerCase().includes("rate") || field.toLowerCase() === "deposit" ? "number" : "text"} 
                                                                        required 
                                                                        min={field.toLowerCase() === 'deposit' ? 1 : undefined}
                                                                        disabled={(field.toLowerCase() === "discount" || field.toLowerCase() === "deposit") ? true : false}
                                                                        readOnly={field.toLowerCase().includes("cost") ? true : false}
                                                                        onChange={e => handleIndividualInputChange(field, e.target.value)} 
                                                                        defaultValue={field.toLowerCase() === "purpose" ? "Lodging" : individualReservationDetails[field] as any}
                                                                        // defaultValue={individualReservationDetails[field] as any || undefined} 
                                                                    />
                                                                    {
                                                                        field.toLowerCase() === 'deposit' && individualReservationDetails['Is Reservation Guaranteed'].toLowerCase() === 'yes' &&
                                                                        <span className='text-danger text-center d-flex row justify-content-center small'>Enter the amount being deposited.</span>
                                                                    }
                                                                    {
                                                                        field.toLowerCase() === "deposit" && formState.showPaymentMethod &&
                                                                        <article  className="d-flex text-center row justify-content-center small mt-2" style={{width: "100%", flexDirection: "column"}}>
                                                                            <label className="mx-1 small ">Payment Method:</label>
                                                                            <span className="row d-flex justify-content-center ">
                                                                                {
                                                                                    formState.paymentMethod.map((method: any) => (
                                                                                        <span className="align-items-center justify-content-center d-flex">
                                                                                            {
                                                                                                !method.description.toLowerCase().includes('charge to') && !method.description.toLowerCase().includes('none') &&
                                                                                                <span className="mx-2" key={method.paymentMethodCode}>
                                                                                                    <input onChange={e => handleIndividualInputChange('Payment Method', method.description)} name="paymentMethod" style={{display: "flex", justifyContent: "center", width: "100%"}} type="radio" /> 
                                                                                                    {method.description}
                                                                                                </span>
                                                                                            }
                                                                                        </span>
                                                                                    ))
                                                                                }
                                                                            </span>
                                                                        </article>
                                                                    }
                                                                </section>
                                                                :
                                                                field.toLowerCase() === "traces" ?
                                                                <textarea
                                                                    style={{resize: "none"}}
                                                                    rows={3}
                                                                    cols={75}
                                                                    value={pathname.includes('update') ? individualReservationDetails[field] as string : undefined} 
                                                                    defaultValue={field.toLowerCase() === "purpose" ? "Lodging" : individualReservationDetails[field] as any}
                                                                    placeholder={field.toLowerCase() === "traces" ? "Allergies or Special preferences (Use coma to seperate items)" : undefined}
                                                                    onChange={e => handleIndividualInputChange(field, e.target.value)} 
                                                                />
                                                                :
                                                                !field.toLowerCase().includes("payment") &&
                                                                <select  
                                                                    value={pathname.includes('update') ? individualReservationDetails[field] as string : undefined} 
                                                                    onChange={e => handleIndividualInputChange(field, e.target.value)}
                                                                    defaultValue={individualReservationDetails[field] as any || undefined} 
                                                                    className={`form-select form-control ${field.toLowerCase().replaceAll(" ", "-")}`} >
                                                                    <option value="">{`Select ${field.toLowerCase().includes('guaranteed') ? "" : field}`}</option>
                                                                    {
                                                                        field.toLowerCase().includes("guaranteed") ?
                                                                        <React.Fragment>
                                                                            {
                                                                                booleanOptions.map(bool => (
                                                                                    <option key={bool} value={bool}>{bool}</option>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                        :
                                                                        field.toLowerCase() === "source" ?
                                                                        formState.sources && Object.values(formState.sources as any).map((source: any) => (
                                                                            <option key={source.sourceCode} value={source.sourceCode}>{source.sourceCode}</option>
                                                                        ))
                                                                        :
                                                                        field.toLowerCase().includes("account") ?
                                                                        formState.autoCreatedPMAccounts && Object.values(formState.autoCreatedPMAccounts as any).map((account: any, index: number) => (
                                                                            <option key={index} value={account.accountName}>{account.accountName}</option>
                                                                        ))
                                                                        :
                                                                        field.toLowerCase() === "membership type" &&
                                                                        formState.membershipTypes && Object.values(formState.membershipTypes).map((membershipType: any, index) => (
                                                                            <option key={membershipType.membershipType} value={membershipType.membershipType}>{membershipType.membershipType}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                                <div className="form-group mt-4 mb-0">
                                                    {
                                                        actionButtons.slice(
                                                            pathname.includes('backtrack') ? 1 : pathname.includes('update') ? 4 : 0,
                                                            pathname.includes('backtrack') ? 4 : pathname.includes('update') ? 5 : 4
                                                            ).map(action => (
                                                            <button 
                                                                disabled={
                                                                    action.toLowerCase().includes('print') ? todaysDateBoolean(individualReservationDetails['Arrival Date'], pathname) 
                                                                    : action.toLowerCase().includes('check') ? todaysDateBoolean(individualReservationDetails['Arrival Date'], pathname) 
                                                                    // : action.toLowerCase() === 'create' ? !todaysDateBoolean(individualReservationDetails['Arrival Date']) 
                                                                    : undefined
                                                                }
                                                                data-toggle="modal" 
                                                                data-target="#modal" 
                                                                onClick={e => showSummaryHandler(e, action.toLowerCase())} 
                                                                key={action}
                                                                className="button create-individual-reservation mx-2">
                                                                    {action}
                                                            </button>
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    <section className='mt-2'>
                                                        <p className="mx-2 font-weight-bold" style={{textDecoration: "underline", fontSize: "16px"}}>Definitions:</p>
                                                        {
                                                            definitions.map((variable) => (
                                                                <article key={variable} className="individualsCheckedInDefinitions">
                                                                    <p style={{fontSize: "14px"}} className="d-flex align-items-center">
                                                                        <span><BsArrowRightCircle /></span>
                                                                        <span className="mx-2 mt-1 font-weight-bold">{variable}</span> 
                                                                        {/* <span className="mt-1">{variable}</span> */}
                                                                    </p>
                                                                </article>
                                                            ))
                                                        }
                                                    </section>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </React.Fragment>
                </div>
            }
        </React.Fragment>
    )
}


const definitions = [
    "You can't print reg-cards, if the guest is not arriving today.",
    "You can't check-in, if the guest is not arriving today.",
]
export default IndividualReservation

