import axios, { Axios } from "axios"
import {getStorageItem} from "./localStorage"

interface AxiosHelperInterface {
    httpVerb: "post" | "get" | "put" | "delete"
    routeName: string,
    dataToPostEditOrDelete?:  any
};



const axiosHelper = async (props: AxiosHelperInterface): Promise<any> => {
    // console.log("\n\t props.routeName: ", window.location)
    // console.log("\n\t props.routeName: ", window.location.origin.split("//")[1]);
    const origin = window.location.origin.split("//")[1];
    const BASE_URL = origin.includes('ikeja') ? process.env.REACT_APP_REMOTE_PRINCIPAL_BASE_URL
    : origin.includes('lekki') ? process.env.REACT_APP_BON_LEKKI_BASE_URL
    : origin.includes('garden-city') ? process.env.REACT_APP_BON_GARDEN_CITY_BASE_URL
    : origin.includes('platinum') ? process.env.REACT_APP_BON_PLATINIUM_ENUGU_BASE_URL
    : origin.includes('ekiti') ? process.env.REACT_APP_BON_EKITI_BASE_URL
    : origin.includes('grandtowers') ? process.env.REACT_APP_BON_GRAND_TOWERS_BASE_URL
    : origin.includes('asokoro') ? process.env.REACT_APP_BON_ASOKORO_RES_BASE_URL
    : origin.includes('demo') ? process.env.REACT_APP_HOTEL_PRO_DEMO_BASE_URL
    : origin.includes('transtell') ? process.env.REACT_APP_HOTEL_PRO_TRANSTELL_BASE_URL
    : origin.includes('boutique') ? process.env.REACT_APP_BON_BOUTIQUE_BASE_URL
    : process.env.REACT_APP_LOCAL_BASE_URL;

    
    
    try {
        const accessToken = await getStorageItem("accessToken");
        // console.log("\n\t BASE_URL: ", BASE_URL);
        switch(props.httpVerb){
            case props.httpVerb = "get":
                const getServerResponse = await axios.get(`${BASE_URL}${props.routeName}`, {
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        authorization: `Bearer ${accessToken}`
                    },
                });
                // console.log("\n\t getServerResponse: ", getServerResponse);
                return  getServerResponse.data;
            case props.httpVerb = "post":
                const postServerResponse = await axios.post(`${BASE_URL}${props.routeName}`, {...props.dataToPostEditOrDelete}, {
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        authorization: `Bearer ${accessToken}`
                    },
                });
                return postServerResponse.data;
            case props.httpVerb = "put":
                const putServerResponse = await axios.put(`${BASE_URL}${props.routeName}`, {...props.dataToPostEditOrDelete}, {
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        authorization: `Bearer ${accessToken}`
                    },
                });
                return putServerResponse.data;
            case props.httpVerb = "delete":
                const deleteServerResponse = await axios.delete(`${BASE_URL}${props.routeName}`, {
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        authorization: `Bearer ${accessToken}`
                    },
                });
                return deleteServerResponse.data;
            default:
                return undefined
        }
    } catch (error: any) {
        // console.log("\n\t error: ", error);
        // console.log("\n\t error.response: ", error.response);
        if(error.response){
            // console.log("\n\t error.response: ", error.response);
            return error.response.data.message;
        }else{
            return "Network error";
        }
    }
};        
export default axiosHelper;


export const axiosErrorHelper = (error: any) => {
    if(error.response){
        return error.response.data.message;
    }else{
        return "Network error";
    }
}