import React, { useEffect, useState } from "react"
import Gifs from "../../../../Gifs";
import "../../../../../styles/TrialBalanceStyles.css";
import { givenDateAndCurrentTime } from "date-fran";
import axiosHelper from "../../../../../utilities/axiosHelper";
import { financialTransactionsRecords } from "../tableActions/tableActionsThree";
import { useLocation } from "react-router-dom";

interface CashiersReportInterface {
    dataToDetail: any

};

export const getCashiersName = (tableData: any[]) => {
    const cashiersName = []as string[];
    tableData.forEach((data:any) => {
        !cashiersName.includes(data.teller) && cashiersName.push(data.teller)
    })
    return cashiersName
};
const CashiersSummaryReport = (props: CashiersReportInterface) => {
    const pathname = useLocation().pathname;
    const [cashiersState, setCashiersState] = useState({
        referenceDate: "",
        tableData: []as any[],
        cashiersData: []as any[],
        cashiersName: []as string[],
        overallTotal: 0
    });
    const getPaymentMethodAndSum = (data: any[]) => {
        const paymentMethodAndSum = {}as any;
        let creditTotal = 0;
        data.forEach((_data:any) => {
            const { credit, paymentMethod } = _data;
            paymentMethodAndSum[paymentMethod] = paymentMethodAndSum[paymentMethod] ? paymentMethodAndSum[paymentMethod]+credit : credit;
            creditTotal += credit;
        });
        paymentMethodAndSum["Total"] = creditTotal.toLocaleString();
        return paymentMethodAndSum;
    };
    // console.log("\n\t CashiersSummaryReport: ", props.tableData);
   
    const getCashiersData = (tableData: any[], cashiersName: string[]) => {
        const cashiersData = {}as any;
        cashiersName.forEach(cashierName => {
            const dataRelatingToCashier = tableData.filter(data => cashierName === data.teller);
            const cashiersSummedCreditAndPaymentMethod = getPaymentMethodAndSum(dataRelatingToCashier);
            // console.log("\n\t cashiersSummedCreditAndPaymentMethod: ", cashiersSummedCreditAndPaymentMethod)
            cashiersData[cashierName] = cashiersSummedCreditAndPaymentMethod;
        });
        return cashiersData
    };
    useEffect(() => {
        (async () => {
            console.log("\n\t props.tableData: ", props.dataToDetail);
            // await serverPaginator();
            console.log("\n\t dataToDetail: ", props.dataToDetail)
            const dataToDetail = props.dataToDetail;
            cashiersState.referenceDate = dataToDetail.recordDate;
            const recordResponse = await axiosHelper({
                httpVerb: "get",
                routeName: `${pathname}/${dataToDetail.recordDate}`
            });
            console.log("\n\t recordResponse: ", recordResponse)
            const data = recordResponse ? recordResponse.data : {data: []}
            const transactionsData = financialTransactionsRecords(data, pathname)

            // recordsData(data, true);
            const cashiersName = getCashiersName(transactionsData);
            if(cashiersName.length > 0){
                const cashiersData = getCashiersData(transactionsData, cashiersName);
                let total = 0;
                Object.values(cashiersData).forEach((data:any) => {
                    const req = data[data.length-1]
                    total += Number(data["Total"].replaceAll(",", ""));
                })
                setCashiersState({
                    ...cashiersState,
                    cashiersName,
                    cashiersData,
                    overallTotal: total
                });
            }
        })();
    }, []);
    const subHeaders = ["Payment Methods", "Amount"]
    // console.log("\n\t cashiersState-cashiersData: ", cashiersState)
    return (
        <main  className='trial-balance-table-container'>
            <div className="mt-4 row"></div>
            <section className="mt-2 trial-balance-header">
                <span className='text-center'>
                    <Gifs gifName='hotel-logo' />
                </span>
                {
                    cashiersState.referenceDate &&
                    <h5 className="text-center mt-2 font-weight-bold">Cashiers Summary Report: {cashiersState.referenceDate}</h5>
                }
                <hr />
            </section>
            <section className="ml-1 ">
                <table className="trial-balance-table-table table bg-light table-striped table-hover table-bordered">
                    {
                        cashiersState.cashiersName.length > 0 && cashiersState.cashiersName.map(cashierName => (
                            <React.Fragment>
                                <thead key={cashierName}>
                                    <tr>
                                        <th colSpan={12} className="text-center">{cashierName}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {
                                            subHeaders.map(header => (
                                                <th key={header}>{header}</th>
                                            ))
                                        }
                                    </tr>
                                    {
                                        Object.keys(cashiersState.cashiersData[cashierName as any]).map(methodName => (
                                            <tr key={methodName}>
                                                <td>{methodName}</td>
                                                <td>{cashiersState.cashiersData[cashierName as any][methodName].toLocaleString()}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </React.Fragment>
                        ))
                    }
                </table>
                <table className="trial-balance-table-table table bg-light table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th>Net</th>
                            <th>{cashiersState.overallTotal.toLocaleString()}</th>
                        </tr>
                    </thead>
                </table>
            </section>
            <section className="print-btn-container float-right mb-4 mr-5">
                <button onClick={() => window.print()} className="btn btn-primary mb-5 mr-4">
                    Print
                </button>
            </section>
        </main>
    )
};



export default CashiersSummaryReport;