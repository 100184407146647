import React, { useEffect, useState, lazy, Suspense } from 'react'
import "../../../../styles/TableStyles.css";

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { titleCase, titledTextJoiner } from '../../../../utilities/strings';

import { changeRoomExtension, frontdeskHandleFormChange, frontdeskState, handleFrontdeskActions } from './tableActions/tableActionsOne';
import {
    frontdeskDashboardRouteChecker, 
    individualsHistoryTableHeader, 
    paginationButtons, 
} from './tableData';

import { showConfirmModalAction, showFormModalAction, toggleFooterAction } from '../../../../redux/actions/storeActions/appActionsUtilities';
import { inputValidator } from '../../../../utilities/security';
import { customAlert } from '../../../../utilities/customAlert';
import { BsArrowRightCircle } from 'react-icons/bs';
import Gifs from '../../../Gifs';
import axiosHelper from '../../../../utilities/axiosHelper';
import { frontdeskTableActions } from './subTables/subTableHandleActions';
import { handleDataPagination } from './tableActions/tableActionsThree';
import Modal from '../../Modal';
import RegCard from '../../Forms/regCards/RegCard';
import FrontdeskSubTable from './subTables/FrontdeskSubTable';
import Filter from '../../Filter';
import ModuleSummary from '../ModuleSummary/ModuleSummary';
import { getAllSystemCharges, getAllSystemCredits, getReservationDetails } from '../../../../utilities/databaseHelpers';
import FolioAndReceipt from '../../Forms/FolioAndReceipt';

// const Filter = lazy(() => import('../../Filter')) ;
const Details = lazy(() => import('./Details')) ;
// const ModuleSummary = lazy(() => import('../ModuleSummary/ModuleSummary'));
// const FrontdeskSubTable = lazy(() => import('./subTables/FrontdeskSubTable'));
interface Props {

}

// Profile", "Folios",
export const individualFrontdeskActions = ["Add Charges", "Add Credits", "Reverse Charges", "Reverse Credits", "Pay Balance", "Transfer Charges", "Edit Details", "Change Room", "Guest Bill", "Reg. Card", "Backtrack Guest", "Check-Out",  "Extend Stay", "Assign To PM-Account",];
export const individualsCheckedInDefinitions = {
    "Positive Balance": "A positive balance implies that the guest has outstanding debts to clear-out, before checking-out.",
    "Negative Balance": "A negative balance implies that the hotel owes the guest, an amount equal to the indicated figure.",
    "Debit value greater than zero": "Signifies that a guest owes the hotel an amount equivalent to the indicated figure",
    "Credit value greater than zero": "Signifies that the hotel owes the guest an amount equivalent to the indicated figure.",
    "Refund": "Debits the account",
    "Pay-Debt": "Credits the account",
};
export const actionsInvolvingGuestsBill = ["transfer charges", "transfer bill", "transfer balance", "reverse credits", "reverse charges"]
const FrontdeskTable = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;

    const [frontdesksState, setFrontdesksState] = useState({
        tableData: [] as any,
        showDetails: false,
        previewData: false,
        btnAction: "",
        minDate: "",
        maxDate: "",
        generateSecurityReport: false,
        dataToBeDetailed: [],
        utilityData: {}as any,
        tableHeaderForDataToBeDetailed: [],
        groupName: "",
        numberOfFields: 0,
        detailsDataTableTitle: "",
        paginationStartPoint: 0,
        paginationLength:  pathname.includes('room') ? 15 : 10,
        paginationEndPoint: pathname.includes('room') ? 15 : 10,
        paginatedTableData: [] as any,
        actualLengthOfTableData: 0,
        footerArray: [],
        printGuestBill: false,
        printRegCard: false,
        serverSelectOption: {} as any,
        radioOptions: [] as any,
        formModalTitle: "",
        formModalSaveButtonName: "",
        formFields: [] as string[],
        formFieldTypes: [] as string[],
        formFieldPlaceholder: []as any[],
        confirmModalTitle: "",
        formInputs: {} as any,
        appModulesData: [] as any[], 
        readOnlyFields: [] as boolean[],
        defaultSelectOption: {}as any,
        membershipTypes: []as any[],
        guestBills: []as any[],
        modalFormEditFlag: false,
        modalFormInstruction: "",
        allSystemCredits: []as any[],
        allSystemCharges: []as any[],
        reservationData: {}as any,
        groupCheckInData: {}as any,
        groupCheckInFormData: {}as any,
        titles: []as any[],
        historyDetails: []as any[],
        fAndBData: {}as any,
        loadingPrompt: "Please wait, frontdesk data is being fetched...."
    });

    const handlePagination = (btnName: string) => {
        handleDataPagination({
            paginationBtnName: btnName,
            state: frontdesksState,
            stateHandler: setFrontdesksState
        })
    };
  
    const dispatch = useDispatch();
    const store = useSelector((store: any) => store);
    const { confirmModal, modalStatus, sidebarStatus } = store
    const { showSidebar } = sidebarStatus;
    const { showConfirm } = confirmModal;
    const { showFormModal } = modalStatus;
    const formModalStatus: boolean = showFormModal ? showFormModal.modalStatus : false;

    const handleAction = () => {
        frontdeskTableActions({
            frontdesksState,
            pathname,
        });
    };
    const handleFormChange = (field: string, value: string) => {
        // console.log("\n\t handleFormChange: ", field, value);
        frontdeskHandleFormChange({
            field,
            frontdesksState,
            setFrontdesksState,
            value,
            btnAction: frontdesksState.btnAction
        })
    };
    // console.log("\n\t frontdesksState.tableData: ", frontdesksState.tableData)
    // console.log("\n\t frontdesksState.serverSelectOption: ", frontdesksState.serverSelectOption)

    const frontdeskActionsHandler = async (action: string, reservationData: any) => {
        if (action.toLowerCase().includes('reg. card')) {
            return setFrontdesksState({
                ...frontdesksState,
                printRegCard: true,
                printGuestBill: false, 
                reservationData
            });
        };
        // console.log("\n\t action: ", action)
        if(actionsInvolvingGuestsBill.includes(action.toLowerCase())){
            // If you're going to call a backend-service and dispatch a frontdesk state effect,
            // first dispatch the frontdesk state effect; before calling the backend service. This explains why
            // there's a dispatxh call here, before the axios-call
            dispatch(showConfirmModalAction(false));
            dispatch(showFormModalAction(true));
            const guestbillResponse = await axiosHelper({
                httpVerb: "get",
                routeName: `/audit-reports/get-${pathname.includes('pm-account') ? 'pm-account' : 'individual'}-guestbill/${reservationData._id}`
            });
            if(action.toLowerCase() === 'transfer balance' || action.toLowerCase() === 'transfer bill'){
                const guestInHouseResponse = await axiosHelper({
                    httpVerb: "get",
                    routeName: `/frontdesk/checked-in/individual`
                });
                // console.log("\n\t guestInHouseResponse: ", guestInHouseResponse)
                reservationData["guestsInHouse"] = guestInHouseResponse.data;
            }
            // console.log("\n\t guestbillResponse: ", guestbillResponse)
            reservationData["guestsBill"] = guestbillResponse.data;
        };
        if(pathname.includes("history") && !frontdesksState.showDetails && !frontdesksState.printGuestBill){
            setFrontdesksState({
                ...frontdesksState,
                loadingPrompt: "Please wait, frontdesk data is being fetched...."
            });
            const recordResponse = await axiosHelper({
                httpVerb: "get",
                routeName: `${pathname}/${reservationData.recordDate}`
            });
            const data = recordResponse ? recordResponse : {data: []};
            // console.log("\n\t data.data: ", data.data)
            setFrontdesksState({
                ...frontdesksState,
                showDetails: true,
                historyDetails: data.data,
                // tableData: data.data,
                tableHeaderForDataToBeDetailed: individualsHistoryTableHeader.slice(0, 16) as any,
                loadingPrompt: "",
                detailsDataTableTitle: `Check-out History For: ${reservationData.recordDate} - (${data.data.length} Guests).`
            })
        }else{
            handleFrontdeskActions({
                pathname,
                action,
                dispatch,
                reservationData,
                setDetailsData: setFrontdesksState,
                setFrontdesksState,
                frontdesksState,
                // setGroupCheckInFormData,
                detailsStatus: frontdesksState.showDetails
            })
        }
    };
    const modulesState = useSelector((state:any) => state.modulesData);
    // console.log("\n\t FDesk tableData: ", frontdesksState.tableData)
    // console.log("\n\t FDesk dataToBeDetailed: ", frontdesksState.dataToBeDetailed)
    useEffect(() => {
        (async () => {
            const [response, utilityData, allSystemCharges, allSystemCredits] = await Promise.all([
                axiosHelper({   // table data for frontdesk sidebar menus
                    routeName: pathname,
                    httpVerb: "get"
                }),
                frontdeskState(),
                getAllSystemCharges(),
                getAllSystemCredits()
            ]);
            console.log("\n\t response: ", response);
            frontdesksState.appModulesData = modulesState.modulesData ? modulesState.modulesData[0] : [];
            frontdesksState.allSystemCharges = allSystemCharges;
            frontdesksState.allSystemCredits = allSystemCredits;
            frontdesksState.utilityData = utilityData;
            // console.log("\n\t utilityData: ",utilityData)
            // console.log("\n\t frontdesksState.appModulesData: ", frontdesksState.appModulesData);
            if(pathname === "/frontdesk") {
                const tableDataFromServer: any[] = Object.values(response.data.tableData)
                // console.log("\n\t frontdesksState.appModulesData: ", frontdesksState.appModulesData)
                setFrontdesksState({
                    ...frontdesksState,
                    tableData: [...tableDataFromServer[0], ...tableDataFromServer[1]],
                    actualLengthOfTableData: tableDataFromServer[0].length + tableDataFromServer[1].length,
                    loadingPrompt: "",  // after loading the required data hide the loading text
                    paginatedTableData: [...tableDataFromServer[0], ...tableDataFromServer[1]].slice(frontdesksState.paginationStartPoint, frontdesksState.paginationEndPoint),
                });
            } else {
                setFrontdesksState({
                    ...frontdesksState,
                    tableData: pathname.includes('history') ? response.data : Object.values(response.data),
                    paginatedTableData: pathname.includes('history') ? response.data.slice(0, frontdesksState.paginationLength) : (Object.values(response.data)).slice(frontdesksState.paginationStartPoint, frontdesksState.paginationEndPoint),
                    loadingPrompt: "",  // after loading the required data hide the loading text
                    actualLengthOfTableData: (Object.values(response.data)).length,
                })
            };
        })();
    }, [pathname]);
    const toggleModals = () => {
        const validation = inputValidator(frontdesksState.formInputs, Object.keys(frontdesksState.formInputs).length, Object.keys(frontdesksState.formInputs));
        if (typeof (validation) === "string") {
            return customAlert("error", validation as string);
        };
        dispatch(showFormModalAction(false));
        dispatch(showConfirmModalAction(true));
    };
    const handleFolioToggle = (guestsBill: any) => {
        // console.log("\n\t handleFolioToggle-tableData: ", frontdesksState.tableData)
        // console.log("\n\t handleFolioToggle-dataToBeDetailed: ", frontdesksState.dataToBeDetailed)
        setFrontdesksState({
            ...frontdesksState,
            btnAction: "",
            printGuestBill: false,
            printRegCard: false,
            reservationData: {...frontdesksState.reservationData, guestsBill}
        })
        dispatch(toggleFooterAction(true));
    };
    const setShowRowDetailsForDetailsComponent=() => {
        setFrontdesksState({
            ...frontdesksState,
            showDetails: !frontdesksState.showDetails,
            dataToBeDetailed: frontdesksState.tableData
        })
    };
    const splittedPathname = pathname.split("frontdesk/");
    // console.log("\n\t formInputs: ", frontdesksState.formInputs)
    const printRegCardArray = ["checked-in/individual", "arrivals/group"]
    const setPrintRegCard = () => {
        delete frontdesksState.reservationData['floorNumber'];
        return setFrontdesksState({
            ...frontdesksState,
            printRegCard: frontdesksState.printGuestBill || printRegCardArray.includes(splittedPathname[1]) ? !frontdesksState.printRegCard : false
        })
    }
    const setGroupCheckInData = (data: any) => {
        // console.log("\n\t setGroupCheckInData-data: ", data)
        setFrontdesksState({
            ...frontdesksState,
            groupCheckInData: {...data},
            printRegCard: true
        })
    };
    const toggleSecurityReport = () => {
        setFrontdesksState({
            ...frontdesksState,
            generateSecurityReport: !frontdesksState.generateSecurityReport
        })
    };
    // console.log("\n\t frontdesksState-data: ", frontdesksState)
    return (
        <React.Fragment>
            {
                frontdesksState.printRegCard ?
                <React.Fragment>
                    {
                        frontdesksState.printGuestBill ?
                        <FolioAndReceipt 
                            pathname={pathname}
                            type={frontdesksState.btnAction.includes("guest") ? "guestbill" : "groupBill"}
                            state={frontdesksState}
                            hideFolio={(guestsBill:any) => handleFolioToggle(guestsBill)}
                            folioReceiptData={frontdesksState.dataToBeDetailed}
                            guestBillTitle={frontdesksState.detailsDataTableTitle}
                        />
                        :
                        <RegCard
                            reservationDetails={frontdesksState.reservationData}
                            groupCheckInData={frontdesksState.groupCheckInData}
                            setPrintRegCard={setPrintRegCard}
                            // setGroupCheckInData={setGroupCheckInFormData}
                        />
                    }
                </React.Fragment>
                :
                <div style={{ width: '80vw',}} className={`${showSidebar ? 'm-0' : 'm-5'}`}>
                    {
                        formModalStatus &&
                            <Modal
                                type={pathname.includes("arrivals/group") ? "groupCheckInForm" : "form"}
                                formFields={frontdesksState.formFields || undefined}
                                formFieldType={frontdesksState.formFieldTypes || undefined}
                                placeholders={frontdesksState.formFieldPlaceholder || undefined}
                                onChange={handleFormChange}
                                numberOfFields={frontdesksState.formFields && frontdesksState.formFields.length as number || 0}
                                title={frontdesksState.formModalTitle}
                                handleAction={toggleModals}
                                pathname={pathname}
                                formInput={frontdesksState.formInputs}
                                minDate={frontdesksState.minDate}
                                maxDate={frontdesksState.maxDate}
                                stopFormModalSubmission={true}
                                formModalSaveButtonName={frontdesksState.formModalSaveButtonName}
                                serverSelectOption={frontdesksState.serverSelectOption}
                                radioOptions={frontdesksState.radioOptions}
                                readOnly={frontdesksState.readOnlyFields}
                                editFlag={frontdesksState.modalFormEditFlag}
                                groupCheckInFormData={frontdesksState.groupCheckInFormData}
                                optionalFormText={frontdesksState.modalFormInstruction}
                                // defaultSelectOption={frontdesksState.defaultSelectOption}
                                setGroupCheckInData={(data: any) => setGroupCheckInData(data)}
                            />
                    }
                    {
                        showConfirm &&
                        <Modal
                            type="confirm"
                            onChange={() => ""}
                            numberOfFields={frontdesksState.numberOfFields}
                            formFields={frontdesksState.formFields}
                            pathname={pathname}
                            fieldValues={Object.values(frontdesksState.formInputs) || []}
                            handleAction={handleAction}
                            previewData={frontdesksState.previewData}
                            title={frontdesksState.confirmModalTitle}
                            cancelButtonText="No"
                            continueButtonText="Yes"
                            fAndBData={frontdesksState.fAndBData}
                            setGroupCheckInData={setFrontdesksState}
                        />
                    }
                    {
                        pathname === "/frontdesk" &&
                        // <Suspense fallback={frontdesksState.loadingPrompt}>
                            <ModuleSummary pathname={`${pathname}`} dashboardName="Frontdesk" />
                        // </Suspense>
                    }
                    <div className={`${showSidebar ? 'm-0' : 'm-3'}`} >
                        <h4 className="frontdesk-summary-title mt-5">{frontdeskDashboardRouteChecker(pathname)}</h4>
                        {
                            !pathname.includes("security") &&
                            <article style={{justifyContent: "space-between", flexDirection: "column", width: "100%"}} className="d-flex align-items-center ">
                                <p className='frontdesk-total-count' style={{ fontFamily: "serif" }}>
                                    Total {frontdeskDashboardRouteChecker(pathname)}: {frontdesksState.tableData && Object.values(frontdesksState.tableData).length}
                                </p>
                                <section style={{width: "100%", justifyContent: "space-between"}} className='frontdesk-top-buttons d-flex px-1'>
                                    {
                                        (pathname.includes('account') && pathname.includes('in-house')) &&
                                        <button 
                                            data-toggle="modal"
                                            data-target="#modal"
                                            onClick={() => frontdeskActionsHandler(`create-${pathname.includes('facilities') ? pathname.split("/")[3] : pathname.split("/")[2]}`, frontdesksState.reservationData)}
                                            className="btn btn-secondary font-weight-bold">
                                            Create {titleCase(pathname.includes('facilities') ? pathname.split("/")[3] : pathname.split("/")[2])}
                                        </button>
                                    }
                                    <article style={{width: pathname.includes("logs") || pathname.includes("status") || pathname.includes("company") ? "100%" : undefined}} className={document.querySelector('.settings-add-button') ? 'row justify-content-center' : undefined}>
                                        {
                                            !frontdesksState.showDetails &&
                                        // {/* <Suspense fallback={frontdesksState.loadingPrompt}> */}
                                            <Filter 
                                                tableData={frontdesksState.tableData}
                                                componentsState={frontdesksState}
                                                componentsStateSetter={setFrontdesksState}
                                                paginationEndPoint={frontdesksState.paginationEndPoint}
                                                paginationStartPoint={frontdesksState.paginationStartPoint}
                                            />
                                        // {/* </Suspense> */}
                                        }
                                    </article>
                                    <button 
                                        data-toggle="modal"
                                        data-target="#modal"
                                        onClick={() => frontdeskActionsHandler('Message', frontdesksState.reservationData)}
                                        className="btn btn-info font-weight-bold">
                                        Message
                                    </button>
                                </section>
                            </article>
                        }
                        {
                            pathname.includes('security') &&
                            <button 
                                // data-toggle="modal"
                                // data-target="#modal"
                                onClick={() => toggleSecurityReport()}
                                className={`btn btn-${frontdesksState.generateSecurityReport ? 'success' : 'secondary'} font-weight-bold`}>
                                {frontdesksState.generateSecurityReport ? "Night Audit" : "Security Report"}
                            </button>
                        }
                        <p className="animate__animated animate__headShake animate__infinite font-weight-bold text-success">{frontdesksState.loadingPrompt}</p>
                        {
                            !frontdesksState.showDetails ?
                                <div className="frontdesk-table-container">
                                    {
                                        pathname.includes("security") && 
                                        <article style={{fontSize: "23px", flexDirection: "column"}} className="text-center font-weight-bold col-md-10 d-flex justify-content-center align-self-center frontdesk-logo-container">
                                            <span className='text-center'>
                                                <Gifs gifName='hotel-logo' />
                                            </span>
                                            {
                                                pathname.includes("security/individual") ? 
                                                <p className="mt-3">{frontdesksState.generateSecurityReport ? "Security Report" : "Night Auditors Report"} {frontdeskDashboardRouteChecker(pathname)} - {frontdesksState.tableData && Object.values(frontdesksState.tableData).length}</p>
                                                :
                                                <p className="mt-3">{frontdeskDashboardRouteChecker(pathname)} - {frontdesksState.tableData && Object.values(frontdesksState.tableData).length}</p>
                                            }
                                        </article>
                                    }

                                    {/* <Suspense fallback={frontdesksState.loadingPrompt}> */}
                                        <FrontdeskSubTable 
                                            // showHistoryDetails={frontdesksState.showHistoryDetails}
                                            frontdesksState={frontdesksState}
                                            pathname={pathname}
                                            generateSecurityReport={frontdesksState.generateSecurityReport}
                                            frontdeskActionsHandler={frontdeskActionsHandler}
                                        />
                                    {/* </Suspense> */}
                                    {
                                        !frontdesksState.loadingPrompt && !pathname.includes('security') &&
                                        <span className="mx-3 btn-container float-right">
                                            <p>{frontdesksState.paginationStartPoint === 0 ? 1 : frontdesksState.paginationStartPoint} to {frontdesksState.paginationEndPoint >= frontdesksState.tableData.length ? frontdesksState.tableData.length : frontdesksState.paginationEndPoint} of {frontdesksState.tableData.length}</p>
                                            {
                                                frontdesksState.tableData && frontdesksState.tableData.length > frontdesksState.paginationLength &&
                                                paginationButtons.slice(
                                                    frontdesksState.paginationStartPoint <= 0 ? 1: 0, 
                                                    frontdesksState.paginationEndPoint >= frontdesksState.tableData.length ? 1
                                                    : undefined
                                                ).map(btn => (
                                                    <button 
                                                        onClick={e => handlePagination(btn)}
                                                        key={btn}
                                                        className={`btn ${btn.toLowerCase() === 'next' ? 'btn-primary' : 'btn-secondary'} mx-2 mb-4`}>
                                                        {btn}
                                                    </button>
                                                ))
                                            }
                                        </span>
                                    }
                                    <div className="managers-flash-notes">
                                        {
                                            (pathname.includes('arrivals') || pathname.includes('departures') || pathname.includes('security')) &&
                                            <section className="print-btn-container">
                                                <button onClick={() => window.print()} className="btn btn-secondary">
                                                    Print
                                                </button>
                                            </section>
                                        }
                                        {
                                            (pathname.includes('account') || pathname.includes('bills') || pathname.includes('checked-in')) &&
                                            <section>
                                                <p className="mx-2 font-weight-bold" style={{textDecoration: "underline", fontSize: "16px"}}>Definitions:</p>
                                                {
                                                    Object.keys(individualsCheckedInDefinitions).slice(
                                                        pathname.includes("account") ? 2 : 0,
                                                        pathname.includes("account") ? undefined : 2,
                                                    ).map((variable) => (
                                                        <article key={variable} className="individualsCheckedInDefinitions">
                                                            <p style={{fontSize: "14px"}} className="d-flex align-items-center">
                                                                <span><BsArrowRightCircle /></span>
                                                                <span className="mx-2 mt-1 font-weight-bold">{variable}:</span> 
                                                                <span className="mt-1">{(individualsCheckedInDefinitions as any)[variable]}</span>
                                                            </p>
                                                        </article>
                                                    ))
                                                }
                                            </section>
                                        }
                                    </div>
                                </div>
                                :
                                <Suspense fallback={frontdesksState.loadingPrompt}>
                                    <Details
                                        tableData={pathname.includes("history") ? frontdesksState.historyDetails : frontdesksState.dataToBeDetailed}
                                        setShowRowDetails={setShowRowDetailsForDetailsComponent}
                                        tableHeader={pathname.includes('history') ? individualsHistoryTableHeader.slice(0, 16) : frontdesksState.tableHeaderForDataToBeDetailed}
                                        showRowDetails={frontdesksState.showDetails}
                                        tableTitle={frontdesksState.detailsDataTableTitle}
                                        actionsHandler={frontdeskActionsHandler}
                                        footerArray={[frontdesksState.footerArray]}
                                        // stateSetter={setFrontdesksState}
                                    />
                                </Suspense>
                        }
                    </div>
                </div>
            }
        </React.Fragment>

    )
};


export default FrontdeskTable

