import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { customAlert } from '../../../../utilities/customAlert';
import GroupReservation from '../../Forms/GroupReservation';
import IndividualReservation from '../../Forms/IndividualReservation';
import ModuleSummary from '../ModuleSummary/ModuleSummary';
import BacktrackTable from '../Tables/BacktrackTable';
import BarTable from '../Tables/FAndBTable';
import DailySales from '../Tables/DailySales';
import RecordsTable from '../Tables/RecordsTable';
import RecordsDashboard from './RecordsDashboard';

interface Props {
    summaryHeader: any[]
    tableData: []
};


const BackTrackDashboard = (props: Props) => {
    const location = useLocation();
    const [backtrackState, setBacktrackState] = useState({
        permitted: false
    })
    const pathname = location.pathname;
    const navigate = useNavigate();
    const store = useSelector((store: any) => store);
    const { userDetails } = store;
    const userData = userDetails ? userDetails.userDetails : undefined;
    // console.log("\n\t userData: ", userData)
    // const permittedUserTypes = ["Admin", "Super Admin", "Frontdesk Supervisor", "Accounts"]
    useEffect(() => {
        // setTimeout(() => {
        //     console.log("\n\t Triggered a check on backtrack.userData.2..", userData);
        //     if(userData && permittedUserTypes.includes(userData.userType)){
                return setBacktrackState({
                    ...backtrackState,
                    permitted: true
                })
        //     };
        //     console.log("\n\t Triggered a check on backtrack........")
        //     customAlert("error", "You're not permitted to perform backtracking operations", 6000)
        //     return navigate("/dashboard");
        // }, 7000);
    }, []);
    return (
        <React.Fragment>
            {
                backtrackState.permitted &&
                <div>
                    {
                        pathname === "/backtrack" ?
                        <div>
                            <ModuleSummary dashboardName="Backtrack" />
                        </div>
                        :
                        pathname.includes("accommodation/individual") ?
                        <IndividualReservation  />
                        :
                        pathname.includes("accommodation/group") ?
                        <GroupReservation />
                        :
                        <BacktrackTable />
                    }
                </div>
            }
        </React.Fragment>
    )
}

export default BackTrackDashboard
