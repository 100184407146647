import React, { useEffect, useState } from 'react'
import { FaChevronCircleLeft } from 'react-icons/fa'
import { pascalCaseSeparator, tableDataCleaner, titleCase } from '../../../utilities/strings'

import { useSelector } from 'react-redux';
import Gifs from '../../Gifs';

import "../../../styles/GuestsBillStyles.css";

interface Props {
    billData: any
    guestBillTitle: string
    // state: any
    toggleBillVisibilty: Function
}

const GroupBill = (props: Props) => {
    const groupBill = props.billData && props.billData.data;
    const { individualDetails, transactionDetails, reservationDetailsData } = props.billData && props.billData.data;
    
    console.log("\n\t In Group bill-transactionDetails...", transactionDetails[0])
    // console.log("\n\t groupBill: ", groupBill)
    const reservationDetails = ["Accomodation Cost", "Expected Number Of Guests", "Number of Guests Checked-in", "Arrival Date", "Departure Date", ];
    const individualDetailsHeading = ["Fullname", "Phone", "Room Type", "Room Number", "Floor Number", "Check-in Date"];
    const transactionDetailsHeading =  ["Description", "Individual", "Location", "Date", "Charges", "Credits"];
    const authorizedUser = useSelector((store: any) => store.userDetails.userDetails)
    console.log("\n\t In Group bill...", groupBill && groupBill)
    const [groupBillState, setGroupBillState] = useState({
        reRenderCount: 0,
        summedCharges: 0,
        summedCredits: 0,
        summedVAT: 0,
        summedServiceCharge: 0,
        summedConsumptionCharge: 0
    });
    const footerArray1 = ["", "", "", "Total", transactionDetails ? transactionDetails[0].charges : 0, transactionDetails ? transactionDetails[0].credits : 0,];
    const footerArray2 = ["Balance", "Net Amount", "VAT", "Service Charge", "Consumption Charge", "Total (VAT inclusive)"]
    const footerArray3 = [ 
        groupBillState.summedCharges - groupBillState.summedCredits, 
        groupBillState.summedCharges -(groupBillState.summedConsumptionCharge + groupBillState.summedServiceCharge + groupBillState.summedVAT), 
        groupBillState.summedVAT, 
        groupBillState.summedServiceCharge, 
        groupBillState.summedConsumptionCharge, 
        groupBillState.summedCharges
    ]
    useEffect(() => {
        if(groupBillState.reRenderCount < 1){
            console.log("\n\t groupBillState-transactionDetails: ", transactionDetails)
            if(transactionDetails){
                transactionDetails[0]['bills'].forEach((bill: any) => {
                    console.log("\n\t Group's bill: ", bill);
                    groupBillState.summedCharges += bill.charges;
                    groupBillState.summedCredits += bill.credits;

                    groupBillState.summedVAT += bill.vatCharge || 0;
                    groupBillState.summedConsumptionCharge += bill.consumptionCharge || 0;
                    groupBillState.summedServiceCharge += bill.serviceCharge || 0;
                });
                setGroupBillState({
                    ...groupBillState,
                    reRenderCount: groupBillState.reRenderCount ++
                });
            };
        };
    }, [groupBillState.reRenderCount]);
    return (
        <div className="">
            <section className="mt-3">
                <button 
                    onClick={() => props.toggleBillVisibilty()}
                    className="mt-5 btn btn-secondary bill-close-btn">
                    <FaChevronCircleLeft size={23} className="mx-2" />
                    Close Bill
                </button>
                <article className='Group-bill-gif-logo-container'>
                    <span className='text-center'>
                        <Gifs gifName='hotel-logo' />
                    </span>
                    <span className="font-weight-bold">
                        <p className="text-center">{props.guestBillTitle}</p>
                        <p className="text-center">{new Date().toDateString()}</p>
                    </span>
                </article>
                <hr />
            </section>
            <div className="tables-container">
                <section className="bill-accomodation-table">
                    <p className="section-text font-weight-bold">Reservation Details:</p>
                    <table className="table-table table table-bordered table-hover table-striped ">
                        <thead>
                            {
                                reservationDetailsData && reservationDetails.map((key: string) => (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{tableDataCleaner(key, reservationDetailsData[0])}</td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <td>Teller</td>
                                <td>{authorizedUser && authorizedUser.firstName.concat(" ", authorizedUser.lastName)}</td>
                            </tr>
                        </thead>
                    </table>
                </section>
                <section className="bill-accomodation-table">
                    <p className="section-text font-weight-bold">Individual Details:</p>
                    <table className="table-table table table-bordered table-hover table-striped ">
                        <thead>
                            <tr>
                                {
                                    props.billData && individualDetailsHeading.map(key => (
                                        <th key={key}>{key}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                individualDetails && individualDetails.map((individual:any, index:number) => (
                                    <tr key={index}>
                                        {
                                            Object.keys(individual).map(key => (
                                                <td>{tableDataCleaner(key, individual)}</td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </section>
                <hr />
                <section className="bill-transaction-table">
                    <p className="font-weight-bold">Transaction Details:</p>
                    <table className="table-table table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                {
                                    transactionDetails && transactionDetailsHeading.map(detail => (
                                        <th key={detail}>{detail}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                transactionDetails && transactionDetails[0]['bills'].map((bill: any, billIndex: number) => (
                                    <tr key={billIndex}>
                                        {
                                            transactionDetailsHeading.map((title, titleIndex: number) => (
                                                <td key={title}>
                                                    {tableDataCleaner(title, bill)}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            {
                                props.billData && 
                                <tr>
                                    {
                                        footerArray1.map((val, index) => (
                                            <td className="font-weight-bold" key={index}>{val.toLocaleString() || ""}</td>
                                        ))
                                    }
                                </tr>
                            }
                        </tfoot>
                    </table>
                </section>
                <hr />
                <section style={{width: "50%"}} className="balance-table">
                    <table className="table-table table table-bordered table-hover table-striped">
                        <tfoot>
                            {
                                props.billData && 
                                footerArray2.map((val, index) => (
                                    <tr key={val} className="text-center">
                                        <td className="font-weight-bold">{val.toLocaleString()}</td>
                                        <td className="font-weight-bold">{footerArray3[index].toLocaleString()}</td>
                                    </tr>
                                ))
                            }
                        </tfoot>
                    </table>
                    <button 
                        onClick={() => window.print()}
                        className="btn btn-secondary float-right mb-4 bill-print-btn">
                        Print Bill
                    </button>
                </section>
            </div>
        </div>
    )
}

export default GroupBill