import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";



const Component404 = () => {
    const appLocation = useLocation()
    const pathname = appLocation.pathname;
    const navigate = useNavigate();
    useEffect(() => {
        if(pathname.includes("end-of-day")){
            navigate("/frontdesk/end-of-day")
        }
    }, []);
    return (
        <div>
            {/* <Redirect /> */}
        </div>
    );
};

export default Component404;