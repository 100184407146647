import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "../../../../styles/TableStyles.css";
import axios from 'axios';

import { useLocation } from 'react-router';
import { callBackendAction, showConfirmModalAction, showFormModalAction } from '../../../../redux/actions/storeActions/appActionsUtilities';
import axiosHelper from '../../../../utilities/axiosHelper';
import { getReservationDetails } from '../../../../utilities/databaseHelpers';
import { buttonTitle, titledTextJoiner } from '../../../../utilities/strings';
import Modal from '../../Modal';
import {  
    departments, 
    formFieldsSelector,  
    houseKeepingStatus, 
    paginationButtons,
    rowActionFunctions,
    serverSelectOptionsForSettings,
} from './tableData';
import Filter from '../../Filter';
import { customAlert } from '../../../../utilities/customAlert';
import { handleDataPagination } from './tableActions/tableActionsThree';
// import SettingsSubTable from './subTables/SettingsSubTable';


const SettingsSubTable = lazy(() => import('./subTables/SettingsSubTable'));



interface Props {
   
}


const SettingsTable = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    
    const [settingsState, setSettingsState] = useState({
        loadingPrompt: "Please wait, table data is loading....",
        searchParametre: "",
        confirmModalTitle: "",
        dataToUpdate: {}as any,
        staffs: []as string[],
        shiftName: []as string[],
        dataId: ""as any,
        groups: [] as any,
        roomTypes: [] as any,
        roomFloor: [] as any,
        inventoryCategory: [] as any,
        inventoryCategoryVarieties: [] as any,
        tableData: [] as any,
        paginationStartPoint: 0,
        paginationLength: pathname.includes('room') ? 15 : 10,
        paginationEndPoint: pathname.includes('room') ? 15 : 10,
        paginatedTableData: [] as any,
        actualLengthOfTableData: 0,
        allCompanies: [],
        licenseDuration: [],
        warningDaysCount: [],
        userRoles: [],
        settingsInput: {} as any,
        btnAction: "",
        defaultValue: {}as any,
        roomTypeDetails: []as any[]
    });

    const dispatch = useDispatch();
    const store = useSelector((store: any) => store);
    const { modalStatus, sidebarStatus, userDetails, confirmModal } = store;
    const {showSidebar} = sidebarStatus;
    const { showFormModal } = modalStatus;
    const { showConfirm } = confirmModal;
    const userData = userDetails.userDetails;
    // console.log(`\n\t SettingsTable userDetails: `, userDetails);

    const settingsAction = ["Edit details", pathname.includes('pm-account') ? "Check-out" : "Delete"];
    const currentSubmenu = pathname.split("/")[3] || pathname.split("/")[2];
    // console.log("\n\t currentSubmenu: ", currentSubmenu)

    const dashboardRouteChecker = () => {
        return "Settings"
    }
    
    const handleInputChange = (field: string, value: string, files?: any) => {
        // console.log("\n\t Settings: ", field, value);
        const newData = settingsState.settingsInput;
        newData[titledTextJoiner(field)] = value;
        settingsState.defaultValue[titledTextJoiner(field)] = value;
        if(field.toLowerCase() === "logo"){
            const acceptedFiletypes = ['jpeg', 'jpg', 'png'];
            const filetype = files[0].type.split("/")[1];
            // console.log("\n\t Files: ", files[0]);
            if(!acceptedFiletypes.includes(filetype)) {
                return customAlert('error', "This image filtype is forbidden.", 5000)
            };
            settingsState.settingsInput['logo'] = files[0];
        }else if(field.toLowerCase().includes('room type')){
            const roomTypeDetails = settingsState.roomTypeDetails.filter((type: any) => type.roomTypeName === value)
            // console.log("\n\t Settings-roomTypeDetails: ", roomTypeDetails, roomTypeDetails[0].roomTypeRate);
            settingsState.defaultValue[titledTextJoiner("Current Rate")] = roomTypeDetails[0].roomTypeRate;
            // (document as any).querySelector(".current-rate").value = roomTypeDetails[0].roomTypeRate;
        };
        console.log("\n\t newData: ", newData);
        return setSettingsState({
            ...settingsState,
            settingsInput: newData,
        });
    };
    // console.log("\n\t settingsInput: ", {...settingsState.settingsInput, ...settingsState.defaultValue})
    const parseFormData = async() => {
        // console.log("\n\t parseFormData-Settings.settingsInput: ", settingsState.settingsInput);
        try {
            const formData = new FormData();
            if(pathname.includes('companies')){
                formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET_NAME as string)
                formData.append("api_key", process.env.REACT_APP_CLOUDINARY_UPLOAD_API_KEY as string)
                formData.append("api_secret", process.env.REACT_APP_CLOUDINARY_UPLOAD_API_SECRET as string)

                if(!settingsState.btnAction.includes('create')){
                    const itemBeingupdatedOrDeleted = settingsState.tableData.filter((item:any) => item._id === settingsState.dataId)
                    const { cloudinaryPublic_Id } = itemBeingupdatedOrDeleted;
                    cloudinaryPublic_Id && await axios.post(process.env.REACT_APP_CLOUDINARY_DELETE_API_URL as string, cloudinaryPublic_Id)
                }
                // && !settingsState.btnAction.includes('delete')
                formData.append("file", settingsState.settingsInput['logo'])
    
                const uploadResponse = await axios.post(process.env.REACT_APP_CLOUDINARY_UPLOAD_API_URL as string, formData)
                // console.log("\n\t Settings.uploadResponse: ", uploadResponse);
                const { secure_url, public_id } = uploadResponse.data;
                settingsState.settingsInput['cloudinarySecureUrl'] = secure_url;
                settingsState.settingsInput['cloudinaryPublic_Id'] = public_id;
                setSettingsState({
                    ...settingsState
                })
            };
            // console.log("\n\t settingsInput: ", settingsState.settingsInput)
            return {...settingsState.defaultValue, ...settingsState.settingsInput};
        } catch (error) {
            console.log("\n\t Upload error: ", error)
        }
    };
    // console.log("\n\t Settings.settingsState.settingsInput: ", settingsState.settingsInput);
    const handleRowAction = (action: string, data: any) => {
        // console.log("\n\t handleRowAction: ", action, data._id);
        settingsState.btnAction = action.toLowerCase().replaceAll(" ", "-");
        settingsState.dataId = data._id;
        switch(action){
            case action = "Delete":
                dispatch(showConfirmModalAction(true));
                dispatch(showFormModalAction(false));
                return setSettingsState({...settingsState}); 
            case action = "Check-out":
                dispatch(showConfirmModalAction(true));
                dispatch(showFormModalAction(false));
                return setSettingsState({...settingsState}); 
            default:
                const fields = formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[0];
                // console.log("\n\t currentSubmenu: ", currentSubmenu)
                
                if(fields){
                    console.log("\n\t data: ", data)
                    for(let field of fields){
                        if(field && field.toLowerCase() !== 'logo'){
                            const actualField = titledTextJoiner(field);
                            // console.log("\n\t actualField: ", actualField)
                            settingsState.defaultValue[actualField] = data[
                                field.toLowerCase().includes("first") ? "firstName" 
                                : field.toLowerCase().includes("last") ? "lastName" 
                                : field.toLowerCase().includes("username") ? "userName" 
                                : field.toLowerCase().includes("role") ? "userType" 
                                : actualField
                            ];
                        }
                    };
                    // console.log("\n\t settingsState.defaultValue: ", settingsState.defaultValue)
                    setSettingsState({...settingsState});
                }
                if(currentSubmenu === "room-status") {
                    setSettingsState({...settingsState})
                }
                dispatch(showFormModalAction(true, "Edit"));
        }
    };
    const handlePagination = (btnName: string) => {
        handleDataPagination({
            paginationBtnName: btnName,
            state: settingsState,
            stateHandler: setSettingsState
        })
    }
    useEffect(() => {
        console.log("\n\t useEffect-Settings......: ",)
        dispatch(callBackendAction(false));
        (async() => {
            try {
                // if(pathname.includes("shifts")){
                //     const [users, shiftTiming] = await Promise.all([
                //         axiosHelper({
                //             routeName: "/settings/user-management/users",
                //             httpVerb: "get"
                //         }),
                //         axiosHelper({
                //             httpVerb: "get",
                //             routeName: "/settings/user-management/shift-timing"
                //         })
                //     ]);
                //     const allUsers = users.data;
                //     const allShitTiminig = shiftTiming.data;
                //     console.log("\n\t allShitTiminig: ", allShitTiminig)
                //     allUsers.map((user:any) => {
                //         settingsState.staffs.push(`${user.firstName} ${user.lastName}`)
                //     });
                //     allShitTiminig.map((shift:any) => {
                //         settingsState.shiftName.push(shift.shiftName)
                //     })
                //     console.log("\n\t Users: ", users.data)
                // }
                let reservationDetails = undefined; 
                let roomTypeNames = []as any[];
                let roomTypeDetails = []as any[];
                let floorNumber = []as any[];
                let categories = []as any[];
                let varieties = []as any[];
                if(currentSubmenu.includes("rate") || currentSubmenu.includes("menu-items") || currentSubmenu.includes("family-group")){
                    reservationDetails = await getReservationDetails();
                    const { roomTypes, floors, restaurantData } = reservationDetails as any;
                    roomTypeDetails = roomTypes;
                    roomTypeNames = roomTypes && roomTypes.map((type: any) => type.roomTypeName)
                    floorNumber = floors && floors.map((floor: any) => floor.floorNumber)
                    // console.log("\n\t Room types: ", roomTypes)
                    // console.log("\n\t Room types-roomTypeNames: ", roomTypeNames)
                    categories = restaurantData && restaurantData.categories.map((category: any) => category.categoryName)
                    varieties = restaurantData && restaurantData.categoryVarieties.map((category: any) => category.categoryVarietyName);
                };
                // console.log("\n\t reservationDetails: ", reservationDetails)
                let groupNames = []as any;
                if(pathname.includes('group-management') && !pathname.includes('membership') && !pathname.includes('groups')){
                    (reservationDetails as any).groups.forEach((group: any) => {
                        groupNames.push(group.groupName);
                    });
                }
    
                const response = await axiosHelper({
                    routeName: pathname,
                    httpVerb: "get"
                });
                console.log(`\n\t SettingsTable response-: `,response);
                console.log(`\n\t SettingsTable groupNames-: `,groupNames);
                if(pathname.includes('users')){
                    const userRoles = await axiosHelper({
                        routeName: `/users/roles`,
                        httpVerb: "get"
                    })
                    settingsState.userRoles = userRoles.data
                    // console.log(`\n\t SettingsTable response-userRoles: `,userRoles.data);
                };
                setSettingsState({
                    ...settingsState,
                    groups: groupNames,
                    roomTypes: roomTypeNames,
                    roomTypeDetails,
                    roomFloor: floorNumber,
                    inventoryCategory: categories,
                    inventoryCategoryVarieties: varieties,
                    tableData: pathname.includes('licenses') ? Object.values(response.data['allLicense']) : Object.values(response.data),
                    actualLengthOfTableData: pathname.includes('licenses') ? (Object.values(response.data['allLicense'])).length : (Object.values(response.data)).length,
                    paginatedTableData: pathname.includes('licenses') ? Object.values(response.data['allLicense']).slice(settingsState.paginationStartPoint, settingsState.paginationEndPoint) : (Object.values(response.data)).slice(settingsState.paginationStartPoint, settingsState.paginationEndPoint,),
                    allCompanies: pathname.includes('licenses') ? Object.values(response.data['allCompanies']) : [],
                    licenseDuration: pathname.includes('licenses') ? Object.values(response.data['licenseDuration']) : [],
                    warningDaysCount: pathname.includes('licenses') ? Object.values(response.data['warningDaysCount']) : [],
                    loadingPrompt: "",
                })
            } catch (error) {
                console.log("\n\t Settings-error: ", error)
                setSettingsState({
                    ...settingsState,
                    loadingPrompt: "",
                });
            }
        })();
    }, [pathname]);
    const createButton = (action: string) => {
        setSettingsState({
            ...settingsState,
            btnAction: action.includes('bulk') ? "bulk-email" : "create",
        })
        dispatch(showFormModalAction(true))
    }
    // console.log("\n\t (formFieldsSelector(pathname, userDetails) as string[][])[0] : ",(formFieldsSelector(pathname, userDetails) as string[][])[0] )
    // console.log("\n\t settingsState.defaultValue: ", settingsState.defaultValue);
    return (
        <React.Fragment>
            {
                showFormModal && showFormModal.modalStatus ?
                <Modal
                    onChange={handleInputChange}
                    type="form"
                    title={settingsState.btnAction.includes("bulk-email") ? "Send Bulk-email" : showFormModal.actionText ? `${showFormModal['actionText']} ${buttonTitle(pathname)}` : buttonTitle(pathname).includes("Add") ? buttonTitle(pathname) : `${pathname.includes('pm') ? "Create" : "Add"} ${buttonTitle(pathname)}`}
                    formFields={(formFieldsSelector(pathname, settingsState.btnAction) as string[][])[0].slice(0, pathname.includes('f-and-b-tables') && settingsState.btnAction.includes('create') ? 3 : undefined)}
                    formFieldType={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[1] || undefined}
                    handleAction={showFormModal.actionText ? async() => rowActionFunctions.updateAction(pathname, settingsState.dataId, await parseFormData()) : async() => rowActionFunctions.postAction(pathname, await parseFormData())}
                    placeholders={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[2] || undefined}
                    selectOption={formFieldsSelector(pathname) && (formFieldsSelector(pathname, settingsState.btnAction) as string[][])[3]  || undefined}
                    readOnly={pathname.includes("bar-rates") ?  [false, false, true, false] : undefined}
                    serverSelectOption={{...serverSelectOptionsForSettings(settingsState)}}
                    radioOptions={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[4]  || undefined}
                    formInput={{...settingsState.settingsInput, ...settingsState.defaultValue}}
                    numberOfFields={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[0].length as number || 0}
                    optionalFormText={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as any)[5] || undefined}
                    // editingDetails={editingDetails}
                    // setPrintRegCard={() => undefined}
                    setGroupCheckInData={() => undefined}
                    pathname={pathname}
                    formModalSaveButtonName={settingsState.btnAction.includes("bulk-email") ? "Send" : "Save"}
                    defaultValue={settingsState.defaultValue}
                />
                :
                showConfirm &&
                <Modal
                    onChange={handleInputChange}
                    type="confirm"
                    handleAction={(settingsState.btnAction.includes("check-out") || settingsState.btnAction.includes("suspend")) ? () => rowActionFunctions.updateAction(`${pathname}/${settingsState.btnAction}`, settingsState.dataId, settingsState.dataToUpdate) : () => rowActionFunctions.deleteAction(pathname, settingsState.dataId)}
                    title={settingsState.confirmModalTitle || `Are you sure about ${pathname.includes('pm-account') ? "checking-out" : "deleting"} this ${pathname.includes('pm-account') ? pathname.split("/")[2] : (pathname.split("/")[3] || pathname.split("/")[2]).replaceAll("-", " ")}?`}
                    numberOfFields={0}
                    pathname={pathname}
                    // setPrintRegCard={() => undefined}
                    setGroupCheckInData={() => undefined}
                />
            }
           <div style={{flexDirection: "column", marginLeft: "17px", 
                                            width: 
                                            pathname.includes('room')? "85vw" :
                                            pathname.includes("companies") ? "90vw"
                                            :
                                            "80vw"}} className={`mt-4 ${showSidebar ? 'm-0' : 'mx-4'}`}>
                <h4 className=" mt-2">{pathname.includes('pm-account') ? `${buttonTitle(pathname).split(" ")[0].toUpperCase()} Account` : buttonTitle(pathname)} {!pathname.includes("logs") && dashboardRouteChecker()}</h4>
                <p style={{fontFamily: "serif"}}> 
                    Total {pathname.includes('pm-account') ? `${buttonTitle(pathname).split(" ")[0].toUpperCase()} Account` : buttonTitle(pathname)} : {settingsState.tableData && Object.values(settingsState.tableData).length}
                </p>
                <span className="font-weight-bolder small text-success animate__animated animate__pulse animate__infinite">{settingsState.loadingPrompt}</span>

                <span style={{borderBottomWidth: "2px", borderBottomColor: "ghostwhite", borderBottomStyle: "solid", width: "80vw"}} className="hr d-flex"></span>
                <section style={{ justifyContent: "space-between", width: "100%"}} className="my-2 row d-flex"> 
                    {
                        !pathname.includes("hotel-date") && !pathname.includes("checked-out") && !pathname.includes("logs") && !pathname.includes("status") && !pathname.includes("company")  &&
                        <span>
                            <button onClick={() =>createButton("create")} className="btn button ml-3 settings-add-button" data-toggle="modal" data-target="#modal">
                                {(buttonTitle(pathname).includes("Add") || buttonTitle(pathname).includes("Create")) ? buttonTitle(pathname) : buttonTitle(pathname).includes("Manage") ? "Create user" : pathname.includes('pm-account') ? "Create Account" : `Add ${buttonTitle(pathname)}`}
                            </button>
                            {
                                pathname.includes("communications") &&
                                <button onClick={() => createButton('bulk-email')} className="btn button ml-3 settings-add-button" data-toggle="modal" data-target="#modal">
                                    Bulk Email
                                </button>
                            }
                            {
                                userData != undefined && 
                                userData.firstName.concat(" ", userData.lastName) == process.env.REACT_APP_COMPANY_OWNER && userData.userType == process.env.REACT_APP_COMPANY_OWNER_USER_TYPE &&
                                <input className='btn btn-info mx-2' type="file" />
                            }
                        </span>
                    };
                    {
                        !pathname.includes("hotel-date") &&
                        <article style={{width: pathname.includes("logs") || pathname.includes("status") || pathname.includes("company") ? "100%" : undefined}} className={document.querySelector('.settings-add-button') ? 'row justify-content-center' : undefined}>
                            <Filter 
                                tableData={settingsState.tableData}
                                componentsState={settingsState}
                                componentsStateSetter={setSettingsState}
                                paginationStartPoint={settingsState.paginationStartPoint}
                                paginationEndPoint={settingsState.paginationEndPoint}
                            />
                        </article>
                    }
                </section>
                <div style={{width: ''}} className="table-responsive d-flex">
                   <Suspense fallback={settingsState.loadingPrompt}>
                        <SettingsSubTable 
                            currentSubmenu={currentSubmenu} 
                            handleRowAction={handleRowAction}
                            pathname={pathname}
                            settingsAction={settingsAction}
                            settingsState={settingsState}
                        />
                   </Suspense>
                </div>
                {
                    !settingsState.loadingPrompt &&
                    <section>
                        <p>{settingsState.paginationStartPoint === 0 ? 1 : settingsState.paginationStartPoint} to {settingsState.paginationEndPoint >= settingsState.tableData.length ? settingsState.tableData.length : settingsState.paginationEndPoint} of {settingsState.tableData.length}</p>
                        <span className="mx-3 btn-container row d-flex float-right">
                            {
                                paginationButtons.slice(
                                    (settingsState.paginationStartPoint <= 0) ? 1 
                                    :
                                    0, (settingsState.paginationEndPoint >= settingsState.actualLengthOfTableData) ? 1
                                    : undefined
                                ).map(btn => (
                                    <button 
                                        onClick={e => handlePagination(btn)}
                                        key={btn}
                                        className={`btn ${btn.toLowerCase() === 'next' ? 'btn-primary' : 'btn-secondary'} mx-2 mb-4`}>
                                        {btn}
                                    </button>
                                ))
                            }
                        </span>
                    </section>
                }
            </div>
        </React.Fragment>
    )
}

export default SettingsTable

