import { yesterdaysFormDate } from "../../../../../assets/events/individualReservations"
import { showConfirmModalAction, showFormModalAction } from "../../../../../redux/actions/storeActions/appActionsUtilities"
import axiosHelper from "../../../../../utilities/axiosHelper"
import { getMonth, givenDateAndCurrentTime, todaysDate } from "date-fran"
import { dateMaker } from "../../../../../utilities/dateHelper"
import { groupDetailsMaker } from "../tableActions/tableActionsOne"
import { titleCase } from "../../../../../utilities/strings"
import { restaurantOrderDetailsTableHeader } from "../tableData"


interface ActionsInterface {
    action: string
    state: any 
    stateHandler: Function 
    dispatch: Function
    dataToModify: any
    pathname: string
    appModulesData: any[]
    allSystemCredits: any[]
}


export const reportsAccountHandler = async(params: ActionsInterface) => {
    console.log("\n\t reportsAccountHandler-params.action: ", params.action);
    const { paymentMethod, membershipTypes } = params.state.utilityData as any;
    const paymentMethods = []as string[];
    paymentMethod.forEach((method: any) => paymentMethods.push(method.description));
    const allSystemCredits = params.state.allSystemCredits;
    const allSystemCharges = params.state.allSystemCharges;
    const systemMembershipTypes = []as string[];
    membershipTypes.forEach((type: any) => systemMembershipTypes.push(type.membershipType))
    switch(params.action){
        case params.action = "Pay Debt":
            console.log("\n\t Clear debt: ", params.action, params.dataToModify)
            // console.log("\n\t paymentMethods: ", params.paymentMethods)
            params.dispatch(showFormModalAction(true));
            params.dispatch(showConfirmModalAction(false));
            return params.stateHandler({
                ...params.state,
                formModalSaveButtonName: params.action,
                formModalTitle: `Clear ${params.dataToModify.accountName || params.dataToModify.groupName}'s Debt`,
                formFields: ["Amount", "Payment Method"],
                formFieldTypes: ["number", "select"],
                formFieldPlaceholder: [`Please enter the amount ${params.dataToModify.accountName || params.dataToModify.groupName} is paying.`],
                showForm: true,
                dbIdOfDataToModify: params.dataToModify._id, 
                btnAction: params.action.toLowerCase().replaceAll(" ", "-"),
                confirmModalTitle: `${params.dataToModify.accountName || params.dataToModify.groupName} is owing ${Number(params.dataToModify.credit || Math.abs(params.dataToModify.balance)).toLocaleString()} and you have entered `, // to be copleted in component
                serverSelectOption: {
                    paymentMethod: paymentMethods

                },
            });
        case params.action = "Refund":
            console.log("\n\t Refund: ", params.action, params.dataToModify)
            // console.log("\n\t Refund-paymentMethods: ", params.paymentMethods)
            params.dispatch(showFormModalAction(true));
            params.dispatch(showConfirmModalAction(false));
            return params.stateHandler({
                ...params.state,
                formModalSaveButtonName: params.action,
                formModalTitle: `Refund ${params.dataToModify.accountName}'s Money`,
                formFields: ["Amount", "Payment Method"],
                formFieldTypes: ["number", "select"],
                formFieldPlaceholder: [`Please enter the amount the hotel is refundung ${params.dataToModify.accountName}.`],
                showForm: true,
                dbIdOfDataToModify: params.dataToModify._id, 
                btnAction: params.action.toLowerCase().replaceAll(" ", "-"),
                confirmModalTitle: `The hotel is paying ${params.dataToModify.accountName} a refund of: `, // to be copleted in component
                serverSelectOption: {
                    paymentMethod: paymentMethods

                },
            });
        case params.action = "end-of-shift-details":
            console.log("\n\t end-of-shift-details handleRestaurantActions-dataToModify----: ", params.dataToModify)
            const { salesData, teller, shiftDate} = params.dataToModify;

            const endOfShiftOrderDetails = []as any[];
            salesData.forEach((order:any) => endOfShiftOrderDetails.push(...order.orderDetails));
            const endOfShiftOrderItems = []as any[];
            endOfShiftOrderDetails.forEach((personsOrder:any) => endOfShiftOrderItems.push(...Object.values(personsOrder)));
            const endOfShiftNumberedOrder = []as any[];
            let endOfShiftCount = 0;
            endOfShiftOrderItems.map((individualOrder: any) => {
                individualOrder.map((order: any) => {
                    const { cost, quantity } = order;
                    order['ID'] = endOfShiftCount+1;
                    order['total'] = cost*quantity;
                    endOfShiftNumberedOrder.push(order)
                    endOfShiftCount++
                })
            });
            // params.dataToModify['orderDetails'] = endOfShiftNumberedOrder;
            let cumSumQuantity = 0;
            let cumSumCost = 0;
            let cumSumTotal = 0;
            endOfShiftNumberedOrder.forEach((order:any) => {
                const { cost, quantity, total } = order;
                cumSumCost += cost;
                cumSumQuantity += +quantity; cumSumTotal += total;
            });
            console.log("\n\t dataToBeDetailed-endOfShiftNumberedOrder: ", endOfShiftNumberedOrder)
            return params.stateHandler({
                ...params.state,
                dataToBeDetailed: endOfShiftNumberedOrder,
                showDetails: true,
                showRowDetails: false,
                showConfirm: false,
                showForm: false,
                tableHeaderForDataToBeDetailed: restaurantOrderDetailsTableHeader,
                footerArray: [["", "", cumSumQuantity, cumSumCost, cumSumTotal]],
                detailsTableTitle: `${titleCase((params.pathname.split("/")[2]))} for ${teller} on ${shiftDate}.`
            });
        case params.action = "Guest Bill":
            params.dispatch(showFormModalAction(false));
            params.dispatch(showConfirmModalAction(false));
            console.log("\n\t Guest bill: ", params.dataToModify)
            const guestsBills = params.dataToModify.guestsBill;
            console.log("\n\t Guest bill-guestBills: ", guestsBills)
           
            return params.stateHandler({
                ...params.state,
                // printRegCard: true,
                guestBillType: "individual",
                dataToBeDetailed: {
                    guestBillId: params.dataToModify._id,
                    roomNumber: params.dataToModify.roomNumber,
                    // accountName: params.dataToModify.accountName,
                    dateOfCreation: params.dataToModify.dateCreated,
                    folioCount: params.pathname.includes("pm-account") ? params.dataToModify.folios.length : 0,
                    departureDate: params.dataToModify.checkOutDate,
                    checkedInDate: params.dataToModify.checkedInDate
                },
                showConfirm: false,
                showForm: false,
                showRowDetails: true,
                showDetails: false,
                printGuestBill: true, // works for backtrack
                printRegCard: true, // works for backtrack
                btnAction: params.action.toLowerCase().replaceAll(" ", "-"),
                groupName: params.dataToModify.groupName,
                guestBillTitle: `Guest's Bill: ${params.dataToModify.accountName}`
            });
        case params.action = "Group Details":
            params.dispatch(showConfirmModalAction(false));
            params.dispatch(showFormModalAction(false));
            console.log("\n\t Group Details: ", params.dataToModify)
            params.stateHandler({
                showConfirm: false,
                showForm: false,
                btnAction: params.action.toLowerCase().replaceAll(" ", "-"),
            })
            return groupDetailsMaker({
                setDetailsData: params.stateHandler,
                frontdesksState: params.state,
                reservationData: params.dataToModify
            });
        case params.action = "Group Bill":
            params.dispatch(showConfirmModalAction(false));
            params.dispatch(showFormModalAction(false));
            // console.log("\n\t Group bill: ", params.dataToModify)
            const groupBills = await axiosHelper({
                httpVerb: "get",
                routeName: `/audit-reports/guest-bills/group/${params.dataToModify._id}`
            });
            // console.log("\n\t server-Group bill: ", groupBills)
            return params.stateHandler({
                ...params.state,
                printGuestBill: true,
                guestBillType: "group",
                printRegCard: false,
                showConfirm: false,
                showForm: false,
                btnAction: params.action.toLowerCase().replaceAll(" ", "-"),
                dataToBeDetailed:  groupBills,
                tableHeaderForDataToBeDetailed: ["Description", "Location", "Date", "Total Charge", "Balance"],
                groupName: params.dataToModify.groupName,
                guestBillTitle: `Group Bill: ${params.dataToModify.groupName || params.dataToModify.guestName}`
            });
        case params.action = "Add Charges":
            params.dispatch(showConfirmModalAction(false));
            params.dispatch(showFormModalAction(true));
            console.log("\n\t Add charges: ", params.dataToModify);
            const allSystemChargesCategories: any = [];
            allSystemCharges && allSystemCharges.forEach((charge: any) => {
                allSystemChargesCategories.push(charge.description)
            });
            const { accountName, roomNumber } = params.dataToModify;

            params.dispatch(showFormModalAction(true));
            const newMadeMinChargeDate = new Date(!params.pathname.includes("pm-account") ? params.dataToModify.arrivalDate : params.dataToModify.dateCreated);
            const minChargeDate = dateMaker(newMadeMinChargeDate.getFullYear(), newMadeMinChargeDate.getMonth(), newMadeMinChargeDate.getDate());
            const newMadeMaxChargeDate = new Date(params.dataToModify.dateCreated || params.dataToModify.checkOutDate);
            const maxChargeDate = dateMaker(newMadeMaxChargeDate.getFullYear(), newMadeMaxChargeDate.getMonth(), newMadeMaxChargeDate.getDate());
            // console.log("\n\t allSystemCharges: ", allSystemChargesCategories)
            return params.stateHandler({
                ...params.state,
                confirmModalTitle: `Are you sure about the amount and description being added as a charge to ${accountName}'s bill?`,
                formModalTitle: `Add Charges To ${accountName} Bill.`,
                formModalSaveButtonName: "Charge",
                readOnlyFields: [false, false],
                maxDate: minChargeDate,
                minDate: params.pathname.includes("pm-account") ? undefined : maxChargeDate || yesterdaysFormDate(),
                formFieldPlaceholder: ["", roomNumber, ""],
                formFields: ["Amount", "Description", "Backtrack Date", "Username", "Password"].slice(0, params.pathname.includes("backtrack") ? undefined : 2),
                formFieldTypes: ["number", "select", "date", "text", "password"],
                dbIdOfDataToModify: params.dataToModify._id, 
                showForm: true,
                serverSelectOption: {
                    description: allSystemChargesCategories
                },
                btnAction: `${params.action.toLowerCase().replaceAll(" ", "-")}`
            });
        case params.action = "Add Credits":
            params.dispatch(showConfirmModalAction(false));
            params.dispatch(showFormModalAction(true));
            console.log("\n\t Add Credits-dataToModify: ", params.dataToModify);
            const allSystemCreditCategories = []as string[];
            allSystemCredits && allSystemCredits.forEach((credit: any) => {
                allSystemCreditCategories.push(credit.description)
            });
            console.log("\n\t allSystemCreditCategories: ", allSystemCreditCategories)
            const newMadeMinCreditDate = new Date(!params.pathname.includes("pm-account") ? params.dataToModify.checkedInDate : params.dataToModify.dateCreated);
            const minCreditDate = dateMaker(newMadeMinCreditDate.getFullYear(), newMadeMinCreditDate.getMonth(), newMadeMinCreditDate.getDate());
            // const newMadeMaxCreditDate = new Date(!params.pathname.includes("pm-account") ? params.dataToModify.dateCreated : params.dataToModify.checkOutDate);
            // const maxCreditDate = dateMaker(newMadeMaxCreditDate.getFullYear(), newMadeMaxCreditDate.getMonth(), newMadeMaxCreditDate.getDate());

            console.log("\n\t newMadeMinCreditDate: ", newMadeMinCreditDate)
            return params.stateHandler({
                ...params.state,
                confirmModalTitle: `Are you sure about the amount and description being added as a charge to ${params.dataToModify['accountName']}'s bill?`,
                formModalTitle: `Add Credits To ${params.dataToModify["accountName"]} Bill.`,
                formModalSaveButtonName: "Credit",
                readOnlyFields: [false, false],
                maxDate: minCreditDate,
                // maxDate: params.pathname.includes("pm-account") ? undefined : maxCreditDate || yesterdaysFormDate(),
                formFieldPlaceholder: ["", params.dataToModify["roomNumber"], ""],
                formFields: ["Amount", "Description", "Payment Method", "Backtrack Date", "Username", "Password"].slice(0, params.pathname.includes("backtrack") ? undefined : 3),
                formFieldTypes: ["number", "select", "select", "date", "text", "password"],
                dbIdOfDataToModify: params.dataToModify._id, 
                showForm: true,
                serverSelectOption: {
                    description: allSystemCreditCategories,
                    paymentMethod: paymentMethods
                },
                btnAction: `${params.action.toLowerCase().replaceAll(" ", "-")}`
            });
        case params.action = "Reverse Charges":
            console.log("\n\t Reverse Charges-reservationData: ", params.dataToModify)
            params.dispatch(showConfirmModalAction(false));
            params.dispatch(showFormModalAction(true));
            const guestsBillsCharges = params.dataToModify.guestsBill;

            const actualBills = guestsBillsCharges[0].bills;
            console.log("\n\t guestsBillsCharges: ", guestsBillsCharges)
            const billsToReverse = []as string[];
            const chargesAndFolioIds = []as string[];
            actualBills.forEach((bill:any) => {
                const { dateCreated, credits, charges, folioId, description } = bill; 
                if((charges > 0) && (credits === 0)){
                    const data = `${description} - (${(givenDateAndCurrentTime(dateCreated.split("T")[0])as Date).toDateString()}) - ${charges.toLocaleString()}`;
                    billsToReverse.push(data);
                    chargesAndFolioIds.push(`${data}/${folioId}`)
                }
            });
            return params.stateHandler({
                ...params.state,
                formModalSaveButtonName: "Reverse",
                formFields: ["Charges", "Username", "Password"],
                formFieldTypes: ["select", "text", "password"],
                confirmModalTitle: "Reversing this charge implies that the guest was incorrectly charged and that the system should negate this charge. Are you sure about this?",
                formModalTitle: `Reverse Superfluous Charges`,
                btnAction: `${params.action.toLowerCase().replaceAll(" ", "-")}`,
                serverSelectOption: {
                    charges: billsToReverse,
                    chargesAndFolioIds
                },
            });
        case params.action = "Reverse Credits":
            console.log("\n\t Reverse Charges-da: ", params.dataToModify)
            params.state.formFields = ["Credits", "Username", "Password"]
            params.state.formFieldTypes = ["select", "text", "password"];
            params.dispatch(showConfirmModalAction(false));
            params.dispatch(showFormModalAction(true));
            const guestsBillsCredits = params.dataToModify.guestsBill;

            const guestCreditBillDescription =  guestsBillsCredits[0].bills;
            const creditBillsToReverse = []as string[];
            const creditsAndFolioIds = []as string[];
            guestCreditBillDescription.forEach((bill:any) => {
                const { dateCreated, charges, credits, folioId, description } = bill; 
                if(credits > 0 && charges === 0){
                    const data = `${description} - (${(givenDateAndCurrentTime(dateCreated.split("T")[0])as Date).toDateString()}) - ${credits.toLocaleString()}`;
                    creditBillsToReverse.push(data);
                    creditsAndFolioIds.push(`${data}/${folioId}`)
                }
            })
            console.log("\n\t guestCreditBillDescription: ", guestCreditBillDescription);
            return params.stateHandler({
                ...params.state,
                formModalSaveButtonName: "Reverse",
                confirmModalTitle: "Reversing this credit implies that the guest was incorrectly credited and that the system should negate this credit. Are you sure about this?",
                formModalTitle: `Reverse Superfluous Credits`,
                btnAction: `${params.action.toLowerCase().replaceAll(" ", "-")}`,
                serverSelectOption: {
                    credits: creditBillsToReverse,
                    creditsAndFolioIds
                },
            });
        default:
            return params.stateHandler({
                ...params.state
            })
    }
};


export const monthSelection = (data: any[], selection: string) => {
    try {
        const requiredData = []as any[];
        if(selection === "Current"){
            console.log("\n\t Selection: ", selection)
            data.forEach(entity => {
                console.log("\n\t entity: ", entity)
                const entityMonth = entity.monthRecord;
                const currentMonth = getMonth(todaysDate());
                console.log("\n\t entityMonth: ", entityMonth)
                console.log("\n\t currentMonth: ", currentMonth)
                if(currentMonth === entityMonth){
                    requiredData.push(entity)
                }
            })
        }else{
            data.forEach(entity => {
                const entityMonth = entity.monthRecord
                const currentMonth = getMonth(todaysDate())
                if(currentMonth-1 !== entityMonth){
                    requiredData.push(entity)
                }
            })
        }
        return requiredData
    } catch (error) {
        console.log(error)
        return []
    }
};


interface UseEffectFunctionInterface {
    pathname: string
    reportsState: any
    tableData: any
}
export const reportsUseEffectFunction = (params: UseEffectFunctionInterface) => {
    params.reportsState.summedDebit = 0;
    params.reportsState.summedCredit = 0;
    params.reportsState.summedVAT = 0;
    params.reportsState.summedServiceCharge = 0;
    params.reportsState.summedConsumptionCharge = 0;
    params.reportsState.summedDepositAmount = 0;
    params.reportsState.summedNetCharge = 0;
    params.reportsState.summedDepositAmount = 0;
    params.reportsState.summedPMDebitAmount = 0;
    params.reportsState.summedPMCreditAmount = 0;
    params.reportsState.summedReceiptTotalAmount = 0;
    params.reportsState.summedReceiptActualAmountPaid = 0;

    // console.log("\n\t tableData: ", params.tableData)
    if(params.pathname.includes('transactions')){
        params.tableData.map((data: any) => {
            params.reportsState.summedDebit += data.debit;
            params.reportsState.summedCredit += data.credit;
            params.reportsState.summedVAT += data.vat;
            params.reportsState.summedServiceCharge += data.serviceCharge;
            params.reportsState.summedConsumptionCharge += data.consumptionCharge;
            params.reportsState.summedDepositAmount += data.amount;
            params.reportsState.summedNetCharge += data.netCharge;
        })
    }else if(params.pathname.includes('deposit')){
        params.tableData.map((data: any) => {
            params.reportsState.summedDepositAmount += data.amount;
        });
    }else if(params.pathname.includes('pm-account')){
        params.tableData.map((data: any) => {
            params.reportsState.summedPMDebitAmount += data.debit;
            params.reportsState.summedPMCreditAmount += data.credit;
        });
    }else if(params.pathname.includes('receipts') || params.pathname.includes('bills')){
        params.tableData.map((data: any) => {
            params.reportsState.summedReceiptTotalAmount += data.totalCost;
            params.reportsState.summedReceiptActualAmountPaid += data.amountPaid;
        });
    }
}