import React, { useEffect, useState } from 'react';

import "../../styles/HomeScreen.css";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import userDetailsAction from '../../redux/actions/storeActions/userDetailsAction';
import { setStorageItem } from '../../utilities/localStorage';
import greeter from '../../utilities/greeter';
import Login from '../containers/Login';
import Logout from '../containers/Logout';

import loadingGif1 from "../../assets/img/loading-gif3.gif";
import loadingGif2 from "../../assets/img/loading-gif2.gif";
import { inputValidator } from '../../utilities/security';
import { customAlert } from '../../utilities/customAlert';
import axiosHelper, { axiosErrorHelper } from '../../utilities/axiosHelper';
import WarningModal from '../containers/WarningModal';

interface Props {
    
}

interface LoginDetailsInterface {
    Username: string
    Password: string
    [key: string]: string
}

const HomeScreen = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [homeScreeState, setHomeScreeState] = useS
   
    const [loginState, setLoginState] = useState({
        hideLoginButton: false,
        welcomeMessage: "",
        showActivityIndicator: false,
        showSecondGif: false
    })
    const [showWarningModal, setShowWarningModal] = useState({
        status: false,
        message: "",
        loginMessage: "Please, enter your login credentials."
    });
    const [loginDetails, setLoginDetails] = useState<LoginDetailsInterface>({
        Username: "",
        Password: "",
    });
    const handleInputChange = (field: string, value: string) => {
        // console.log("\n\t HomeScreen-handleInputChange: ", field, value)
        const newDetails = loginDetails;
        newDetails[field] = value;
        setLoginDetails({
            ...newDetails,    
        })
    }

    const handleSignIn = async () => {
        const validations = inputValidator(loginDetails, 2, Object.keys(loginDetails));
        if(typeof(validations) === "string"){
            return customAlert("error", validations as string);
        }
        try {
            loginState.hideLoginButton = true;
            // console.log("\n\t loginDetails: ", loginDetails);
            const loginResponse = await axiosHelper({
                routeName: `/users/login`,
                dataToPostEditOrDelete: {...loginDetails},
                httpVerb: "post"
            });
            // console.log("\n\t loginResponse: ", loginResponse);
            if(typeof(loginResponse) === "string" && loginResponse.includes('expired')){
                customAlert("error", loginResponse, 5000)
                return setShowWarningModal({
                    ...showWarningModal,
                    loginMessage: loginResponse
                });
            }
            if(typeof(loginResponse) !== "string"){
                loginState.showActivityIndicator = true;
                const data = (loginResponse as any).data;
                const {licenseDetails, shiftStatus, accessToken, user, hotelDate, companysId, companysAddress, companysLogo} = data;
                // console.log("\n\t loginResponse-licenseDetails: ", licenseDetails);
                const usersId = user._id;
    
                const { userType, userName, firstName, lastName, email, companysEmail } = user;
                // dispatch the users' details upon successful login
                dispatch(userDetailsAction({userName, shiftStatus, hotelDate, companysEmail, userType, companysAddress, companysLogo, firstName, lastName, licenseDetails, email}));
                setStorageItem("accessToken", accessToken);
                setStorageItem("usersId", usersId);
                setStorageItem("companysId", companysId)
                //======================================================
                setLoginState({
                    ...loginState,
                    welcomeMessage: `Hi ${firstName.concat(" ", lastName)}.`
                })
                // let pTag: any = ""
                setTimeout(() => {
                    setLoginState({
                        ...loginState,
                        welcomeMessage: `HotelPro is setting-up your dashboard, please wait...`
                    })
                }, 3000);
                setTimeout(() => {
                    setLoginState({
                        ...loginState,
                        welcomeMessage: `HotelPro is almost done setting up...`
                    })
                }, 4500);
                loginState.showSecondGif = true;
                setTimeout(() => {
                    setLoginState({
                        ...loginState,
                        welcomeMessage: `${greeter()} ${firstName.concat(" ", lastName)} You work at the ${userType} section.`
                    })
                }, 6000);
                setTimeout(() => {
                    setLoginState({
                        ...loginState,
                        welcomeMessage: `Dashboard set-up completed successfully,...`
                    })
                    // setWelcomeMessage(`Dashboard set-up completed successfully,...`);
                }, 8000);
                return setTimeout(() => {
                    setLoginState({
                        ...loginState,
                        showActivityIndicator: false
                    })
                    // setShowActivityIndicator(false);
                    navigate("/dashboard");
                }, 10000);
            };
            throw new Error(loginResponse);
        } catch (error: any) {
            customAlert("error", error.message, 5000);
            setLoginState({
                ...loginState,
                hideLoginButton: false
            })
            console.log("\n\t Error: ", error.message)
            // axiosErrorHelper(error)
        }
    };
    const enterKeyAction = (e: any) => {
        // console.log("\n\t enterKeyAction: ", e)
        if(e.key === "Enter"){
            setLoginState({
                ...loginState,
                hideLoginButton: true
            })
            document.getElementById("login-button")!.click()
        }
    }
    useEffect(() => {
        (async() => {
            try {
                const initialiserDetails = await axiosHelper({
                    httpVerb: 'get',
                    routeName: "/users/system-initialisation"
                })
                customAlert("success", initialiserDetails.data.message, 7000)
            } catch (error: any) {
                console.log(error.message)
            }
        })()
    }, []);
    return (
        <React.Fragment>
            {
                showWarningModal.status && 
                <WarningModal
                    warningMessage={showWarningModal.message}
                />
            }
            {
                pathname !== "/logout" ?
                <div className={`hold-transition home_body ${loginState.showActivityIndicator ? !loginState.showSecondGif ? "gif_active_in_home_body" : 'second_gif_active_in_home_body' : ""}`}>
                    {
                        loginState.showActivityIndicator ?
                        <span className="indicator_container_style">
                            {
                                !loginState.showSecondGif ?
                                <img className="gif_style" src={loadingGif1} />
                                :
                                <img className="gif_style" src={loadingGif2} />
                            }
                            {
                                loginState.welcomeMessage.length > 0 &&
                                <div className='welcome-message-container'>
                                    <p id="message-ptag"
                                        className={`gif_text ${!loginState.showSecondGif ? 'animate__animated animate__pulse animate__infinite' : 'animate__animated animate__bounce animate__infinite text-white'} `}>
                                        {loginState.welcomeMessage}
                                    </p>
                                </div>
                            }
                        </span>
                        :
                        <div className="row d-flex justify-content-center">
                            <div style={{width: "50%", justifyContent: "center"}} className="">
                                <Login 
                                    handleInputChange={handleInputChange}
                                    handleSignIn={handleSignIn}
                                    loginDetails={[...Object.keys(loginDetails)]}
                                    loginMessage={showWarningModal.loginMessage}
                                    hideLoginButton={loginState.hideLoginButton}
                                    enterKeyAction={enterKeyAction}
                                />
                            </div>
                        </div>
                    }
                </div>
                :
                <Logout />
            }
        </React.Fragment>
    )
}

export default HomeScreen
