import { HTMLAttributes, useEffect, useState } from "react";
import { useLocation } from "react-router";
import colors from "../../../../assets/colors";
import { getDashboardDetails } from "../../../../utilities/databaseHelpers";

interface Props extends HTMLAttributes<any> {
    dashboardName: string
    pathname?: string
}


const ModuleSummary = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    const [resolvedSummaryHeader, setResolvedSummaryHeader] = useState<any[]>();
    useEffect(() => {
        (async () => {
            const dashboardDetails = await getDashboardDetails(props.pathname ? props.pathname : pathname);
            // console.log("\n\t dashboardDetails: ", dashboardDetails)
            const { summaryHeader } = dashboardDetails as any;
            setResolvedSummaryHeader(summaryHeader);
        })();
    }, []);
    console.log("\n\t ModuleSummary-summaryHeader: ", resolvedSummaryHeader && resolvedSummaryHeader)
    return (
        <div className="mt-4 mr-md-4 module-summary">
            <h4 className="mt-5 dashboard-title">
                {props.dashboardName} 
            </h4>
            <span style={{borderBottomWidth: "2px", borderBottomColor: "ghostwhite", borderBottomStyle: "solid", width: "80vw"}} className="hr d-flex mb-3"></span>

            <div className="row">
                {
                    resolvedSummaryHeader && Object.keys(resolvedSummaryHeader).map((dataKey, index)  => (
                        <div key={index} className="col-xl-3 col-md-3">
                            <div style={{height: (pathname.includes("reports") || pathname.includes("backtrack")) ? "150px" : "120px"}} className="card bg-white text-dark mb-4">
                                <div style={{flexDirection: "column"}} className="d-flex card-body">
                                    <h5 style={{marginBottom: "-3px"}} className="card-title font-weight-bold ">{dataKey}:</h5>
                                    <span className="mt-1 text-light" style={{borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: colors.sideNav, opacity: 0.35}}></span>
                                    <div className="my-2">
                                        <p className="mb-3">{Object.values(resolvedSummaryHeader)[index]} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <hr />
            </div>
        </div>
    )
}

export default ModuleSummary;
