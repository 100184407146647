
export const frontdeskSidebarNavlinks = [
    "Arrivals",
    "Checked-in",
    "Departures", 
    // "Checked-out", 
    "Security & Auditor Report",
    // "Guest Bills",
    "PM Account",
    "Room Details", 
    "Facilities", 
    "History", 
    // "House Account", 
    "End Of Day",
];

export const fAndBSidebarNavlinks = [
    "Point-of-sales",
    "Receipts",
    // "Folios",
    "Tables",
    // "Backtrack",
    "Daily Sales",
    "Shift Sales",
    "Stocks",
];
export const storeSidebarNavlinks = [
    "Sections",
    "Items",
    "Records",
    "Outlets",
    "Logs",
];
export const storeSubMenus  = {
    "Sections": [],
    "Items":  [],
    "Records": ["Items", "Outlets"], 
    "Outlets": [],
    "Logs": [],
};

export const reservationSidebarLinks =  [
    "New Reservation", 
    "Reservation Table", 
    "Calendar",
    "Schedules",
    "Room status",
    "Receipts",
    "Profiles"
];

export const reservationsSubMenus  = {
    "New Reservation":  ["Group", "Individual"],
    "Reservation Table": ["Group", "Individual"],
    "Schedules": ["Future Individual Arrivals", "Future Group Arrivals", "Future Occupancies"],
    "Room status": [], 
    "Receipts": [],
    "Profiles": []
};


export const settingsSidebarNavlinks = [
    "Charges", 
    "Credits",
    "Inventory Management", 
    "User Management",
    "User Activities",
    "Group Management", 
    "Other Setups", 
    "Room Settings", 
    "Communications",
    "PM Account",
    "Facilities",
    // "Table Management", 
    "Companies",
    "Licenses"
   
]

export const reportsSidebarNavlinks = [
    "Guest Ledger", 
    "Guest In-house", 
    // "Guest Bills",
    "City Ledger", 
    "Credit Balance",
    "Financial Transactions", 
    // "Todays Transactions", 
    // "Room Discrepancy", 
    "Managers' Flash", 
    "Restaurant Daily Sales",
    "Bar Daily Sales",
    "Pool-Bar Daily Sales",

    "Daily Logs",
    "Restaurant Shift Sales",
    "Bar Shift Sales",
    "Pool-Bar Shift Sales",

    // "Trial Balance", 
    "PM Account",
    "Aging Analysis", 
    "Checkout History", 
    "Deposits", 

    "Receipts",
    // "History",
    "End Of Day",
]

export const recordsSidebarNavlinks = [
    // "Guest Ledger", 
    // "Guest In-house", 
    // "Guest Bills",
    "Individual In-House",
    "Group In-House",
    "Financial Transactions", 
    "Charges",
    "Credits",
    // "City Ledger", 
    // "Room Discrepancy", 
    "Managers' Flash", 
    "Trial Balance", 
    // "Aging Analysis", 
    "Folios",
    "Cashiers Summary Report", 
    "Cashiers Breakdown Report", 
    "Deposits", 
    "Market Count Segmentation", 
    "Market Worth Segmentation", 
    "Room-Type Count Segmentation", 
    "Room-Type Worth Segmentation", 
    "Receipts",
    // "Restaurant Daily Sales",
    // "Bar Daily Sales",
    // "Pool-Bar Daily Sales",
    "Daily Logs"
]
export const reportsSidebarNavlinksSubMenu = {
    "Guest Ledger": ["Group", "Individual"], 
    "Guest In-house": ["Group", "Individual"], 
    // "Guest Bills": ["Group", "Individual"],
    "City Ledger": ["Group", "Individual"], 
    "Credit Balance": ["Group", "Individual"], 
    "Financial Transactions": [], 
    // "Rate Variance": [], 
    // "Room Discrepancy": [], 
    "Managers' Flash": [], 
    // "Trial Balance": [], 
    "PM Account": [],
    "Aging Analysis": [], 
    "Deposits": [], 
    "Cashiers Report": [], 
    "Receipts": [],
    // "History": [],
    "End Of Day": [],

}


export const frontdeskSubMenus  = {
    "Arrivals": ["Group", "Individual"],
    "Checked-in":  ["Group", "Individual"],
    "Departures": ["Group", "Individual"], 
    // "Checked-out": ["Group", "Individual"], 
    "Security & Auditor Report": ['Group', "Individual"],
    // "Guest Bills": ['Group', "Individual"],
    "PM Account": ["Checked-Out", "In-House"],
    "Room Details": ["Room Status", "Room-type Summary"], 
    "Facilities": ["Facility", "Events"],
    "History": [], 
    // "House Account": [], 
    "End Of Day": [],
};


export const settingsSubMenus = {
    "Charges": ["Frontdesk Charges", "F and B Charges", "F and B Discounts", "Taxes", "Payment Method"],
    "Credits": ["Frontdesk Credits", "F and B Credits"],
    "Inventory Management": ["Major Group", "Family Group", "Menu Items", "F and B Tables", "F and B Time"],
    "User Management": ["Users", "Shifts", "Shift timing"],
    "User Activities": ["User Logs"],
    "Group Management": ["Groups", "Group Rate", "Membership Type"],
    "Room Settings": [
        "Room Floor", 
        "Room Type", 
        "Room and Rates", 
        "Room Status", 
        "Bar Rates",
        // "Schedule Rates"
    ],
    "Communications": [""],
    "Other Setups": ["Title", "Source", "Hotel Date"],
    "PM Account": ["Checked-Out", "In-House"],
    "Facilities": [''],
    // "Table Management": [],
    "Companies": [],
    "Licenses": []
}




export const houseKeepingNavlinks = [
    "Room Status",
    "Room-type Summary",
    
];

export const backTrackNavlinks = [
    "Accommodation",
    "Guests",
    "Facilities",
    "PM Account",
    "Restaurant",
    "Pool Bar",
    "Bar",
    "No EOD",
];


export const backTrackSubMenus  = {
    "Accommodation": ["Group", "Individual"],
    "Guests": ["Group", "Individual"],
    "PM Account": [],
    "Restaurant": [],
    "Pool Bar": [],
    "Bar": [],
    "Facilities": ["Facility", "Events"],
    "No EOD": []
};

export const statisticsNavlinks = [
    "Daily Audit Count",
    "Monthly Averages",
    "Department Averages",
];


interface HandleMenuInterface {
    state: any
    stateSetter: Function
    toggledLink: string
}
export const handleSubMenus = (props: HandleMenuInterface) => {
    props.state.toggleMenuState[props.toggledLink] = !props.state.toggleMenuState[props.toggledLink];
    Object.keys(props.state.toggleMenuState).map(link => {
        if(link !== props.toggledLink){
            props.state.toggleMenuState[link] = props.state.toggleMenuState[link] ? !props.state.toggleMenuState[link] : props.state.toggleMenuState[link];
        }
    });
    props.stateSetter({...props.state});
};