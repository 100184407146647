import React, { HTMLAttributes, lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Footer from '../Footer';
import "../../../styles/ModuleDashboardStyles.css";
import ReservationsDashbords from './Dashboards/ReservationsDashbords';
import ModuleSidebar from "./ModuleSidebar/ModuleSidebar";
import { Link } from 'react-router-dom';

import { 
    reservationSidebarLinks, 
    frontdeskSidebarNavlinks,
    settingsSidebarNavlinks,
    fAndBSidebarNavlinks,
    reportsSidebarNavlinks,
    storeSidebarNavlinks,
    recordsSidebarNavlinks,
    houseKeepingNavlinks,
    backTrackNavlinks,
    statisticsNavlinks
} from "./ModuleSidebar/SidebarNavLinks";

import { getDashboardDetails } from '../../../utilities/databaseHelpers';
import SettingsDashboard from './Dashboards/SettingsDashboard';
import FrontdeskDashboard from './Dashboards/FrontDeskDashboard';
import FAndBDashboard from './Dashboards/FAndBDashboard ';
import StoreDashboard from './Dashboards/StoreDashboard ';
// // import RestaurantDashboard from './Dashboards/RestaurantDashboard';
import Modal from '../Modal';
import AuditReportsDashboard from './Dashboards/AuditReportsDashboard';
import RecordsDashboard from './Dashboards/RecordsDashboard';
import HouseKeepingDashboard from './Dashboards/HouseKeepingDashboard';
import BackTrackDashboard from './Dashboards/Backtrack';
// import { Link } from 'react-router-dom';
// import { toggleSidebarAction } from '../../../redux/actions/storeActions/appActionsUtilities';
import StatisticsDashboard from './Dashboards/StatisticsDashboard';
import { toggleSidebarAction } from '../../../redux/actions/storeActions/appActionsUtilities';

// const SettingsDashboard = lazy(() => import('./Dashboards/SettingsDashboard'));
// const FrontdeskDashboard = lazy(() => import('./Dashboards/FrontDeskDashboard'));
// const FAndBDashboard = lazy(() => import('./Dashboards/FAndBDashboard '));
// const StoreDashboard = lazy(() => import('./Dashboards/StoreDashboard '));
// const AuditReportsDashboard = lazy(() => import('./Dashboards/AuditReportsDashboard'));
// const RecordsDashboard = lazy(() => import('./Dashboards/RecordsDashboard'));
// const HouseKeepingDashboard = lazy(() => import('./Dashboards/HouseKeepingDashboard'));
// const BackTrackDashboard = lazy(() => import('./Dashboards/Backtrack'));
// const StatisticsDashboard = lazy(() => import('./Dashboards/StatisticsDashboard'));

interface Props extends HTMLAttributes<any> {
    
};

const ModulesDashboard = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    const currentPath = location.pathname.split("/");
    // const highAdmins = ["Admin", "Halogen Admin"]
    
    const store = useSelector((store: any) => store);
    const { sidebarStatus, userDetails, footer, processingGifStatus } = store;
    const showSidebar = sidebarStatus ? sidebarStatus.showSidebar : false;
    const { userName, userType }  =  userDetails.userDetails ? userDetails.userDetails : "";
    const showProcessingGif = processingGifStatus ? processingGifStatus.showProcessingGif : false
    const { showFooter } = footer;
    const [summaryHeader, setSummaryHeader] = useState<any>();
    const [tableData, setTableData] = useState<any>();  
    const headerUser = userType || "";
    useEffect(() => {
        (async () => {
            if(!pathname.includes('backtrack') && !pathname.includes('end-of-day')){
                const dashboardDetails = await getDashboardDetails(pathname);
                // console.log("\n\t reservationDetails: ", dashboardDetails)
                const { summaryHeader, tableData } = dashboardDetails as any;
                setSummaryHeader(summaryHeader);
                setTableData(tableData);
            }
        })();
    }, []);
    // console.log("\n\t currentPath: ", currentPath);
    const dispatch = useDispatch();
    return (
        <div className=''>
            <div className="row mt-3"></div>
            <div className="row mt-3"></div>
          
            <div className="mt-4 d-flex" data-aos="fade-up" data-aos-delay="400">
                {
                    showProcessingGif &&
                    <Modal
                        pathname={pathname}
                        handleAction={() => undefined}
                        // setPrintRegCard={() => undefined}
                        onChange={() => undefined}
                        type="processing"
                        numberOfFields={0}
                        setGroupCheckInData={() => undefined}
                    />
                }
                <div className="d-flex row module-dashboard">
                    {
                        !pathname.toLowerCase().includes("audit-reports/end-of-day") && !pathname.toLowerCase().includes("frontdesk/end-of-day") &&
                        <div style={{zIndex: 10}} className={`modules-sidebar ${showSidebar ? 'col-md-2' : 'col-md-0'} print-side-navbar`}>
                            <span style={{zIndex: 10}}>
                                <Link onClick={() => dispatch(toggleSidebarAction(true))} className={`navbar-brand heading `} style={{fontSize: "1.8rem"}} to="/dashboard">Hotel-Pro (V3)</Link>
                                <Link className="navbar-brand-icon heading" to="/"><i className="fas fa-home"></i></Link>
                            </span>
                            <ModuleSidebar navLinks={
                                currentPath[1].includes("reservations") ? reservationSidebarLinks 
                                :  currentPath[1] == "frontdesk" ? frontdeskSidebarNavlinks 
                                :  currentPath[1] == "settings" ? settingsSidebarNavlinks 
                                :  currentPath[1].includes("bar") ? fAndBSidebarNavlinks.slice(headerUser.toLowerCase().includes("accounts") ? 3 : 0, headerUser.toLowerCase().includes("admin") ? undefined : 5) 
                                :  currentPath[1].includes("reports") ? reportsSidebarNavlinks 
                                :  currentPath[1] == "restaurant" ? fAndBSidebarNavlinks.slice(headerUser.toLowerCase().includes("accounts") ? 3 : 0, headerUser.toLowerCase().includes("admin") ? undefined : 5)
                                :  currentPath[1] == "store" ? storeSidebarNavlinks
                                :  currentPath[1] == "backtrack" ? backTrackNavlinks
                                :  currentPath[1] == "records" ? recordsSidebarNavlinks
                                :  currentPath[1] == "statistics" ? statisticsNavlinks
                                :  currentPath[1].includes("keeping") ? houseKeepingNavlinks  //for house-keepers
                                : ['']
                                } 
                            />
                        </div>
                    }
                    <div style={{flexDirection: "column",}} className={` ${showSidebar ? 'col-md-10 ml-sm-2 ml-md-0' : 'col-md-12'}`}>
                        {
                            currentPath[1] == "reservations" ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <ReservationsDashbords 
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1] == "settings" ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <SettingsDashboard
                                    summaryHeader={summaryHeader}
                                />
                            // </Suspense>
                            :
                            currentPath[1] == "frontdesk" ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <FrontdeskDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1].includes("bar") ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <FAndBDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1].includes("house-keeping") ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <HouseKeepingDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1] == "store" ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <StoreDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1] == "backtrack" ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <BackTrackDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1] == "restaurant" ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <FAndBDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1] == "statistics" ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <StatisticsDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1] == "records" ?
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <RecordsDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                            :
                            currentPath[1].includes("reports") &&
                            // <Suspense fallback={<h2>HotelPro</h2>}>
                                <AuditReportsDashboard
                                    summaryHeader={summaryHeader}
                                    tableData={tableData}
                                />
                            // </Suspense>
                        }
                        {
                            showFooter &&
                            <Footer />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModulesDashboard;
