import React, { useEffect, useState } from 'react'
import { FaChevronCircleLeft, FaChevronCircleRight, FaChevronLeft, FaChevronRight, FaMinus, FaPlus, FaTimes } from 'react-icons/fa';
import { yesterdaysFormDate } from '../../../assets/events/individualReservations';
import { computeOrderTotals } from './fAndBTrayHelper';


interface Props {
    orderMode: "new" | "modify"
    fAndBTrayState: any
    orderDetailsToModify: any
    modifyItemOnTray: Function
    setFAndBTrayState: Function
    personCount: string
    fieldNames: string[]
    fieldTypes: string[]
    setCategoryVariety: Function
    setVarietyMenuOptions: Function
    handleRestaurantTrayInputChange: Function
    setRestaurantTableItems: Function
    setTableItemsFromOrderToModify: Function
    tableOrder: any,
    totals: number[]
    pathname: string
};


const TrayOrder = (props: Props) => {
    console.log("\n\t TrayOrder-tableOrder: ", props.tableOrder)
    console.log("\n\t TrayOrder-totals: ", props.totals)

    const [trayState, setTrayState] = useState({
        totals: [] as number[]
    })
    useEffect(() => {
        const orderTotalPerPerson = computeOrderTotals(props.tableOrder);
        console.log("\n\t Recalled...");
        
        setTrayState({
            ...trayState,
            totals: orderTotalPerPerson
        })
    }, []);
    console.log("\n\t props.fAndBTrayState.categoryVarietiesUnderChosenCategory: ", props.fAndBTrayState.categoryVarietiesUnderChosenCategory)
    return (
        <div>
            <section style={{justifyContent: "space-around", width: '100%'}} className="row d-flex mt-3 align-items-center">
                <hr className='ml-2 row d-flex' />
                <tr className='text-center'><th colSpan={13}>Order For: Person {props.personCount} </th></tr>
                <hr className='ml-2 row d-flex' />
                {
                    props.fieldNames.map((fieldName, index) => (
                        <article key={index} className="form-group col-md-3">
                            {
                                props.fieldTypes[index] === 'select' ?
                                <article>
                                    <label>{fieldName}:</label>
                                    <select onChange={
                                        fieldName.toLowerCase() === 'major group' ?
                                        e => props.setCategoryVariety(e.target.value) : 
                                        undefined 
                                        } 
                                        style={{fontSize: "1.3em"}}
                                        className={`form-select form-control ${fieldName.toLowerCase().replaceAll(" ", "-")}`} name="" id="">
                                        <option value="">Select {fieldName}</option>
                                        {
                                            fieldName.toLowerCase() === 'major group' && props.fAndBTrayState.restaurantCategoryNames &&
                                            props.fAndBTrayState.restaurantCategoryNames.sort().map((cat: any, index: number) => (
                                                <option key={index} value={cat}>{cat}</option>
                                            ))
                                        }
                                    </select>
                                </article>
                                :
                                props.fieldTypes[index] === 'datalist' ?
                                <article >
                                    <label>{fieldName}:</label>
                                    <input
                                        onChange={e => 
                                            fieldName.toLowerCase() === 'family group' ?
                                        props.setVarietyMenuOptions(e.target.value) : undefined}
                                        className={`form-control ${fieldName.toLowerCase().replaceAll(" ", "-")}`}
                                        style={{fontSize: "1.5em"}} 
                                        name={fieldName.toLowerCase().replaceAll(" ", "-")}
                                        list={`datalist-${fieldName.toLowerCase().replaceAll(" ", "-")}`}
                                        placeholder={`Select ${fieldName}`}
                                        type="text"
                                    />
                                    <datalist style={{fontSize: "1.5em",}} id={`datalist-${fieldName.toLowerCase().replaceAll(" ", "-")}`}>
                                        {
                                            fieldName.toLowerCase() === 'family group' && props.fAndBTrayState.categoryVarietiesUnderChosenCategory ?
                                            props.fAndBTrayState.categoryVarietiesUnderChosenCategory.sort().map((variety: any, index: number) => (
                                                <option style={{fontSize: "1.5em",}} key={index} value={variety}>{variety}</option>
                                            ))
                                            :
                                            fieldName.toLowerCase() === 'menu item' && props.fAndBTrayState.varietyMenuUnderChosenCategoryVariety &&
                                            props.fAndBTrayState.varietyMenuUnderChosenCategoryVariety.sort().map((menu: any, index: number) => (
                                                <option style={{fontSize: "1.5em",}} key={index} value={menu}>{menu}</option>
                                            ))
                                        }

                                    </datalist>
                                </article>
                                :
                                <article>
                                    <label>{fieldName}:</label>
                                    <input style={{fontSize: "1.3em"}}  onChange={e => props.handleRestaurantTrayInputChange(`${fieldName.toLowerCase().replaceAll(" ", "-")}`, e.target.value)} min={1} className={`form-control ${fieldName.toLowerCase().replaceAll(" ", "-")}`} type="number" />
                                </article>
                            }
                        </article>
                    ))
                }
                
                <article className="btn-container">
                    <button 
                        id='add-item-button'
                        onClick={e => props.setRestaurantTableItems(props.fAndBTrayState, props.setFAndBTrayState, +props.personCount-1)}
                        className="btn btn-info mt-1 mb-3 mx-2">
                        Add item
                    </button>
                </article>
            </section>
            <section className="row d-flex justify-content-center">
                <div style={{width: "40vw"}} className="table-responsive row d-flex align-self-center">
                    <table className="table table-hover table-striped table-bordered ">
                        <thead className="text-center">
                            <tr className="text-center col-md-12">
                                <th className="" colSpan={1}>S/N</th>
                                <th className="" colSpan={4}>Item</th>
                                <th className="" colSpan={3}>Quantity</th>
                                <th className="" colSpan={3}>Cost</th>
                                <th className="" colSpan={1}>Total</th>
                                <th className="" colSpan={1}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.tableOrder &&
                                props.tableOrder[`Person-${+props.personCount}`].map((order: any, index: number) => (
                                    <tr key={index} className="text-center">
                                        <td colSpan={1}>{index+1}</td>
                                        <td colSpan={4}>
                                            <FaChevronCircleLeft className='box_shadow mr-4' onClick={e => props.modifyItemOnTray(+props.personCount-1, index, props.fAndBTrayState, props.setFAndBTrayState, 'change-item-left')} style={{cursor: "pointer"}} color="steelblue" size={20} />
                                            {order.item}
                                            <FaChevronCircleRight className='ml-4 box_shadow' onClick={e => props.modifyItemOnTray(+props.personCount-1, index, props.fAndBTrayState, props.setFAndBTrayState, 'change-item-right')} style={{cursor: "pointer"}} color="steelblue" size={20} />
                                        </td>

                                        <td colSpan={3}>
                                            <FaMinus className='mr-4' onClick={e => props.modifyItemOnTray(+props.personCount-1, index, props.fAndBTrayState, props.setFAndBTrayState, 'minus-quantity')} style={{cursor: "pointer"}} color="red" size={23} />
                                            {order.quantity}
                                            <FaPlus className='ml-4' onClick={e => props.modifyItemOnTray(+props.personCount-1, index, props.fAndBTrayState, props.setFAndBTrayState, "add-quantity")} style={{cursor: "pointer"}} color="green" size={23} />
                                        </td>
                                        <td colSpan={3}>{order.cost.toLocaleString()}</td>
                                        <td>{(order.cost*order.quantity).toLocaleString()}</td>
                                        <td colSpan={1}>
                                            <FaTimes onClick={e => props.modifyItemOnTray(+props.personCount-1, index, props.fAndBTrayState, props.setFAndBTrayState, 'delete-item')} style={{cursor: "pointer"}} color="red" size={20} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        {
                            props.totals && props.totals.length > 0 &&
                            <tfoot className="">
                                <tr className="text-center">
                                    <td colSpan={1} className="text-center font-weight-bold">Total</td>
                                    <td colSpan={4}></td>
                                    <td colSpan={3}></td>
                                    <td colSpan={3}></td>
                                    <td colSpan={1}>{props.totals[+props.personCount-1].toLocaleString()}</td>
                                    
                                    <td colSpan={3}>{}</td>
                                </tr>
                            </tfoot>
                        }
                    </table>
                    {
                        props.pathname.includes("backtrack") &&
                        <section className='d-flex' style={{flexDirection: "column"}}>
                            <label htmlFor="">Backtrack Date:</label>
                            <input 
                                max={yesterdaysFormDate()}
                                className='form-control' 
                                onChange={e => props.handleRestaurantTrayInputChange('backtrackDate', e.target.value)} 
                                type={"date"} 
                            />
                        </section>
                    }
                </div>
            </section>
        </div>
    )
}

export default TrayOrder