import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "../../../../styles/TableStyles.css";

import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import colors from '../../../../assets/colors';
import { callBackendAction, showConfirmModalAction, showFormModalAction } from '../../../../redux/actions/storeActions/appActionsUtilities';
import axiosHelper from '../../../../utilities/axiosHelper';
import { getReservationDetails } from '../../../../utilities/databaseHelpers';
import { buttonTitle, tableDataCleaner, titledTextJoiner } from '../../../../utilities/strings';
import Modal from '../../Modal';
import { 
    departments, 
    fAndBVirtualRoomsTableHeader, 
    formFieldsSelector, 
    houseKeepingStatus, 
    paginationButtons, 
    paymentMethodTableHeader, 
    roomFloorTableHeader, 
    roomStatusTableHeader, 
    roomTypeSummaryTableHeader, 
    rowActionFunctions,
} from './tableData';
import Filter from '../../Filter';
import { customAlert } from '../../../../utilities/customAlert';
import { handleDataPagination, houseKeepingStylist } from './tableActions/tableActionsThree';


interface Props {
   
}


const HouseKeepingTable = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    
    const [houseKeepingState, sethouseKeepingState] = useState({
        loadingPrompt: "Please wait, table data is loading....",
        searchParametre: "",
        dataId: ""as any,
        roomTypes: [] as any,
        roomFloor: [] as any,
        tableData: [] as any,
        paginationStartPoint: 0,
        paginationEndPoint: pathname.includes('room') ? 15 : 10,
        paginationLength: pathname.includes('room') ? 15 : 10,
        paginatedTableData: [] as any,
        actualLengthOfTableData: 0,
        userRoles: [],
        houseKeepingInput: {} as any,
        btnAction: ""
    });

    const dispatch = useDispatch();
    const store = useSelector((store: any) => store);
    const { modalStatus, sidebarStatus, userDetails, confirmModal } = store;
    const {showSidebar} = sidebarStatus;
    const { showFormModal } = modalStatus;
    const { showConfirm } = confirmModal;
    const userDetailsData = userDetails ? userDetails['userDetails'] : undefined;
    const userType = userDetailsData ? userDetailsData['userType'] : undefined;
    console.log(`\n\t HouseKeepingTable userType: `, userType);

    const houseKeepingAction = ["Edit details", "Delete"];
    const currentSubmenu = pathname.split("/")[3] || pathname.split("/")[2];

    const dashboardRouteChecker = () => {
        return "House Keeping"
    }
    
    const handleInputChange = (field: string, value: string, files?: any) => {
        console.log("\n\t houseKeeping: ", field, value);
        houseKeepingState.houseKeepingInput[titledTextJoiner(field)] = value;
        if(field.toLowerCase() == "logo"){
            const acceptedFiletypes = ['jpeg', 'jpg', 'png'];
            const filetype = files[0].type.split("/")[1];
            console.log("\n\t Files: ", files[0]);
            if(!acceptedFiletypes.includes(filetype)) {
                return customAlert('error', "This image filtype is forbidden.", 5000)
            };
            houseKeepingState.houseKeepingInput['logo'] = files[0];
        };
        sethouseKeepingState({
            ...houseKeepingState,
        });
    };
    const parseFormData = async() => {
        // console.log("\n\t parseFormData-houseKeeping.houseKeepingInput: ", houseKeepingState.houseKeepingInput);
        try {
            return houseKeepingState.houseKeepingInput;
        } catch (error) {
            console.log("\n\t Upload error: ", error)
        }
    };
    // console.log("\n\t houseKeeping.houseKeepingState.houseKeepingInput: ", houseKeepingState.houseKeepingInput);
    const handleRowAction = (action: string, data: any) => {
        console.log("\n\t handleRowAction: ", action, data._id);
        houseKeepingState.btnAction = action.toLowerCase().replaceAll(" ", "-");
        switch(action){
            case action = "Delete":
                dispatch(showConfirmModalAction(true));
                dispatch(showFormModalAction(false));
                return sethouseKeepingState({
                    ...houseKeepingState,
                    dataId: data._id
                }); 
            default:
                const fields = formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[0];
                console.log("\n\t currentSubmenu: ", currentSubmenu)
                
                if(fields){
                    for(let field of fields){
                        if(field.toLowerCase() != 'logo'){
                            const actualField = titledTextJoiner(field);
                            houseKeepingState.houseKeepingInput[actualField] = data[actualField];
                        }
                    }
                    sethouseKeepingState({
                        ...houseKeepingState,
                        dataId: data._id
                    });
                }
                if(currentSubmenu === "room-status") {
                    sethouseKeepingState({
                        ...houseKeepingState,
                        dataId: data._id
                    })
                }
                dispatch(showFormModalAction(true, "Edit"));
        }
    };
    const handlePagination = (btnName: string) => {
        handleDataPagination({
            paginationBtnName: btnName,
            state: houseKeepingState,
            stateHandler: sethouseKeepingState
        })
    }
    useEffect(() => {
        dispatch(callBackendAction(false));
        (async() => {
            try {
                const reservationDetails = (currentSubmenu.includes("rate") || currentSubmenu.includes("variety-menu") || currentSubmenu.includes("varieties")) && await getReservationDetails();
                const { groups, roomTypes, floors, restaurantData } = reservationDetails as any;
                const roomTypeNames = roomTypes && roomTypes.map((type: any) => type.roomTypeName)
                const floorNumber = floors && floors.map((floor: any) => floor.floorNumber)
                const response = await axiosHelper({
                    routeName: pathname,
                    httpVerb: "get"
                });
                console.log(`\n\t HouseKeepingTable response-: `,response);
                if(pathname.includes('users')){
                    const userRoles = await axiosHelper({
                        routeName: `/users/roles`,
                        httpVerb: "get"
                    })
                    houseKeepingState.userRoles = userRoles.data
                    console.log(`\n\t HouseKeepingTable response-userRoles: `,userRoles.data);
                };
                sethouseKeepingState({
                    ...houseKeepingState,
                    roomTypes: roomTypeNames,
                    roomFloor: floorNumber,
                    tableData: pathname.includes('licenses') ? Object.values(response.data['allLicense']) : Object.values(response.data),
                    actualLengthOfTableData: pathname.includes('licenses') ? (Object.values(response.data['allLicense'])).length : (Object.values(response.data)).length,
                    paginatedTableData: pathname.includes('licenses') ? Object.values(response.data['allLicense']).slice(houseKeepingState.paginationStartPoint, houseKeepingState.paginationEndPoint) : (Object.values(response.data)).slice(houseKeepingState.paginationStartPoint, houseKeepingState.paginationEndPoint,),
                    loadingPrompt: "",
                })
            } catch (error) {
                sethouseKeepingState({
                    ...houseKeepingState,
                    loadingPrompt: "",
                })
            }
        })();
    }, [pathname]);
    return (
        <React.Fragment>
            {
                showFormModal && showFormModal.modalStatus ?
                <Modal
                    onChange={handleInputChange}
                    type="form"
                    title={showFormModal.actionText ? `${showFormModal['actionText']} ${buttonTitle(pathname)}` : buttonTitle(pathname).includes("Add") ? buttonTitle(pathname) : `${pathname.includes('pm') ? "Create" : "Add"} ${buttonTitle(pathname)}`}
                    formFields={formFieldsSelector(pathname) && (formFieldsSelector(pathname, userDetails) as string[][])[0].slice(2) || undefined}
                    formFieldType={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[1] || undefined}
                    handleAction={showFormModal.actionText ? async() => rowActionFunctions.updateAction(pathname, houseKeepingState.dataId, await parseFormData()) : async() => rowActionFunctions.postAction(pathname, await parseFormData())}
                    placeholders={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[2] || undefined}
                    selectOption={formFieldsSelector(pathname) && (formFieldsSelector(pathname, userDetails) as string[][])[3]  || undefined}
                    serverSelectOption={{
                        roomType: houseKeepingState.roomTypes,
                        roomFloor: houseKeepingState.roomFloor, 
                        houseKeepingStatus, 
                        department: departments, 
                        outOfOrderReason: ["Heater", "A/C fault", "Beddings", "Furnitures", "POP Issues", "Door Malfunction", "Electrical faults", "Electronics", "Plumbing faults", "NONE"].sort(),
                        role: houseKeepingState.userRoles
                    }}
                    radioOptions={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[4]  || undefined}
                    formInput={houseKeepingState.houseKeepingInput}
                    numberOfFields={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as string[][])[0].length as number || 0}
                    optionalFormText={formFieldsSelector(pathname) && (formFieldsSelector(pathname) as any)[5] || undefined}
                    // setPrintRegCard={() => undefined}
                    setGroupCheckInData={() => undefined}
                    pathname={pathname}
                />
                :
                showConfirm &&
                <Modal
                    onChange={handleInputChange}
                    type="confirm"
                    handleAction={() => rowActionFunctions.deleteAction(pathname, houseKeepingState.dataId)}
                    pathname={pathname}
                    title={`Are you sure about deleting this ${(pathname.split("/")[3] || pathname.split("/")[2]).replaceAll("-", " ")}?`}
                    numberOfFields={0}
                    // setPrintRegCard={() => undefined}
                    setGroupCheckInData={() => undefined}
                />
            }
           <div style={{flexDirection: "column", marginLeft: "17px", 
                                            width: 
                                            pathname.includes('room')? "85vw" :
                                            pathname.includes("companies") ? "90vw"
                                            :
                                            "80vw"}} className={`mt-4 ${showSidebar ? 'm-0' : 'mx-4'}`}>
                <span className="my-1 font-weight-bolder small text-success animate__animated animate__pulse animate__infinite">{houseKeepingState.loadingPrompt}</span>
                <section style={{ justifyContent: "space-between", width: "100%", alignItems: "center"}} className="my-2 row d-flex"> 
                    <span className=''>
                        <h4 className=" mt-2">{dashboardRouteChecker()} ({buttonTitle(pathname)})</h4>
                        <p style={{fontFamily: "serif"}}> 
                            Total {pathname.includes('pm-account') ? `${buttonTitle(pathname).split(" ")[0].toUpperCase()} Account` : buttonTitle(pathname)} : {houseKeepingState.tableData && Object.values(houseKeepingState.tableData).length}
                        </p>
                    </span>
                    <article style={{width: undefined}} className={document.querySelector('.houseKeeping-add-button') ? 'row justify-content-center' : undefined}>
                        <Filter 
                            tableData={houseKeepingState.tableData}
                            componentsState={houseKeepingState}
                            componentsStateSetter={sethouseKeepingState}
                        />
                    </article>
                </section>

                <span style={{borderBottomWidth: "2px", borderBottomColor: "ghostwhite", borderBottomStyle: "solid", width: "80vw"}} className="mb-3 mt-2 hr d-flex"></span>
                <div style={{width: ''}} className="table-responsive d-flex">
                    <table className="table-table table bg-light table-striped table-hover table-bordered" id="example1" width="100%" cellSpacing="0">
                        <thead style={{backgroundColor: colors.sideNav}} className="py-2">
                            <tr className="text-center text-light">
                                {
                                    pathname.includes('virtual-rooms') ?
                                    fAndBVirtualRoomsTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    pathname.includes('room-type') ?
                                    roomTypeSummaryTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    pathname.includes('room-floor') ?
                                    roomFloorTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    pathname.includes('status') ?
                                    roomStatusTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    paymentMethodTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))                                    
                                    
                                }
                            </tr>
                        </thead>
                            {
                                houseKeepingState.paginatedTableData && houseKeepingState.paginatedTableData.length > 0 ?
                                <tbody>
                                    {
                                        houseKeepingState.paginatedTableData && houseKeepingState.paginatedTableData.map((data: any, index: number) => (
                                            <tr className="text-center" key={data._id}>
                                                <React.Fragment>
                                                {
                                                    Object.entries(data).slice(
                                                        currentSubmenu.includes("status") ? 1
                                                        : 0
                                                        ).map((prop, propIndex) => (
                                                        <td key={propIndex} className={`${!pathname.includes('room-and-rates') ? houseKeepingStylist(pathname, data, propIndex): undefined}`}>
                                                            {
                                                                pathname.includes('virtual-rooms') ?
                                                                tableDataCleaner(fAndBVirtualRoomsTableHeader[propIndex], data) || "--"
                                                                :
                                                                pathname.includes("room-type-summary") ?
                                                                tableDataCleaner(roomTypeSummaryTableHeader[propIndex], data) || "--"
                                                                :
                                                                pathname.includes('room-floor') ?
                                                                tableDataCleaner(roomFloorTableHeader[propIndex], data) || "--"
                                                                :
                                                                pathname.includes('status') ?
                                                                tableDataCleaner(roomStatusTableHeader[propIndex], data) || "--"
                                                                :
                                                                tableDataCleaner(paymentMethodTableHeader[propIndex], data) || "--"
                                                            }
                                                        </td>
                                                    ))
                                                }
                                                {
                                                    !pathname.includes('room-type-summary') &&
                                                    <td style={{backgroundColor: colors.sideNav}} className="print"> 
                                                        <div className="dropdown show">
                                                            <Link className="btn button dropdown-toggle sb-sidenav-menu" style={{color: "#f4f4f4"}} to="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions <span></span><span></span><span></span> <span></span></Link>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                {
                                                                    pathname.includes('room-status') ?
                                                                    <Link 
                                                                        data-toggle="modal"
                                                                        data-target="#modal" 
                                                                        className="dropdown-item button" 
                                                                        onClick={() => handleRowAction('edit', data)}
                                                                        to="#">
                                                                            Update Room Status <span></span><span></span><span></span> <span></span>
                                                                    </Link>
                                                                    :
                                                                    houseKeepingAction.slice(0, pathname.includes("taxes") ? 1 : undefined).map(action => (
                                                                        <Link 
                                                                            key={action}
                                                                            data-toggle="modal"
                                                                            data-target="#modal" 
                                                                            className="dropdown-item button" 
                                                                            onClick={() => handleRowAction(action, data)}
                                                                            to="#">
                                                                                {action}
                                                                        </Link>
                                                                    ))
                                                                }

                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                </React.Fragment>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                :
                                <tr className="no-record">
                                    <td>No Existing Record</td>
                                </tr>
                            }
                    </table>
                </div>
                {
                    !houseKeepingState.loadingPrompt &&
                    <section>
                        <p>{houseKeepingState.paginationStartPoint === 0 ? 1 : houseKeepingState.paginationStartPoint} to {houseKeepingState.paginationEndPoint >= houseKeepingState.tableData.length ? houseKeepingState.tableData.length : houseKeepingState.paginationEndPoint} of {houseKeepingState.tableData.length}</p>
                        <span className="mx-3 btn-container row d-flex float-right">
                            {
                                paginationButtons.slice(
                                    (houseKeepingState.paginationStartPoint <= 0) ? 1 
                                    :
                                    0, (houseKeepingState.paginationEndPoint >= houseKeepingState.actualLengthOfTableData) ? 1
                                    : undefined
                                ).map(btn => (
                                    <button 
                                        onClick={e => handlePagination(btn)}
                                        key={btn}
                                        className={`btn ${btn.toLowerCase() === 'next' ? 'btn-primary' : 'btn-secondary'} mx-2 mb-4`}>
                                        {btn}
                                    </button>
                                ))
                            }
                        </span>
                    </section>
                }
            </div>
        </React.Fragment>
    )
}

export default HouseKeepingTable;

