import { showConfirmModalAction, showFormModalAction } from "../../../../../redux/actions/storeActions/appActionsUtilities";
import axiosHelper from "../../../../../utilities/axiosHelper";
import { departments, roomStatusTableHeader } from "../tableData";


// console.log("\n\t dateFran-givenDateAndCurrentTime: ", givenDateAndCurrentTime())
// console.log("\n\t dateFran-todaysDate: ", todaysDate())


export const houseKeepingStylist = (pathname: string, dataObject: any, propIndex: number) => {
    if(pathname.toLowerCase().includes("room")){
        let hKStatus = dataObject['houseKeepingStatus'];
        let occupancyStatus = dataObject['occupancyStatus'];
        if(roomStatusTableHeader[propIndex].toLowerCase().includes('occupancy') || roomStatusTableHeader[propIndex].toLowerCase().includes('house keeping')){
            if(hKStatus === "Clean" && occupancyStatus === "Occupied"){
                return 'bg-success';
            }
        }
        if(roomStatusTableHeader[propIndex].toLowerCase().includes('house keeping')){
            if(hKStatus === "Dirty"){
                return 'bg-danger';
            }if(hKStatus === "Clean"){
                return 'bg-success';
            }if(hKStatus === "Out-of-order"){
                return 'bg-dark';
            }
        }if(roomStatusTableHeader[propIndex].toLowerCase().includes('occupancy')){
            if(hKStatus === "Dirty" && occupancyStatus === "Occupied"){
                return 'bg-success';
            }else{
                return 'bg-white'
            }
        }
    }
}



interface RestaurantactionInterface {
    modalType: string, 
    action: string,
    dispatch: Function, 
    setState: Function
    state: any
    orderToUpdate?: any
    paymentMethods: any[],
    department: string
    pointOfSalesData?: any
    // autoCreatedPMAccounts: any[]
    pathname: string
};

interface FAndBStateInterface {
    pathname: string
    dispatch: Function
}
export const barRestaurantHelpers = async(props: FAndBStateInterface) => {
    // console.log("\n\t barRestaurantHelpers-pathname", pathname)
    const [orderDataDetails] = await Promise.all([
        axiosHelper({
            httpVerb: "get",
            routeName: props.pathname
        }),
       
    ]);
    
    return {
        orderDataDetails
    }
}
 
export const handleRestaurantActions = async(props: RestaurantactionInterface) => {
    props.dispatch(showFormModalAction(true))
    const { paymentMethod, autoCreatedPMAccounts } = props.state.appModulesData[0] as any;
    // console.log("\n\t handleRestaurantActions-paymentMethod: ", paymentMethod)
    const paymentMethods = []as string[];
    console.log("\n\t handleRestaurantActions-modalType: ", props.modalType)
    paymentMethod.forEach((method: any) => paymentMethods.push(method.description));
    switch(props.modalType){
        case props.modalType = "tray":
            return props.setState({
            ...props.state,
            btnAction: props.action,
            modalType: props.modalType,
            confirmModalTitle: "Order Summary",
            formModalSaveButtonName: "Enter Order",
            orderDetailsToModify: {},
            orderMode: "new"
        })
        case props.modalType = "message":
            
            props.state.formModalTitle = "Send Message";
            props.state.confirmModalTitle = "Proceed with sending message?";

            props.state.formModalSaveButtonName = "Send Message"
            props.state.formFields = ["Receipient", "Subject", "File", "Message",];
            const actualDepts = departments.filter(item => item.toLowerCase() !== props.department)
            props.state.serverSelectOption = {receipient: actualDepts.sort()}
            // state.setFormFieldPlaceholder(["Outstanding balance before checkout"]);
            // console.log("\n\t actualDepts: ", actualDepts);
            props.state.formFieldTypes = ["select", 'text', "file", "textarea"];
            return props.setState({
                ...props.state,
                modalType: props.modalType,
                previewData: true,
                btnAction: props.action
            });
        case props.modalType = "pay-balance":
            props.state.formModalTitle = "Pay Balance";
            props.state.confirmModalTitle = "Pay Guest Bill?";
            props.state.formModalSaveButtonName = "Pay";
            // console.log("\n\t orderDetails: ", props.state.orderDetails)
            props.state.formFields = ["Guest Type", "Room Number", "PM Account", 'Amount', 'Payment Method', "POS Reference", ]
            props.state.formFieldTypes = ["select", 'number', "select", "number", "select", 'text'];
            
            return props.setState({
                ...props.state,
                modalType: props.action.replaceAll(" ", "-"),
                btnAction: props.action.replaceAll(" ", "-"),
                orderToUpdate: props.orderToUpdate,
                fAndBDataToPreview: "",
                previewData: true,
                placeholders: ["", "", "Enter guests' room number.", "", "", "Only if payment was made with POS."],
                paymentMethods: paymentMethod,
                optionalFormText: "Please enter the payment reference, if the method of payment isn't cash."
            })
        case props.modalType = "order-details":
            console.log("\n\t order details handleRestaurantActions-orderToUpdate: ", props.orderToUpdate)
            // const actualTableData = props.state.tableData.filter((data:any) => data._id === props.orderToUpdate._id)
            // console.log("\n\t actualTableData: ", actualTableData)

            const orderDetails = props.orderToUpdate['orderDetails'][0];
            const orderItems = [...Object.values(orderDetails)];
            const numberedOrder = []as any[];
            let count = 0
            orderItems.map((individualOrder: any) => {
                individualOrder.map((order: any) => {
                    const { cost, quantity } = order;
                    order['ID'] = count+1;
                    order['total'] = cost*quantity;
                    numberedOrder.push(order)
                    count++
                })
            });
            props.orderToUpdate['orderToBeDetailed'] = numberedOrder;
            let orderDetailsSumQuantity = 0;
            let orderDetailsSumCost = 0;
            let orderDetailsSumTotal = 0;
            numberedOrder.forEach((order:any) => {
                const { cost, quantity, total } = order;
                orderDetailsSumCost += cost;
                orderDetailsSumQuantity += +quantity; orderDetailsSumTotal += total;
            })
            console.log("\n\t orderDetails: ", orderDetails)
            return props.setState({
                ...props.state,
                orderToUpdate: props.orderToUpdate,
                showOrderDetails: true,
                showDetails: true,
                showShiftSalesOrderDetails: true,
                footerArray: [["", "", orderDetailsSumQuantity, orderDetailsSumCost, orderDetailsSumTotal]],
                tableTitle: `Order details for Table: ${props.orderToUpdate['tableNumber']}`,
                detailsDataTableTitle: `Order details for Table: ${props.orderToUpdate['tableNumber']}`,
            });
        case props.modalType = "print-consent-folio":
            // console.log("\n\t handleRestaurantActions-print consent folio: ", props.orderToUpdate)
            return props.setState({
                ...props.state,
                modalType: "consent-folio",
                orderToUpdate: props.orderToUpdate,
                orderDetails: props.orderToUpdate
            });
        case props.modalType = "sign-consent-folio":
            props.dispatch(showConfirmModalAction(true))
            props.dispatch(showFormModalAction(props.pathname.includes("backtrack") ? false : true))
            return props.setState({
                ...props.state,
                confirmModalTitle: "Signing this 'Consent Folio' implies that the guest has received the order. Are you sure about this?",
                formModalSaveButtonName: "Yes",
                modalType: "confirm",
                previewData: false,
                formFields: [],
                fAndBDataToPreview: "",
                btnAction: "sign-consent-folio",
                orderToUpdate: props.orderToUpdate,
            });
        case props.modalType = "unsign-consent-folio":
            props.state.formFields = ["Username", "Password"]
            props.state.formFieldTypes = ["text", "password"];
            props.dispatch(showConfirmModalAction(false));
            props.dispatch(showFormModalAction(true));
            return props.setState({
                ...props.state,
                confirmModalTitle: "Unsigning this 'Consent Folio' implies that the order was rejected or uncompleted. Are you sure about this?",
                formModalSaveButtonName: "Continue",
                modalType: props.modalType,
                btnAction: props.modalType,
                previewData: false,
                fAndBDataToPreview: undefined,
                formModalTitle: "Unsigning a consent-folio requires authorization.",
                orderToUpdate: props.orderToUpdate,
            });
        case props.modalType = "sign-payment-folio":
            props.dispatch(showConfirmModalAction(true))
            return props.setState({
                ...props.state,
                confirmModalTitle: "Signing this 'Payment Folio' implies that the guest has paid for the order. Are you sure about this?",
                formModalSaveButtonName: "Yes",
                fAndBDataToPreview:  "",
                modalType: "confirm",
                btnAction: "sign-payment-folio",
                orderToUpdate: props.orderToUpdate
            })
        case props.modalType = "print-receipt":
            console.log("\n\t print-receipt-props.orderToUpdate: ", props.orderToUpdate);
    
            return props.setState({
                ...props.state,
                modalType: "folio",
                previewData: false,
                orderDetails: {
                    receiptNumber: props.orderToUpdate['receiptNumber'],
                    teller: props.orderToUpdate.teller, 
                    generatedBy: props.orderToUpdate.generatedBy,
                    cost: props.orderToUpdate.totalCost
                } 
            });
        case props.modalType = "cancel-order":
            // console.log("\n\t cancel order: ", props.orderToUpdate)
            props.state.formFields = ["Username", "Password"]
            props.state.formFieldTypes = ["text", "password"];
            props.dispatch(showConfirmModalAction(false));
            props.dispatch(showFormModalAction(true));

            return props.setState({
                ...props.state,
                confirmModalTitle: "Are you sure about cancelling this order?",
                formModalSaveButtonName: "Continue",
                modalType: "cancel-order",
                btnAction: "cancel-order",
                previewData: false,
                formModalTitle: "Cancelling an Order Requires Authorization",
                orderToUpdate: props.orderToUpdate,
                fAndBDataToPreview: undefined
            });
        case props.modalType = "modify-order":
            // console.log("\n\t modify handleRestaurantActions-orderToUpdate: ", props.orderToUpdate)
            return props.setState({
                ...props.state,
                btnAction: props.action,
                modalType: 'tray',
                confirmModalTitle: "Order Summary",
                formModalSaveButtonName: "Modify Order",
                orderDetailsToModify: props.orderToUpdate,
                orderToUpdate: props.orderToUpdate,
                previewData: true,
                orderMode: "modify"
            });
        case props.modalType = "assign-to-pm-account": 
            // console.log("\n\t assign-to-pm-account: ", props.orderToUpdate)
            console.log("\n\t state: ", props.state)
            
            props.state.formModalTitle = "Assign Order to a PM-Account";
            props.state.confirmModalTitle = "Assigning this order to a PM-Account would clear the order from the table and transfer the bills to the selected account. Are you sure aboout this?";
            props.state.formModalSaveButtonName = "Assign";
            // console.log("\n\t autoCreatedPMAccounts: ", autoCreatedPMAccounts)
            const pmAccount = []as string[]
            autoCreatedPMAccounts.forEach((account:any) => pmAccount.push(account.accountName))
            // console.log("\n\t pmAccount: ", pmAccount)
            return props.setState({
                ...props.state,
                formFieldTypes: ["select"],
                formFields: ["Account Name"],
                modalType: props.modalType,
                btnAction: props.action,
                fAndBDataToPreview:  "",
                orderToUpdate: props.orderToUpdate,
                footerArray: [],
                serverSelectOption: {
                    accountName: pmAccount.sort()
                }
            });
        case props.modalType = "backtrack-order": 
            console.log("\n\t state: ", props.state)
            props.dispatch(showConfirmModalAction(false));
            props.dispatch(showFormModalAction(true));
            
            props.state.formModalSaveButtonName = "Continue";
            return props.setState({
                ...props.state,
                formFields: ["Username", "Password"],
                formFieldTypes: ["text", "password"],
                confirmModalTitle: "Bactracking this order would move this order from this table to the backtrack module. This procedure is irreversible. Would you like to continue?",
                modalType: props.modalType,
                btnAction: props.action,
                fAndBDataToPreview: "",
                formModalTitle: `Backtracking a ${props.pathname.includes("pool") ? "Pool-Bar" : props.pathname.includes("bar") ? "Bar" : "Restaurant"} Order Requires Authorization`,
                previewData: false,
                orderToUpdate: props.orderToUpdate,
            });
        case props.modalType = "shift-or-daily-sales":
            // console.log("\n\t shift-or-daily-sales: ", props.orderToUpdate)
            const { teller, shiftDate, salesDate } = props.orderToUpdate;
            return props.setState({
                ...props.state,
                showOrderDetails: true,
                tableData: props.pathname.includes('shift') ? [teller, shiftDate] : props.orderToUpdate['salesDate'],
                tableTitle: props.pathname.includes('shift') ? `Shift sales for ${teller} on ${shiftDate}.` : `- ${salesDate}`
            });
        case props.modalType = "bill-backtrack-guest":
            // console.log("\n\t Billing a backtrack guest......")
            props.dispatch(showFormModalAction(true));
            props.dispatch(showConfirmModalAction(false));
            props.state.formModalSaveButtonName = "Bill Guest";
            props.state.formFields = ["Guest Name"];
            props.state.formFieldTypes = ["select"];
            props.state.formModalTitle = "Assigning An F&B Charge to a Backtrack Guest";
            props.state.confirmModalTitle = "Are you sure about assigning this order to the guest?";
            const allBacktrackGuest = props.state.allBacktrackGuest;
            const guestName = []as string[]
            allBacktrackGuest.forEach((guest:any) => guestName.push(`${guest.guestName} - (Room: ${guest.roomNumber})`))
            // console.log("\n\t guestName: ", guestName)
            return props.setState({
                ...props.state,
                modalType: "",
                orderToUpdate: props.orderToUpdate,
                fAndBDataToPreview:  "",
                btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
                serverSelectOption: {
                    guestName: guestName.sort()
                }
            });
        default:
            return props.setState({
                ...props.state,
            })
    }
};

