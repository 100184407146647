import { UserDetails } from "../../types";

export enum ActionTypes {
    USER_DETAILS = "USER_DETAILS",
    TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
    TOGGLE_NAVBAR = "TOGGLE_NAVBAR",
    CALL_BACKEND = "CALL_BACKEND",
    SHOW_PROCESSING_GIF = "SHOW_PROCESSING_GIF",
    SHOW_MODAL = "SHOW_MODAL",
    SHOW_FOOTER = "SHOW_FOOTER",
    SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL",
    MODULES_DATA = "MODULES_DATA",
    SYSTEM_CHARGES = "SYSTEM_CHARGES",
}

export interface UserDetailsInterface {
    type: string
    payload: UserDetails
}

export interface ToggleSidebarInterface {
    type: string
    payload: boolean
}

export interface ToggleNavbarInterface {
    type: string
    payload: boolean
}

export interface CallBackendInterface {
    type: string
    payload: boolean
}

export interface ShowProcessingGifInterface {
    type: string
    payload: boolean
}

export interface ShowConfirmModalInterface {
    type: string
    payload: boolean
}

export interface ModulesDataInterface {
    type: string
    payload: any
}

export interface ShowFooterInterface {
    type: string
    payload: boolean
}

export interface SystemChargesInterface {
    type: string
    payload: any
}


export interface ShowFormModalInterface  {
    type: string
    payload: {
        modalStatus: boolean,
        actionText: string
    }
}