import React, { useEffect, useState } from 'react'

import "../../../../styles/FolioStyles.css";
import { tableDataCleaner } from '../../../../utilities/strings';
import { taxComputation } from '../../../../utilities/taxComputation';
import Gifs from '../../../Gifs';
import { originsToShowTaxData } from '../Receipts/Receipt';

interface Props {
    folioReceiptData: any
    hideFolio: Function
    state: any
    dateCreated?: string
    generatedBy?: string
    type: "Consent" | "Payment"
}


export const sortedOrder = (orderDetails: any[]) => {
    // console.log("\n\t orderDetails: ", orderDetails)
    const requiredData = []as any[];
    orderDetails.forEach((order:any) => {
        // console.log("\n\t order: ", order);
        let count = 0;
        order.map((individualOrder: any) => {
            // console.log("\n\t individualOrder: ", individualOrder);
            const { cost, quantity } = individualOrder;
            individualOrder['total'] = cost*(Number(quantity));
            requiredData.push(individualOrder)
            count++
        });
    })
    return requiredData;
};

const totalCostPerIndividualOrder = (orders: any[]) => {
    const totals = []as number[];
    orders.forEach(individualOrder => {
        let totalForIndividualOrder = 0
        for(let item of individualOrder){
            const { quantity, cost } = item;
            totalForIndividualOrder += (quantity * cost)
        }
        totals.push(totalForIndividualOrder)
    });
    // console.log("\n\t Totals: ", totals)
    return totals;
}

export const orderHeader = ["Category", "Item", "Cost", "Quantity"];
const Folio = (props: Props) => {
    const {guestName, orderDetails } = props.folioReceiptData;
    console.log("\n\t Consent folio-2-folioReceiptData: ", props.folioReceiptData)
    // const headers = ["Guest Name", "Room Number", "Receipt Number", "Floor Number", "Total Cost", "Teller"]
    const [folioState, setFolioState] = useState({
        orderDetails: [] as any[],
        showCaptainOrder: false,
        orderTotal: 0,
        splitBill: false,
        totalCostPerIndividualOrder: []as number[]
    });
    const operations = ["Back", "Print", folioState.splitBill ? "Unsplit Bill" : "Split Bill", "Captain Order"];
    const headers = ["Item", "Quantity", "Cost", "Total"];
    const folioReceiptData = props.folioReceiptData;
    console.log("\n\t consent-folio-folioState.orderDetails: ", folioState.orderDetails)
    useEffect(() => {
        const personsOrder = Object.values(orderDetails[0]);
        // console.log("\n\t Object.values(orderDetails[0])-useEffect: ", Object.values(orderDetails[0]))
        folioState.totalCostPerIndividualOrder = totalCostPerIndividualOrder(Object.values(orderDetails[0]));
        const sortedPersonsOrders = sortedOrder(personsOrder);
        // console.log("\n\t sortedPersonsOrders: ", sortedPersonsOrders)
        sortedPersonsOrders.forEach((order: any) => folioState.orderTotal += (order.quantity * order.cost));
        folioState.orderDetails = sortedPersonsOrders;
        setFolioState({
            ...folioState
        })
    }, []);
    const footerValues = {
        Total: folioState.orderTotal.toLocaleString(),
        Discount: props.folioReceiptData.discount,
        Pay: props.folioReceiptData.totalCharge.toLocaleString()
    };
    const folioOperations =(ops: string) => {
        if(ops.toLowerCase() === 'print'){
            window.print(); 
        }if(ops.toLowerCase().includes('captain')){
            setFolioState({
                ...folioState,
                showCaptainOrder: true,
            });
        }else if(ops.toLowerCase().includes('split')){
            setFolioState({
                ...folioState,
                splitBill: !folioState.splitBill
            });
        }else{
            props.hideFolio({
                ...props.state,
                modalType: ""
            })
        }
    };
    // console.log("\n\t orderDetails[0]: ", orderDetails[0])
    return (
        <React.Fragment>
            <div className="row d-flex justify-content-center ml-5 ">
                <div className="row d-flex justify-content-center ml-5 folio-container">
                    <span style={{flexDirection: "column"}} className='ml-1 row d-flex receipt-logo'>
                        <Gifs width={150} height={150} gifName='hotel-logo' />
                        <Gifs className='companys-address-and-name' gifName='company-details' />
                        {
                            !folioState.showCaptainOrder ?
                            <p className="text-black font-weight-bold folio-title">{props.type} Folio</p> 
                            :
                            <p className="text-black font-weight-bold folio-title">Captain Order</p> 
                        }
                        <p className="text-black font-weight-bold text-small">S/N: {props.folioReceiptData.receiptNumber}</p> 
                    </span>
                    <React.Fragment>
                        {
                            !folioState.splitBill ?
                            <table className="f-and-b-table table table-hover table-bordered table-striped">
                                <tbody>
                                    {
                                        folioState.orderDetails &&
                                        folioState.orderDetails.map((order: any, index: number) => (
                                            <tr key={index} style={{width: "100%"}} className="text-center text-black">
                                                {
                                                    headers.slice(0, folioState.showCaptainOrder ? 2 : undefined).map(detail=> (
                                                        <td style={{fontSize: order['item'].toLowerCase().includes("pay") ? "1.8rem" : "1.6rem", fontWeight: order['item'].toLowerCase().includes("pay") ? "bolder" : "bold"}} key={detail} >
                                                            {
                                                                order['item'].toLowerCase().includes("total") &&
                                                                <p>____________</p>
                                                            }
                                                            {tableDataCleaner(detail, order)}
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                    {
                                        !folioState.showCaptainOrder && !folioState.splitBill &&
                                        <React.Fragment>
                                            {
                                                Object.keys(footerValues).map(key => (
                                                    <tr  style={{width: "5%", fontSize: "1.3rem"}} className="text-center font-weight-bold text-black">
                                                        <td className="text-black font-weight-bold folio-titl">
                                                            {key}
                                                        </td>
                                                        <td>-------</td>
                                                        <td>-------</td>
                                                        <td>{(footerValues as any)[key]}</td>
                                                    </tr>
                                                ))
                                            }
                                        </React.Fragment>
                                    }
                                    {
                                        (originsToShowTaxData.includes(origin) && props.state != undefined) &&
                                        <React.Fragment>
                                            <tr style={{width: "100%"}} className="text-center text-black">
                                                <td style={{fontSize: "1.3rem"}}>Net Charge:</td>
                                                {
                                                    folioReceiptData &&
                                                    <td style={{fontSize: "1rem"}}>{taxComputation((folioReceiptData[folioReceiptData.length-2]).cost, props.state.taxes).netCharge}</td> 
                                                }
                                            </tr>
                                            {
                                                folioReceiptData &&
                                                <tr style={{width: "100%"}} className="text-center text-black">
                                                    <td>VAT ({props.state.taxes['vat']}%)</td>
                                                    <td style={{fontSize: "1rem"}}>{taxComputation((folioReceiptData[folioReceiptData.length-2]).cost, props.state.taxes).vatCharge}</td> 
                                                </tr>
                                            }
                                            
                                        </React.Fragment>
                                    }
                                </tbody>
                            </table>
                            :
                            orderDetails[0] && Object.keys(orderDetails[0]).map((orderKey: any, ordeKeyIndex: number) => (
                                <table className="f-and-b-table table table-hover table-bordered table-striped">
                                <tbody key={ordeKeyIndex}>
                                    <tr>
                                        <td>{orderKey}</td>
                                    </tr>
                                    {
                                        (orderDetails[0][orderKey]).map((itemPerIndividualOrder: any) => (
                                            <React.Fragment>
                                                <tr style={{width: "100%"}} className="text-center text-black">
                                                    {
                                                        ["Item", "Quantity", "Cost", "Total"].map(detail=> (
                                                            <td style={{fontSize: itemPerIndividualOrder['item'].toLowerCase().includes("total") ? "1.8rem" : "1.6rem", fontWeight: itemPerIndividualOrder['item'].toLowerCase().includes("total") ? "bolder" : "bold"}} key={detail} >
                                                                {
                                                                    itemPerIndividualOrder['item'].toLowerCase().includes("total") &&
                                                                    <p>____________</p>
                                                                }
                                                                {tableDataCleaner(detail, itemPerIndividualOrder)}
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            </React.Fragment>
                                        ))
                                    }
                                    <tr>
                                        <td style={{fontSize: "1.6rem"}}>Total: {folioState.totalCostPerIndividualOrder[ordeKeyIndex].toLocaleString()}</td>
                                    </tr>
                                    {
                                        (originsToShowTaxData.includes(origin) && props.state != undefined) &&
                                        <React.Fragment>
                                            <tr style={{width: "100%"}} className="text-center text-black">
                                                <td style={{fontSize: "1.3rem"}}>Net Charge:</td>
                                                {
                                                    folioReceiptData &&
                                                    <td style={{fontSize: "1rem"}}>{taxComputation((folioReceiptData[folioReceiptData.length-2]).cost, props.state.taxes).netCharge}</td> 
                                                }
                                            </tr>
                                            {
                                                folioReceiptData &&
                                                <tr style={{width: "100%"}} className="text-center text-black">
                                                    <td>VAT ({props.state.taxes['vat']}%)</td>
                                                    <td style={{fontSize: "1rem"}}>{taxComputation((folioReceiptData[folioReceiptData.length-2]).cost, props.state.taxes).vatCharge}</td> 
                                                </tr>
                                            }
                                        </React.Fragment>
                                    }
                                </tbody>
                            </table>
                            ))

                        // {
                        //     !folioState.splitBill &&
                        //     <p className="text-black font-weight-bold folio-title">Total: {folioState.orderTotal.toLocaleString()}</p> 
                        // }
                        }
                    </React.Fragment>
                    <div>
                        {
                            !folioState.showCaptainOrder && 
                            <div className='signature-container text-center mt-2'>
                                <section style={{flexDirection: "column"}} className="d-flex justify-content-center">
                                    <p className="mt-1">Name: ________________________{props.folioReceiptData.receipientsName}</p>
                                    <p className="mt-1">Room: ________________________{props.folioReceiptData.receipientsName}</p>
                                    <p className="mt-1">Signature: ___________________</p>
                                    <p className="text-center">Date: {
                                            props.dateCreated &&
                                            <React.Fragment>
                                                {new Date(props.dateCreated).toDateString()} {new Date(props.dateCreated).toLocaleTimeString()} 
                                            </React.Fragment>
                                        }
                                    </p>
                                    <p className="text-center">
                                        Teller:
                                        {
                                            props.generatedBy &&
                                            <span className='mx-2'>
                                                {props.generatedBy}
                                            </span>
                                        }
                                    </p>
                                </section>
                                <section>
                                    <article className="text-center text-danger">
                                        <p className="small">Please verify data, before appending signatures.</p> 
                                    </article>
                                </section>
                            </div>
                        }
                        {
                            !folioState.showCaptainOrder && 
                            <section>
                                {
                                    props.type === "Consent" ?
                                    <article className="text-center text-danger">
                                        <p className="small">This consent folio signifies that you received the order, but haven't made payment yet.</p> 
                                        <p className="small">If you've made payment, please do request for your <strong>Payment Folio</strong>.</p>
                                    </article>
                                    :
                                    <article className="text-center text-danger">
                                        <p className="small">This payment folio signifies that the guest has received and paid for their order.</p> 
                                        <p className="small">Please, verify details before appending signatures.</p>
                                    </article>
                                }
                            
                            </section>
                        }
                    </div>
                </div>
            </div>
            <div className="row d-flex float-right folio-btns">
                {
                    operations.map(ops => (
                        <button 
                            key={ops}
                            onClick={() => folioOperations(ops)}
                            className={`mx-2 btn ${ops.toLowerCase() === 'print' ? 'btn-success' : 'btn-secondary'}`}>
                            {ops}
                        </button>
                    ))
                }
            </div>
        </React.Fragment>
    )
}

export default Folio;