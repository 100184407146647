import path from "path/posix";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import colors from "../../../../assets/colors";
import { tableDataCleaner, titleCase, titledTextJoiner } from "../../../../utilities/strings";
import { formFieldsSelector, rowActionFunctions, storeItemsTableHeader, userLogsTableHeader, storeSectionTableHeader, storeOutletsTableHeader } from "./tableData";

import "../../../../styles/TableStyles.css";
import axiosHelper from "../../../../utilities/axiosHelper";
import Modal from "../../Modal";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmModalAction, showFormModalAction } from "../../../../redux/actions/storeActions/appActionsUtilities";
import { handleStoreActions } from "./tableActions/tableActionsThree";
import { Link } from "react-router-dom";
import Filter from "../../Filter";

interface Props {

}

const StoreTable =  (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    const dispatch = useDispatch()

    const store = useSelector((store: any) => store)
    const { modalStatus, confirmModal } = store;
    const { showFormModal } = modalStatus;
    const { showConfirm } = confirmModal;
    const storeFormActionHandler = () => {
        console.log("\n\t call storeFormActionHandler........")
        dispatch(showFormModalAction(false));
        dispatch(showConfirmModalAction(true))

    }

    const handleButtonAction = (action: string, dataToModify: any) => {
        console.log("\n\t dataToModify: ", dataToModify)
        console.log("\n\t action: ", action)
        storeState.formButtonAction = action.toLowerCase().replaceAll(" ", "-");
        storeState.dataToModify = dataToModify;
        dispatch(showFormModalAction(true));
        handleStoreActions({
            action,
            storeState,
            setStoreState,
            dataToModify,
            pathname
        })
    }
    const [storeState, setStoreState] = useState({
        tableData: [],
        paginationStartPoint: 0,
        paginationEndPoint: 12,
        paginatedTableData: [],
        storeSections: []as string[],
        dataToModifyId: "",
        dataToModify: {},
        loadingPrompt: "Fetching store data, please wait...",
        formInputs: {} as any,
        formFields: []as string[],
        formFieldType: []as string[],
        placeholders: []as string[],
        users: []as string[],
        formTitle: "",
        formButtonAction: "",
        previewData: true
    });
    useEffect(() => {
        (async() => {
            setStoreState({
                ...storeState,
                loadingPrompt: `Fetching store-${pathname} data, please wait...`,
            })
            const pathRequest = await axiosHelper({
                httpVerb: "get",
                routeName: pathname
            });
            if(pathname.includes("items")){
                const [storeSections, users] = await Promise.all([
                    axiosHelper({
                        httpVerb: "get",
                        routeName: `/store/sections`
                    }),
                    axiosHelper({
                        httpVerb: "get",
                        routeName: `/settings/user-management/users`
                    })
                ])
                console.log("\n\t storeSections: ", storeSections)
                if(storeSections){
                    storeState.storeSections = storeSections.data.map((data:any) => data.description)
                }if(users){
                    console.log("\n\t users: ", users)
                    storeState.users = users.data.map((data:any) => data.firstName.concat(" ", data.lastName))
                }
            }
            console.log("\n\t pathRequest: ", pathRequest)
            pathRequest && setStoreState({
                ...storeState,
                tableData: pathRequest.data,
                loadingPrompt: "",
                paginatedTableData: pathRequest.data.slice(storeState.paginationStartPoint, storeState.paginationEndPoint)
            })
        })();
    }, [pathname]);
    const handleStoreFormChange = (field: string, value: string) => {
        console.log("\n\t form change store: ", field, value)
        const newInput = storeState.formInputs
        newInput[titledTextJoiner(field)] = value
        setStoreState({
            ...storeState,
            formInputs: newInput
        })
    };
    // console.log("\n\t paginatedTableData: ", storeState.paginatedTableData)
    console.log("\n\t users: ", storeState.users)
    return(
        <React.Fragment>
            {
                showFormModal && showFormModal.modalStatus &&
                <Modal 
                    title={storeState.formTitle}
                    pathname={pathname}
                    setGroupCheckInData={() => undefined}
                    // setPrintRegCard={() => undefined}
                    type="form"
                    onChange={handleStoreFormChange}
                    formFields={storeState.formFields}
                    formFieldType={storeState.formFieldType}
                    fieldValues={Object.values(storeState.formInputs) || []}
                    placeholders={storeState.placeholders}
                    formInput={storeState.formInputs}
                    serverSelectOption={{
                        section: storeState.storeSections,
                        collectedBy: storeState.users,
                        outlet: ["Bar", "Pool-Bar", "Kitchen", "Restaurant", "Frontdesk", "Accounts", "Sales"]
                    }}
                    stopFormModalSubmission={true}
                    handleAction={storeFormActionHandler}
                    numberOfFields={storeState.formFields.length}
                />
            }
            {
                showConfirm &&
                <Modal
                    setGroupCheckInData={() => undefined}
                    pathname={pathname}
                    // setPrintRegCard={() => undefined}
                    handleAction={
                        storeState.formButtonAction.includes('create') ?
                        () => rowActionFunctions.postAction(pathname, storeState.formInputs)
                        :
                        storeState.formButtonAction.includes('stock') ?
                        () => rowActionFunctions.postAction(`${pathname}/${storeState.formButtonAction}`, {...storeState.dataToModify, ...storeState.formInputs})
                        :
                        // storeState.formButtonAction.includes('delete') ?
                        // () => rowActionFunctions.deleteAction(pathname, storeState.dataToModifyId)
                        // :
                        () => rowActionFunctions.updateAction(`${pathname.concat(storeState.formButtonAction.includes('delete') ? "/delete" : "")}`, storeState.dataToModifyId, storeState.formInputs)
                    }
                    numberOfFields={0}
                    fieldValues={Object.values(storeState.formInputs) || []}
                    formFields={storeState.formFields}
                    previewData={storeState.previewData}
                    formFieldType={storeState.formFieldType}
                    onChange={() => undefined}
                    type="confirm"
                    title={storeState.formTitle}
                />
            }
            <div style={{flexDirection: "column"}} className="row d-flex mt-3 ml-4">
                <h4 className="ml-4 mt-4">Store ({titleCase(pathname.split("/")[2])})</h4>
                {
                    !pathname.includes('logs') &&
                    <span className="ml-4">Total {titleCase(pathname.split("/")[2])}: {storeState.tableData.length}</span>
                }
                <span className="font-weight-bolder small text-success animate__animated animate__pulse animate__infinite">{storeState.loadingPrompt}</span>
                <span className="hr d-flex ghost-white-line mx-2"></span>
                {
                    !pathname.includes('logs') &&
                    <section style={{justifyContent: "space-between", width: "100%",alignItems: "center"}} className="row d-flex align-items-center mt-1 mx-2">
                        <section>
                            {
                                !pathname.includes("outlet") && !pathname.includes("logs") &&
                                <button 
                                    style={{ backgroundColor: colors.sideNav }}
                                    data-toggle="modal"
                                    data-target="#modal"
                                    onClick={() => handleButtonAction(`create`, 'create')}
                                    className="button ml-2 text-white btn mt-2">
                                    Add {pathname.split("/")[2]}
                                </button>
                            }
                            <button 
                                style={{ backgroundColor: colors.sideNav }}
                                data-toggle="modal"
                                data-target="#modal"
                                onClick={() => handleButtonAction(`message`, 'create')}
                                className="button ml-2 text-white btn mt-2">
                                Message
                            </button>
                        </section>
                        <Filter  
                            componentsState={storeState}
                            componentsStateSetter={setStoreState}
                            tableData={storeState.tableData}
                            paginationEndPoint={storeState.paginationEndPoint}
                            paginationStartPoint={storeState.paginationStartPoint}
                        />
                    </section>
                }
                <hr />
                <div  className="mx-2 row d-flex">
                    <table className="mt-3 table-table table table-hover table-bordered table-striped">
                        <thead style={{ backgroundColor: colors.sideNav }} className="py-2">
                            <tr className="text-center text-light">
                                {
                                    pathname.includes('items') ?
                                    storeItemsTableHeader.slice(0, pathname.includes("records") ? 11 : undefined).map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    pathname.includes('outlets') ?
                                    storeOutletsTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    pathname.includes('section') ?
                                    storeSectionTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    pathname.includes('logs') ?
                                    userLogsTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    pathname.includes('records') ?
                                    storeItemsTableHeader.map(columnName => (
                                        <th key={columnName}>{columnName}</th>
                                    ))
                                    :
                                    ""
                                }
                            </tr>
                        </thead>
                        <tbody>
                                {
                                    storeState.paginatedTableData &&
                                    storeState.paginatedTableData.map((data: number, index: number) => (
                                        <tr className="text-center" key={index}>
                                            {
                                                pathname.includes('sections') ?
                                                Object.entries(data).slice(1).map((entry: any, entIndex: number) => (
                                                    <td key={entIndex}>{tableDataCleaner(storeSectionTableHeader[entIndex], data)}</td>
                                                ))
                                                :
                                                pathname.includes('outlets') ?
                                                Object.entries(data).slice(11).map((entry: any, entIndex: number) => (
                                                    <td key={entIndex}>{tableDataCleaner(storeOutletsTableHeader[entIndex], data)}</td>
                                                ))
                                                :
                                                pathname.includes('items') ?
                                                Object.entries(data).slice(8).map((entry: any, entIndex: number) => (
                                                    <td key={entIndex}>{tableDataCleaner(storeItemsTableHeader[entIndex], data)}</td>
                                                ))
                                                :
                                                pathname.includes('logs') &&
                                                Object.entries(data).slice(0, 5).map((entry: any, entIndex: number) => (
                                                    <td key={entIndex}>{tableDataCleaner(userLogsTableHeader[entIndex], data)}</td>
                                                ))
                                            }
                                            {
                                                !pathname.includes('logs') && !pathname.includes('outlets') && !pathname.includes('records') &&
                                                <td style={{ backgroundColor: colors.sideNav }} className="print">
                                                    <div className="dropdown show btn-container">
                                                        {
                                                        // pathname !== "/frontdesk" && pathname.includes('room-status') &&
                                                            <Link className="btn button dropdown-toggle sb-sidenav-menu" style={{ color: "#f4f4f4" }} to="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions <span></span><span></span><span></span> <span></span></Link>
                                                        }
                                                        <div className="dropdown-menu frontdesk-buttons" aria-labelledby="dropdownMenuLink">
                                                        {
                                                            ["Edit", pathname.includes('items') ? "Stock Outlet" : null, "Delete"].map(action => (
                                                                action && <Link 
                                                                    key={action}
                                                                    data-toggle="modal"
                                                                    data-target="#modal" 
                                                                    className="dropdown-item button" 
                                                                    onClick={() => handleButtonAction(action, data)}
                                                                    to="#">
                                                                        {action}
                                                                </Link>
                                                            ))
                                                        }
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    ))
                                }

                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
};

export default StoreTable;