import React from 'react'
import { Link } from "react-router-dom"

import {
    FaPersonBooth,
    FaDesktop,
    FaFileInvoiceDollar,
    FaGlassCheers,
    FaUtensils,
    FaWarehouse,
    FaCogs,
    FaUmbrellaBeach,

} from "react-icons/fa"
import colors from '../../assets/colors';
import "../../styles/Modules.css";
import { useDispatch, useSelector } from 'react-redux';
import { StoreInterface } from '../../redux/store';
import { callBackendAction } from '../../redux/actions/storeActions/appActionsUtilities';
interface Props {
    
}


const HomeDashboardModules = (props: Props) => {
    const dispatch = useDispatch();
    const store = useSelector((store: any) => store);
    const { userDetails, sidebarStatus } = store;
    const { showSidebar } = sidebarStatus;
    const userType =  userDetails.userDetails ? userDetails.userDetails.userType : "";
    // console.log("\n\t userType: ", userType)
    const modules = [
        {
            "Reservations": {detail: "Create, Update, Room status etc", icon: <FaPersonBooth size={32} color={colors.appYellow} />}, 
            "Frontdesk": {detail: "Arrivals, In-house Guest, History etc", icon: <FaDesktop size={32} color={colors.appYellow} />}
        },
        {
            "Audit Reports": {detail: "Transactions, Ledgers, Deposits,  Receipts, etc", icon: <FaFileInvoiceDollar size={32} color={colors.appYellow} />}, 
            "Settings": {detail: "Inventory, User accounts, Logs, Communication etc", icon: <FaCogs size={32} color={colors.appYellow}/>}, 
        },
        {
            "Restaurant": {detail: "Point of Sales, Folios, etc", icon: <FaUtensils size={32} color={colors.appYellow}/>}, 
            "Store": {detail: "Inventory, Stock etc", icon: <FaWarehouse size={32} color={colors.appYellow}/>},
        },
        {
            "Bar": {detail: "Point of Sales, Folios, etc", icon: <FaGlassCheers size={32} color={colors.appYellow}/>},
            "Pool Bar": {detail: "Point of Sales, Folios, etc", icon: <FaUmbrellaBeach size={32} color={colors.appYellow}/>},
        }
    ]
    return (
        <div>
            <span className="mt-4" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <h4 style={{fontWeight: "500"}} className="mt-4">{`${(userType as string).replaceAll(" ", "-")}`}s' Dashboard</h4>
            </span>
            <hr className="hr" />
            <div className={`${showSidebar ? '' : 'px-md-3 px-sm-4'}`}>
                {
                    modules.map((moduleGroup, moduleIndex) => (
                        <div key={moduleIndex} className="row">
                            {
                                Object.values(moduleGroup).map((moduleName, groupIndex) => (
                                    <div key={groupIndex} className="col-xl-6 col-md-6">
                                        <div className="card bg-white text-dark py-2 mb-4">
                                            <div style={{flexDirection: "column"}} className="card-body d-flex">
                                                <h3 className="card-title">
                                                    <span className="mr-2">{moduleName.icon}</span>
                                                    <span className="module_group_name">{Object.keys(moduleGroup)[groupIndex]}</span>
                                                </h3> 
                                                <p className="module_group_detail">{moduleName.detail}</p>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between">
                                                <Link 
                                                    onClick={
                                                        () => dispatch(callBackendAction(true))
                                                    } 
                                                    style={{textDecoration: "none"}} 
                                                    className="small stretched-link button animate__animated animate__swing animate__infinite" 
                                                    to={
                                                        userType.toLowerCase().includes("frontdesk") && moduleIndex == 0 ?
                                                        `/${Object.keys(moduleGroup)[groupIndex].toLowerCase().replaceAll(" ", "-")}`
                                                        :
                                                        userType.toLowerCase() === "f&b manager"  && ((moduleIndex === 2 && groupIndex === 0) || (moduleIndex === 3)) ?
                                                        `/${Object.keys(moduleGroup)[groupIndex].toLowerCase().replaceAll(" ", "-")}`
                                                        :
                                                        userType.toLowerCase() === "store" && (moduleIndex == 2 && groupIndex == 1) ?
                                                        `/${Object.keys(moduleGroup)[groupIndex].toLowerCase().replaceAll(" ", "-")}`
                                                        :
                                                        userType.toLowerCase().includes("restaurant") && (moduleIndex == 2 && groupIndex == 0) ?
                                                        `/${Object.keys(moduleGroup)[groupIndex].toLowerCase().replaceAll(" ", "-")}`
                                                        :
                                                        userType.toLowerCase().includes("pool") && (moduleIndex == 3 && groupIndex == 1) ?
                                                        `/${Object.keys(moduleGroup)[groupIndex].toLowerCase().replaceAll(" ", "-")}`
                                                        :
                                                        userType.toLowerCase().includes("bar") && (moduleIndex == 3 && groupIndex == 0) ?
                                                        `/${Object.keys(moduleGroup)[groupIndex].toLowerCase().replaceAll(" ", "-")}`
                                                        :
                                                        userType.toLowerCase().includes("admin") || userType.toLowerCase().includes("developer") ?
                                                        `/${Object.keys(moduleGroup)[groupIndex].toLowerCase().replaceAll(" ", "-")}`
                                                        :
                                                        ""
                                                    }>
                                                    Click Here 
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }                 
            </div>
        </div>
    )
}

export default HomeDashboardModules

