import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router'
import colors from '../../assets/colors';

interface Props {
    
}

const Footer = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    const store = useSelector((store: any) => store)
    const { userDetails } = store;
    const {companysAddress} = userDetails && userDetails?.userDetails || "";
    // console.log("\n\t companysAddress: ", companysAddress)
    return (
        <footer style={{height: "auto", backgroundColor: pathname.includes('end-of-day') ? 'black' : undefined, flexDirection: "column"}} className="row d-flex py-4 footer-">
            <hr />
            <div className='print-footer-address justify-content-center text-center'>
                <span className='text-center'>{companysAddress}</span>
            </div>
            <div className="">
                <div style={{justifyContent: "space-between"}} className="d-flex align-items-center justify-content-between small ml-3 print-footer">
                    <article className="row d-flex align-items-center"> 
                        &copy; 
                        <span style={{color: colors.sideNav, textDecoration: "none"}} className="font-weight-bold">
                            HotelPro {new Date().getFullYear()}
                        </span> 
                    </article>
                    <article>
                        <a  style={{color: colors.sideNav, textDecoration: "none"}} href="#" className="font-weight-bold">{process.env.REACT_APP_VERSION_NUMBER}</a>
                    </article>
                </div>
            </div>
        </footer>
    )
}

export default Footer
