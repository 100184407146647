import { createStore } from "redux";

import { UserDetails } from "../../types";
import combinedReducers from "../reducers";
import { composeWithDevTools } from 'redux-devtools-extension';

export interface StoreInterface {
    modulesData: any
    userDetails: UserDetails
    showSidebar: boolean
    showNavbar: boolean
    callBackend: boolean
    showProcessingGif: boolean
    showFooter: boolean
    showFormModal: {
        modalStatus: boolean,
        actionText: string
    }
    showConfirm: boolean
};

export const InitialStore: StoreInterface = {
    modulesData: "",
    showFooter: true,
    userDetails: {
        userName: "",
        userType: "",
        firstName: "",
        lastName: "",
        hotelDate: "",
        email: "",
        shiftStatus: "",
        companysLogo: "",
        companysAddress: "",
        companysEmail: "",
        licenseDetails: {
            expiryDate: "",
            leftOverDays: "",
            message: "",
            renewedOn: ""
        }
    },
    showSidebar:  true,
    showNavbar:  true,
    showProcessingGif: false,
    callBackend: false,
    showFormModal: {
        modalStatus: false,
        actionText: ""
    },
    showConfirm: false
};


const AppStore = createStore(
    combinedReducers,
    composeWithDevTools(),
);

export default AppStore;