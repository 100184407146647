import React, { useEffect, useState } from 'react';
import { FaChevronCircleLeft } from 'react-icons/fa';
import { GiStrikingBalls } from "react-icons/gi";
import "../../../../styles/DetailsTable.css";
import { Link, useLocation } from 'react-router-dom';
import colors from '../../../../assets/colors';
import { tableDataCleaner } from '../../../../utilities/strings';

import { paginationButtons } from './tableData';
import TableActionButtons from './TableActionButtons';
import Gifs from '../../../Gifs';
import { individualsCheckedInDefinitions } from './FrontdeskTable';
import { BsArrowRightCircle } from 'react-icons/bs';
import { handleDataPagination } from './tableActions/tableActionsThree';
import Filter from '../../Filter';

interface Props {
    tableHeader: string[]
    tableData: any[]
    // stateSetter: Function
    // componentsState: any
    showRowDetails: boolean
    setShowRowDetails: Function
    tableTitle: string
    actionsHandler: Function
    footerArray?: any[]
    className?: string
};

const Details = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    const pathnameModule = location.pathname.split("/")[1];
    const subPathname = location.pathname.split(`${pathnameModule}/`)[1];
    // console.log("\n\t subPathname: ",pathnameModule, subPathname)
    // console.log("\n\t tableData: ", props.tableData)
    // console.log("\n\t footerArray: ", props.footerArray)

    const routeToExcludeActions = ["shift-sales", "calendar", "bar-shift-sales", "bar-daily-sales", "restaurant-shift-sales", "restaurant-daily-sales", "daily-sales", "facilities/facility", "bar", "restaurant", "city", 'end-of-shift', 'daily', 'records', 'bills', 'point-of-sales', 'folios', 'guest-ledger']
    // console.log("\n\t Table details-tableData: ", props.tableData)
    // console.log("\n\t Table details-footerArray: ", props.footerArray)

    const [detailsState, setDetailsState] = useState({
        confirmModalTitle: "",
        paginationStartPoint: 0,
        paginationEndPoint: 12,
        paginationLength: 12,
        paginatedTableData: [] as any,
        tableDataLength: 0,
        totalSum: 0,
        tableData: []as any[],
        showPaginationButtons: true
    });
    // const individualLedgerFooterArray = ["", "", detailsState.totalSum, "", "", "", "", ""];
    const handlePagination = (btnName: string) => {
        handleDataPagination({
            paginationBtnName: btnName,
            state: detailsState,
            stateHandler: setDetailsState
        })
    }
    useEffect(() => {
        setDetailsState({
            ...detailsState,
            tableData: props.tableData,
            tableDataLength: pathname.includes('guest-ledger') ? props.tableData[0].length : props.tableData.length,
            paginatedTableData: pathname.includes('guest-ledger') ?
                            props.tableData[0].slice(detailsState.paginationStartPoint, detailsState.paginationEndPoint)
                            :
                            props.tableData.slice(detailsState.paginationStartPoint, detailsState.paginationEndPoint) || []
        });
    }, [props.tableData]);
    const handleTopDetailsButtons = (type: "all" | "back") => {
        if(type === "back"){
            setDetailsState({
                ...detailsState,
                showPaginationButtons: true, 
            });
            props.setShowRowDetails(!props.showRowDetails);
        }else{
            setDetailsState({
                ...detailsState,
                showPaginationButtons: false, 
                paginatedTableData: props.tableData
            });
        }
    }
    return (
        <div style={{flexDirection: "column", marginLeft: "-.5rem",}} className={`d-flex  ${props.className} `}>
            <section style={{justifyContent: "space-between", width: "80vw"}} className="d-flex align-items-center back-btn-container">
                <span style={{width: "100vw"}} className='d-flex'>
                    {
                        ["Back", "All"].map(btn => (
                            <span onClick={() => handleTopDetailsButtons(btn.toLowerCase()as any)} style={{cursor: "pointer"}} className="mx-1 col-md-1 d-flex align-items-center sb-sidenav-menu text-white btn button my-2">
                                {
                                    btn === "All" ?
                                    <GiStrikingBalls className="mx-2" color={colors.ash} size={23} />
                                    :
                                    <FaChevronCircleLeft className="mx-2" color={colors.ash} size={23} />
                                }
                                {btn}
                            </span>
                        ))
                    }
                </span>
                <span style={{}}>
                    <Filter 
                        tableData={detailsState.tableData}
                        componentsState={detailsState}
                        componentsStateSetter={setDetailsState}
                        paginationEndPoint={detailsState.paginationEndPoint}
                        paginationStartPoint={detailsState.paginationStartPoint}
                    />
                </span>
            </section>
            <article style={{fontSize: "23px", flexDirection: "column"}} className="text-center font-weight-bold col-md-10 d-flex justify-content-center align-self-center gif-logo-container">
                <span className='text-center'>
                    <Gifs gifName='hotel-logo' />
                </span>
                <p className="mt-3">{props.tableTitle} </p>
            </article>
            <React.Fragment>
                <table className="table-table table bg-light table-bordered table-hover table-striped" >
                    <thead style={{backgroundColor: colors.sideNav}} className="py-2 ">
                        <tr className="text-center text-light">
                            {
                                props.tableHeader && props.tableHeader.map((columnName, index) => (
                                    <th key={index}>{columnName}</th>
                                ))
                            }
                            {
                                !routeToExcludeActions.includes(subPathname) && !pathname.includes("records") &&
                                <th>Actions</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.tableData && detailsState.paginatedTableData.map((data: any, index: number) => (
                                <tr className="text-center text-sm" key={data._id}>
                                    {
                                        props.tableHeader.map((header, hIndex) => (
                                            <td key={hIndex}>{tableDataCleaner(header, data)}</td>
                                        ))
                                    }
                                    {
                                        !routeToExcludeActions.includes(subPathname) && !pathname.includes("records") &&
                                        <td style={{backgroundColor: colors.sideNav}} className="">
                                            <div className="dropdown show btn-container">
                                                <Link to="#" className="table-actions-btn btn  dropdown-toggle sb-sidenav-menu" style={{color: "#000", backgroundColor: colors.restaurantTrayColor}} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Actions </Link>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" >
                                                    <TableActionButtons 
                                                        data={data}
                                                        pathname={pathname}
                                                        handleActions={props.actionsHandler}
                                                        details={true}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot style={{backgroundColor: colors.sideNav}}>
                        {
                            props.footerArray &&
                            <tr className="text-center text-white">
                                {
                                    props.footerArray[0].map((val: any, index: number) => (
                                        <td key={index}>{val.toLocaleString()}</td>
                                    ))
                                }
                            </tr>
                        }
                    </tfoot>
                </table>
            </React.Fragment>
            {
                !pathname.includes('records') &&
                <section style={{justifyContent: "space-between"}} className="row">
                    <span></span>
                    <span className="mx-2 btn-container">
                        {
                            detailsState.showPaginationButtons &&
                            paginationButtons.slice(
                                detailsState.paginationStartPoint === 0 ? 1 :
                                0, (detailsState.paginationEndPoint > detailsState.tableDataLength) ? 1 :
                                undefined
                            ).map(btnName => (
                                <button 
                                    key={btnName}
                                    onClick={() => handlePagination(btnName)} 
                                    className={`btn ${btnName.toLowerCase() === 'next' ? 'btn-primary' : 'btn-secondary'} mx-2 mb-4`}>
                                    {btnName}
                                </button>
                            ))
                        }
                    </span>
                </section>
            }
            {
                !pathname.includes("ledger") &&
                <section className="print-btn-container">
                    <button onClick={() => window.print()} className="btn btn-secondary">
                        Print
                    </button>
                </section>
            }
            <div className="managers-flash-notes">
                {
                    (pathname.includes('bills') || pathname.includes('records/individual-in-house')) &&
                    <section>
                        <p className="mx-2 font-weight-bold" style={{textDecoration: "underline", fontSize: "16px"}}>Definitions:</p>
                        {
                            Object.keys(individualsCheckedInDefinitions).map((variable) => (
                                <article key={variable} className="individualsCheckedInDefinitions">
                                    <p style={{fontSize: "14px"}} className="d-flex align-items-center">
                                        <span><BsArrowRightCircle /></span>
                                        <span className="mx-2 mt-1 font-weight-bold">{variable}:</span> 
                                        <span className="mt-1">{(individualsCheckedInDefinitions as any)[variable]}</span>
                                    </p>
                                </article>
                            ))
                        }
                    </section>
                }
            </div>
        </div>
    )
};

export default Details;
