import { customAlert } from "../../utilities/customAlert";

import axiosHelper, { axiosErrorHelper } from "../../utilities/axiosHelper";
import { givenDateAndCurrentTime, todaysDate as todaysDateStructured } from "date-fran";


//create reservation script
export const validateIndividualReservationDetails = (e: any, reservationDetails: object, pathname: string, btnAction: string) => {
    e.preventDefault();
    // console.log("\n\t validateIndividualReservationDetails ", (document as any).querySelector('.adults').value)

    const arrivalDate = (reservationDetails as any)["Arrival Date"];
    const departureDate = (reservationDetails as any)["Departure Date"];
    const roomType = (reservationDetails as any)["Room Type"];
    const roomNumber = (reservationDetails as any)["Room Number"];
    const firstName = (reservationDetails as any)["Firstname"];
    // console.log("\n\t btnAction: ", btnAction)
    if(pathname.includes("backtrack") && btnAction.toLowerCase().includes('create')){
        console.log("\n\t pathname.includes('backtrack'): ", pathname.includes("backtrack"))
        const dateiFiedDepartureDate = givenDateAndCurrentTime(departureDate)as Date;
        // console.log("\n\t dateiFiedDepartureDate: ", dateiFiedDepartureDate)
        // console.log("\n\t todaysDateStructured: ", todaysDateStructured())
        if(dateiFiedDepartureDate <= todaysDateStructured()){
            return customAlert("error", "If you're to use the create button, ensure the departure date exceeds the present date", 5000)
        }
    }
    const lastName = (reservationDetails as any)["Lastname"];
    const guaranteed = (reservationDetails as any)["Is Reservation Guaranteed"];
    const deposit = (reservationDetails as any)["Deposit"];
    const accruedRate = (reservationDetails as any)["Room Rate"];
    const totalCost = (reservationDetails as any)["Total Cost"];
    const days = (reservationDetails as any)["Nights"];
    const roomRate = (reservationDetails as any)["Room Rate"];
    const traces = (reservationDetails as any)["Traces"];


    const adults = (reservationDetails as any)["Adults"];
    const email = (reservationDetails as any)["Email"];
    const title = (reservationDetails as any)["Title"];
    const phoneNumber = (reservationDetails as any)["Phone"];
    const country = (reservationDetails as any)["Country"];
    const nationality = (reservationDetails as any)["Nationality"];
    const state = (reservationDetails as any)["State"];

    const city = (reservationDetails as any)["City"];
    const discount = (reservationDetails as any)["Deposit"]
    const address = (reservationDetails as any)["Address"]
    const membershipType = (reservationDetails as any)["Membership Type"];
    const membershipNumber = (reservationDetails as any)["Membership Number"];
    const membershipLevel = (reservationDetails as any)["Membership Level"]

    const source = (reservationDetails as any)["Agent"]
    const purpose = (reservationDetails as any)["purpose"]
    const dataOfBirth = (reservationDetails as any)["Date of Birth"]
    const paymentMethod = (reservationDetails as any)["Payment Method"]
    const passportNumber = (reservationDetails as any)["Passport Number"]

    console.log("\n\t Assets: ", paymentMethod)

    //validation
    // console.log("Room Rate ", accruedRate)
    if (!arrivalDate) return customAlert("error", "Arrival date is required", 4000);
    // if (!phoneNumber) return customAlert("error", "Phone number is required", 4000);
    if (!departureDate) return customAlert("error", "Departure date is required", 4000);
    // if (!membershipType) return customAlert("error", "Membership type is required", 4000);
    if (!pathname.includes("update") && !guaranteed){
        return customAlert("error", "Please enter 'Yes' or 'No', if this reservation is guaranteed or not.", 5000);
    }
    if (!roomType) return customAlert("error", "Room type is required", 4000);
    // if (!nationality) return customAlert("error", "Nationality is required", 4000);
    if (!title) return customAlert("error", "Title is required", 4000);
    if (!roomNumber) return customAlert("error", "Room  number is required", 4000);
    if (!firstName) return customAlert("error", "Firstname is required", 4000);
    if (!lastName) return customAlert("error", "Lastname is required", 4000);


    if (guaranteed === "Yes" && (deposit <= 0))
        return customAlert(
            "error",
            "Guaranteed reservations must have initial deposit",
            4000
        );
    
    if (deposit > 0 && !paymentMethod)
    return customAlert(
        "error",
        "Please, indicate the payment method for deposit.",
        4000
    );

    const reservationData = ({
        firstName,
        lastName,
        arrivalDate,
        departureDate,
        roomType,
        roomRate,
        roomNumber,
        days,
        totalCost,
        guaranteed,
        deposit,
        accruedRate,
        adults: adults || 1,
        email,
        title,
        phoneNumber,
        passportNumber,
        country,
        nationality,
        state,
        city,
        discount,
        traces,
        address,
        membershipType,
        membershipNumber,
        membershipLevel,
        source,
        purpose,
        dataOfBirth,
        paymentMethod
    });
    return reservationData
};

interface CreateInterface {
    reservationDetails: object, 
    pathname: string, 
    backTrackAction?: string
    btnAction: string
}
export const createIndividualReservation = async(props: CreateInterface) => {
    
    try {
        const res = await axiosHelper({
            routeName: `${
                props.btnAction.includes('create') ? 
                `/reservations/individual-reservation/add` : 
                props.pathname.includes('backtrack') ? 
                `/backtrack/accommodation/individual/add/${props.backTrackAction}` : 
                '/reservations/check-in-from-reservations/individual/add' 
            }`,
            httpVerb: "post",
            dataToPostEditOrDelete: props.reservationDetails
        });
        console.log("\n\t res: ", res)
        if(typeof(res) === "string"){
            customAlert("error", res, 6500);
        }else{
            customAlert("success", res.message, 5500);
        }
        window.setTimeout(() => {
            window.location.assign(
                props.pathname.includes('backtrack') ? 
                "/backtrack/guests/individual" :
                "/reservations/reservation-table/individual"
            );
        }, 6000);
    } catch (error) {
        console.log(error);
        axiosErrorHelper(error);
    }
};

export const updateIndividualReservation = async(reservationDetails: object, id: string) => {
    console.log("\n\t updateReservation-id: ", id)
    try {
        const res = await axiosHelper({
            routeName: `/reservations/individual/update/${id}`,
            httpVerb: "put",
            dataToPostEditOrDelete: reservationDetails
        });
        console.log("\n\t updateReservation: ", res)
        customAlert("success", res.message, 4000);
        window.setTimeout(() => {
            window.location.assign("/reservations/reservation-table/individual");
        }, 4000);
    } catch (error) {
        console.log(error);
        axiosErrorHelper(error);
    }
};

export const onGuaranteedChange = (value: string) => {
    const depositInput = document.querySelector(".deposit");
    if (value === "Yes") {
        depositInput!.removeAttribute('disabled');
    } else {
        (depositInput as any).setAttribute("disabled", true);
    }
};

export const onMemberShipTypeChange = (membershipType: string) => {
    // console.log("\n\t membershipType: ", membershipType.toLowerCase())
    const discountInput = document.querySelector(".discount")
    if(membershipType.toLowerCase() === 'none'){
        discountInput?.removeAttribute('disabled')
    }else{
        (discountInput as any)!.setAttribute('disabled', true)
    }
}

export const todaysDate = () => {
    const date = new Date();
    const today = `${date.getFullYear()}-${
    date.getMonth() + 1 >= 10
      ? date.getMonth() + 1
      : "0" + (date.getMonth() + 1)
  }-${date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()}`;
    return today;
};

export const yesterdaysFormDate = () => {
    const date = new Date();
    const today = `${date.getFullYear()}-${
    date.getMonth() + 1 >= 10
      ? date.getMonth() + 1
      : "0" + (date.getMonth() + 1)
  }-${date.getDate() >= 10 ? date.getDate()-1 : "0" + (date.getDate()-1)}`;
    return today;
};



export const onRoomChange = (rooms: object) => {
    let selectedRoomNumber = (document as any).querySelector(".room-number").value;
    // console.log("\n\t selectedRoomNumber: ", selectedRoomNumber)
    const roomPropertiesForSelectedRoom = Object.values(rooms).filter(room => Number(room.roomNumber) === Number(selectedRoomNumber))
    // console.log("\n\t roomPropertiesForSelectedRoom: ", roomPropertiesForSelectedRoom)
    const {breakfast, doubleRate, rate } = roomPropertiesForSelectedRoom[0];

    const formsRoomRateField = document.querySelector(".room-rate");

    const roomRatePlusBreakfast = rate + +breakfast;
    (formsRoomRateField as any).value = roomRatePlusBreakfast;
    const numberOfAdults = document.querySelector(".adults");
    // console.log("\n\t numberOfAdults: ", (numberOfAdults as any).value);

    if(Number((numberOfAdults as any).value) === 2){
        let cumulativeSum = roomRatePlusBreakfast + +doubleRate;
        (formsRoomRateField as any).value = cumulativeSum;
    }
    //==============================================================================
    
    let totalAmount = document.querySelector(".total-cost");
    let days = (document as any).querySelector(".nights").value;
    (totalAmount as any).value = days* Number((formsRoomRateField as any).value);

    // console.log("\n\t roomRatePlusBreakfast: ", days, roomRatePlusBreakfast);
    return Number((totalAmount as any).value)
};




function incrementDate(dateInput: any) {
    var dateFormatTotime = new Date(dateInput);
    var increasedDate = new Date(dateFormatTotime.getTime() + (1 * 86400000));
    console.log(increasedDate.toISOString().split("T"))
    return increasedDate.toISOString().split("T")[0];
}

export const onArrivalDateChange = async() => {
    console.log("\n\t onArrivalDateChange: ")
    let day = 0;
    let arrivalDate = (document as any).querySelector(".arrival-date").value;
    console.log("\n\t onArrivalDateChange-arrivalDate: ", arrivalDate)

    let departureDate = (document as any).querySelector(".departure-date").value;
    (document as any).querySelector(".departure-date").min = incrementDate(arrivalDate);

    if (arrivalDate && departureDate === "") {
        day = 1;
        (document as any).querySelector(".nights").value = day;
        return;
    } else if (arrivalDate === "" && departureDate === "") {
        day = 0;
        (document as any).querySelector(".nights").value = day;
        return;
    }
    let dayDiffernce = await getDayDifference(departureDate, arrivalDate);
    (document as any).querySelector(".nights").value = dayDiffernce;
};


export const onDepartureDateChange = async() => {
    console.log("\n\t onDepartureDateChange: ")
    let day = 0;
    let arrivalDate = (document as any).querySelector(".arrival-date").value;
    let departureDate = (document as any).querySelector(".departure-date").value;

    if (arrivalDate && departureDate === "") {
        day = 1;
        (document as any).querySelector(".nights").value = day;
        return;
    } else if (arrivalDate === "" && departureDate === "") {
        day = 0;
        (document as any).querySelector(".nights").value = day;
        return;
    }

    let dayDiffernce = await getDayDifference(departureDate, arrivalDate);
    (document as any).querySelector(".nights").value = dayDiffernce;
    (document as any).querySelector(".room-rate").value = 0;
    (document as any).querySelector(".total-cost").value = 0;
};


export const getDayDifference = async(dateOne: any, dateTwo: any) => {
    dateOne = new Date(dateOne);
    dateTwo = new Date(dateTwo);
    const diffTime = Math.abs(dateTwo - dateOne);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;

    // dateOne = new Date(dateOne);
    // dateTwo = new Date(dateTwo);
    // const diffTime = Math.abs(dateTwo - dateOne);
    // const diffDays = (diffTime / (1000 * 60 * 60 * 24));
    // return +(String(diffDays).split(".")[0]);
};

export const getDayDifferenceUnApproximated = (dateOne: any, dateTwo: any) => {
    dateOne = new Date(dateOne);
    dateTwo = new Date(dateTwo);
    const diffTime = Math.abs(dateTwo - dateOne);
    const diffDays = (diffTime / (1000 * 60 * 60 * 24));
    return +(String(diffDays).split(".")[0]);
};

export const onDiscountChange = async() => {
    // console.log("\n\t onDiscountChange... ")
    let discountValue = (document as any).querySelector(".discount").value;
    let totalCost = (document as any).querySelector(".total-cost");
    
    let roomRate = (document as any).querySelector('.room-rate').value;
    let days = (document as any).querySelector('.days').value;
    const totalCostValue = +roomRate*(+days)
    if(Number(discountValue) <= 1){
        return totalCost.value = (Number(roomRate) * Number(days)).toLocaleString();
    };
    let discountedPrice = (Number(discountValue) / 100) * totalCostValue;
    let amountTopay = totalCostValue - Math.ceil(discountedPrice);
    return totalCost.value = Number(amountTopay.toFixed(2));
};


export const onNumberOfAdultChange = (rooms: object) => {
    const selectedRoomNumber = (document as any).querySelector(".room-number").value;
    const selectedRoomProperties = Object.values(rooms).filter(room => 
        room.roomNumber === +selectedRoomNumber
    );
    if(selectedRoomProperties.length === 0) return "Please re-select room type to enable you select a room number.";

    const numberOfAdults = document.querySelector(".adults");
    const { doubleRate, breakfast, rate } = selectedRoomProperties[0] as any;
    // console.log("\n\selectedRoomProperties: ", selectedRoomProperties, doubleRate)
    const numberOfAdultsCount = Number((numberOfAdults as any).value);
    if(numberOfAdultsCount > 2) return "Maximum number of adults in a room can only be two";
    
    let formsRoomRateField = document.querySelector(".room-rate");
    const totalCost = document.querySelector(".total-cost");
    const days = document.querySelector(".days");

    if(Number((numberOfAdults as any).value) === 2){
        let cumulativeSum = +rate + breakfast + doubleRate;
        (formsRoomRateField as any).value = cumulativeSum;
    }
    // console.log("\n\selectedRoomProperties-roomSingleRate: ", ((numberOfAdults as any).value).length)
    if(Number((numberOfAdults as any).value) <= 1){ 
        (formsRoomRateField as any).value = +breakfast + +rate;
    }
    (totalCost as any).value = (formsRoomRateField as any).value * +(days as any).value;
    return Number((totalCost as any).value);
}

export const totalCostCalculator = (cost: number) => {
    const stringifiedTotal = String(cost);
    console.log("\n\t stringifiedTotal: ", stringifiedTotal)
    let actualCost = undefined;
    if(stringifiedTotal.includes(".")){
        const roundedUpCost = Math.ceil(cost)
        console.log("\n\t roundedUpCost: ", roundedUpCost)
        actualCost = +roundedUpCost
    }else{
        actualCost = cost
    };
    console.log("\n\t actualCost: ", actualCost)
    return actualCost
}