import React, { lazy, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
// import ModuleSummary from '../ModuleSummary/ModuleSummary';
// import FAndBTable from '../Tables/FAndBTable';
// import DailySales from '../Tables/DailySales';
// import RecordsTable from '../Tables/RecordsTable';
// import RecordsDashboard from './RecordsDashboard';


const FAndBTable = lazy(() => import('../Tables/FAndBTable'));
// const DailySales = lazy(() => import('../Tables/DailySales'));
const ModuleSummary = lazy(() =>import('../ModuleSummary/ModuleSummary'));
interface Props {
    summaryHeader: any[]
    tableData: []
};


const FAndBDashboard  = (props: Props) => {
    const location = useLocation()
    const pathname = location.pathname;
    // console.log("\n\t pathname.split[1]: ", pathname.split("/")[1])
    return (
        <div>
            {
                pathname === "/restaurant" || pathname === "/bar" || pathname === "/pool-bar" ?
                <div>
                    <ModuleSummary dashboardName={pathname.includes('restaurant') ? "Restaurant" : pathname.includes('pool') ? "Pool Bar" : "Bar"} />
                </div>
                :
                // pathname.includes('daily') ?
                // <DailySales salesRoute={
                //     pathname.includes('bar') ? `/records/${pathname.split("/")[1]}-daily-sales`
                //     :
                //     "/records/restaurant-daily-sales"
                // } />
                // :
                <FAndBTable />
            }
        </div>
    )
}

export default FAndBDashboard 
