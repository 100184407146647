import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import axiosHelper from '../../../../utilities/axiosHelper'
import { titleCase } from '../../../../utilities/strings';
import Gifs from '../../../Gifs';
import Details from './Details';
import { financialTransactionsRecords, handleRecordDetails, managersFlashRecord, trialBalanceRecords } from './tableActions/tableActionsThree';

interface Props {
    dataToDetail: any
    showRowDetails: boolean
    setShowRowDetails: Function
}


const RecordsDetails = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    // console.log("\n\t RecordsDetails: ", pathname)
    const [recordsState, setRecordsState] = useState({
        // paginatedTableData: [],
        tableData: []as any[],
        showDetails: false,
        tableTitle: "",
        detailsTableData: [],
        detailsTableHeader: [] as string[],
        allRecords: [],
        summedDebit: 0,
        summedCredit: 0,
        summedVAT: 0,
        summedServiceCharge: 0,
        summedConsumptionCharge: 0,
        summedDepositAmount: 0,
        summedLedgerAmount: 0,
        summedReceiptTotalAmount: 0,
        summedFAndBTotalAmount: 0,
        summedFAndBBalanceAmount: 0,
        loadingText: `Please wait; Hotel-Pro is fetching ${titleCase(pathname.split("/")[2])} records...`,
        summedNetCharge: 0,
        summedReceiptActualAmountPaid: 0,
        individualReservationSummedAdult: 0,
        individualReservationSummedRoomRate: 0,
        individualReservationSummedTotalCost: 0,
        groupReservationSummedGuest: 0,
        groupReservationSummedTotalCost: 0,
        paginationLength: 12,
        paginationStartPoint: 0,
        paginationEndPoint: 12,
        paginatedTableData: [] as any[],
        referenceDate: "",
    });
    const summedFinancialTransactionData = ["", "", "", recordsState.summedDebit, recordsState.summedCredit, "", "", "", recordsState.summedNetCharge, recordsState.summedVAT, recordsState.summedServiceCharge, recordsState.summedConsumptionCharge, "", "", ""];
    const summedFinancialTransactionDataForCharges = ["", "", "", recordsState.summedDebit, "", "", "", recordsState.summedNetCharge, recordsState.summedVAT, recordsState.summedServiceCharge, recordsState.summedConsumptionCharge, "", "", "",];
    const summedFinancialTransactionDataForCredits = ["", "", "", recordsState.summedCredit, "", "", "", recordsState.summedNetCharge, recordsState.summedVAT, recordsState.summedServiceCharge, recordsState.summedConsumptionCharge, "", "", "",];
    const summedFolioData = ["", "", "",  recordsState.summedDebit, recordsState.summedCredit, "", "", recordsState.summedNetCharge, recordsState.summedVAT, recordsState.summedServiceCharge, recordsState.summedConsumptionCharge, ""];

    // const summedFAndBData = ["", "", "", "", "", recordsState.summedFAndBTotalAmount, "", "", recordsState.summedFAndBBalanceAmount, "", ""];
    const summedDepositData = ["", "", "", recordsState.summedDepositAmount, "", "", "", "",];
    const summedReceiptsData = ["", "", "", "", "", "", recordsState.summedReceiptTotalAmount, recordsState.summedReceiptActualAmountPaid, "", "", "", ];
    const summedIndividualReservationData = ["", "", "", "", "", "", recordsState.individualReservationSummedAdult, "", "", recordsState.individualReservationSummedRoomRate, "", "", recordsState.individualReservationSummedTotalCost];
    const summedGroupReservationData = ["", "", "", "", "", "", "", recordsState.groupReservationSummedGuest, recordsState.groupReservationSummedTotalCost ];

    const recordsData = (recordResponse: any, recordDate: string) => {
        try {
            let transactionsData = undefined;
            const slicedResponseData = recordResponse.data.slice(recordsState.paginationStartPoint, recordsState.paginationEndPoint);
            // recordResponse.slice(recordsState.paginationStartPoint, recordsState.paginationEndPoint);
            if(pathname.includes("transactions") || pathname.includes("charges") || pathname.includes("cashiers") || pathname.includes("credits")){
                transactionsData = financialTransactionsRecords(recordResponse.data, pathname)
            }else if(pathname.includes("trial")){
                transactionsData = trialBalanceRecords(recordResponse.data);
            }else if(pathname.includes("flash")){
                transactionsData = managersFlashRecord(recordResponse.data);
            };
            // console.log("\n\t slicedResponseData: ", slicedResponseData);
            // console.log("\n\t transactionsData: ", transactionsData);
    
            recordsState.loadingText = "";
            recordsState.showDetails = true;
            handleRecordDetails({
                data: (pathname.includes("transactions") || pathname.includes("charges") || pathname.includes("cashiers") || pathname.includes("credits")) ? transactionsData : recordResponse.data,
                pathname,
                recordsState,
                setRecordsState,
                recordDate            
            });
        } catch (error) {
            console.log("\n\t recordsData-error: ", error)
        }
    };
    useEffect(() => {
        (async () => {
            // console.log("\n\t dataToDetail: ", props.dataToDetail)
            const dataToDetail = props.dataToDetail;
            const recordResponse = await axiosHelper({
                httpVerb: "get",
                routeName: `${pathname}/${dataToDetail.recordDate}`
            });
            // console.log("\n\t recordResponse: ", recordResponse)
            const data = recordResponse ? recordResponse : {data: []}
            recordsData(data, dataToDetail.recordDate);
        })();
    }, [pathname]);
    return (
        <React.Fragment>
            {
                !recordsState.showDetails ?
                <div style={{height: "100vh", width: "100vw", marginLeft: "-4rem", flexDirection: "column"}} className='bg-black d-flex justify-content-center align-items-center'>
                    <Gifs width={150} height={150} gifName='fandb' />
                    <p className='font-italic animate__animated animate__headShake animate__infinite'>{recordsState.loadingText}</p>
                </div>
                :
                <React.Fragment>
                    <section style={{justifyContent: "space-between", alignItems: "center", width: "95%"}} className='d-flex '>
                        {
                            pathname.includes('market-worth') ?
                            ""
                            :
                            <Details
                                tableTitle={recordsState.tableTitle}
                                showRowDetails={recordsState.showDetails}
                                setShowRowDetails={() => {
                                        props.setShowRowDetails({
                                        ...recordsState,
                                        showDetails: !props.showRowDetails
                                    });
                                }}
                                actionsHandler={() => undefined}
                                tableData={recordsState.detailsTableData}
                                tableHeader={recordsState.detailsTableHeader}
                                footerArray={[
                                    pathname.includes('deposit') ? summedDepositData : 
                                    pathname.includes('receipts') ? summedReceiptsData : 
                                    pathname.includes('transactions') ? summedFinancialTransactionData :
                                    pathname.includes('folios') ? summedFolioData
                                    : pathname.includes('charges') ? summedFinancialTransactionDataForCharges
                                    : pathname.includes('credits') ? summedFinancialTransactionDataForCredits 
                                    // : pathname.includes('daily-sales') ? summedFAndBData 
                                    : pathname.includes('individual-reservation') ? summedIndividualReservationData 
                                    // : pathname.includes('logs') ? userLogsTableHeader 
                                    : pathname.includes('group-reservation') ? summedGroupReservationData : []
                                ]}
                                // stateSetter={setRecordsState}
                            />
                        }
                    </section>
                </React.Fragment>
            }
        </React.Fragment>
    )
};

export default RecordsDetails