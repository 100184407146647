import React, { useEffect, useState } from 'react'
import colors from '../../../../../assets/colors';
import { pascalCaseSeparator, titleCase } from '../../../../../utilities/strings';

import "../../../../../styles/TrialBalanceStyles.css";
import Gifs from '../../../../Gifs';
import axiosHelper from '../../../../../utilities/axiosHelper';
import {  trialBalanceRecords } from '../tableActions/tableActionsThree';
import { useLocation } from 'react-router-dom';

interface Props {
    dataToDetail: any
}


const TrialBalanceReport = (props: Props) => {
    const pathname = useLocation().pathname;
    const [trialBalanceDataState, setTrialBalanceDataState] = useState({
        revenues: [] as any,
        paymentMethods: [] as any,
        // memberships: []as any[],
        departments: [] as any,
        taxes: [] as any,
        referenceDate: "",
        loadingPrompt: `Please wait; HotelPro is fetching ${titleCase(pathname.split("/")[2])} data...`,
    });
    // console.log("\n\t trialBalanceDataState: ", props.tableData)
    useEffect(() => {
        (async () => {
            console.log("\n\t props.tableData: ", props.dataToDetail);
            // await serverPaginator();
            console.log("\n\t dataToDetail: ", props.dataToDetail)
            const dataToDetail = props.dataToDetail;
            const recordResponse = await axiosHelper({
                httpVerb: "get",
                routeName: `${pathname}/${dataToDetail.recordDate}`
            });
            console.log("\n\t recordResponse: ", recordResponse)
            const data = recordResponse ? recordResponse.data : {data: []}
            console.log("\n\t data: ", data)
            const trialBalanceData = trialBalanceRecords(data);
            console.log("\n\t trialBalanceData: ", trialBalanceData)
            console.log("\n\t useEffect-trilaBalanceData: ", trialBalanceData[0])
            if(trialBalanceData){
                setTrialBalanceDataState({
                    ...trialBalanceDataState,
                    revenues: [trialBalanceData[0].revenues],
                    paymentMethods: [trialBalanceData[0].paymentMethods],
                    // memberships: [trialBalanceData[0].memberships],
                    departments: [trialBalanceData[0].departments],
                    taxes: [trialBalanceData[0].taxes],
                    loadingPrompt: "",
                    referenceDate: new Date(trialBalanceData[0].referenceDate).toDateString()
                })
            }
        })()
    }, []);
    return (
        <React.Fragment>
            {
                trialBalanceDataState.loadingPrompt ?
                <div style={{height: "100vh", width: "100vw", marginLeft: "-4rem", flexDirection: "column"}} className='bg-black d-flex justify-content-center align-items-center'>
                    <Gifs width={150} height={150} gifName='fandb' />
                    {/* <p className='font-italic animate__animated animate__headShake animate__infinite'>{recordsState.loadingText}</p> */}
                    <span className="font-weight-bolder small text-success animate__animated animate__pulse animate__infinite">{trialBalanceDataState.loadingPrompt}</span>
                </div>
                :
                <div className='trial-balance-table-container'>
                    <div className="mt-4 row"></div>
                    <section className="mt-2 trial-balance-header">
                        <span className='text-center'>
                            <Gifs gifName='hotel-logo' />
                        </span>
                        
                        <h4 className="text-center mt-2 font-weight-bold">Trial Balance</h4>
                        <h6 className="text-center font-weight-bold">{trialBalanceDataState.referenceDate}</h6>
                        <hr />
                    </section>
                    <section className="ml-1 ">
                        <table className="trial-balance-table-table table bg-light table-striped table-hover table-bordered">
                            <thead style={{backgroundColor: colors.sideNav}} className="text-black">
                                <tr>
                                    <td colSpan={4} className="font-weight-bold text-white">Variables</td>
                                    <td colSpan={4} className="font-weight-bold text-white">This Year</td>
                                    <td colSpan={4} className="font-weight-bold text-white">Last Year</td>
                                </tr>
                            </thead>
                        </table>
                        <table className="trial-balance-table-table table bg-light table-striped table-hover table-bordered">
                            <tbody>
                                {
                                    trialBalanceDataState.revenues && Object.keys(trialBalanceDataState).slice(0, 4).map(headings => (
                                        <React.Fragment>
                                            <tr className="text-center" key={headings}>
                                                <td colSpan={12} className="font-weight-bold">{pascalCaseSeparator(headings)}</td>
                                            </tr>
                                            {
                                                (trialBalanceDataState as any)[headings] &&
                                                (trialBalanceDataState as any)[headings].map((data: any, dataIndex: number) => (
                                                    <React.Fragment>
                                                        {
                                                            data && Object.keys(data).map((dataKey: any, dataKeyIndex: number) => (
                                                                <tr key={dataKeyIndex}>
                                                                    <td className={`${dataKey.toLowerCase() === "total" ? 'font-weight-bold' : undefined}`} style={{width: "30%"}}>{dataKey}</td>
                                                                    <td className={`${dataKey.toLowerCase() === "total" ? 'font-weight-bold' : undefined}`} style={{width: "30%"}}>{data[dataKey][0] ? data[dataKey][0].toLocaleString() : 0}</td>
                                                                    <td className={`${dataKey.toLowerCase() === "total" ? 'font-weight-bold' : undefined}`} style={{width: "30%"}}>{data[dataKey][1] ? data[dataKey][1].toLocaleString() : 0}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </React.Fragment>
                                                ))
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>
                        </table>
                    </section>
                    <section className="print-btn-container float-right mb-4 mr-5">
                        <button onClick={() => window.print()} className="btn btn-primary mb-5 mr-4">
                            Print
                        </button>
                    </section>
                </div>
            }
        </React.Fragment>
    )
}

export default TrialBalanceReport