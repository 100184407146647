import userDetailsReducer from "./storeReducers/userDetailsReducer";
import { callBackendReducer, 
        showFormModalReducer, 
        showProcessingGifReducer, 
        toggleSidebarReducer,
        toggleNavbarReducer,
        showConfirmModalReducer,
        toggleFooterReducer,
    } from "./appReducersUtilities";
import { combineReducers } from "redux";
import modulesDataReducer from "./storeReducers/modulesData";

const combinedReducers = combineReducers({
    userDetails: userDetailsReducer,
    sidebarStatus: toggleSidebarReducer,
    navbarStatus: toggleNavbarReducer,
    callBackend: callBackendReducer,
    processingGifStatus: showProcessingGifReducer,
    modalStatus: showFormModalReducer,
    confirmModal: showConfirmModalReducer,
    modulesData: modulesDataReducer,
    footer: toggleFooterReducer
})

export default combinedReducers;