import { createIndividualReservation, updateIndividualReservation, validateIndividualReservationDetails } from "../../../assets/events/individualReservations";
import { showConfirmModalAction, showProcessingGifAction } from "../../../redux/actions/storeActions/appActionsUtilities";
import { customAlert } from "../../../utilities/customAlert";
import { todaysDateBoolean } from "../../../utilities/dateHelper";
import { rowActionFunctions } from "../Modules/Tables/tableData";


interface ShowSummaryInterface {
    setFormState: Function
    formState: any
    pathname: string
    dispatch: Function
    action: string
    individualReservationDetails: any
    e: any
}
export const showSummary = (props: ShowSummaryInterface) => {
    let validations = undefined;
    if(!props.action.includes('membership')){
        validations = validateIndividualReservationDetails(props.e, props.individualReservationDetails, props.pathname, props.action)
    };
    if(props.action.includes('membership')){
        props.dispatch(showConfirmModalAction(true));
        // props.dispatch(showFormModalAction(true));
        const formFields = ["Room Type", "Membership Discount", "Membership Price", "Membership Name", "Username", "Password"];
        props.formState.numberOfFields = formFields.length;
        return props.setFormState({
            ...props.formState,
            formFields,
            fieldType: ["select", "number", "number", "text", "text", "password"],
            formType : "form",
            btnAction: props.action.replaceAll(" ", "-").toLowerCase(),
            optionalFormText: "If you're providing the membership-price, then enter zero in the membership-discount input and vice-versa. Do not enter a non-zero input for both membership-discount and membership-price at the same time!",
            formModalTitle: "New Membership Type"
        });
    }
    console.log("\n\t showSummary-validateReservationDetails: ", validations);
    if(validations !== undefined){
        props.formState.optionalFormText = "";
        props.formState.numberOfFields = 0;
        if(props.action.includes("print")){
            props.formState.formType  = "";
            return props.setFormState({
                ...props.formState,
                printRegCard: true
            })
        }else if(props.action.includes('check')){
            todaysDateBoolean(props.individualReservationDetails['Arrival Date'], props.pathname);
            props.formState.formType  = "";
        }
        props.setFormState({
            ...props.formState,
            validationData: validations,
            formFields: [...Object.keys(props.individualReservationDetails)],
            fieldValues: [...Object.values(props.individualReservationDetails)],
            btnAction: props.action,
            fieldType: [],
            confirmModalTitle: props.pathname.includes('backtrack') ? "Bactrack Reservation Summary" : props.action === 'create' ? "Reservation Summary" : "Reservation and Check-in summary"
        })
        return props.dispatch(showConfirmModalAction(true));
    };
    return props.dispatch(showConfirmModalAction(false));
};


interface HandleReservationInterface {
    setFormState: Function
    formState: any
    pathname: string
    dispatch: Function
    reservationId: string
}
export const handleReservation = async(props: HandleReservationInterface) => {
    if(props.formState.btnAction.includes('membership')){
        props.setFormState({
            ...props.formState,
            formType: "",
        });
        const serverResponse = await rowActionFunctions.postAction(`${props.pathname}/membership-type-calculator`, props.formState.formStateInputs)
        console.log("\n\t serverResponse: ", serverResponse)
        if(typeof(serverResponse) === "string"){
            customAlert("error", serverResponse, 5000);
        }else{
            const { status, message } = serverResponse;
            customAlert("success", message, 5000);
        };
        setTimeout(async() => {
            window.location.reload();
        }, 5200);
    }else{
        props.dispatch(showProcessingGifAction(true));
        (props.pathname.includes("backtrack") || props.pathname.includes("new") || props.pathname.includes("profiles")) ? 
        createIndividualReservation({
            pathname: props.pathname,
            reservationDetails: props.formState.validationData,
            btnAction: props.formState.btnAction,
            backTrackAction: props.formState.btnAction
        })
        :
        updateIndividualReservation(props.formState.validationData, props.reservationId as string)
        // document.querySelector(".fade")?.remove();
    }
};