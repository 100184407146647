import React from 'react'
import { useLocation } from 'react-router-dom';

interface Props {
    fAndBData: any
}

const FAndBSummary = (props: Props) => {
    console.log("\n\t FAndBSummary: ", props.fAndBData);
    const header = ["Persons", "Order Details", "Total"];
    const orderSections = ["Item(s)", "Unit cost", "Quantity"];
    console.log("\n\t fAndBData: ", props.fAndBData);
    const location = useLocation();
    const pathname = location.pathname;
    console.log("\n\t fAndBData-pathname: ", pathname);
    return (
        <div>
            <table style={{borderColor: "#fff", borderStyle: "solid", borderWidth: "3px"}} className="table table-sm table-hover table-bordered table-striped">
                {/* <span className="text-center my-2 " >Order Summay For Table: {props.fAndBData.tableNumber}</span> */}
                <thead>
                    <tr className='text-center text-white'>
                        {
                            header.map(key => (
                                <td colSpan={key.toLowerCase() != 'order' ? 1 : 3}>{key}</td>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.fAndBData && Object.values(props.fAndBData).map((val: any, index: number) => (
                            <tr className='text-center text-white' key={index}>
                                {
                                    val.map((item: any, itemIndex: number) => (
                                        <td className={`${itemIndex != 1 ? 'mt-4' : undefined}`}>
                                            {
                                                itemIndex != 1 ? item.toLocaleString()
                                                :
                                                <table style={{width: "100%"}} >
                                                    {
                                                        item.length != 0 &&
                                                        <thead>
                                                            <tr className='text-center text-white'>
                                                                {
                                                                    orderSections.map(sect => (
                                                                            <td key={sect}>{sect}</td>
                                                                        ))
                                                                    }
                                                            </tr>
                                                        </thead>
                                                    }
                                                    {
                                                        item.map((orderDetail: any) => (
                                                            <tr className='text-center'>
                                                                <td>{orderDetail.item}</td>
                                                                <td>{orderDetail.cost.toLocaleString()}</td>
                                                                <td >{orderDetail.quantity}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            }
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default FAndBSummary;