import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import colors from "../../../assets/colors"
import { toggleSidebarAction } from "../../../redux/actions/storeActions/appActionsUtilities"



const AppTitle = () => {
    const dispatch = useDispatch();
    return(
        <section className="main-sidebar-gradient " style={{zIndex: 100, backgroundColor: colors.appYellow, color: colors.sideNav}}>
            <Link onClick={() => dispatch(toggleSidebarAction(true))} className={`navbar-brand  `} style={{fontSize: "1.6rem", marginLeft: "12px", marginTop: "12px", fontFamily: 'Lucida Calligraphy', color: colors.sideNav,}} to="/dashboard">HotelPro (V3)</Link>
            <Link className="navbar-brand-icon heading" to="/"><i className="fas fa-home"></i></Link>
        </section>
    )
};

export default AppTitle;