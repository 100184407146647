import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Modal from '../Modal'
import Folio from './Folios/Folio'
import FolioForTranstell from './Folios/FolioForTranstell'
import GroupBill from './GroupBill'
import GuestBill from './GuestBill'
import ReceiptForTranstell from './Receipts/ReceiptForTranstell'

interface Props  {
    type: "consent" | "payment" | "receipt" | "guestbill" | "groupBill"
    hideFolio: Function
    folioReceiptData: any
    state: any
    pathname: string
    guestBillTitle?: string
    dateCreated?: string
    generatedBy?: string
    receiptType?: "Reservation" | "Bar" | "Restaurant" | "Pool-Bar"
}

const FolioAndReceipt = (props: Props) => {
    const origin = window.location.origin.split("//")[1].split(".")[0].split("-")[1];
    const originForSmallerPrinters = ['transtell', 'garden', "lekki"]
    const appLocation = useLocation();
    const pathname = appLocation.pathname;
    const store = useSelector((store: any) => store);
    const { confirmModal } = store
    const { showConfirm } = confirmModal;
    // console.log("\n\t props.type: ", props.type)
    return (
        <React.Fragment>
            {
                props.type === "guestbill" ?
                <GuestBill 
                    pathname={props.pathname}
                    billData={props.folioReceiptData}
                    guestBillTitle={props.guestBillTitle as string}
                    toggleBillVisibilty={props.hideFolio}
                />
                :
                props.type === "groupBill" ?
                <GroupBill 
                    billData={props.folioReceiptData}
                    guestBillTitle={props.guestBillTitle as string}
                    toggleBillVisibilty={props.hideFolio}
                />
                :   
                props.type !== "receipt" ?
                <React.Fragment>
                    {
                        originForSmallerPrinters.includes(origin) ?
                        <FolioForTranstell 
                            hideFolio={props.hideFolio}
                            folioReceiptData={props.folioReceiptData}
                            state={props.state}
                            dateCreated={props.dateCreated}
                            generatedBy={props.generatedBy}
                            type={props.type === "consent" ? "Consent" : "Payment" }
                        />
                        :
                        <Folio 
                            hideFolio={props.hideFolio}
                            folioReceiptData={props.folioReceiptData}
                            state={props.state}
                            dateCreated={props.dateCreated}
                            generatedBy={props.generatedBy}
                            type={props.type === "consent" ? "Consent" : "Payment" }
                        />
                    }
                </React.Fragment>
                :
                <React.Fragment>
                    {
                        // originForSmallerPrinters.includes(origin) ?
                        <ReceiptForTranstell 
                            hideFolio={props.hideFolio}
                            receiptDetailsData={props.folioReceiptData}
                            state={props.state}
                            generatedBy={props.generatedBy}
                            type={props.receiptType as any}
                        />
                        // :
                        // <Receipt 
                        //     hideFolio={props.hideFolio}
                        //     receiptDetailsData={props.folioReceiptData}
                        //     state={props.state}
                        //     // dateCreated={props.dateCreated}
                        //     generatedBy={props.generatedBy}
                        //     type={props.receiptType as any}
                        // />
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default FolioAndReceipt