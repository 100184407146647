import React, { useEffect, lazy, useState, Suspense } from 'react'
import { BsArrowRightCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import colors from '../../../../../assets/colors';
import { showConfirmModalAction, showFormModalAction } from '../../../../../redux/actions/storeActions/appActionsUtilities';
import { customAlert } from '../../../../../utilities/customAlert';
import { inputValidator } from '../../../../../utilities/security';
import {  titleCase, titledTextJoiner } from '../../../../../utilities/strings';
import FolioAndReceipt from '../../../Forms/FolioAndReceipt';
import Modal from '../../../Modal';
import { handleDataPagination } from '../tableActions/tableActionsThree';
import { FaChevronCircleLeft } from 'react-icons/fa';
import { GiStrikingBalls } from "react-icons/gi";
// import { } from "rea"
import { 
    guestLedgerFoliosTableHeader,
    paginationButtons,
    rowActionFunctions,
} from '../tableData';
import { reportsAccountHandler, reportsUseEffectFunction } from './reportsAccountHandler';
import Gifs from '../../../../Gifs';

const Details =  lazy(() => import('../Details'));
const ReportsSubTable =  lazy(() => import('../subTables/ReportsSubTable'));


interface Props {
    tableData: any
    appModulesData: any[]
    allSystemCredits: any[]
}

const OtherReports = (props: Props) => {
    const store = useSelector((store: any) => store);
    console.log("\n\t Store: ", store)
    const { sidebarStatus, modalStatus, confirmModal } = store;
    const { showSidebar } = sidebarStatus;
    const { showFormModal } = modalStatus;
    const { showConfirm } = confirmModal;
    const location = useLocation();
    const pathname = location.pathname;
    const subPathname = pathname.split("audit-reports/")[1]
    console.log("\n\t subPathname: ", subPathname)
    const dispatch = useDispatch();

    const linksToHideExpandButton = ["guest-bills/individual", "city-ledger", "managers-flash", "restaurant-shift-sales", "pm-account", "bar-shift-sales"]
    const menuToHideFilterOptions = [ "pm-account",]
    const [reportsState, setReportsState] = useState({
        guestBillTitle: "",
        tableHeaderForDataToBeDetailed: [],
        footerArray: [],
        summedDebit: 0,
        summedCredit: 0,
        showPaginationButtons: false,
        summedVAT: 0,
        summedServiceCharge: 0,
        summedConsumptionCharge: 0,
        summedDepositAmount: 0,
        summedLedgerAmount: 0,
        summedReceiptTotalAmount: 0,
        summedNetCharge: 0,
        summedReceiptActualAmountPaid: 0,
        summedPMDebitAmount: 0,
        summedPMCreditAmount: 0,
        showRowDetails: false,
        detailsData: {},
        detailsTableTitle: "",
        paginatedTableData: [],
        paginationStartPoint: 0,
        paginationEndPoint: 12,
        actualLengthOfTableData: 0,
        paginationLength: 12,
        formInputs: {}as any,
        showForm: false,
        showConfirm: false,
        formModalTitle: "",
        formModalSaveButtonName: "",
        loadingMessage: "Computing data, please wait.",
        btnAction: "",
        confirmModalTitle: "",
        formFields: []as string[],
        serverSelectOption: {} as any,
        formFieldPlaceholder: []as string[],
        formFieldTypes: []as string[],
        dbIdOfDataToModify: "",
        superfluousData: "",
        printGuestBill: false,
        guestBillType: "",
        tableData: []as any[],
        // paymentMethods: []as any[],
        dataToBeDetailed: []as any[],
        showDetails: false,
    });
    const summedFinancialTransactionData = ["", "", "", reportsState.summedDebit, reportsState.summedCredit, "", "", "", reportsState.summedNetCharge, reportsState.summedVAT, reportsState.summedServiceCharge, reportsState.summedConsumptionCharge, "", "", ""];
    const summedDepositData = ["", "", "", reportsState.summedDepositAmount, "", "", "", "",];
    const summedReceiptsData = ["", "", "", "", "", "", reportsState.summedReceiptTotalAmount, reportsState.summedReceiptActualAmountPaid, "", "", "",];
    const summedPMAccountData = ["", "", reportsState.summedPMCreditAmount, reportsState.summedPMDebitAmount, "", "", "", "", "", ""];

   

    const dashboardRouteChecker = () => { 
        if(pathname.includes("guest-in-house")) return "Guest In-House";
        const path = pathname.split("/")[2].replaceAll("-", " ");
        const required = titleCase(path.split(" ")[0]).concat(" ", titleCase(path.split(" ")[1]))
        return  required
    };
    console.log("\n\t reportsState.tableData: ", reportsState.tableData)
    const handlePagination = (btnName: string) => {
        handleDataPagination({
            paginationBtnName: btnName,
            state: reportsState,
            stateHandler: setReportsState
        })
    }
    
    let rerenderCount = 0
    useEffect(() => {
        reportsUseEffectFunction({
            pathname,
            reportsState,
            tableData: props.tableData
        })
        setReportsState({
            ...reportsState,
            actualLengthOfTableData: props.tableData.length,
            tableData: props.tableData,
            loadingMessage: "",
            paginatedTableData: props.tableData.slice(reportsState.paginationStartPoint, reportsState.paginationEndPoint)
        });
        props.tableData.map((data: any) => {
            props.tableData.totalSum += data.totalCharge;
        });
        console.log("\n\t Re-render....", rerenderCount++)
    }, [props.tableData]);
    // reportsState.showDetails
    const actionsHandler = async(action: string, data: any, order?: any) => {
        console.log("\n\t actionsHandler: ", order)
        reportsAccountHandler({
            action,
            dataToModify: pathname.includes("shift-sales") ? order : data,
            dispatch,
            pathname,
            state: reportsState,
            stateHandler: setReportsState,
            appModulesData: props.appModulesData,
            allSystemCredits: props.allSystemCredits,
        })
    };
    const handleFormChange = (field: string, value: string) => {
        // console.log("\n\t handleFormChange: ", field, value)
        reportsState.formInputs[titledTextJoiner(field)] = value;
        setReportsState({
            ...reportsState,
        });
    };
    const toggleModals = () => {
        const validation = inputValidator(reportsState.formInputs, Object.keys(reportsState.formInputs).length, Object.keys(reportsState.formInputs));
        if (typeof (validation) === "string") {
            return customAlert("error", validation as string);
        };
        dispatch(showFormModalAction(false));
        dispatch(showConfirmModalAction(true));
        setReportsState({
            ...reportsState,
            showConfirm: true,
            superfluousData: (reportsState.btnAction.includes('refund') || reportsState.btnAction.includes('pay-debt')) ? reportsState.formInputs['amount'] : "",
            showForm: false
        })
    };
    const definitions = {
        "Debit value greater than zero": "Signifies that a guest owes the hotel an amount equivalent to the indicated figure",
        "Credit value greater than zero": "Signifies that the hotel owes the guest an amount equivalent to the indicated figure.",
        "Refund": "Debits the account",
        "Pay-Debt": "Credits the account",
        "Guest Ledger": "Shows all reservations made for the month and was checked-in."
        // "Dirty rooms": "Signifies the number of rooms checked-out from, but requires clean-up.",
    };
    // const monthFilter = (month: string) => {
    //     const selection = monthSelection(reportsState.tableData, month);
    //     console.log("\n\t selection: ", selection)
    //     setReportsState({
    //         ...reportsState,
    //         tableData: selection
    //     })
    // };
    const setShowRowDetails = (action: string) => {
        setReportsState({
            ...reportsState,
            showDetails: action === "showDetails" ? !reportsState.showDetails : reportsState.showDetails,
            showRowDetails: action === "showRowDetails" ? !reportsState.showRowDetails : reportsState.showRowDetails,
        })
    }
    const handleTopDetailsButtons = (type: "expand" | "contract") => {
        console.log("\n\t reportsState.type: ", type)
        if(type === "contract"){
            setReportsState({
                ...reportsState,
                showPaginationButtons: false, 
                paginatedTableData: pathname.includes('guest-ledger') ? props.tableData[0] : props.tableData.slice(reportsState.paginationStartPoint, reportsState.paginationEndPoint)
            });
            // props.setShowRowDetails(!props.showRowDetails);
        }else{
            setReportsState({
                ...reportsState,
                showPaginationButtons: true, 
                paginatedTableData: pathname.includes('guest-ledger') ? props.tableData[0] : props.tableData
            });
        }
    }
    console.log("\n\t reportsState.showPaginationButtons: ", reportsState.showPaginationButtons)
    const hideFolio = () => {
        setReportsState({
            ...reportsState,
            printGuestBill: !reportsState.printGuestBill,
            showRowDetails: false
        })
    };
    const filterOptions = ["Today", "Yesterday", "This Week", "Last Week", "This Month", "Last Month"]
    return (
        <React.Fragment>
            {
                showFormModal && showFormModal.modalStatus && reportsState.showForm &&
                <Modal
                    type='form'
                    // setPrintRegCard={() => undefined}
                    pathname={pathname}
                    formFields={reportsState.formFields || undefined}
                    formFieldType={reportsState.formFieldTypes || undefined}
                    placeholders={reportsState.formFieldPlaceholder || undefined}
                    onChange={handleFormChange}
                    numberOfFields={reportsState.formFields && reportsState.formFields.length as number || 0}
                    title={reportsState.formModalTitle}
                    handleAction={toggleModals}
                    formInput={reportsState.formInputs}
                    stopFormModalSubmission={true}
                    formModalSaveButtonName={reportsState.formModalSaveButtonName}
                    serverSelectOption={reportsState.serverSelectOption}
                    setGroupCheckInData={() => ""}
                />
            }
            {
                showConfirm && reportsState.showConfirm &&
                <Modal
                    type="confirm"
                    onChange={() => ""}
                    // setPrintRegCard={() => ""}
                    numberOfFields={0}
                    pathname={pathname}
                    handleAction={
                        (reportsState.btnAction.includes('add-charges')|| reportsState.btnAction.includes('add-credits')) ?
                        () => rowActionFunctions.updateAction(`/${pathname}/${reportsState.btnAction}`, reportsState.dbIdOfDataToModify, {...reportsState.formInputs})
                        :
                        () => ""
                    }
                    title={reportsState.btnAction.includes('pay-debt') ? `${reportsState.confirmModalTitle} ${Number(reportsState.superfluousData).toLocaleString()}. Is this correct?` : `${reportsState.confirmModalTitle} ${Number(reportsState.superfluousData).toLocaleString()}`}
                    cancelButtonText="No"
                    continueButtonText="Yes"
                    setGroupCheckInData={() => undefined}
                />
            }
            {
                reportsState.printGuestBill ?
                <FolioAndReceipt 
                    type={reportsState.guestBillType.includes('individual') ? "guestbill" : "groupBill"}
                    state={reportsState}
                    hideFolio={hideFolio}
                    pathname={pathname}
                    folioReceiptData={reportsState.dataToBeDetailed}
                    guestBillTitle={reportsState.guestBillTitle}
                />
                :
                <React.Fragment>
                    {
                        reportsState.loadingMessage ?
                        <div style={{height: "100vh", width: "100vw", marginLeft: "-4rem", flexDirection: "column"}} className='bg-black d-flex justify-content-center align-items-center'>
                            <Gifs width={150} height={150} gifName='fandb' />
                            <p className='font-italic animate__animated animate__headShake animate__infinite'>{reportsState.loadingMessage}</p>
                        </div>
                        :
                        !reportsState.showDetails ?
                        <div className="reports-table-container " style={{ marginLeft: 
                                                            showSidebar && pathname.includes('transaction') ? "-25px" : 
                                                            "17px", 
                                                            width: 
                                                            // pathname.includes('deposits')? "75vw" :
                                                            "80vw",
                                                        }}>
                            <h4 className="mt-5">{dashboardRouteChecker()} {(pathname.includes('receipts') || pathname.includes('deposits') || pathname.includes('monthly-transactions')) && "(This Month)"} {`${pathname.includes('guest') ? (pathname.includes('group') ? '(Group)' : "(Individual)") : ""} `}</h4>
                            <span style={{borderBottomWidth: "2px", borderBottomColor: "ghostwhite", borderBottomStyle: "solid", }} className=" d-flex justify-content-center mb-3"></span>
                            <section style={{display: "flex", justifyContent: "space-between"}}>
                                <span>
                                    {
                                        !props.tableData ? 
                                        <p className='animate__animated animate__infinite animate__pulse' style={{color: colors.danger, fontWeight: "bold", fontSize: "20px", fontStyle: "italic"}}>{reportsState.loadingMessage}</p>
                                        :
                                        <span>
                                        {
                                            !pathname.includes('city') &&
                                            <React.Fragment>
                                                <p>{titleCase(subPathname).replaceAll("/", " ")} ({props.tableData.length})</p>
                                                <select className='form-control' name="" id="">
                                                    {
                                                        filterOptions.map(item => (
                                                            <option key={item} value={item}>{item}</option>
                                                        ))
                                                    }
                                                </select>
                                            </React.Fragment>
                                        }
                                        </span>
                                    }
                                </span>
                                <section className="d-flex align-items-center back-btn-container">
                                    {
                                        !linksToHideExpandButton.includes(subPathname) && ["Expand", "Contract"].slice(reportsState.showPaginationButtons ? 1 : 0, reportsState.showPaginationButtons ? undefined : 1).map(btn => (
                                            <React.Fragment>
                                                <span onClick={() => handleTopDetailsButtons(btn.toLowerCase()as any)} style={{cursor: "pointer"}} className="mx-1col-md-1 d-flex align-items-center sb-sidenav-menu text-white btn button my-2">
                                                    {
                                                        btn === "Expand" ?
                                                        <GiStrikingBalls className="mx-2" color={colors.ash} size={23} />
                                                        :
                                                        <FaChevronCircleLeft className="mx-2" color={colors.ash} size={23} />
                                                    }
                                                    {btn}
                                                </span>
                                                {
                                                    btn !== "Expand" &&
                                                    <button className='mx-2 btn btn-secondary' onClick={()=> window.print()}>Print</button>
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </section>
                            </section>
                            <hr />
                            {
                                !reportsState.showRowDetails ?
                                <div style={{width: "80vw"}} >
                                    <Suspense fallback={<p>{reportsState.loadingMessage}</p>}>
                                        <ReportsSubTable
                                            pathname={pathname}
                                            actionsHandler={actionsHandler}
                                            reportsState={reportsState}
                                            summedDepositData={summedDepositData}
                                            summedFinancialTransactionData={summedFinancialTransactionData}
                                            summedPMAccountData={summedPMAccountData}
                                            summedReceiptsData={summedReceiptsData}
                                        />
                                    </Suspense>
                                </div>
                                :
                                <Suspense fallback={<p>{reportsState.loadingMessage}</p>}>
                                    <Details 
                                        tableData={[reportsState.detailsData]}
                                        tableHeader={pathname.includes("guest-ledger") ? guestLedgerFoliosTableHeader : Object.keys(reportsState.detailsData)}
                                        showRowDetails={reportsState.showRowDetails}
                                        setShowRowDetails={() => setShowRowDetails("showRowDetails")}
                                        tableTitle={reportsState.detailsTableTitle}
                                        actionsHandler={() => undefined}
                                        footerArray={[
                                            pathname.includes('deposit') ? summedDepositData : 
                                            pathname.includes('receipts') ? summedReceiptsData : 
                                            pathname.includes('transactions') ? summedFinancialTransactionData : []
                                        ]}
                                        // stateSetter={setReportsState}
                                    />
                                </Suspense>
                            }
                            <div className="managers-flash-notes">
                                <React.Fragment>
                                    {
                                        (pathname.includes('ledger') || pathname.includes('pm-account') || pathname.includes('transactions')) && 
                                        <article>
                                            <p className="mx-2 font-weight-bold" style={{textDecoration: "underline", fontSize: "16px"}}>Definitions:</p>
                                            {
                                                Object.keys(definitions).slice(pathname.includes("ledger") ? 4 : undefined).map((variable) => (
                                                    <article key={variable} className="definitions">
                                                        <p style={{fontSize: "14px"}} className="d-flex align-items-center">
                                                            <span><BsArrowRightCircle /></span>
                                                            <span className="mx-2 mt-1 font-weight-bold">{variable}:</span> 
                                                            <span className="mt-1">{(definitions as any)[variable]}</span>
                                                        </p>
                                                    </article>
                                                ))
                                            }
                                        </article>
                                    }
                                </React.Fragment>
                            </div>
                            <section style={{justifyContent: "space-between"}} className="row">
                                {
                                    pathname.includes("daily-logs") &&
                                    <span className="mx-3 print-btn-container">
                                        <button className="btn btn-secondary text-center" onClick={e => window.print()}>
                                            Print Table
                                        </button>
                                    </span>
                                }
                                {
                                    reportsState.showPaginationButtons && reportsState.paginatedTableData && reportsState.tableData.length > reportsState.paginationLength &&
                                    <span className="mx-3 btn-container pagination-buttons">
                                        <p>{reportsState.paginationStartPoint === 0 ? 1 : reportsState.paginationStartPoint} to {reportsState.paginationEndPoint >= reportsState.tableData.length ? reportsState.tableData.length : reportsState.paginationEndPoint} of {reportsState.tableData.length}</p>
                                        {
                                            paginationButtons.slice(
                                                (reportsState.paginationStartPoint === 0) ? 1 
                                                :
                                                0, (reportsState.paginationEndPoint > reportsState.actualLengthOfTableData) ? 1
                                                : undefined
                                            ).map(btn => (
                                                <button 
                                                    onClick={e => handlePagination(btn)}
                                                    key={btn}
                                                    className={`btn ${btn.toLowerCase() === 'next' ? 'btn-primary' : 'btn-secondary'} mx-2 mb-4`}>
                                                    {btn}
                                                </button>
                                            ))
                                        }
                                    </span>
                                }
                            </section>
                        </div>
                        :
                        <div className='ml-5 mt-5'>
                            <Suspense fallback={<p>{reportsState.loadingMessage}</p>}>
                                <Details
                                    tableData={reportsState.dataToBeDetailed}
                                    tableHeader={reportsState.tableHeaderForDataToBeDetailed }
                                    showRowDetails={reportsState.showDetails}
                                    setShowRowDetails={() => setShowRowDetails("showDetails")}
                                    tableTitle={reportsState.detailsTableTitle || reportsState.guestBillTitle}
                                    actionsHandler={actionsHandler}
                                    footerArray={reportsState.footerArray}
                                    // stateSetter={setReportsState}
                                />
                            </Suspense>
                        </div>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default OtherReports;