import { customAlert } from "../../utilities/customAlert";
import axiosHelper from "../../utilities/axiosHelper";
import groupReservationDataCleaner from "../../components/containers/Forms/groupReservationDataCleaner";


//create reservation script
export const validateGroupReservationDetails = (roomTypes: any, reservationDetails: object) => {
    console.log("\n\t reservationDetails: ", reservationDetails)
    const days = (document.querySelector(".days") as any).value;
    const numberOfGuests = (document.querySelector(".number-of-guests") as any).value;

    const arrivalDate = (reservationDetails as any)["Arrival Date"];
    const departureDate = (reservationDetails as any)["Departure Date"];
    const groupName = (reservationDetails as any)["Group"];
    const groupEmail = (reservationDetails as any)["Group Email"];
    const guaranteed = (reservationDetails as any)["Is Reservation Guaranteed"];
    const deposit = (reservationDetails as any)["Deposit"];
    const paymentMethod = (reservationDetails as any)["Payment Method"];
    const totalCost = (reservationDetails as any)["Total Cost"];

    console.log("\n\t totalCost: ", totalCost)


    //validation
    // if(+numberOfGuests !== totalNumberOfRooms) return customAlert("error", "Number of guests must equal total number of rooms.");
    if (!arrivalDate) return customAlert("error", "Arrival date is required", 4000);
    if (!departureDate) return customAlert("error", "Departure date is required", 4000);
    if (!groupName) return customAlert("error", "Group name is required", 4000);
    if (!groupEmail) return customAlert("error", "Group email is required", 4000);
    if (!numberOfGuests) return customAlert("error", "Please enter the number of guests expected.", 4000);
    if (!guaranteed) {
        return customAlert("error", "Please enter 'Yes' or 'No', if this reservation is guaranteed or not.", 4000);
    }


    const roomTypeLength = Object.values(roomTypes).length;
    let count = 0;
    const selectedRoomTypeAndNumberOfRooms = [];
    while(count < roomTypeLength){
        const numberOfRooms = +(document.querySelector(`.number-of-rooms-${count}`) as any).value;
        const groupRate = +(Object.values(roomTypes)[count] as any).groupRate;
        console.log("\n\t Loop: ", +days, groupRate, numberOfRooms);
        +numberOfRooms !== 0 && selectedRoomTypeAndNumberOfRooms.push({
            roomTypeName: (Object.values(roomTypes)[count] as any).roomTypeName,
            numberOfRooms
        });
        count++
    };
    let totalNumberOfRooms = 0;
    for(let objects of selectedRoomTypeAndNumberOfRooms){
        totalNumberOfRooms += +objects.numberOfRooms;
    }
    console.log("\n\t Loop: ", selectedRoomTypeAndNumberOfRooms, totalNumberOfRooms);

    if (guaranteed === "Yes" && (deposit <= 0))
    return customAlert(
        "error",
        "Guaranteed reservations must have initial deposit",
        4000
    );

    if (deposit > 0 && !paymentMethod)
    return customAlert(
        "error",
        "Please, indicate the payment method for deposit.",
        4000
    );

    if(+numberOfGuests < +totalNumberOfRooms){
        return customAlert(
            "error",
            "The number of guests should be equal to or more than the number of rooms.",
            5000
        );
    }
    const reservationData = ({
        groupName,
        groupEmail,
        arrivalDate,
        departureDate,
        days,
        totalCost,
        guaranteed,
        deposit,
        roomTypeAndNumberOfRooms: selectedRoomTypeAndNumberOfRooms,
        totalNumberOfRooms,
        paymentMethod,
        numberOfGuests
    });
    return reservationData;
};


export const createGroupReservation = async(reservationDetails: any, reservationDetailsId: string, pathname: string) => {
    console.log("\n\t createGroupReservation: ", pathname)
    try {
        const res = await axiosHelper({
            routeName: 
                pathname.includes('cancel') ? `/reservations/reservation-table/group/update/${reservationDetailsId}` 
                : pathname.includes('update') ? `/reservations/group/update/${reservationDetailsId}` 
                : pathname.includes('backtrack') ? `/${pathname}/accommodation/group-reservation/add` 
                : "/reservations/group-reservation/add",
            httpVerb: (pathname.includes('cancel') || pathname.includes('update')) ? "put" : "post",
            dataToPostEditOrDelete: reservationDetails
        });
        if(typeof(res) === "string"){
            customAlert("error", res, 6000);
        }else{
            customAlert("success", res.message, 4000);
        }
        window.setTimeout(() => {
            window.location.assign("/reservations/reservation-table/group");
        }, 4000);
    } catch (error) {
        console.log(error);
        customAlert("error", "Network error", 4000);
    }
};

export const onGuaranteedChange = (value: string) => {
    const depositInput = document.querySelector(".deposit");
    if (value === "Yes") {
        depositInput!.removeAttribute('disabled');
    } else {
        (depositInput as any).setAttribute("disabled", true);
    }
};

export const todaysDate = () => {
    const date = new Date();
    const today = `${date.getFullYear()}-${
    date.getMonth() + 1 >= 10
      ? date.getMonth() + 1
      : "0" + (date.getMonth() + 1)
  }-${date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()}`;
    return today;
};


export const onRoomChange = async (roomTypeName: string, groupRate: string, count: number) => {
    console.log("\n\t onRoomChange-groupRate: ", groupRate)
    const container = document.querySelector(".new-room-container")
    // container?.childNodes

    const numberOfRooms = (document as any).querySelector(`.number-of-rooms-${count}`).value;
    let totalAmount = document.querySelector(".total-cost");
    totalAmount?.setAttribute('_groupRate', groupRate);
    let days = (document as any).querySelector(".days").value;
    let currentTotal = (totalAmount as any).value || 0;
    const existingGroupRate = totalAmount?.getAttribute("_groupRate");
    
    if(String(existingGroupRate) === groupRate){
        console.log("\n\t same onRoomChange-existingGroupRate: ", +String(existingGroupRate) === +groupRate);
        currentTotal = +days* Number(groupRate) * Number(numberOfRooms);
        totalAmount?.setAttribute("_existingTotal", currentTotal);
       return (totalAmount as any).value = currentTotal;
    }
    console.log("\n\t onRoomChange-existingGroupRate: ", String(existingGroupRate) === roomTypeName);
    let existingTotal = totalAmount?.getAttribute("_existingTotal") || 0;
    console.log("\n\t not same onRoomChange-existingTotal: ", existingTotal);
    (totalAmount as any).value =  +existingTotal + (+days* Number(groupRate) * Number(numberOfRooms));
    
    // console.log("\n\t roomRatePlusBreakfast: ", days, roomRatePlusBreakfast);
};


export const onRoomTypeChange = async(roomTypeRate: number | string, rooms: any, count: number) => {
    const container = document.getElementById("new-room-container")
    
    const formsRoomRateField = document.querySelector(`.room-rate-${count}`) as any;
    formsRoomRateField.setAttribute("defaultRoomTypeRate", roomTypeRate as string);
    formsRoomRateField.value = roomTypeRate; // populate room-rate field;
    
    let selectedRoomType = (document.querySelector(".room-type") as any).value;
    let roomsUnderSelectedRoomType = Object.values(rooms).filter((room: any) => room.roomTypeName === selectedRoomType);
    console.log("\n\t Room roomsUnderSelectedRoomType: ", roomsUnderSelectedRoomType)

    // fill in room-numbers based on the room-type chosen
    let roomNumbers = document.querySelector(".room-number");
    (roomNumbers as any).innerHTML = `<option value="">Select Room</option>`
    for (let room of roomsUnderSelectedRoomType) {
        (roomNumbers as any).innerHTML += `<option  value=${(room as any).roomNumber}>${(room as any).roomNumber}</option>`
    }

}


function incrementDate(dateInput: any) {
    var dateFormatTotime = new Date(dateInput);
    var increasedDate = new Date(dateFormatTotime.getTime() + (1 * 86400000));
    console.log(increasedDate.toISOString().split("T"))
    return increasedDate.toISOString().split("T")[0];
}

export const onArrivalDateChange = async() => {
    console.log("\n\t onArrivalDateChange: ")
    let day = 0;
    let arrivalDate = (document as any).querySelector(".arrival-date").value;
    console.log("\n\t onArrivalDateChange-arrivalDate: ", arrivalDate)

    let departureDate = (document as any).querySelector(".departure-date").value;
    (document as any).querySelector(".departure-date").min = incrementDate(arrivalDate);

    if (arrivalDate && departureDate === "") {
        day = 1;
        (document as any).querySelector(".days").value = day;
        return day;
    } else if (arrivalDate === "" && departureDate === "") {
        day = 0;
        (document as any).querySelector(".days").value = day;
        return day;
    }
    let dayDiffernce = await getDayDifference(departureDate, arrivalDate);
    (document as any).querySelector(".days").value = dayDiffernce;
    return dayDiffernce;
};


export const onDepartureDateChange = async() => {
    console.log("\n\t onDepartureDateChange: ")
    let day = 0;
    let arrivalDate = (document as any).querySelector(".arrival-date").value;
    let departureDate = (document as any).querySelector(".departure-date").value;

    if (arrivalDate && departureDate === "") {
        day = 1;
        (document as any).querySelector(".days").value = day;
        return day;
    } else if (arrivalDate === "" && departureDate === "") {
        day = 0;
        (document as any).querySelector(".days").value = day;
        return day;
    }

    let dayDiffernce = await getDayDifference(departureDate, arrivalDate);
    (document as any).querySelector(".days").value = dayDiffernce;
    return dayDiffernce;
    // (document as any).querySelector(".room-rate").value = 0;
    // (document as any).querySelector(".total-cost").value = 0;
};


export const getDayDifference = async(dateOne: any, dateTwo: any) => {
    dateOne = new Date(dateOne);
    dateTwo = new Date(dateTwo);
    const diffTime = Math.abs(dateTwo - dateOne);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

export const onNumberOfRoomsChange = (groupRoomTypes: any[], days: number) => {
    // console.log("\n\t fieldDetails: ", fieldDetails)
    // console.log("\n\t valueDetails: ", valueDetails)
    // console.log("\n\t days: ", days)
    let totalCost = (document as any).querySelector(".total-cost");
    
    let count = 0;
    let total = 0;
    while(count < groupRoomTypes.length){
        const roomTypeRow = (document as any).querySelector(`.number-of-rooms-${count}`);
        const numberOfRoomsEnteredPerRoomType = +(roomTypeRow.value);
        // const pricePerRooms = valueDetails.split("-")[1]
        const groupRate = groupRoomTypes[count].groupRate;
        const reservationCostForNumberOfGuests = +numberOfRoomsEnteredPerRoomType * +groupRate;
        // console.log()
        total += reservationCostForNumberOfGuests;
        count++
    };
    totalCost.value = Number((days * total).toFixed(2)).toLocaleString();
    // console.log("\n\t total*days: ", total*days)
    return Number((days * total).toFixed(2));
};

interface GroupNameInputInterface {
    formData: any, 
    setFormData: Function, 
    setGroupReservationDetails: Function, 
    setFormState: Function, 
    formState: any, 
    groupReservationDetails: any, 
    groupReservationDataCleaner: Function, 
    groupValues: any, 
    groupRateValues: any, 
    inputtedGroupName: string, 
    roomTypes: any
}

export const onGroupNameInput = (props: GroupNameInputInterface) => {
    try {
        
        const selectedGroup = props.groupValues.filter((group: any) => group.groupName === props.inputtedGroupName);
    
        const email = selectedGroup && (selectedGroup[0] as any).groupEmail;
        console.log("\n\t Email: ", email);
        (document as any).querySelector(".group-email").value = email;
        
        // console.log("\n\t groupReservationDataCleaner: ", reqData);
        props.setFormState({
            ...props.formState,
            message: `${selectedGroup[0].groupName} group is recognised. But, the room-rates hasn't been set yet.`,
        });
        const reqData = groupReservationDataCleaner(props.groupValues, props.groupRateValues, props.roomTypes, props.inputtedGroupName);
        console.log("\n\t groupReservationDataCleaner: ", reqData);
        if(reqData.length == 0) return customAlert("error", `Please set group rates for ${selectedGroup[0].groupName} `, 6000);
        props.setFormState({
            ...props.formState,
            message: "Enter the required number of rooms to be reserved for each room type.",
            showRoomTypeRatesContainer: true
        });
        props.setFormData({
            ...props.formData,
            groupRatesAndRoomType: reqData
        });
        props.setGroupReservationDetails({
            ...props.groupReservationDetails,
            "Group Email": email
        });
        return email
    } catch (error) {
        props.setFormState({
            ...props.formState,
            showRoomTypeRatesContainer: false,
            message: "Unrecognised group. Have you registered this group from the group settings?",
        });
    }
    // console.log("\n\t groupValues: ", groupValues);
} 


export const onRoomTypeAndNumberOfRooms = (roomTypeAndNumberOfRooms: any[]) => {

}