import React, { AllHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';
import colors from '../../../../assets/colors';
import { useDispatch, useSelector } from 'react-redux';

import "../../../../styles/ModuleSidebar.css";
import "../../../../styles/SidebarStyles.css";


import SettingsSidebar from './SettingsSidebar';
import { callBackendAction } from '../../../../redux/actions/storeActions/appActionsUtilities';
import FrontdeskSidebar from './FrontdeskSidebar';
import BackTrackSidebar from './BackTrackSidebar';

import ReservationsSidebar from './ReservationsSidebar';
import AuditReportsSidebar from './AuditReportsSidebar';
import AppTitle from '../AppTitle';
import StoreSidebar from './StoreSidebar';

interface Props extends AllHTMLAttributes<any> {
    navLinks: string[]
}


const ModuleSidebar = (props: Props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const pathname = location.pathname.split("/");

    // console.log("\n\t ModuleSidebar-nonSplitPathname: ",pathname);
    const store = useSelector((store: any) => store);
    const { userDetails, sidebarStatus } = store;
    const userName  =  userDetails.userDetails ? userDetails.userDetails.userName : "";
    const { showSidebar } = sidebarStatus;
    return (
        <React.Fragment>
            {
                showSidebar &&
                <div  className="mt-4" id="">
                    <div className="mb-2" id="">
                        <nav style={{ display: "flex"}} className="module_sidebar_container" id="">
                            <div className="sidebar-menu mt-0">
                                <div>
                                    <section style={{marginBottom: "-2rem"}}>
                                        <AppTitle />
                                    </section>
                                    <div style={{fontFamily: "serif", textDecoration: "none"}} className="sb-sidenav-menu-heading heading pl-3 pt-4 mt-5">Modules</div>
                                    <NavLink style={{color: colors.ash}} className={`nav-link d-flex my-2 mt-2`} to="/dashboard">
                                        <strong>Home</strong>
                                    </NavLink>
                                    <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
                                    {
                                        !pathname.includes("store") && !pathname.includes("backtrack") && !pathname.includes("settings") && !pathname.includes("frontdesk") && !pathname.includes("reservations") && !pathname.includes("audit-reports") ?
                                        props.navLinks && props.navLinks.map(link => (
                                            <div className="" key={link}>
                                                {
                                                    !pathname[2]?.includes(link.toLowerCase().replaceAll(" ", "-")) &&
                                                    <div className="" key={link}>
                                                        {
                                                            <NavLink onClick={() => dispatch(callBackendAction(false))} 
                                                                style={{flexDirection: "column"}}  
                                                                className={`nav-link collapsed d-flex my-3`} 
                                                                to={`/${pathname[1]}/${link.toLowerCase().replaceAll(" ", "-")}`} >
                                                                <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}} >
                                                                    <span style={{letterSpacing: 2}} className="link_hover">{link}</span>
                                                                </div>
                                                            </NavLink>
                                                        }
                                                        <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
                                                    </div>
                                                }
                                            </div>
                                        ))
                                        :
                                        pathname[1] == "settings" ?
                                        <SettingsSidebar navLinks={props.navLinks} />
                                        :
                                        pathname[1] == "reservations" ?
                                        <ReservationsSidebar navLinks={props.navLinks} />
                                        :
                                        pathname.includes("audit-reports") ?
                                        <AuditReportsSidebar navLinks={props.navLinks} />
                                        :
                                        pathname.includes("backtrack") ?
                                        <BackTrackSidebar navLinks={props.navLinks} />
                                        :
                                        pathname.includes("store") ?
                                        <StoreSidebar navLinks={props.navLinks} />
                                        :
                                        pathname.includes("frontdesk") && 
                                        <FrontdeskSidebar navLinks={props.navLinks} />
                                    }
                                </div>
                            </div>
                            <div style={{justifyContent: "flex-end", flexDirection: "column", backgroundColor: colors.appYellow}}  className="gradient sidebar-footer-container mb-1">
                                {
                                    userName &&
                                    <article style={{flexDirection: "column"}} className='row d-flex text-white pl-4 pt-2 pb-1'>
                                        <span className="small ">User:</span>
                                        <span className="animate__animated animate__heartBeat animate__inifinite">{userName}</span>
                                    </article>
                                }
                            </div>
                        </nav>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default ModuleSidebar;

