import React, { useEffect, useState, Suspense } from 'react'
import ModulesDashboard from '../containers/Modules/ModulesDashboard'



interface Props {
    
}
const Modules = (props: Props) => {
    return (
        <Suspense fallback={<h2>HotelPro</h2>}>
            <ModulesDashboard />
        </Suspense>
    )
}

export default Modules
