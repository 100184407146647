import { Link, NavLink, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { FaAngleRight } from "react-icons/fa"
import colors from '../../assets/colors';

import "../../styles/SidebarStyles.css";
import { callBackendAction, toggleSidebarAction } from '../../redux/actions/storeActions/appActionsUtilities';
import AppTitle from "./Modules/AppTitle";
interface Props {
    
}

const Sidebar = (props: Props) => {
    const appLocation = useLocation();
    // const pathname = appLocation.pathname;
    
    const dispatch = useDispatch();
    const store = useSelector((store: any) => store);
    const { userDetails } = store;
    const { userName, userType }  =  userDetails.userDetails ? userDetails.userDetails : "";
    const navLinks = ["Reservations", "Frontdesk", "House Keeping", "Bar", "Pool Bar", "Restaurant",
                        "Store", "Settings", "Backtrack", "Records", "Audit Reports", "Statistics",];
   
                        
    const linkOptions = (slicedLink: string[]) => (
        slicedLink.map(link => (           
            <div className='sidebar-menu mt-2' key={link}>
                <NavLink  
                    onClick={() => dispatch(callBackendAction(true))} 
                    style={{flexDirection: "column", textDecoration: "none"}}  
                    className="px-2 my-2 nav-menu-item" to={`/${link.toLowerCase().replaceAll(" ", "-")}`} 
                    aria-expanded="false" 
                    aria-controls={`${link.toLowerCase()}`}>
                    <div style={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginRight: "12px"}} >
                        <span style={{letterSpacing: 2}} className="link_hover">{link}</span>
                        <div className="sb-sidenav-collapse-arrow">
                            <FaAngleRight size={25} color={colors.appYellow} />
                        </div>
                    </div>
                </NavLink>
                <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
            </div>
        ))
    );
    const frontdeskAndRecords = [...navLinks.slice(0, 3), navLinks[9]]
    return (
        <div className="d-flex" id="" style={{ backgroundColor: colors.sideNav, zIndex: 10}} >
            <div id="" className="general-sidebar-container ">
                <AppTitle />
                <nav style={{justifyContent: "space-between", backgroundColor: colors.sideNav, display: "flex", flexDirection: "column", height: userType ? userType.toLowerCase().includes("admin") ? undefined : "100vh" : "0vh"}} className=" " id="">
                    <div className="">
                        {
                            userType &&
                            <div className="nav mt-4 d-flex">
                                <div style={{fontFamily: "serif"}} className="sb-sidenav-menu-heading heading pl-2 pt-3">Modules</div>
                                {
                                    userType.toLowerCase().includes ("frontdesk") ?
                                    linkOptions(frontdeskAndRecords)
                                    :
                                    userType.toLowerCase().includes ("keeping") ?  //for house - keepers
                                    linkOptions(navLinks.slice(2, 3))
                                    :
                                    (userType.toLowerCase().includes("store") || userType.toLowerCase().includes("chef")) ?
                                    linkOptions(navLinks.slice(6, 7))
                                    :
                                    userType.toLowerCase().includes("f&b manager") ?
                                    linkOptions([...navLinks.slice(3, 6), navLinks[7]])
                                    :
                                    userType.toLowerCase() === "night auditor" ?
                                    linkOptions([...frontdeskAndRecords, ...navLinks.slice(3, 6)])
                                    :
                                    (userType.toLowerCase() === "auditors" || userType.toLowerCase() === "accounts") ?
                                    linkOptions([...navLinks.slice(9, 11), ...navLinks.slice(3, 6)])
                                    :
                                    userType.toLowerCase().includes("restaurant") ?
                                    linkOptions(navLinks.slice(5, 6))
                                    :
                                    userType.toLowerCase().includes("pool") ?
                                    linkOptions(navLinks.slice(4, 5))
                                    :
                                    userType.toLowerCase().includes("bar") ?
                                    linkOptions(navLinks.slice(3, 4))
                                    :
                                    userType.toLowerCase().includes("developer") ?
                                    linkOptions(navLinks.slice(0, 9))
                                    :
                                    userType.toLowerCase().includes("i.t") || userType.toLowerCase().includes("admin") ?
                                    linkOptions(navLinks)
                                    :
                                    []
                                }
                            </div>
                        }
                    </div>
                    <div style={{backgroundColor: colors.appYellow}} className="text-white py-3 pl-2 main-sidebar-gradient sidebar-footer-container">
                        <div className="small">User:</div>
                        {
                            userName &&
                            <span className="title animate__animated animate__swing animate__infinite">{userName}</span>
                        }
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Sidebar;
