import React from 'react'

interface Props {
    summaryHeader: any[]
    tableData: []
};

const StatisticsDashboard = (props: Props) => {
    return (
        <div>StatisticsDashboard</div>
    )
}

export default StatisticsDashboard