import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { showProcessingGifAction } from '../../redux/actions/storeActions/appActionsUtilities';

interface Props {
    message?: string
}

const ProcessingGif = (props: Props) => {
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count + 15);
        }, 500);
        if(count > 100){
            dispatch(showProcessingGifAction(false)) // hide the gif at count 100
            return () => clearInterval(interval)
        }
    }, [count]);
    return (
        <div className="bg-black">
            <div className="row d-flex">
                <div style={{flexDirection: "column", borderRadius: "7px"}} className="bg-black text-center">
                    <img width="80%" src={require("../../assets/img/processing.gif")} /> <br />
                    <span style={{fontSize: "20px", fontStyle: 'italic', color: "royalblue", }} className="text-center font-weight-bold">{count}%</span>
                    <article className="font-weight-bold" style={{fontSize: "23px", fontStyle: 'italic', color: "skyblue"}}>
                        <span className="animate__animated animate__headShake animate__infinite">{props.message ? props.message : 'Hotel-Pro is processing your data'}</span> <br/>
                        <span className="animate__animated animate__pulse animate__infinite">please wait...</span>
                    </article>
                </div> 
            </div>
        </div>
    )
}

export default ProcessingGif;
