import React, { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import colors from '../../assets/colors';
import greeter from '../../utilities/greeter';
import { toggleSidebarAction } from '../../redux/actions/storeActions/appActionsUtilities';
import { modulesList } from './Modules/ModuleSummary/SummaryData';
import { FaBars, FaChevronCircleDown, FaChevronCircleLeft, FaChevronCircleRight, FaUser } from "react-icons/fa"
import pathResolver from '../../routes/pathResolver';

import { FaSmileWink } from "react-icons/fa"
import "../../styles/HomeScreen.css"
interface Props {
    
}


const Header = (props: Props) => {
    const store = useSelector((store: any) => store);
    const { userDetails, navbarStatus, sidebarStatus } = store;
    const {firstName, shiftStatus, hotelDate, lastName, userType } =  userDetails.userDetails ? userDetails.userDetails : "";
    const licenseDetails =  userDetails.userDetails ? userDetails.userDetails.licenseDetails : "";

    const appLocation = useLocation();
    const pathname = appLocation.pathname;
    // console.log("|n\t Header-hotelDate: ", hotelDate)
    const subPath = pathname.split("/")[2];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const headerActions = [
        "Update profile", 
        "Create user", 
        // "Records", 
        "Logout"
    ];
    const acceptedRoles = [process.env.REACT_APP_COMPANY_DEVELOPER_USER_TYPE, "Super Admin", "Admin", "Developer"];
    const pathSegments = pathname.split("/");
    
    const { showSidebar } = sidebarStatus;
    const { showNavbar } = navbarStatus;
    // console.log("\n\t Header - pathSegments[5] ", pathSegments[5])
    // console.log("\n\t Header - userDetails ", userType)
    
    useEffect(() => {
        //========= This section of code helps to re-route the app, if a submenu clicked, is in the modules' list========
        const submenuClickedLikeRoute = pathSegments[2] && pathSegments[2].toLocaleLowerCase().replaceAll(" ", "-") 
        // console.log("|n\t Header-submenuClickedLikeRoute: ", submenuClickedLikeRoute)
        modulesList.includes(`/${submenuClickedLikeRoute}`) && navigate(`/${submenuClickedLikeRoute}`);
        //==================================================================================================================
        pathResolver(pathSegments, pathname, navigate, pathSegments[1]) // resolve sidebar path correctly

        if(pathname.includes('store') && !subPath){
            navigate("/store/sections")
        }else if(pathname.includes('records') && !subPath){
            navigate("/records/financial-transactions")
        };
    }, [pathname]);
    const access = ["create", "update", "records"]

    
    return (
        <div style={{width: showSidebar ? "90vw" : "100vw", background: !showNavbar ? 'black' : undefined, paddingRight: "3rem"}} className="header-container">
            <nav style={{height: "10vh", }} className="navbar navbar-expand " >
                <section  className='ml-5 row'>
                    {
                        showNavbar && 
                        <span onClick={() => dispatch(toggleSidebarAction(!showSidebar))} className="d-flex btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle">
                            {
                                showSidebar ?
                                <FaChevronCircleLeft className='ml-5 box_shadow mt-1' color={licenseDetails.includes("expiration") ? colors.danger : colors.appYellow} size={23} />
                                :
                                <FaChevronCircleRight className='ml-5 box_shadow mt-1' color={licenseDetails.includes("expiration") ? colors.danger : colors.appYellow} size={23} />
                            }
                        </span>
                    }
                    {
                        showNavbar && licenseDetails &&
                        <React.Fragment>
                            <span style={{borderRadius: "3px", alignItems: "center", backgroundColor: licenseDetails.includes("expiration") ? colors.danger : colors.appYellow}} className={`box_shadow license_style p-1 px-2 pt-2`}> 
                                <span style={{fontWeight: "bold", color: licenseDetails.includes("expiration") ? colors.white : colors.sideNav, fontSize: licenseDetails.includes("expiration") ? "20px" : "13px"}} className={`"p-2" ${licenseDetails.includes("expiration") ? "animate__animated animate__infinite animate__flash animate__slower 5s px-3 mt-4" : undefined}` }>{licenseDetails}</span>
                            </span>
                            <span style={{flexDirection: "column", borderRadius: "3px", alignItems: "center",  backgroundColor: colors.appYellow}} className={`box_shadow license_style p-1 px-2 mx-2 d-flex`}> 
                                <span>Hotel Date:</span>
                                <span className={`"p-2"` }>{hotelDate}</span>
                            </span>
                            {/* {
                                headerUser &&
                                (headerUser.toLowerCase().includes("admin") || headerUser.toLowerCase().includes("store")) &&
                                <span style={{flexDirection: "column", borderRadius: "3px", alignItems: "center",  backgroundColor: colors.appYellow}} className={`box_shadow license_style p-1 px-2 mx-2 d-flex`}> 
                                    <span>HotelPro Info:</span>
                                    <span className={`p-2` }>The Store module is fully operational and functional.</span>
                                </span>
                            } */}
                        </React.Fragment>
                    }
                    {/* <FaBars color={colors.appYellow} size={23} /> */}
                </section>
                <div className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0"></div>
                {
                    showNavbar && 
                    <ul className="navbar-nav ml-auto ml-md-0 mt-2">
                        {
                            firstName &&
                            <span className="mt-1 mx-1 row" style={{fontWeight: "600", fontSize: "13px", textAlign: "center", flexDirection: "column"}}>
                                <span style={{color: colors.ash}}>{greeter()}</span>
                                <span style={{color: colors.appYellow}}>{firstName.concat(" ", lastName)}</span>
                                <p style={{color: colors.appYellow}} className='font-weight-bold'>{userType}</p>
                            </span>
                        }
                        <li className="nav-item dropdown mt-2">
                            <Link style={{flexDirection: "row", display: "flex"}} className="" id="userDropdown" to="#" role="button" data-toggle="dropdown" aria-modal aria-haspopup="true" aria-expanded="false">
                                <span>
                                    <FaUser className='mt-3' size={25} color={colors.appYellow} />
                                    <FaChevronCircleDown color={colors.appYellow} className='ml-1 mt-2' size={12} />
                                </span>
                                <span style={{}}>
                                    {
                                        new Date().getDay() === 5 &&
                                        <span className=' mx-2' style={{flexDirection: "column", display: "flex", color: colors.ash}}>
                                            Thank God it's Friday
                                            <FaSmileWink style={{ alignSelf: "center", }} className='mb-1' size={20} color={colors.appYellow} />
                                        </span>
                                    }
                                </span>
                            </Link>
                            <div
                                // style={{backgroundColor: "cornflowerblue"}}
                                className={`dropdown-menu dropdown-menu-right sb-sidenav-menu`} aria-labelledby="userDropdown">
                                {
                                    userDetails &&
                                    headerActions.map(action => (
                                        <div key={action}>
                                            {
                                                (action.toLowerCase().includes('create') || action.toLowerCase().includes('update')) && acceptedRoles.includes(userType) ?
                                                <Link
                                                    data-toggle="modal"
                                                    data-target="#modal"
                                                    className="dropdown-item button"
                                                    to={`/settings/user-management/users`}>
                                                        {action}
                                                </Link>
                                                :
                                                <Link className="dropdown-item button" to={`/${action.replaceAll(" ", "-").toLowerCase()}`}>{action}</Link>
                                            }
                                            <div className="dropdown-divider"></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </li>
                    </ul>
                }
            </nav>
        </div>
    )
}


export default Header;
