import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { showProcessingGifAction } from '../../redux/actions/storeActions/appActionsUtilities';
import ProcessingGif from './ProcessingGif';
import "../../styles/ModalStyles.css";
import Confirm from './Confirm';
import { titledTextJoiner } from '../../utilities/strings';
import { inputValidator } from '../../utilities/security';
import { customAlert } from '../../utilities/customAlert';
import colors from '../../assets/colors';
import { todaysDate } from '../../assets/events/individualReservations';
import GroupCheckInForm from './Forms/GroupCheckInForm';
import WarningModal from './WarningModal';



interface Props {
    type: "form" | "confirm" | "processing" | "groupCheckInForm" | "warning" | "payment" | undefined
    formFields?: string[]
    formFieldType?: string[]
    title?: string 
    handleAction: () => any
    placeholders?: string[]
    selectOption?: any
    radioOptions?: string[]
    serverSelectOption?: any
    pathname: string
    formInput?: Object
    onChange: (field: string, value: string, file?: any) => void
    numberOfFields: number 
    // fieldNames: string[]
    fieldValues?: string[]
    editingDetails?: {}
    hideConfirmModalButtons?: boolean
    cancelButtonText?: string
    continueButtonText?: string
    stopFormModalSubmission?: boolean
    formModalSaveButtonName?: string
    optionalFormText?: string
    readOnly?: boolean[]
    editFlag?: boolean // to notify the modal dialog that you're editing or updating
    groupCheckInFormData?: any
    processingMessage?: string // the preferred processing message

    // printRegCard?: boolean
    // setPrintRegCard: Function
    setGroupCheckInData: Function
    fAndBData?: any
    defaultSelectOption?: {}
    defaultValue?: any
    minDate?: string
    maxDate?: string
    previewData?: boolean
} 


const Modal = (props: Props) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    // console.log("\n\t Modal - props.formInput...", props.formInput)
    // console.log("\n\t Modal - props.serverSelectOption...", props.serverSelectOption)
    const handleFormModalSaveClick = () => {
        // console.log("\n\t handleFormModalSaveClick...")
        const validatorFields: any = (props.formFields as string[]).map(field => titledTextJoiner(field));
        // console.log("\n\t handleFormModalSaveClick-validatorFields: ", validatorFields)
        // console.log("\n\t Modal - props.formInput.2..", props.formInput)
        const validation = inputValidator(props.formInput as Object, props.numberOfFields, validatorFields as string[]);
        console.log("\n\t validation: ",validation)
        if(typeof(validation) === "string"){
            return customAlert("error", validation as string);
        };
        dispatch(showProcessingGifAction(true));
        setTimeout(async () => {
            const serverResponse = await props.handleAction();
            // console.log("\n\t Modal-serverResponse: ", serverResponse)
            if(typeof(serverResponse) === "string"){
                customAlert("error", serverResponse, 5000);
            }else{
                customAlert("success", serverResponse.message, 5000);
            };
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        }, 4000);
    }
    // console.log("\n\t Modal-props.defaultValue...", props.type)
  
    return (
        <div className={`modal fade ${props.type === "groupCheckInForm" ? 'animate__animated animate__swing' : props.type === "confirm" ? 'animate__animated animate__swing' : props.type === "form" ? 'animate__animated animate__pulse' : 'animate__animated animate__shake'}`} tabIndex={-1} id="modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                {
                    props.type === "form" ?
                    <form>
                        <div className="modal-content">
                            <div style={{backgroundColor: colors.appYellow}} className="modal-header float-right">
                                <h5 className="modal-title">{props.title && props.title.toLowerCase().includes("manage") ? "Create User" : props.title}</h5>
                            </div>
                            <div className="modal-body">
                                {
                                    props.formFields && props.formFields.map((field, index) => (
                                        <React.Fragment>
                                            {
                                                field &&
                                                <div key={field} className="form-group">
                                                    {
                                                        field &&
                                                        <label className="small mb-1">{ field.toLowerCase() === "role" ? `${field} (Department)` : field}:</label>
                                                    }
                                                    {
                                                        props.formFieldType && props.formFieldType[index] !== "select" && props.formFieldType[index] !== "textarea"  && props.formFieldType[index] !== "radio" ?
                                                        <span className={`d-flex align-items-center ${field.toLowerCase() === "password" ? 'form-control py-4' : undefined}`}>
                                                            <input 
                                                                className={`bg-white form-control ${field} `} 
                                                                name={`${field.toLowerCase().replaceAll(" ", "-")}`} 
                                                                min={props.formFieldType[index] === "number" ? 1 : props.formFieldType[index] === "date" ? (props.pathname.includes('checked-in/group') || props.pathname.includes('checked-in/individual') || props.pathname.includes('backtrack')) ? props.minDate : todaysDate() : props.formFieldType[index] === "datetime-local" ? todaysDate() : undefined}
                                                                max={props.formFieldType[index] === "date" ? (props.pathname?.includes('checked-in/group') || props.pathname.includes('checked-in/individual') || props.pathname?.includes('backtrack')) ? props.maxDate : undefined : undefined}
                                                                type={field.toLowerCase().includes("password") && visible ? undefined : props.formFieldType[index]}
                                                                // type=""
                                                                onChange={e => props.onChange(field, e.target.value, e.target.files)}
                                                                aria-describedby="emailHelp" 
                                                                readOnly={props.readOnly && props.readOnly[index] ? true : false}
                                                                placeholder={props.placeholders && props.placeholders[index]}
                                                                style={{border: field.toLowerCase() === "password" ? 'none' : undefined}}
                                                                id={field.toLowerCase().replaceAll(" ", "-")}
                                                                defaultValue={props.defaultValue || props.editFlag || props.title?.toLowerCase().includes('edit') ? (props.defaultValue || props.formInput as any)[titledTextJoiner(field)] : undefined }
                                                            />
                                                            {
                                                                field.toLowerCase() === "password" &&
                                                                <span onClick={() => setVisible(!visible)} className="pl-2"  style={{cursor: "pointer"}}>
                                                                    {
                                                                        !visible ?
                                                                        <FaEyeSlash size={30} />
                                                                        :
                                                                        <FaEye size={30}/>
                                                                    }
                                                                </span>
                                                            }
                                                        </span>
                                                        :
                                                        props.formFieldType && props.formFieldType[index] === "textarea" ?
                                                        <textarea 
                                                            placeholder={props.placeholders && props.placeholders[index]} 
                                                            onChange={e => props.onChange(field, e.target.value)} 
                                                            style={{resize: "none"}} 
                                                            className="form-control" 
                                                            defaultValue={props.defaultValue || props.editFlag || props.title?.toLowerCase().includes('edit') ? (props.defaultValue || props.formInput as any)[titledTextJoiner(field)] : undefined }
                                                            cols={35} rows={3}>
                                                        </textarea>
                                                        :
                                                        props.formFieldType && props.formFieldType[index] === "radio" ?
                                                        <div className="d-flex row justify-content-center align-items-center">
                                                            {
                                                                <article className="mt-2" style={{width: "100%"}}>
                                                                    <span className="row justify-content-center">
                                                                        {
                                                                            props.radioOptions?.map((option: any) => (
                                                                                <span className="mx-3" key={option}>
                                                                                    <input 
                                                                                        value={option} 
                                                                                        onChange={e => props.onChange(field, e.target.value)} 
                                                                                        name="paymentMethod" 
                                                                                        style={{display: "flex", justifyContent: "center", width: "100%", height: "1.7em"}} type="radio" 
                                                                                    /> 
                                                                                    {option}
                                                                                </span>
                                                                            ))
                                                                        }
                                                                    </span>
                                                                </article>
                                                            }
                                                        </div>
                                                        :
                                                        <select 
                                                            onChange={e => props.onChange(field, e.target.value)}
                                                            className="form-select form-control">
                                                            <option>{props.defaultSelectOption ? (props.defaultSelectOption as any)[field] : `Select ${field}`}</option>
                                                            {
                                                                props.selectOption && props.selectOption.length > 0  ? 
                                                                props.selectOption.map((option: any) => (
                                                                    <option key={option as string}>
                                                                        {option}
                                                                    </option>
                                                                ))
                                                                :
                                                                props.serverSelectOption && Object.keys(props.serverSelectOption).map((key, index) => (
                                                                    key === titledTextJoiner(field) &&
                                                                    Object.values(props.serverSelectOption[key]).map((item: any) => (
                                                                        <option key={item}>{item}</option>
                                                                    ))
                                                                ))
                                                            }
                                                        </select>
                                                    }
                                                </div>
                                            }
                                        </React.Fragment>
                                    ))
                                }
                                </div>
                                {
                                    props.optionalFormText && props.optionalFormText &&
                                    <p className="text-center text-danger small font-weight-bold">{props.optionalFormText && props.optionalFormText}</p>
                                }
                                <div  style={{backgroundColor: "tomato"}}  className="modal-footer btn-container">
                                    <button 
                                        data-toggle="modal"
                                        data-target="#modal"
                                        onClick={!props.stopFormModalSubmission ? () => handleFormModalSaveClick() : () => props.handleAction()} 
                                        data-dismiss="modal"
                                        type="button" 
                                        id="save-button"
                                        className="btn btn-info">
                                            {props.formModalSaveButtonName ? props.formModalSaveButtonName : "Save"} <span></span><span></span><span></span> <span></span>
                                    </button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                    </form>
                    :
                    props.type === "processing" ?
                    <ProcessingGif 
                        message={props.processingMessage}
                    />
                    :
                    props.type === "groupCheckInForm" ?
                    <div>
                        <div className="row d-flex mt-2"></div>
                        <GroupCheckInForm 
                            checkInData={props.groupCheckInFormData}
                            onChange={props.onChange}
                            // printRegCard={props.printRegCard as boolean}
                            // setPrintRegCard={props.setPrintRegCard}
                            setGroupCheckInData={props.setGroupCheckInData}
                        />
                    </div>
                    :
                    <div>
                        <div className="row d-flex mt-2"></div>
                        <Confirm 
                            message={props.title as string}
                            formFields={props.formFields}
                            fieldValues={props.fieldValues}
                            actionForContinue={() => props.handleAction()}
                            cancelButtonText={props.cancelButtonText}
                            continueButtonText={props.continueButtonText}
                            fAndBData={props.fAndBData}
                            hideConfirmModalButtons={props.hideConfirmModalButtons}
                            previewData={!props.previewData ? false : true}
                            // pathnameForContinueAction={props.pathname as string}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Modal
