import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import colors from '../../../../assets/colors'
import { callBackendAction } from '../../../../redux/actions/storeActions/appActionsUtilities'
import { handleSubMenus, reportsSidebarNavlinksSubMenu, } from './SidebarNavLinks'

interface Props {
    navLinks: string[]
}

const AuditReportsSidebar = (props: Props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const pathname = location.pathname;
    const [auditMenu, setAuditMenu] = useState({
        toggleMenuState: {}as any
    })
    useEffect(() => {
        !pathname.includes("audit-reports") && dispatch(callBackendAction(false));
        props.navLinks.map(link => {
            auditMenu.toggleMenuState[link] = false;
        })
        setAuditMenu({...auditMenu})
    }, []);
    const handleStateSubMenus = (toggledLink: string) => {
        handleSubMenus({
            state: auditMenu,
            stateSetter: setAuditMenu,
            toggledLink
        })
    };
    return (
        <div>
            {
                props.navLinks && props.navLinks.map((link, index) => (
                    <div className="" key={link}>
                        <NavLink 
                            style={{flexDirection: "column"}}  
                            className={`nav-link collapsed d-flex`} data-toggle={(link.toLowerCase().includes("credit-balance") || link.toLowerCase().includes("city") || link.toLowerCase().includes("guest")) ? "collapse" : undefined} data-target={`#${link.toLowerCase().replaceAll(" ", "-")}`} aria-expanded="false" aria-controls="accounts"
                            to={(link.toLowerCase().includes("credit-balance") || link.toLowerCase().includes("city") || link.toLowerCase().includes("guest")) ? "#" : `${link.toLowerCase().includes('manager') ? link.toLowerCase().replaceAll(" ", "-").replace("'", "") : link.toLowerCase().replaceAll(" ", "-")}`}
                            key={link}
                            onClick={() => handleStateSubMenus(link)}
                            >
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}} >
                                <span style={{letterSpacing: 2}} className="link_hover">{link}</span>
                                {
                                    (link.toLowerCase().includes("credit-balance") || link.toLowerCase().includes("city") || link.toLowerCase().includes("guest")) && 
                                    auditMenu.toggleMenuState[link] ?
                                    <FaAngleRight size={25} color={colors.appYellow}  />
                                    :
                                    (link.toLowerCase().includes("credit-balance") || link.toLowerCase().includes("city") || link.toLowerCase().includes("guest")) && 
                                    <FaAngleDown size={25} color={colors.appYellow}  />

                                }
                            </div>
                        </NavLink>
                        <div className={(link.toLowerCase().includes("credit-balance") || link.toLowerCase().includes("city") || link.toLowerCase().includes("guest")) ? "collapse ml-3" : undefined} id={`${link.toLowerCase().replaceAll(" ", "-")}`} aria-labelledby="headingOne" >
                            <nav className="sb-sidenav-menu-nested nav">
                                {
                                    Object.entries(reportsSidebarNavlinksSubMenu).map((entery, secIndex: number) => (
                                        <span className="px-2" style={{width: "100%"}} key={secIndex}>
                                            {
                                                entery[0].toLowerCase() === link.toLowerCase() && 
                                                <span>
                                                    {
                                                         entery[1].map(value => (
                                                            <Link 
                                                                key={value}
                                                                style={{color: colors.appYellow, flexDirection: "column", textDecoration: "none"}} 
                                                                className="" 
                                                                to={`${pathname}/${link.toLowerCase().replaceAll(" ", "-")}/${value.toLowerCase().replaceAll(" ", "-")}`}>
                                                                {value}
                                                                <hr style={{width: "100%", backgroundColor: colors.warning}} />
                                                            </Link>
                                                        ))
                                                    }
                                                </span>
                                            }
                                        </span>
                                    ))
                                }
                            </nav>
                        </div>
                        <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
                    </div>
                ))
            }
        </div>
    )
}

export default AuditReportsSidebar;
