import { ActionTypes, ModulesDataInterface } from "../../actions/actionTypes";
import { StoreInterface } from "../../store";



const modulesDataReducer = (store: StoreInterface,  action: ModulesDataInterface) => {
    switch(action.type){
        case ActionTypes.MODULES_DATA:
            // console.log("\n\t modulesDataReducer: ", action.payload);
            store.modulesData = action.payload;
            return ({
                ...store
            });
        default:
            return ({
                ...store
            })
    }
}

export default modulesDataReducer;