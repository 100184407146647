import { UserDetailsInterface, ActionTypes } from "../actionTypes"
import { UserDetails } from "../../../types";

const userDetailsAction = (userDetails: UserDetails) => {
    // console.log("\n\t User details action: ", userDetails);
    return<UserDetailsInterface>({
        type: ActionTypes.USER_DETAILS,
        payload: userDetails
    })
}

export default userDetailsAction;