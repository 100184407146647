
export const customAlert = (level: "success" | "error" | "warning", message: string, time: number = 3000) => {
    const mockup = `<div style="background-color: ${level === "success" ? "#fff" : level === "error" ? "#8B0000" : "#f4f4f4"}" class="animate__animated animate__pulse animate__infinite custom-alert ">
                        ${
                            level === "success" ? 
                            `<img style="width: 10vw; height: 17vh" src=${require("../assets/img/success.gif")} />`
                            :
                            level === "error" ?
                            `<img style="width: 10vw; height: 17vh" src=${require('../assets/img/warning-gif.gif')} />`
                            :
                            `<img style="width: 10vw; height: 17vh" src=${require("../assets/img/error.gif")} />`
                        }
                        <h3 style="font-size: 17px; color: ${level === "success" ? "green" : level === "warning" ? "#ff0000" : "#fff"}" class='text-sm font-weight-bold'>${message}</h3>
                    </div>`;
    (document as any).querySelector('body').insertAdjacentHTML('afterbegin', mockup);
    window.setTimeout(() => hideCustomAlert(), time);
};

const hideCustomAlert = () => {
    const alert = document.querySelector('.custom-alert');
    if (alert) (alert as any).parentElement.removeChild(alert);
};