import React, { useEffect, useState } from 'react'
import { FaChevronCircleLeft } from 'react-icons/fa'
import { tableDataCleaner } from '../../../utilities/strings'

import { useDispatch, useSelector } from 'react-redux';
import Gifs from '../../Gifs';
import "../../../styles/GuestsBillStyles.css"
import { showConfirmModalAction, showFormModalAction, toggleFooterAction } from '../../../redux/actions/storeActions/appActionsUtilities';
import { givenDateAndCurrentTime } from 'date-fran';
import axiosHelper from '../../../utilities/axiosHelper';
import { Link, useLocation } from 'react-router-dom';
import Modal from '../Modal';
import { handleFrontdeskActions } from '../Modules/Tables/tableActions/tableActionsOne';


interface Props {
    billData: any
    guestBillTitle: string
    guestBills?: any[],
    pathname: string
    // state: any
    toggleBillVisibilty: Function
}

export const originsToHideTaxData = ['bon-platinum'];
const GuestBill = (props: Props) => {
    // console.log("\n\t In guest bill...", props.billData)
    console.log("\n\t billDataId: ", props.billData)
    const dispatch = useDispatch();
    const guestBills = []as any[];
    const accomodationDetailsForInHouse = ["Room Number", "Check-in Date", "Departure Date", ];
    const accomodationDetailsForPMs = ["Date Of Creation", "Folio Count"];
    const transactionDetails =  ["Description", "Location", "Date", "Charges", "Credits"];
    const authorizedUser = useSelector((store: any) => store.userDetails.userDetails);
    const origin = window.location.origin.split("//")[1].split(".")[0];
    const appLocation = useLocation();
    const pathname = appLocation.pathname;
    const store = useSelector((store: any) => store);
    const { confirmModal } = store
    const { showConfirm } = confirmModal;
    // console.log("\n\t In guest bill...", guestBills && guestBills)
    const [guestBillState, setGuestBillState] = useState({
        reRenderCount: 0,
        summedCharges: 0,
        summedCredits: 0,
        showFolioDetails: false,
        summedVAT: 0,
        summedServiceCharge: 0,
        summedConsumptionCharge: 0,
        accommodationBills: []as any[],
        otherBills: []as any[],
        accommodationBillsTotal: 0,
        billData: false,
        otherBillsTotal: 0,
        guestBills: []as any[],
        splitted: false,
        numberOfFields: 0,
        previewData: false,
        billDataToPreview: {}as any,
        formFields: []as string[],
        confirmModalTitle: "",
        fieldValues: []as any,
        showOnlyAccomodationBills: true,
    });
    const billActions = ["Print", !guestBillState.splitted ? "Split" : "Unsplit", guestBillState.splitted ? guestBillState.showOnlyAccomodationBills ? "Other" : "Accommodation" : null]
    const footerArray1 = ["", "", "Total",  guestBillState.summedCharges, guestBillState.summedCredits];
    const footerArray2 = ["Balance", "Net Amount", "VAT", "Service Charge", "Consumption Charge", "Total (VAT inclusive)"]
    const footerArrayExcludingTax = ["Balance", "Net Amount", "Total (VAT inclusive)"]
    const footerArray3 = [ 
        guestBillState.summedCharges - guestBillState.summedCredits, 
        guestBillState.summedCharges -(guestBillState.summedConsumptionCharge + guestBillState.summedServiceCharge + guestBillState.summedVAT), 
        guestBillState.summedVAT, 
        guestBillState.summedServiceCharge, 
        guestBillState.summedConsumptionCharge, 
        guestBillState.summedCharges
    ];
    const billActionHandler = (action: string) => {
        if(action === "print"){
            return window.print();
        }else if(action === "split" || action === "accommodation"){
            guestBillState.splitted = true;
            const accommodationBills = guestBillState.guestBills.filter((bill: any) => !bill.description.toLowerCase().includes("f&b") && !bill.description.toLowerCase().includes("charge to room") && !bill.description.toLowerCase().includes("bar") && !bill.description.toLowerCase().includes("restaurant"))
            guestBillState.summedCharges = 0;
            guestBillState.summedCredits = 0;
            accommodationBills.forEach((accBills: any) => {
                console.log("\n\t accBills: ", accBills)
                guestBillState.summedCharges += accBills.charges || 0;
                guestBillState.summedCredits += accBills.credits || 0;
            })
            guestBillState.showOnlyAccomodationBills = true;
            guestBillState.accommodationBills = accommodationBills;
        }else if(action === "unsplit"){
            guestBillState.splitted = false;
            defaulBillValues();
        }else if(action === "other"){
            guestBillState.summedCharges = 0;
            guestBillState.summedCredits = 0;
            const otherBills = guestBillState.guestBills.filter((bill: any) => (bill.description.toLowerCase().includes("f&b") || bill.description.toLowerCase().includes("charge to room") || bill.description.toLowerCase().includes("bar") || bill.description.toLowerCase().includes("restaurant")))
            otherBills.forEach((othBills: any) => {
                guestBillState.summedCharges += othBills.charges || 0;
                guestBillState.summedCredits += othBills.credits || 0;
            });
            guestBillState.showOnlyAccomodationBills = false
            guestBillState.otherBills = otherBills;
            // otherBillsTotal: 0,
        };
        return setGuestBillState({...guestBillState});
    };
    const defaulBillValues = () => {
        guestBillState.summedCharges = 0;
        guestBillState.summedCredits = 0;
        guestBillState.guestBills.forEach((bill: any) => {
            // console.log("\n\t Guest's bill: ", bill);
            guestBillState.summedCharges += bill.charges || 0;
            guestBillState.summedCredits += bill.credits || 0;
            guestBillState.summedVAT += bill.vatCharge || 0;
            guestBillState.summedConsumptionCharge += bill.consumptionCharge || 0;
            guestBillState.summedServiceCharge += bill.serviceCharge || 0;
        });
    }
    useEffect(() => {
        dispatch(toggleFooterAction(false));
        if(guestBillState.reRenderCount < 1){
            if(guestBillState.guestBills.length > 0){
                // console.log("\n\t guestBillState.guestBills > 0:  ", guestBillState.guestBills)
                defaulBillValues();
                setGuestBillState({
                    ...guestBillState,
                    reRenderCount: guestBillState.reRenderCount ++
                });
            }else{
                (async() => {
                    // console.log("\n\t props.pathname: ", props.pathname)
                    const guestbillResponse = await axiosHelper({
                        httpVerb: "get",
                        routeName: `/audit-reports/${props.pathname.includes('account') ? 'pm-account/guest-bills' : 'get-individual-guestbill'}/${props.billData.guestBillId}`
                    });
                    if(guestbillResponse.data){
                        setGuestBillState({
                            ...guestBillState,
                            guestBills: guestbillResponse.data[0].bills,
                            billData: true
                        })
                        console.log("\n\t guestbillResponse: ", guestbillResponse.data)
                    }
                })();
            }
        }
    }, [guestBillState.guestBills, showConfirm]);
    // console.log("\n\t guestBillState.guestBills:  ", guestBillState.guestBills)
    // console.log("\n\t accommodationBills: ", guestBillState.accommodationBills);
    const folioDetails = (billDetails: any) => {
        console.log("\n\t showFolioData.......")
        dispatch(showConfirmModalAction(true));
        setGuestBillState({
            ...guestBillState,
            numberOfFields: Object.keys(billDetails).length,
            showFolioDetails: true,
            formFields: Object.keys(billDetails).slice(0, 6),
            fieldValues: Object.values(billDetails).slice(0, 6),
            billDataToPreview: [billDetails],
            confirmModalTitle: "Folio Details",
            previewData: true,
        });
    };
    console.log("\n\t showConfirm: ", showConfirm)
    return (
        <div className="">
            {
                showConfirm && guestBillState.showFolioDetails &&
                <Modal
                    type="confirm"
                    onChange={() => ""}
                    numberOfFields={guestBillState.numberOfFields}
                    formFields={guestBillState.formFields}
                    pathname={pathname}
                    fieldValues={guestBillState.fieldValues || []}
                    handleAction={() => undefined}
                    previewData={guestBillState.previewData}
                    hideConfirmModalButtons={true}
                    title={guestBillState.confirmModalTitle}
                    cancelButtonText="No"
                    continueButtonText="Yes"
                    // fAndBData={guestBillState.billDataToPreview}
                    setGroupCheckInData={() => undefined}
                />
            }
            <section className="mt-3">
                <button 
                    onClick={() => props.toggleBillVisibilty(guestBillState.guestBills)}
                    className="mt-5 btn btn-secondary bill-close-btn">
                    <FaChevronCircleLeft size={23} className="mx-2" />
                    Close Bill
                </button>
                <article className='guest-bill-gif-logo-container'>
                    <span className='text-center'>
                        <Gifs gifName='hotel-logo' />
                    </span>
                    <span className="font-weight-bold">
                        <p className="text-center">{props.guestBillTitle}</p>
                        <p className="text-center">{new Date().toDateString()}</p>
                    </span>
                </article>
                <hr />
            </section>
            <div className="guest-bill-tables-container">
                <section className="guest-bill-accomodation-table">
                    <p className="section-text font-weight-bold">{props.pathname.includes("account") ? "PM" : "Accomodation"} Details:</p>
                    <table className="guest-bill-table-table table table-bordered table-hover table-striped ">
                        <thead>
                            {
                                guestBillState.billData && (props.pathname.includes("account") ? accomodationDetailsForPMs : accomodationDetailsForInHouse).map((detail, index) => (
                                    <tr key={detail}>
                                        <td>{detail}</td>
                                        <td>{tableDataCleaner(detail, props.billData)}</td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <td>Teller</td>
                                <td>{authorizedUser && authorizedUser.firstName.concat(" ", authorizedUser.lastName)}</td>
                            </tr>
                        </thead>
                    </table>
                </section>
                <hr />
                <section className="guest-bill-transaction-table">
                    <p className="font-weight-bold">Transaction Details:</p>
                    {
                        !guestBillState.splitted ?
                        <table className="guest-bill-table-table table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    {
                                        guestBillState.billData && transactionDetails.map(detail => (
                                                <th key={detail}>{detail}</th>
                                            ))
                                        }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    guestBills && guestBillState.guestBills.map((bill: any, billIndex: number) => (
                                        <tr key={billIndex}>
                                            {
                                                bill.description &&
                                                transactionDetails.map((title, titleIndex: number) => (
                                                    <td key={bill.folioId}>
                                                        {
                                                            titleIndex === 0 ? 
                                                            <Link data-toggle="modal" data-target="#modal" onClick={() => folioDetails(bill)} to="#">{tableDataCleaner(title, bill)}</Link> 
                                                            : 
                                                            tableDataCleaner(title, bill)
                                                        }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                {
                                    guestBillState.billData && 
                                    <tr>
                                        {
                                            footerArray1.map((val, index) => (
                                                <td className="font-weight-bold" key={`${index}-${val}`}>{val.toLocaleString() || ""}</td>
                                            ))
                                        }
                                    </tr>
                                }
                            </tfoot>
                        </table>
                        :
                        <React.Fragment>
                            {
                                guestBillState.showOnlyAccomodationBills ?
                                <React.Fragment>
                                    <p className="font-weight-bold">Accommodation Bills:</p>
                                    <table className="guest-bill-table-table table table-bordered table-hover table-striped">
                                        <tbody>
                                            {
                                                guestBillState.billData && guestBillState.accommodationBills.map((bill: any, billIndex: number) => (
                                                    <tr key={`${bill.folioId}-${bill.description}`}>
                                                        {
                                                            bill.description &&
                                                            transactionDetails.map((title, titleIndex: number) => (
                                                                <td key={title}>
                                                                    {tableDataCleaner(title, bill)}
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            {
                                                guestBillState.billData && 
                                                <tr>
                                                    {
                                                        footerArray1.map((val, index) => (
                                                            <td className="font-weight-bold" key={index}>{val.toLocaleString() || ""}</td>
                                                        ))
                                                    }
                                                </tr>
                                            }
                                        </tfoot>
                                    </table>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <p className="font-weight-bold mt-3">Other Bills:</p>
                                    <table className="guest-bill-table-table table table-bordered table-hover table-striped">
                                        <tfoot>
                                            {
                                                guestBillState.billData && 
                                                <tr>
                                                    {
                                                        footerArray1.map((val, index) => (
                                                            <td className="font-weight-bold" key={index}>{val.toLocaleString() || ""}</td>
                                                        ))
                                                    }
                                                </tr>
                                            }
                                        </tfoot>
                                        <tbody>
                                            {
                                                guestBillState.billData && guestBillState.otherBills.map((bill: any, billIndex: number) => (
                                                    <tr key={bill.dateCreated}>
                                                        {
                                                            bill.description &&
                                                            transactionDetails.map((title, titleIndex: number) => (
                                                                <td key={title}>
                                                                    {tableDataCleaner(title, bill)}
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </section>
                <hr />
                <section style={{width: "100%",  flexDirection: "column"}} className=" d-flex">
                    <section style={{justifyContent: "space-between", alignItems: "center", display: "flex"}} className="guest-bill-balance-table">
                        <article style={{width: "40%"}} className="mr-4 guest-bill-disclaimer">
                            <p>
                                <span className='font-weight-bold' style={{fontSize: "20px"}} >Disclaimer:</span> This is to certify that I <span style={{fontSize: "20px"}} className='font-weight-bold mx-1'>{props.billData.guestName},</span> 
                                do consent that the transaction details appended on this bill are satisfactory and consistent.
                            </p>
                            <p>Signature: _______________________________________</p>
                            <p className=''>
                                {
                                    props.billData["checkedInDate"] && givenDateAndCurrentTime(props.billData["checkedInDate"].split("T")[0]).toLocaleString()
                                }
                            </p>
                        </article>
                        <table  style={{width: "60%", justifyContent: "flex-end"}} className="guest-bill-table-table table table-bordered table-hover table-striped">
                            <tfoot>
                                {
                                    guestBillState.billData && 
                                    (originsToHideTaxData.includes(origin) ? footerArrayExcludingTax : footerArray2).map((val, index) => (
                                        <tr key={val} className="text-center">
                                            <td className="font-weight-bold" key={index}>{val}</td>
                                            <td className="font-weight-bold" key={index}>{footerArray3[index].toLocaleString()}</td>
                                        </tr>
                                    ))
                                }
                            </tfoot>
                        </table>
                    </section>
                    <section>
                        {
                            billActions.map(action => (
                                <React.Fragment>
                                    {
                                        action &&
                                        <button 
                                            key={action}
                                            onClick={() => billActionHandler(action.toLowerCase())}
                                            className={`btn btn-${action.toLowerCase().includes('accommodation') || action.toLowerCase().toLowerCase().includes('other') ? 'warning' : action.toLowerCase() === "print" ? "info" : "secondary"} float-right mb-4 bill-print-btn mx-2`}>
                                            {action} Bill
                                        </button>
                                    }
                                </React.Fragment>
                            ))
                        }
                    </section>
                </section>
            </div>
        </div>
    )
}

export default GuestBill