import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { callBackendAction, } from '../../../../../redux/actions/storeActions/appActionsUtilities';
import axiosHelper from '../../../../../utilities/axiosHelper';

import OtherReports from './OtherReports';
import ManagersFlashReport from './ManagersFlashReport';
import TrialBalanceReport from './TrialBalanceReport';
import { getAllSystemCharges, getAllSystemCredits, paymentMethodsGetter } from '../../../../../utilities/databaseHelpers';


interface Props {
   
}


const AuditReportsTable = (props: Props) => {
    const [auditReportsState, setAuditReportState] = useState({
        tableData: []as any[],
        allSystemCredits: []as any[],
        appModulesData: []as any[]
    });

    const dispatch = useDispatch();
    const modulesState = useSelector((state:any) => state.modulesData);
    // console.log(`\n\t AuditReportsTable modal: `, store);
    const location = useLocation();
    const pathname = location.pathname;
    useEffect(() => {
        dispatch(callBackendAction(false));
        (async() => {
            const [response, allSystemCredits] = await Promise.all([
                axiosHelper({
                    routeName: pathname,
                    httpVerb: "get"
                }),
                getAllSystemCredits(),
            ]);
            auditReportsState.appModulesData = modulesState.modulesData ? modulesState.modulesData[0] : [];
            console.log(`\n\t AuditReportsTable auditReportsState-response: `, response);
            // console.log(`\n\t AuditReportsTable auditReportsState-paymentMethods: `, paymentMethods);
            console.log(`\n\t AuditReportsTable auditReportsState-${pathname}: `, Object.values(response.data));
            setAuditReportState({
                ...auditReportsState,
                allSystemCredits,
                tableData: Object.values(response.data)
            })
        })();
    }, [pathname]);
    return (
        <React.Fragment>
            <div style={{flexDirection: "column", marginLeft: ".85rem"}} className=" mt-4">
                <div className="" id="">
                    {
                        pathname.includes('flash') ?
                        <ManagersFlashReport dataToDetail={auditReportsState.tableData} />
                        :
                        pathname.includes('trial') ?
                        <TrialBalanceReport dataToDetail={auditReportsState.tableData} />
                        :
                        <OtherReports 
                            tableData={auditReportsState.tableData} 
                            appModulesData={auditReportsState.appModulesData}
                            allSystemCredits={auditReportsState.allSystemCredits}
                        />
                    }
                </div>
            </div>
        </React.Fragment>

    )
}

export default AuditReportsTable

