import React, { useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import colors from '../../../assets/colors';
import "../../../styles/FormStyle.css";


interface Props {
    fields: string[]
    types: string[]
    icon?: any[]
    onChange: Function
    enterKeyAction: Function
}

const Forms = (props: Props) => {
    const [visible, setVisible] = useState(false);
   
    return (
        <div className="form-group ">
            {
                props.fields.map((field, index) => (
                    <div key={field} style={{display: "flex", border: "1.5px", borderStyle: "solid", borderRadius: "3px", borderColor: colors.sideNav,}} className={`row  mb-3 bg-white `}>
                        {
                            props.icon &&
                            <div className={`col-md-2 form-control text-center `} style={{backgroundColor: colors.sideNav}}>
                                {props.icon[index]}
                            </div>
                        }
                        <input
                            id={`${field.toLowerCase().replaceAll(" ", "-")}`}
                            type={field.toLowerCase() === "password" && visible ? undefined : props.types[index]} 
                            onKeyDown={e => props.enterKeyAction(e)}
                            className={`bg-light ${field.toLowerCase() === 'password' ? 'col-md-8' : 'col-md-10'} ${field.toLowerCase()}`}
                            placeholder={field} 
                            onChange={e => props.onChange(field, e.target.value)}
                            style={{border: "none", outline: "none", width: "100vw", borderRightColor: colors.appYellow, borderRightWidth: field.toLowerCase() === "password" ? "2px" : 0, borderRightStyle: field.toLowerCase() === "password" ? "solid" : undefined}}
                        />
                        {
                            field.toLowerCase() === "password" &&
                            <span onClick={() => setVisible(!visible)} className="d-flex col-md-2 pl-4"  style={{cursor: "pointer", backgroundColor: colors.sideNav,}}>
                                {
                                    !visible ?
                                    <FaEyeSlash color={'ghostwhite'} className="mt-2 ml-2 text-center" size={23} />
                                    :
                                    <FaEye color={'ghostwhite'} className="mt-2 ml-2 text-center" size={23}/>
                                }
                            </span>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default Forms;


