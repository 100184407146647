import React from 'react'
import { Link } from 'react-router-dom'
import colors from '../../../../../assets/colors'
import { tableDataCleaner } from '../../../../../utilities/strings'
import TableActionButtons from '../TableActionButtons'
import { classNameForDepartures, houseKeepingStylist } from '../tableActions/tableActionsThree'
import { checkedOutTableHeader, eventsTableHeader, expectedGroupCheckOutTableHeader, expectedIndividualArrivalsTableHeader, facilitiesTableHeader, frontdeskTableHeader, generalGroupsCheckedInTableHeader, groupGuestBillsTableHeader, groupGuestInHouseTableHeader, groupReservationTableHeader, individualGuestBillsTableHeader, individualGuestInHouseTableHeader, individualsCheckedInTableHeader, individualsCheckoutHistoryTableHeader, individualsDepartureTableHeader, individualsHistoryTableHeader, pmAccountTableHeader, roomStatusTableHeader, roomTypeSummaryTableHeader, securityReportForIndividualGuestInHouseTableHeader } from '../tableData'
import { recordTableHeaders } from '../RecordsTable'

interface Props {
    pathname: string
    frontdesksState: any
    frontdeskActionsHandler: Function
    // showHistoryDetails: boolean
    generateSecurityReport: boolean
}

const FrontdeskSubTable = (props: Props) => {

    return (
        <section style={{width: "80vw", marginLeft: "-2em", display: "flex"}}>
            <table className="table-table table bg-light table-striped table-hover table-bordered" id="example1" width="100%" cellSpacing="0">
                <thead style={{ backgroundColor: colors.sideNav }} className="py-2">
                    <tr className="text-center text-light">
                        {
                            props.pathname.toLowerCase().includes('pm-account') ?
                            pmAccountTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('checked-in/individual') ?
                            individualsCheckedInTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('history') ?
                            recordTableHeaders.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('checked-in/group') ?
                            generalGroupsCheckedInTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('checked-out') ?
                            checkedOutTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('room-status') ?
                            roomStatusTableHeader.slice(0, 9).map(columnName => (
                                <th key={columnName}>{columnName.toLowerCase() !== "actions" && columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('room-type-summary') ?
                            roomTypeSummaryTableHeader.slice(0, 8).map(columnName => (
                                <th key={columnName}>{columnName.toLowerCase() !== "actions" && columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('arrivals/group') ?
                            groupReservationTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('arrivals/individual') ?
                            expectedIndividualArrivalsTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('security') ?
                            (props.generateSecurityReport ? securityReportForIndividualGuestInHouseTableHeader : individualGuestInHouseTableHeader).map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            // props.pathname.toLowerCase().includes('security/group') ?
                            // groupGuestInHouseTableHeader.map(columnName => (
                            //     <th key={columnName}>{columnName}</th>
                            // ))
                            // :
                            props.pathname.toLowerCase().includes('departures/individual') ?
                            individualsDepartureTableHeader.slice(0, 17).map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('departures/group') ?
                            expectedGroupCheckOutTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('facility') ?
                            facilitiesTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('event') ?
                            eventsTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                        
                            props.pathname.toLowerCase().includes('bills/individual') ?
                            individualGuestBillsTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            props.pathname.toLowerCase().includes('bills/group') ?
                            groupGuestBillsTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                            :
                            frontdeskTableHeader.map(columnName => (
                                <th key={columnName}>{columnName}</th>
                            ))
                        }
                    </tr>
                </thead>
                {
                    props.frontdesksState.paginatedTableData && props.frontdesksState.paginatedTableData.length > 0 ?
                        <tbody>
                            {
                                Object.values(props.pathname.includes('security') ? props.frontdesksState.tableData : props.frontdesksState.paginatedTableData).map((data: any, index: number) => (
                                    <tr className="text-center" key={index}>
                                        <React.Fragment>
                                            {
                                                Object.entries(data).slice(
                                                    props.pathname.includes('departures/group') ? 5 :
                                                    props.pathname.includes('facility') ? 6 :
                                                    props.pathname.includes('arrivals/group') || props.pathname.includes('event') ? 7 :
                                                    props.pathname.includes('account')  || props.pathname.includes('checked-in/group') || props.pathname.includes('departures/individual') ? 4 :
                                                    // props.pathname.includes('security/group') ? 2 :
                                                    props.pathname.includes('checked-in/individual') ? 3 :
                                                    props.pathname.includes('security') ? props.generateSecurityReport ? 9 : 6 :
                                                    props.pathname === "/frontdesk" || props.pathname.includes("room-status") || props.pathname.includes('arrivals/individual') ? 1 : 
                                                    0 
                                                ).map((prop, propIndex) => (
                                                    <td key={propIndex} className={`${houseKeepingStylist(props.pathname, data, propIndex)} ${props.pathname.includes('checked-in') ? classNameForDepartures(data) : ""}`}>
                                                        {
                                                            props.pathname.includes('checked-in/individual') ?
                                                            tableDataCleaner(individualsCheckedInTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("pm-account") ?
                                                            tableDataCleaner(pmAccountTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("history") ?
                                                            recordTableHeaders[propIndex] && recordTableHeaders[propIndex].toLowerCase() !== 'actions' &&
                                                            tableDataCleaner(recordTableHeaders[propIndex], data, 'records')
                                                            :
                                                            props.pathname.includes('checked-in/group') ?
                                                            tableDataCleaner(generalGroupsCheckedInTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes('checked-out') ?
                                                            tableDataCleaner(checkedOutTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("room-status") ?
                                                            tableDataCleaner(roomStatusTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("room-type-summary") ?
                                                            tableDataCleaner(roomTypeSummaryTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("facility") ?
                                                            tableDataCleaner(facilitiesTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("event") ?
                                                            tableDataCleaner(eventsTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("arrivals/group") ?
                                                            tableDataCleaner(groupReservationTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("arrivals/individual") ?
                                                            tableDataCleaner(expectedIndividualArrivalsTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("security") ?
                                                            tableDataCleaner(props.generateSecurityReport ? securityReportForIndividualGuestInHouseTableHeader[propIndex] : individualGuestInHouseTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("departures/individual") ?
                                                            tableDataCleaner(individualsDepartureTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("departures/group") ?
                                                            tableDataCleaner(expectedGroupCheckOutTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("bills/individual") ?
                                                            tableDataCleaner(individualGuestBillsTableHeader[propIndex], data) || "--"
                                                            :
                                                            props.pathname.includes("bills/group") ?
                                                            tableDataCleaner(groupGuestBillsTableHeader[propIndex], data) || "--"
                                                            :
                                                            tableDataCleaner(frontdeskTableHeader[propIndex], data) || "--"
                                                        }
                                                    </td>
                                                ))
                                            }
                                            {
                                                !props.pathname.includes('room-details') && !props.pathname.includes('security') &&
                                                <td style={{ backgroundColor: colors.sideNav }} className="print">
                                                    <div className="dropdown show btn-container">
                                                        {
                                                            props.pathname !== "/frontdesk" && !props.pathname.includes('room-status') &&
                                                            <Link className="btn button dropdown-toggle sb-sidenav-menu" style={{ color: "#f4f4f4" }} to="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions <span></span><span></span><span></span> <span></span></Link>
                                                        }
                                                        <div className="dropdown-menu frontdesk-buttons " aria-labelledby="dropdownMenuLink">
                                                        <TableActionButtons
                                                            data={data}
                                                            pathname={props.pathname}
                                                            handleActions={props.frontdeskActionsHandler}
                                                        />
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                        </React.Fragment>
                                    </tr>
                                ))
                            }
                        </tbody>
                        :
                        <tr className="no-record">
                            <td>No Existing Record</td>
                        </tr>
                }
            </table>
        </section>
    )
}

export default FrontdeskSubTable