import {
    ActionTypes,
    CallBackendInterface,
    ShowConfirmModalInterface,
    ShowFormModalInterface,
    ShowProcessingGifInterface,
    ToggleSidebarInterface,
    ShowFooterInterface
} from "../actionTypes";


export const toggleSidebarAction = (newSidebarStatus: boolean) => {
    // console.log("\n\t toggleSidebarAction: ", newSidebarStatus)
    return <ToggleSidebarInterface>({
        type: ActionTypes.TOGGLE_SIDEBAR,
        payload: newSidebarStatus
    })
}

export const toggleFooterAction = (footerStatus: boolean) => {
    // console.log("\n\t toggleSidebarAction: ", footerStatus)
    return <ShowFooterInterface>({
        type: ActionTypes.SHOW_FOOTER,
        payload: footerStatus
    })
};


export const toggleNavbarAction = (newNavbarStatus: boolean) => {
    // console.log("\n\t toggleSidebarAction: ", newNavbarStatus)
    return <ToggleSidebarInterface>({
        type: ActionTypes.TOGGLE_NAVBAR,
        payload: newNavbarStatus
    })
}

export const callBackendAction = (callOption: boolean) => {
    // console.log("\n\t callBackendAction: ", callOption)
    return <CallBackendInterface>({
        type: ActionTypes.CALL_BACKEND,
        payload: callOption
    })
}

export const showProcessingGifAction = (gifStatus: boolean) => {
    // console.log("\n\t showProcessingGifAction: ", gifStatus)
    return <ShowProcessingGifInterface>({
        type: ActionTypes.SHOW_PROCESSING_GIF,
        payload: gifStatus
    })
}

export const showFormModalAction = (modalStatus: boolean, actionText?: string) => {
    // console.log("\n\t showFormModalAction: ", modalStatus, actionText)
    return <ShowFormModalInterface>({
        type: ActionTypes.SHOW_MODAL,
        payload: {
            modalStatus,
            actionText: actionText || ""
        }
    })
}

export const showConfirmModalAction = (modalStatus: boolean) => {
    // console.log("\n\t showConfirmModalAction: ", modalStatus)
    return <ShowConfirmModalInterface>({
        type: ActionTypes.SHOW_CONFIRM_MODAL,
        payload: modalStatus
    })
}