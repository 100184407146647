import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { showFormModalAction } from '../../../redux/actions/storeActions/appActionsUtilities';
import axiosHelper from '../../../utilities/axiosHelper';
import { customAlert } from '../../../utilities/customAlert';
import { getCheckInFormData } from '../../../utilities/databaseHelpers';
import { titledTextJoiner } from '../../../utilities/strings';

import "../../../styles/GroupCheckinFormStyles.css";

interface Props {
    checkInData: any
    onChange: Function
    // printRegCard: boolean
    // setPrintRegCard: Function
    setGroupCheckInData: Function
};

const GroupCheckInForm = (props: Props) => {
    const dispatch = useDispatch();
    const { _id, arrivalDate, departureDate, totalNumberOfRooms, roomTypeAndNumberOfRooms, days, groupName } = props.checkInData;
    console.log("\n\t props.checkInData: ", props.checkInData);
    const [paramCheck, setParamCheck] = useState({
        countForPeoplePresent: [] as number[],
        selectedPeoplePresent: false,
        verifiedReservationCode: false,
        reservationCodeStatus: "",
        numberOfTimesToLoop: [] as any,
        inputtedReservationCode: "",
        loadingPrompt: "Hotel-Pro is computing the number of forms required. Please wait...."
    })

    const [serverNotification, setServerNotification] = useState({
        message: "",
        numberOfGuestsCheckedIn: 0,
        reservationCode: "",
        checkInId: ""
    });
    const verifyReservationCode = (code: string) => {
        if(code === serverNotification.reservationCode){
            setParamCheck({
                ...paramCheck,
                reservationCodeStatus: 'Reservation code is correct. Please wait'
            });
            const count = totalNumberOfRooms - serverNotification.numberOfGuestsCheckedIn;
            console.log("\n\t subtraction: ", count)
            let index = 0;
            while(index < count){
                paramCheck.countForPeoplePresent.push(index+1)
                index++
            };
            setTimeout(() => {
                setParamCheck({
                    ...paramCheck,
                    verifiedReservationCode: true,
                    reservationCodeStatus: ''
                })
            }, 2000)
        }else{
            setParamCheck({
                ...paramCheck,
                reservationCodeStatus: 'Reservation code is wrong. Try again'
            });
            setTimeout(() => {
                setParamCheck({
                    ...paramCheck,
                    reservationCodeStatus: ''
                })
            }, 2000)
        }
    };
    const [formData, setFormData] = useState({
        rooms: [],
        groupRates: [], 
        roomTypes: [], 
        groups: {}, 
        floors: [], 
        titles: {}
    });
    const [formState, setFormstate] = useState({
        guestTitle: [] as any, 
        hotelFloorNumbers: [] as any, 
        roomTypeNamesForGroupCheckingIn: [] as any, 
        roomNumbersListForRoomsUnderEachRoomType: {} as any,  
        selectedRoomNumber: '',
        roomTypeRatesForGroupCheckingIn: [] as any,
        modalButtonTitle: "Print reg-card",
        personData: {} as any
    });
    const fields = ['Title', 'Firstname', 'Lastname', 'Phone', "Email Address", "Home Address", 'Nationality', 'Room Type', 'Floor Number', 'Room Number', 'Room Rate', 'Days']

    const handleReservationCodeChange = (field: string, value: number | string) => {
        if(field.toLowerCase().includes('code')){
            setParamCheck({
                ...paramCheck,
                inputtedReservationCode: value as string,
            })
        }else if(field.toLowerCase().includes('number')){
            let index = 0;
            while(index < value){
                paramCheck.numberOfTimesToLoop.push(index)
                index++
            };
            setParamCheck({
                ...paramCheck,
                loadingPrompt: "Hotel-Pro is preparing check-in form. Please wait..."
            })
            setTimeout(() => {
                placeForm(
                    formData.groups, 
                    formData.groupRates, 
                    formData.roomTypes, 
                    formData.rooms, 
                    formData.floors, 
                    formData.titles
                );
                setParamCheck({
                    ...paramCheck,
                    selectedPeoplePresent: true
                })
            }, 2000);
        } 
    }
    const handleGroupFormChange = (personNumber: number, field: string, value: string) => {
        if(field.toLowerCase() === 'room number'){
            const selectedRoomNumber = (document.querySelector(`.details-${personNumber}`)?.querySelector('.room-number') as any).value;
            console.log("\n\t selectedRoomNumber ", selectedRoomNumber);
            const selectedRoomDetails = formData.rooms.find((room: any) => String(room.roomNumber) === String(selectedRoomNumber)) as any
            const roomFloorDetails = formData.floors.find((floor: any) => String(floor._id) === selectedRoomDetails?.roomFloorId) as any
            // console.log("\n\t selectedRoomDetails ", selectedRoomDetails);
            (document.querySelector(`.details-${personNumber}`)?.querySelector('.floor-number') as any).value = roomFloorDetails.floorNumber;
            // set floor number
            (formState.personData)[personNumber]['floorNumber'] = roomFloorDetails.floorNumber;
            setFormstate({
                ...formState
            });
        }else if(field.toLowerCase().includes('type')){
            // console.log("\n\t personNumber: ", personNumber)
            const selectedRoomTypeProps = formData.roomTypes.find((type: any) => type.roomTypeName === value) as any;
            const requiredRoomNumbers = formData.rooms.filter((room: any) => (String(room.roomTypeId) === String(selectedRoomTypeProps?._id)) && (room.occupancyStatus === "Vacant"));
            formState.roomNumbersListForRoomsUnderEachRoomType[personNumber] = [];
            requiredRoomNumbers.forEach((room: any) => {
                (formState.roomNumbersListForRoomsUnderEachRoomType)[personNumber].push(room.roomNumber)
            });
            const groupRoomTypeRate = formData.groupRates.find((group: any) => String(group.roomTypeId) === String(selectedRoomTypeProps._id)) as any;
            (document.querySelector(`.details-${personNumber}`)?.querySelector('.room-rate') as any).value = groupRoomTypeRate.rate + groupRoomTypeRate.breakfast;
            formState.personData[personNumber]['roomRate'] = groupRoomTypeRate.rate + groupRoomTypeRate.breakfast;
            // console.log("\n\t requiredRoomNumbers: ", formState.roomNumbersListForRoomsUnderEachRoomType)
        }
        // console.log("\n\t form state: ", formState.personData);
        (formState.personData)[personNumber][titledTextJoiner(field)] = value;
        setFormstate({
            ...formState
        });
    };
    // console.log("\n\t formData.rooms: ", formData.rooms);
    useEffect(() => {
        (async () => {
            const {rooms, groupRates, roomTypes, groups, floors, titles} = await getCheckInFormData() as any;
            setFormData({
                rooms,
                roomTypes,
                groupRates,
                groups,
                floors,
                titles
            });
            setParamCheck({
                ...paramCheck,
                loadingPrompt: ""
            })
            const checkInDetailsFromServer = await axiosHelper({
                routeName: `/frontdesk/group/checkin-details-computation/${_id}`,
                httpVerb: "get"
            });
            console.log("\n\t checkInDetailsFromServer: ", checkInDetailsFromServer)
            setServerNotification({
                numberOfGuestsCheckedIn: checkInDetailsFromServer.data.numberOfGuestsCheckedIn,
                message: checkInDetailsFromServer.message,
                reservationCode: checkInDetailsFromServer.data.reservationCode,
                checkInId: checkInDetailsFromServer.data.checkInId || ""
            });
        })();
    }, []);
    // console.log("\n\t serverNotification: ", serverNotification)
    const placeForm = (hotelGroups: any, hotelGroupRates: any, hotelRoomTypes: any, hotelRooms: any, hotelFloors: any, hotelGuestTitle: any) => {
        // set guest title
        hotelGuestTitle.forEach((title: any) => {
            formState.guestTitle.push(title.titleCode)
        });
        
        const groupDetailsCheckingIn = hotelGroups.find((group: any) => group.groupName === groupName)
        const groupRatesForGroupCheckingIn = hotelGroupRates.filter((group: any) => group.groupId === groupDetailsCheckingIn._id)
        groupRatesForGroupCheckingIn.forEach((item: any) => {
            formState.roomTypeRatesForGroupCheckingIn.push(item.rate + item.breakfast)
        });
        const roomTypes = [] as any
        (roomTypeAndNumberOfRooms as any[]).forEach(item => {
            roomTypes.push(item.roomTypeName)
        });
        for(let index of paramCheck.numberOfTimesToLoop){
            formState.roomTypeNamesForGroupCheckingIn.push(roomTypes)
        }

        // console.log(`\n\t roomNumbersListForRoomsUnderEachRoomType: `, roomNumbersListForRoomsUnderEachRoomType)
        // on load, create the required number of containers to hold individual details
        // of each person in the group
        for(let index of paramCheck.numberOfTimesToLoop){
            // console.log("\n\t Place form index: ", index)
            formState.personData[index] = {
                roomType: formState.roomTypeNamesForGroupCheckingIn[index],
                roomRate: formState.roomTypeRatesForGroupCheckingIn[index],
                arrivalDate, 
                departureDate,
                individualCheckInDate: new Date(),
                days
            };
            formState.roomNumbersListForRoomsUnderEachRoomType[index] = [];
        }
        setFormstate({
            ...formState,
        });
        // console.log("\n\t formState: ", formState)
       
    };
    // console.log("\n\t roomTypeNamesForGroupCheckingIn: ", formState.roomTypeNamesForGroupCheckingIn)
    const showRegCards = () => {
        // console.log("\n\t showRegCards: ", numberOfTimesToLoop)
        let count = 0;
        while(count < paramCheck.numberOfTimesToLoop.length){
            // console.log("\n\t showRegCards-While loop: ", paramCheck.numberOfTimesToLoop)
            const values = formState.personData[count];
            if(Object.values(values).length < 10) return customAlert("error", "You can't print reg-card without filling-up the form completely.", 6000)
            count++
        };
        // console.log("\n\t personData: ", formState.personData)
        props.setGroupCheckInData({
            formData: formState.personData,
            groupCheckInId: serverNotification.checkInId
        });
        dispatch(showFormModalAction(false));
        // props.setPrintRegCard(true);
    };
    console.log("\n\t personData: ", formState.personData)
    return (
        <div className={` bg-black`} >
            <div className="modal-content checkin-code ">
                {
                    !paramCheck.verifiedReservationCode ?
                    <div className="modal-body checkin-code text-white form-group">
                        <label className={`bg-white small d-flex ${paramCheck.reservationCodeStatus.toLowerCase().includes('correct') ? 'text-success p-2' : paramCheck.reservationCodeStatus.toLowerCase().includes('wrong') && 'text-danger p-2'} animate__animated animate__pulse animate__infinite`} htmlFor="">{paramCheck.reservationCodeStatus}</label>
                        <label htmlFor="">Enter Group Reservation Code:</label>
                        <input onChange={e => handleReservationCodeChange('inputted code', e.target.value)}  className="form-control" type="text" />
                        <span className="btn-container my-1 float-right">
                            <button onClick={e => verifyReservationCode(paramCheck.inputtedReservationCode)} className="btn mt-2 btn-info checkin-code ">
                                Verify Code
                            </button>
                        </span>
                    </div>
                    :
                    <div>
                        <div style={{flexDirection: "column"}} className="modal-header bg-success text-center d-flex justify-content-center align-items-center">
                            <span className="my-3 text-warning table-title" >Group Check-in Form for {groupName}</span>
                            {
                                !serverNotification.message ?
                                // <span className="table-title">There are {totalNumberOfRooms && totalNumberOfRooms} persons to be checked-in. <br /> Enter the data for each person in the group.</span>
                                <span style={{fontSize: "14px"}} className="font-weight-bolder small text-light animate__animated animate__bounce animate__infinite">{paramCheck.loadingPrompt}</span>
                                :
                                <span className="table-title">{serverNotification.message}</span>
                            }
                        </div>
                        {
                            !paramCheck.selectedPeoplePresent ?
                            <div className="modal-body form-group text-center">
                                {
                                    paramCheck.loadingPrompt ?
                                    <span className="table-title text-danger bg-white p-2 mb-3 text-enter d-flex">{paramCheck.loadingPrompt}</span>
                                    :
                                    <section>
                                        <label className="small text-white" htmlFor="">How many people are present?</label>
                                        <select onChange={e => handleReservationCodeChange('number chosen', e.target.value)} className="form-select form-control" name="" id="">
                                            <option value="">Select</option>
                                            {
                                                paramCheck.countForPeoplePresent && paramCheck.countForPeoplePresent.map((count: number) => (
                                                    <option key={count} value={count}>{count}</option>
                                                ))
                                            }
                                        </select>
                                    </section>
                                }
                            </div>
                            :
                            <div className="modal-body">
                                {
                                    paramCheck.numberOfTimesToLoop && paramCheck.numberOfTimesToLoop.map((item: any, loopIndex: any) => (
                                        <section className={`form-group details-${loopIndex}`} key={item}>
                                            <span>{loopIndex+1}</span>
                                            <hr className="sidebar_hr" />
                                            <div className="row">
                                                {
                                                    fields.map((field, index) => (
                                                        <span key={index} className="col-md-6">
                                                            <span className="small mt-2 text-white font-weight-bold">{field}:</span>
                                                            {
                                                                !field.toLowerCase().includes('room number') && !field.toLowerCase().includes('type') && !field.toLowerCase().includes('title') ?
                                                                <input 
                                                                    readOnly={field.toLowerCase().includes('floor') || field.toLowerCase().includes('days') || field.toLowerCase().includes('rate') ? true : false} 
                                                                    className={`form-control ${field.toLowerCase().replaceAll(" ", "-")}`}
                                                                    type={field.toLowerCase() === 'phone' ? 'tel' : "text"} 
                                                                    onChange={e => handleGroupFormChange(loopIndex, field, e.target.value)}
                                                                    placeholder={
                                                                        field.toLowerCase().includes('days') ? days : 
                                                                        field
                                                                    } 
                                                                />
                                                                :
                                                                <select 
                                                                    onChange={e => handleGroupFormChange(loopIndex, field, e.target.value)}
                                                                    className={`form-select form-control ${field.toLowerCase().replaceAll(" ", "-")}`}>
                                                                    <option value="">Select {field}</option>
                                                                    {
                                                                        field.toLowerCase().includes('title') ? 
                                                                        formState.guestTitle && formState.guestTitle.map((title: string) => (
                                                                            <option key={title} value={title}>{title}</option>
                                                                        ))
                                                                        :
                                                                        field.toLowerCase() === 'room number' ? 
                                                                        formState.roomNumbersListForRoomsUnderEachRoomType[loopIndex] !== undefined && formState.roomNumbersListForRoomsUnderEachRoomType[loopIndex].map((roomNumber: any) => (
                                                                            <option key={roomNumber} value={roomNumber}>{roomNumber}</option>
                                                                        ))
                                                                        :
                                                                        formState.roomTypeNamesForGroupCheckingIn[loopIndex] !== undefined && formState.roomTypeNamesForGroupCheckingIn[loopIndex].map((roomTypeName: any, index: number) => (
                                                                            <option key={index} value={roomTypeName}>{roomTypeName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            }
                                                        </span>
                                                    ))
                                                }
                                            </div>
                                            <hr className="confirm_modal_hr mt-2" style={{width: "100%"}} />
                                        </section >
                                    ))
                                }
                            </div>
                        }
                        <div className="modal-footer bg-warning text-white">
                            {
                                paramCheck.selectedPeoplePresent &&
                                <Link 
                                    // target={modalButtonTitle.includes('card') ? `_blank` : undefined}
                                    onClick={e => showRegCards()}
                                    data-dismiss="modal"
                                    to="#" className="bg-info btn btn-info">
                                    {formState.modalButtonTitle}
                                </Link>
                            }
                            </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default GroupCheckInForm;
