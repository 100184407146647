import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import colors from '../../../../assets/colors'
import { callBackendAction } from '../../../../redux/actions/storeActions/appActionsUtilities'
import { buttonTitle } from '../../../../utilities/strings'
import { handleSubMenus, storeSubMenus } from './SidebarNavLinks'

interface Props {
    navLinks: string[]
}

const StoreSidebar = (props: Props) => {
    const location = useLocation();
    
    const pathname = location.pathname;
    const redirectRule = (link: string, subLink?: string) => {
        if(link.toLowerCase() === "records"){
            return `/store/${link.toLowerCase()}/${subLink?.toLowerCase()}`
        }
        return `/store/${link.toLowerCase()}`
    };
    const [storeMenu, setstoreMenu] = useState({
        toggleMenuState: {}as any
    })
    useEffect(() => {
        // pathname !== "/store" && dispatch(callBackendAction(false));
        props.navLinks.map(link => {
            storeMenu.toggleMenuState[link] = false;
        });
        setstoreMenu({...storeMenu})
    }, []);
    const handleStateSubMenus = (toggledLink: string) => {
        handleSubMenus({
            state: storeMenu,
            stateSetter: setstoreMenu,
            toggledLink
        })
    };
    return (
        <div style={{height: "auto"}}>
            <NavLink style={{color: colors.ash}} className={`nav-link d-flex`} to="/dashboard">
                <strong>Home</strong>
            </NavLink>
            {/* <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
            <NavLink style={{color: colors.ash}} className={`nav-link d-flex`} to="/reservations">
                <strong>Reservations</strong>
            </NavLink> */}
            <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />

            {
                props.navLinks && props.navLinks.map((link, index) => (
                    <div  key={link}>
                        <NavLink 
                            style={{flexDirection: "column"}}  
                            className={`nav-link collapsed d-flex my-3`} data-toggle="collapse" data-target={`#${link.toLowerCase().replaceAll(" ", "-")}`} aria-expanded="false" aria-controls="accounts"
                            to={link.toLowerCase().includes('records') ? "#" : redirectRule(link)}
                            key={link}
                            onClick={() => handleStateSubMenus(link)}
                            >
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}} >
                                <span style={{letterSpacing: link.toLowerCase().includes('departures') ? 1 : 2, }} className="link_hover">{link}</span>
                                {
                                    storeMenu.toggleMenuState[link] ?
                                    (link.toLowerCase().includes('records'))  && 
                                    <FaAngleRight size={25} color={colors.appYellow}  />
                                    :
                                    (link.toLowerCase().includes('records'))  && 
                                    <FaAngleDown size={25} color={colors.appYellow}  />

                                }
                            </div>
                        </NavLink>
                        {
                            (storeSubMenus as any)[link].length > 0 && 
                            <div className={`${!storeMenu.toggleMenuState[link] ? "collapse" : undefined} ml-3`} id={`${link.toLowerCase().replaceAll(" ", "-")}`} aria-labelledby="headingOne" >
                                <nav className="sb-sidenav-menu-nested nav row d-flex">
                                    {
                                        Object.entries(storeSubMenus).map((entery, secIndex: number) => (
                                            <span className="px-2" style={{width: "100%"}} key={secIndex}>
                                                {
                                                    entery[0].toLowerCase() === link.toLowerCase() && entery[1].length > 0 &&
                                                    <span>
                                                        {
                                                            entery[1].map(value => (
                                                                <Link 
                                                                    key={value}
                                                                    style={{color: colors.appYellow, flexDirection: "column", textDecoration: "none"}} 
                                                                    className="" 
                                                                    to={redirectRule(link, value)}>
                                                                    {value}
                                                                    <hr style={{width: "100%", backgroundColor: colors.warning}} />
                                                                </Link>
                                                            ))
                                                        }
                                                    </span>
                                                }
                                            </span>
                                        ))
                                    }
                                </nav>
                            </div>
                        }
                        <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
                    </div>
                ))
            }
        </div>
    )
}

export default StoreSidebar;
