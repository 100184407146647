import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FaAngleDown, FaAngleRight } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import colors from '../../../../assets/colors'
import { callBackendAction } from '../../../../redux/actions/storeActions/appActionsUtilities'
import { buttonTitle } from '../../../../utilities/strings'
import { frontdeskSubMenus, handleSubMenus } from './SidebarNavLinks'

interface Props {
    navLinks: string[]
}

const FrontdeskSidebar = (props: Props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const pathname = location.pathname;
    
    const [frontdeskMenu, setfrontdeskMenu] = useState({
        toggleMenuState: {}as any
    })
    useEffect(() => {
        pathname !== "/frontdesk" && dispatch(callBackendAction(false));
        props.navLinks.map(link => {
            frontdeskMenu.toggleMenuState[link] = false;
        })
        setfrontdeskMenu({...frontdeskMenu})
    }, []);
    const handleStateSubMenus = (toggledLink: string) => {
        handleSubMenus({
            state: frontdeskMenu,
            stateSetter: setfrontdeskMenu,
            toggledLink
        })
    };
    return (
        <div style={{height: "auto"}}>
            <NavLink style={{color: colors.ash}} className={`nav-link d-flex`} to="/frontdesk">
                <strong>Frontdesk</strong>
            </NavLink>
            <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
            <NavLink style={{color: colors.ash}} className={`nav-link d-flex`} to="/reservations">
                <strong>Reservations</strong>
            </NavLink>
            <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />

            {
                props.navLinks && props.navLinks.map((link, index) => (
                    <div  key={link}>
                        <NavLink 
                            style={{flexDirection: "column"}}  
                            className={`nav-link collapsed d-flex my-3`} data-toggle="collapse" data-target={`#${link.toLowerCase().replaceAll(" ", "-")}`} aria-expanded="false" aria-controls="accounts"
                            to={(link.toLowerCase().includes('account') || link.toLowerCase().includes('facilities') || link.toLowerCase().includes('details') || link.toLowerCase().includes('bills') || link.toLowerCase().includes('in-house') || link.toLowerCase() === "checked-out" || link.toLowerCase() === "arrivals" || link.toLowerCase().includes("departures") || link.toLowerCase() === "checked-in") ? "#" : `${link.toLowerCase().replaceAll(" ", "-")}`}
                            key={link}
                            onClick={() => handleStateSubMenus(link)}
                            >
                            <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}} >
                                <span style={{letterSpacing: link.toLowerCase().includes('departures') ? 1 : 2, }} className="link_hover">{link}</span>
                                {
                                    !frontdeskMenu.toggleMenuState[link] ?
                                    (link.toLowerCase().includes('account') || link.toLowerCase().includes('facilities') || link.toLowerCase().includes('details') || link.toLowerCase().includes('bills') || link.toLowerCase().includes('in-house') || link.toLowerCase() === "checked-out" || link.toLowerCase() === "arrivals" || link.toLowerCase() === "checked-in" || link.toLowerCase().includes("departures"))  && 
                                    <FaAngleRight size={25} color={colors.appYellow}  />
                                    :
                                    (link.toLowerCase().includes('account') || link.toLowerCase().includes('facilities') || link.toLowerCase().includes('details') || link.toLowerCase().includes('bills') || link.toLowerCase().includes('in-house') || link.toLowerCase() === "checked-out" || link.toLowerCase() === "arrivals" || link.toLowerCase() === "checked-in" || link.toLowerCase().includes("departures"))  && 
                                    <FaAngleDown size={25} color={colors.appYellow}  />

                                }
                            </div>
                        </NavLink>
                        <div className={`${!frontdeskMenu.toggleMenuState[link] ? "collapse" : undefined} ml-3`} id={`${link.toLowerCase().replaceAll(" ", "-")}`} aria-labelledby="headingOne" >
                            <nav className="sb-sidenav-menu-nested nav row d-flex">
                                {
                                    Object.entries(frontdeskSubMenus).map((entery, secIndex: number) => (
                                        <span className="px-2" style={{width: "100%"}} key={secIndex}>
                                            {
                                                entery[0].toLowerCase() === link.toLowerCase() && 
                                                <span>
                                                    {
                                                        entery[1].map(value => (
                                                            <Link 
                                                                key={value}
                                                                style={{color: colors.appYellow, flexDirection: "column", textDecoration: "none"}} 
                                                                className="" 
                                                                to={`${pathname}/${link.toLowerCase().replaceAll(" ", "-")}/${value.toLowerCase().replaceAll(" ", "-")}`}>
                                                                {value}
                                                                <hr style={{width: "100%", backgroundColor: colors.warning}} />
                                                            </Link>
                                                        ))
                                                    }
                                                </span>
                                            }
                                        </span>
                                    ))
                                }
                            </nav>
                        </div>
                        <hr style={{width: "100%", backgroundColor: colors.appYellow, marginTop: "2px"}} />
                    </div>
                ))
            }
        </div>
    )
}

export default FrontdeskSidebar;
