import React, { useEffect, useRef, useState } from "react";
import "../../../../../../styles/EndOfDayResultsStyles.css";

import { GrStatusGood, GrStatusWarning } from "react-icons/gr"

import { toggleNavbarAction } from "../../../../../../redux/actions/storeActions/appActionsUtilities";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
    serverResponse: object | string
}

const EndOfDayResults = (props: Props) => {
    const dispatch = useDispatch();
    const modules = ["Reservations", "Frontdesk", "Restaurant", "Bar", "Pool-Bar"]
    // console.log("\n\t props.serverResponse: ", props.serverResponse)
    const [resultDetails, setResultDetails] = useState({
        serverMessage: "End of day is concluding. Please, do a refresh to see if the hotel-date has been updated. If yes, do not run the EOD again.",
        // serverData: "" as any,
    });
    const navigate = useNavigate();
    useEffect(() => {
        try {
            // console.log("\n\t EndOfDayResults-useEffect-props.serverResponse-1: ", props.serverResponse)
            console.log("\n\t EndOfDayResults-useEffect-props.serverResponse-1-type: ", typeof(props.serverResponse))
            setResultDetails({
                ...resultDetails,
                serverMessage: typeof(props.serverResponse) === "string" ? props.serverResponse : Object.keys(props.serverResponse).length > 0 ? (props.serverResponse as any).message : resultDetails.serverMessage,
            });
            setTimeout(() => {
                navigate(`/${process.env.REACT_APP_RECORDS_ROUTE}`)
            }, 10000);
            // console.log("\n\t resultDetails.managersFlash: ", props.serverResponse.data.managersFlash[0][0])
        } catch (error) {
            console.log("\n\t useEffect-props.serverResponse: ", props.serverResponse)
            setResultDetails({
                ...resultDetails,
                serverMessage: typeof(props.serverResponse) !== "string" ? "" : props.serverResponse,
            });
        }
        dispatch(toggleNavbarAction(true)); 
    }, [props.serverResponse]);
    // console.log("\n\t resultDetails.serverMessage: ", resultDetails.serverMessage)
    // console.log("\n\t props.serverResponse: ", props.serverResponse)
    return (
        <div className="d-flex row justify-content-center mt-2 align-items results-container">
            <p className="text-center text-white mt-5 result-title">End Of Day Results</p>
            {
                Object.keys(props.serverResponse).length > 0 && resultDetails.serverMessage.includes('success') ?
                <p className={`text-center status-message font-weight-bold text-success`}>{resultDetails.serverMessage}</p>
                :
                <p className={`text-center status-message font-weight-bold ${resultDetails.serverMessage && resultDetails.serverMessage.includes('success') ? "text-success" : "text-danger"}`}>{resultDetails.serverMessage}</p>
            }
            <div style={{height: '100vh', width: "100vw"}} className="modules-status-container mt-4 d-flex row">
                {
                    // Object.keys(props.serverResponse).length > 0 && resultDetails.serverMessage.includes('success') ?
                    <article className="d-flex row justify-content-center mt-4">
                        {
                            modules.map(item => (
                                <span className={`${resultDetails.serverMessage && resultDetails.serverMessage.includes("success") ? 'bg-success' : 'bg-danger'} col-md-12 my-3 py-3 row  modules-title-icon`}>
                                    <span className="" key={item}>{item}</span>
                                    <GrStatusGood size={30} />
                                </span>
                            ))
                        }
                        {
                            <div className="row d-flex animate__animated animate__pulse animate__infinite">
                                <p>Navigating to Records Module in 5 seconds...</p>
                            </div>
                        }
                    </article>
                }
            </div>
        </div>
    )
};

export default EndOfDayResults;