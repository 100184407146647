import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yesterdaysFormDate } from '../../../assets/events/individualReservations';
import "../../../styles/RestaurantTrayStyles.css";

import axiosHelper from '../../../utilities/axiosHelper';
import { titleCase } from '../../../utilities/strings';

import { 
    fAndBDiscountAction,
    handleTrayBtnClick, 
    modifyItemOnTray, 
    setRestaurantTableItems, 
    setTableItemsFromOrderToModify 
} from './fAndBTrayHelper';
import TrayOrder from './TrayOrder';
import { spawn } from 'child_process';

interface Props {
    setState: Function,
    state: any
    formInput: any
    orderDetails: any
    pathname: string
    orderMode: "modify" | "new"
    orderDetailsToModify?: any
};

const FAndBTray = (props: Props) => {
    console.log("\n\t FAndBTray-orderMode", props.orderMode)
    const [fAndBTrayState, setFAndBTrayState] = useState({
        inventoryCategory: [] as any,
        categoryVarieties: [] as any[],
        varietyMenus: [] as any[],
        tableOrder : {} as any,
        paymentMethod: []as any,
        fAndBTables: [] as any,
        restaurantCategoryNames: [] as any,
        categoryVarietiesUnderChosenCategory: [] as any,
        varietyMenuUnderChosenCategoryVariety: [] as any,
        fAndBCharges: [] as any, 
        fAndBDiscount: [] as any, 
        chosenFAndBDiscount: "",
        loadingPrompt: "Hotel-Pro is setting up order sequence. Please wait",
        restaurantStateInputs: {},
        checkedInData: []as any,
        action: "",
        showTray: true,
        categoryName: "",
        tableNumber: "",
        pmAccounts: [] as any,
        showOrdersToModify: false,
        numberOfTrays: [] as number[],
        personNumber: "",
        showDiscount: false,
        orderTotalPerPerson: [] as number[],
        maxPersonsOnTableList: [] as number[],
        orderMode: ""
    });
    const handleDatalistSelect = (field:string, varietyMenu: string) => {
        const selectedMenuItemDetails = fAndBTrayState.varietyMenus.filter((item:any) => item.varietyMenu === varietyMenu);
        const familyGroupForSearchedItem = fAndBTrayState.categoryVarieties.filter((item:any) => item._id === selectedMenuItemDetails[0].inventoryVarietyDetails);
        // console.log("\n\t handleDatalistSelect-familyGroupForSearchedItem: ", familyGroupForSearchedItem)
        const majorGroupForSearchedItem = fAndBTrayState.inventoryCategory.filter((item:any) => item._id === familyGroupForSearchedItem[0].inventoryCategoryDetails);
        const varietiesUnderChosenCategory = fAndBTrayState.categoryVarieties.sort().map((cat: any) => cat.inventoryCategoryDetails === majorGroupForSearchedItem[0]._id);
        setFAndBTrayState({
            ...fAndBTrayState,
            categoryVarietiesUnderChosenCategory: [
                ...varietiesUnderChosenCategory
            ],
            categoryName: majorGroupForSearchedItem[0].categoryName
        });
        (document as any).querySelector('.major-group').value = majorGroupForSearchedItem[0].categoryName;
        (document as any).querySelector('.family-group').value = familyGroupForSearchedItem[0].categoryVarietyName;
        (document as any).querySelector('.menu-item').value = varietyMenu;
        // console.log("\n\t handleDatalistSelect-majorGroupForSearchedItem: ", majorGroupForSearchedItem)
    };
    const populateNumberOfPersonsOnTable = (value: string) => {
        const personsOnTable = +value;
        let count = 0;
        let numberOfTrays = [];
        let tableOrder = {};
        while(count < personsOnTable){
            numberOfTrays.push(count);
            (tableOrder as any)[`Person-${count+1}`] = []
            count++
        };
        return [numberOfTrays, tableOrder]
    }
    const handleRestaurantTrayInputChange = (field: string, value: string) => {
        // console.log("\n\t input: ", field, value)
        if(field === "tableNumber"){
            let count = 0;
            fAndBTrayState.maxPersonsOnTableList = [];
            const maxPersonsOnTable = fAndBTrayState.fAndBTables.find((table:any) => table.tableNumber == +value)
            while(count < maxPersonsOnTable.maximumPersonsOnTable){
                fAndBTrayState.maxPersonsOnTableList.push(count+1);
                count++
            }
            return  setFAndBTrayState({
                ...fAndBTrayState,
                tableNumber: value,
                restaurantStateInputs: {
                    tableNumber: value
                }
            })
        }else if(field == "numberOfPersonsOnTable"){
            const data = populateNumberOfPersonsOnTable(value);
            fAndBTrayState.numberOfTrays = (data as any[])[0];
            fAndBTrayState.tableOrder = data[1];
        }else if(field === "backtrackDate"){
            // console.log("\n\t backtrackDate: ", value);
            (fAndBTrayState.restaurantStateInputs as any)["backtrackDate"] = value;
        }else if(field === "personNumber"){
            // console.log("\n\t personNumber: ", (+value)+1)
            fAndBTrayState.personNumber = "";
            return setFAndBTrayState({
                ...fAndBTrayState,
                personNumber: String((+value)+1)
            })
        }else if(field === "discountCode"){
            // console.log("\n\t orderTotalPerPerson: ", value)
            const newOrderTotalPerPersonDueDiscount = fAndBDiscountAction({
                fAndBDiscount: value,
                fAndBTrayState,
                tableOrder: fAndBTrayState.tableOrder
            })
            // console.log("\n\t newOrderTotalPerPersonDueDiscount: ", newOrderTotalPerPersonDueDiscount)
            fAndBTrayState.orderTotalPerPerson = newOrderTotalPerPersonDueDiscount;
            fAndBTrayState.chosenFAndBDiscount = value
        }else if(field.includes("username") || field.includes("password")){
            props.formInput[field] = value;
        }
        (fAndBTrayState.restaurantStateInputs as any)[field] = value;
        setFAndBTrayState({
            ...fAndBTrayState
        })
    };
    // console.log("\n\t restaurantStateInputs: ", fAndBTrayState.restaurantStateInputs)
    // console.log("\n\t props.formInput: ", props.formInput)
    const dispatch = useDispatch();
    const buttonActions = ["Preview", "Cancel Order"];
    const roomNumberActions = ["Add Persons Order", "Cancel Order"];
    
    // ===========================
    const setCategoryVariety = (categoryName: string, fromSearch?: boolean) => {
        if(!fromSearch){
            (document as any).querySelector('.family-group').value = "";
            (document as any).querySelector('.menu-item').value = ""
        }
        const selectedCategory = fAndBTrayState.inventoryCategory.find((cat: any) => cat.categoryName === categoryName);
        const requiredVarieties = fAndBTrayState.categoryVarieties.sort().filter((catVariety: any) => String(catVariety.inventoryCategoryDetails) === String(selectedCategory._id));
        const varietiesUnderChosenCategory = requiredVarieties.sort().map((cat: any) => cat.categoryVarietyName);
        setFAndBTrayState({
            ...fAndBTrayState,
            categoryVarietiesUnderChosenCategory: [
                ...varietiesUnderChosenCategory
            ],
            categoryName
        });
        document.getElementById('family-group')?.focus();
        // const elementSize = document.getElementById('variety')?.setAttribute("size", 10);
        // (document as any).getElementById('variety')?.onfocus(() => 
    };
    // ==========================
    const setVarietyMenuOptions = (categoryVariety: string) => {
        const selectedCategoryVariety = fAndBTrayState.categoryVarieties.find((cat: any) => cat.categoryVarietyName === categoryVariety)
        // console.log("\n\t selectedCategoryVariety: ", selectedCategoryVariety);
        const requiredCategoryVariety = fAndBTrayState.varietyMenus.filter((varietyMenu: any) => String(varietyMenu.inventoryVarietyDetails) === String(selectedCategoryVariety._id))
        const varietyMenuUnderChosenCategoryVariety = requiredCategoryVariety.sort().map((cat: any) => cat.varietyMenu);
        setFAndBTrayState({
            ...fAndBTrayState,
            varietyMenuUnderChosenCategoryVariety: [
                ...varietyMenuUnderChosenCategoryVariety,
            ]
        })
    };
    // ==========================
    useEffect(() => {
        (async () => {
            try {
                const trayData = await axiosHelper({
                        routeName: `${props.pathname}/tray-data`,
                        httpVerb: "get"
                    });
                // console.log("\n\t trayData: ", trayData.data);
                const { category, pmAccount, fAndBTables, categoryVariety, fAndBCharges, fAndBDiscount, varietyMenu, paymentMethod } = trayData.data;
                const categoryNames = Object.values(category).map((cat: any) => cat.categoryName)
                // console.log("\n\t varietyMenu: ", varietyMenu);
                setFAndBTrayState({
                    ...fAndBTrayState,
                    inventoryCategory: [...category],
                    restaurantCategoryNames: [...categoryNames],
                    categoryVarieties: [...categoryVariety],
                    varietyMenus: [...varietyMenu],
                    loadingPrompt: "",
                    paymentMethod: [...paymentMethod],
                    pmAccounts: [...pmAccount],
                    fAndBCharges: [...fAndBCharges], 
                    fAndBDiscount: [...fAndBDiscount], 
                    fAndBTables: [...fAndBTables],
                    numberOfTrays: fAndBTrayState.orderMode == "" && props.orderMode == "modify" ? populateNumberOfPersonsOnTable(String(props.orderDetailsToModify['numberOfPersonsOnTable']))[0]as any: [0],
                    tableOrder:  fAndBTrayState.orderMode == "" && props.orderMode == "modify" ? props.orderDetailsToModify['orderDetails'][0] : [],
                    tableNumber: fAndBTrayState.orderMode == "" && props.orderMode == "modify" ? String(props.orderDetailsToModify['tableNumber']) : "",
                    personNumber: fAndBTrayState.orderMode == "" && props.orderMode == "modify" ? String(Object.keys(props.orderDetailsToModify['orderDetails']).length) : "",
                });
            } catch (error) {
                setFAndBTrayState({
                    ...fAndBTrayState,
                    loadingPrompt: "",
                })
            }
        })();
    }, []);
    // console.log("\n\t fAndBTrayState.varietyMenus: ", fAndBTrayState.varietyMenus);
    // console.log("\n\t modules: ", modules && modules);
    const fieldNames = ['Major Group', 'Family Group', 'Menu Item', 'Quantity'];
    const fieldTypes = ['select', 'datalist', 'datalist', 'input'];
    return (
        <div className="modal fade row d-flex justify-content-center align-items-center tray-container animate__animated animate__bounce" role="dialog" id="restaurantTray" >
            <div className="container">
                <p className="text-white">{fAndBTrayState.loadingPrompt}</p>
                {
                    props.orderMode === "new" &&
                    fAndBTrayState.showTray ?
                    <section className="form-group">
                        <label htmlFor="">Select Table:</label>
                        <select 
                            onChange={e => handleRestaurantTrayInputChange('tableNumber', e.target.value)}
                            className={`form-select form-control my-3`}
                            style={{fontSize: "1.3em"}}
                            id="">
                            <option className="my-5" value=''>Select Table</option>
                            {
                                fAndBTrayState.fAndBTables && fAndBTrayState.fAndBTables.map((table: any) => (
                                    <option style={{height: "3em"}} className="my-5" key={table.tableNumber} value={table.tableNumber}>{table.tableNumber} - (Maximum persons allowed: {table.maximumPersonsOnTable})</option>
                                ))
                            }
                        </select>
                        {
                            fAndBTrayState.tableNumber &&
                            <span>
                                <label htmlFor="">How many persons are seated on table {`${fAndBTrayState.tableNumber}?`}:</label>
                                <select 
                                    onChange={e => handleRestaurantTrayInputChange("numberOfPersonsOnTable", e.target.value)} 
                                    style={{fontSize: "1.6em"}} className='form-select form-control'>
                                    <option value="">Select</option>
                                {
                                    fAndBTrayState.maxPersonsOnTableList.map(num => (
                                        <option key={num} value={num}>{num}</option>
                                    ))
                                }
                                </select>
                            </span>
                        }
                        <span className="my-3 btn-container float-right">
                            {
                                roomNumberActions.map(action => (
                                    <span key={action}>
                                        {
                                            !(fAndBTrayState.restaurantStateInputs as any)['numberOfPersonsOnTable'] && (action.toLowerCase().includes('add') || action.toLowerCase().includes('select')) ? undefined :
                                            <button 
                                                key={action}
                                                onClick={e => handleTrayBtnClick(action, dispatch, fAndBTrayState, setFAndBTrayState, props.setState, props.state, props.pathname)} 
                                                data-toggle="modal"
                                                data-target="#restaurantTray"
                                                data-dismiss={action.toLowerCase().includes('cancel') ? "modal" : undefined}
                                                className={`btn ${action.toLowerCase().includes('cancel') ? 'btn-secondary' : 'btn-info'} my-2 mx-2`}>
                                                {action}
                                            </button>
                                        }
                                    </span>
                                ))
                            }
                        </span>
                    </section>
                    :
                    <div>
                        <section className='text-center'>
                            <table className="table table-hover table-striped table-bordered ">
                                <thead>
                                    <tr><th colSpan={13}>{
                                        props.orderMode === "new" ?
                                        `Order For Table: ${fAndBTrayState.tableNumber} (${titleCase(props.pathname.split("/")[1])})`
                                        :
                                        `Modifying Order For Table: ${props.orderDetailsToModify && props.orderDetailsToModify['tableNumber']}`
                                    }
                                    </th></tr>
                                </thead>
                            </table>
                            {
                            //props.orderMode == "modify" ? fAndBTrayState.tableOrder : undefined, fAndBTrayState.restaurantStateInputs, fAndBTrayState.tableNumber
                                <article style={{flexDirection: "row"}} className='row justify-content-center align-items-center d-flex'>
                                    <section style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                        <h4>Select Person</h4>
                                        <select style={{fontSize: "1.5em", display: "flex", width: "100%"}} onChange={e => handleRestaurantTrayInputChange('personNumber', e.target.value)} className='col-md-6 form-select form-control' name="" id="">
                                            <option value="">Select Person to place order for</option>
                                            {
                                                fAndBTrayState.numberOfTrays.map(person => (
                                                    <option  key={person} value={person}>Person {person+1}</option>
                                                ))
                                            }
                                        </select>
                                    </section>
                                    {
                                        fAndBTrayState.personNumber &&
                                        <div className="form-group mt-3">
                                            <h4>Search Menu-items</h4>
                                            <input 
                                                style={{fontSize: "1.3em",}}
                                                placeholder='Search menu-items'
                                                onClick={e => setRestaurantTableItems(fAndBTrayState, setFAndBTrayState, fAndBTrayState.personNumber as any)}
                                                // value={fAndBTrayState.restaurantStateInputs[field] as string || undefined} 
                                                onChange={e => handleDatalistSelect("data-list-menu-item", e.target.value)} className="form-control country" 
                                                list="menu-items" name="menu-item" type="text" id="menu-item" />
                                            <datalist style={{fontSize: "1.5em",}} id="menu-items">
                                                {
                                                    fAndBTrayState.varietyMenus && fAndBTrayState.varietyMenus.map((menu:any) => (
                                                        <option key={menu.varietyMenu} value={menu.varietyMenu} ></option>
                                                    ))
                                                }
                                            </datalist>
                                        </div>
                                    }
                                </article>
                            }
                        </section>
                        {
                            fAndBTrayState.personNumber &&
                            <TrayOrder
                                orderDetailsToModify={props.orderDetailsToModify}
                                orderMode={props.orderMode}
                                modifyItemOnTray={modifyItemOnTray}
                                fAndBTrayState={fAndBTrayState}
                                setFAndBTrayState={setFAndBTrayState}
                                personCount={fAndBTrayState.personNumber}
                                fieldNames={fieldNames}
                                fieldTypes={fieldTypes}
                                handleRestaurantTrayInputChange={handleRestaurantTrayInputChange}
                                setCategoryVariety={setCategoryVariety}
                                setRestaurantTableItems={setRestaurantTableItems}
                                setTableItemsFromOrderToModify={setTableItemsFromOrderToModify}
                                setVarietyMenuOptions={setVarietyMenuOptions}
                                tableOrder={fAndBTrayState.tableOrder}
                                totals={fAndBTrayState.orderTotalPerPerson}
                                pathname={props.pathname}
                            />
                        }
                        <section className="row d-flex justify-content-center mb-4 align-items-center">
                            {
                                (props.orderMode !== "new" || fAndBTrayState.showDiscount) &&
                                <div className="col-5">
                                    <label htmlFor="">Discounts:</label>
                                    <select 
                                        onChange={e => handleRestaurantTrayInputChange('discountCode', e.target.value)}
                                        style={{fontSize: "1.3em"}} className="form-select form-control" name="" id="">
                                        <option value="">Select F & B Discount</option>
                                        {
                                            fAndBTrayState.fAndBDiscount.map((charges: any, chargeIndex: number) => (
                                                <option key={charges.description} value={charges.description}>{charges.description}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            }
                            {
                                (props.orderMode !== "new") &&
                                <div className="col-4">
                                    {
                                        ["Username", "Password"].map(field => (
                                            <span key={field}>
                                                <label htmlFor="">{field}:</label>
                                                <input 
                                                    onChange={e => handleRestaurantTrayInputChange(`${field.toLowerCase()}`, e.target.value)} 
                                                    className='form-control' 
                                                    type={field.toLowerCase() === "username" ? "text" : "password"} 
                                                />
                                            </span>
                                        ))
                                    }
                                </div>
                            }
                        </section>
                        <section className="btn-container float-right">
                            {
                                buttonActions.map(action => (
                                    <span key={action}>
                                        {
                                            fAndBTrayState.tableOrder.length === 0 && action.toLowerCase() === 'pay' ? undefined : 
                                            <button
                                                onClick={e => handleTrayBtnClick(action, dispatch, fAndBTrayState, setFAndBTrayState, props.setState, props.state, props.pathname)} 
                                                data-toggle="modal"
                                                data-target="#modal"
                                                data-dismiss={action.toLowerCase().includes('cancel') ? "modal" : undefined}
                                                className={`btn btn-${action.toLowerCase().includes('cancel') ? 'secondary' : 'info'} mx-2`}>
                                                {action}
                                            </button>
                                        }
                                    </span>
                                ))
                            }
                        </section>
                    </div>
                }
            </div>
        </div>

    )
};

export default FAndBTray;
