import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import colors from '../../../../assets/colors';
import "../../../../styles/FolioStyles.css";
import axiosHelper from '../../../../utilities/axiosHelper';

import { pascalCaseSeparator, tableDataCleaner } from '../../../../utilities/strings';
import { taxComputation } from '../../../../utilities/taxComputation';
import Gifs from '../../../Gifs';

interface Props {
    receiptDetailsData: any
    hideFolio: Function
    state: any
    generatedBy?: string
    type: "Reservation" | "Bar" | "Restaurant" | "Pool-Bar"
};

const orderPrep = (relatedOrder: any) => {
    // console.log("\n\t props.pointOfSalesData['orderDetails']: ", relatedOrder['orderDetails']);
    const receiptsOrderDetails = []as any[];
    let totalDue = 0;
    
    const orderDataDetails = relatedOrder;
    // console.log("\n\t orderDataDetails: ", orderDataDetails)
    orderDataDetails['orderDetails'].forEach((personsOrder: any) => {
        const orderForEachPersons = Object.values(personsOrder);
        for(let order of orderForEachPersons){
            // console.log("\n\t order: ", order)
            for(let inenerItems of order as any){
                // console.log("\n\t inenerItems: ", inenerItems)
                const dataForReceiptDisplay = {}as any;
                dataForReceiptDisplay["item"] = inenerItems.item; 
                dataForReceiptDisplay["cost"] = inenerItems.cost; 
                dataForReceiptDisplay["quantity"] = inenerItems.quantity; 
                dataForReceiptDisplay["total"] = +inenerItems.cost * +inenerItems.quantity;
                totalDue += +inenerItems.cost * +inenerItems.quantity;
                receiptsOrderDetails.push(dataForReceiptDisplay);
            }
        }
    });
    const totalForReceipt = {item: "Total", total: totalDue};
    const discount = {item: "Discount", total: relatedOrder.discount};
    const pay = {item: "Pay", total: relatedOrder.totalCharge};
    const receiptNumber = {item: "Receipt Number", total: relatedOrder.receiptNumber};
    receiptsOrderDetails.push(totalForReceipt);
    receiptsOrderDetails.push(discount);
    receiptsOrderDetails.push(pay);
    receiptsOrderDetails.push(receiptNumber);
    // console.log("\n\t receiptsOrderDetails: ", receiptsOrderDetails)
    return receiptsOrderDetails
}

export const originsToShowTaxData = ['lekki'];
const Receipt = (props: Props) => {
    const appLocation = useLocation();
    const pathname = appLocation.pathname;
    const origin = window.location.origin.split("//")[1].split(".")[0].split("-")[1];

    const receiptDetailsData = props.receiptDetailsData;
    // console.log("\n\t receiptDetailsData: ", receiptDetailsData)
    const [receiptState, setReceiptState] = useState({
        orderDetails: [] as any[],
        dateCreated: "",
        headers: []as string[],
    })

    // console.log("\n\t props.generatedBy: ", props.generatedBy)
    const headers = [] as string[];
    const operations = ["Back", "Print"];
    useEffect(() => {
        (async() => {
            if(pathname.includes('reservations')){
                console.log("\n\t Number: ", props.receiptDetailsData)
                const {receiptNumber, teller, generatedBy} = props.receiptDetailsData;
                const response = await axiosHelper({
                    httpVerb: "get",
                    routeName: `${pathname}/${receiptNumber}`
                });
                // console.log("\n\t reseponse: ", response)
                const data = response ? response.data : {};
                if(Object.keys(data).length > 0){
                    console.log("\n\t data: ", data)
                    Object.keys(data.requiredReceipts[0]).map((key: string) => {
                        (!key.toLowerCase().includes("generated") && !key.toLowerCase().includes("receipts") && !key.toLowerCase().includes("string") && !key.toLowerCase().includes("month")) && headers.push(key)
                    });
                    // console.log("\n\t orderDetails: ", data.requiredReceipts)
                    setReceiptState({
                        ...receiptState,
                        orderDetails: data.requiredReceipts[0],
                        headers,
                        dateCreated: data.requiredReceipts[0].dateCreated
                    });
                };
            }else{
                // console.log("\n\t Number: ", props.receiptDetailsData)
                const {receiptNumber, teller, generatedBy} = props.receiptDetailsData;
                const response = await axiosHelper({
                    httpVerb: "get",
                    routeName: `${pathname}/${receiptNumber}`
                });
                console.log("\n\t reseponse: ", response)
                const data = response ? response.data : {};
                if(Object.keys(data).length > 0){
                    console.log("\n\t data: ", data)
                    const {discount, relatedOrder} = data.requiredReceipts[0];
                    // console.log("\n\t data.requiredReceipts: ", data.requiredReceipts[0])
                    // console.log("\n\t relatedOrder: ", relatedOrder)
                    Object.keys(relatedOrder).map((key: string) => {
                        (!key.toLowerCase().includes("receipients") && !key.toLowerCase().includes("generated")) && headers.push(key)
                    });
                    const relatedOrderDetails = {
                        ...relatedOrder, 
                        receiptNumber,
                    };
                    const orderDetails = orderPrep(relatedOrderDetails);
                    console.log("\n\t orderDetails: ", orderDetails)
                    setReceiptState({
                        ...receiptState,
                        orderDetails,
                        headers,
                        dateCreated: relatedOrder.dateCreated || data.requiredReceipts.dateCreated
                    });
                };
            }
        })();
    }, []);
    return (
        <React.Fragment>
            <div className="row d-flex justify-content-center ml-5 ">
                <div className="row d-flex justify-content-center ml-5 folio-container">
                    <section style={{flexDirection: "column"}} className='receipt-header row d-flex'>
                        <span style={{flexDirection: "column"}} className='ml-1 row d-flex receipt-logo'>
                            <Gifs width={150} height={150} gifName='hotel-logo' />
                            <Gifs className='companys-address-and-name' gifName='company-details' />
                        </span>
                        <p className="text-black font-weight-bold folio-title">{props.type} Receipt</p> 
                        <p className="text-center font-weight-bold folio-timestamp text-black">
                            {
                                receiptState.dateCreated ?
                                <React.Fragment>
                                    {new Date(receiptState.dateCreated).toDateString()} {new Date(receiptState.dateCreated).toLocaleTimeString()} 
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {new Date(receiptState.dateCreated).toDateString()} {new Date(receiptState.dateCreated).toLocaleTimeString()}
                                </React.Fragment>
                            }
                        </p>
                    </section>
                    {
                        (pathname.includes("bar") || pathname.includes("restaurant")) ?
                        <table className="f-and-b-table table table-hover table-bordered table-striped">
                            <tbody>
                                {
                                    receiptState.orderDetails.length > 0 &&
                                    receiptState.orderDetails.map((order: any, index: number) => (
                                        <tr key={index} style={{width: "100%"}} className="text-center text-black">
                                            {
                                                ["Item", "Quantity", "Cost", "Total"].map(detail=> (
                                                    <td style={{fontSize: order['item'].toLowerCase().includes("pay") ? "1.8rem" : "1.6rem", fontWeight: order['item'].toLowerCase().includes("pay") ? "bolder" : "bold"}} key={detail} >
                                                        {
                                                            order['item'].toLowerCase().includes("total") &&
                                                            <p>____________</p>
                                                        }
                                                        {tableDataCleaner(detail, order)}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                                {
                                    (originsToShowTaxData.includes(origin) && props.state != undefined) &&
                                    <React.Fragment>
                                        <tr style={{width: "100%"}} className="text-center text-black">
                                            <td style={{fontSize: "1.3rem"}}>Net Charge:</td>
                                            {
                                                receiptDetailsData &&
                                                <td style={{fontSize: "1rem"}}>{Number(taxComputation(receiptDetailsData.cost, props.state.taxes).netCharge).toLocaleString()}</td> 
                                            }
                                        </tr>
                                        {
                                            receiptDetailsData &&
                                            <tr style={{width: "100%"}} className="text-center text-black">
                                                <td>VAT ({props.state.taxes['vat']}%)</td>
                                                <td style={{fontSize: "1rem"}}>{Number(taxComputation(receiptDetailsData.cost, props.state.taxes).vatCharge).toLocaleString()}</td> 
                                            </tr>
                                        }
                                    </React.Fragment>
                                }
                            </tbody>
                        </table>
                        :
                        <table className="f-and-b-table table table-hover  table-bordered table-striped">
                            <tbody style={{ backgroundColor: colors.ash }} className="text-dark font-weight-bold">
                                {
                                    receiptState.headers.length > 0 && receiptState.headers.sort().map(header => (
                                        <tr style={{width: "100%"}} className="text-center" key={header}>
                                            <td >{pascalCaseSeparator(header)}</td>
                                            <td >{tableDataCleaner(header, receiptState.orderDetails)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            {
                                originsToShowTaxData.includes(origin) &&
                                <tfoot>
                                    {
                                        <tr>
                                            <td>Net Charge:</td>
                                            <td></td>
                                        </tr>
                                    }
                                </tfoot>
                            }
                        </table>
                    }
                    <div className='signature-container mt-2'>
                        <section style={{flexDirection: "column"}} className="d-flex justify-content-center">
                            {
                                !pathname.includes("bar") && !pathname.includes("restaurant") &&
                                <React.Fragment>
                                    <p style={{}} className="mt-1 font-weight-bold">Name: {props.receiptDetailsData.receipientsName}</p>
                                    {/* <p className="mt-1">Room: {props.receiptDetailsData.receipientsName}</p> */}
                                    <p className="mt-1">Signature: ___________________</p>
                                </React.Fragment>
                            }
                            <p className="mt-1 font-weight-bold text-black">Teller: 
                                {
                                    props.generatedBy ?
                                    <span className='mx-2'>
                                        {props.generatedBy}
                                    </span>
                                    :
                                    <span className='mx-2'>
                                        {props.receiptDetailsData.generatedBy}
                                    </span>
                                }
                            </p>
                            <p className="mt-1">Signature: ___________________</p>
                        </section>
                        {
                            !pathname.includes("bar") && !pathname.includes("restaurant") &&
                            <section style={{width: "80%"}}>
                                <article className="text-center ">
                                    <p className="mt-1 font-weight-bold text-black">Disclaimer: This is to certify that I <span className='text-black'>{props.receiptDetailsData.receipientsName.toUpperCase()}</span>, consent that the data hereon are accurate and appropriate.</p> 
                                    <p className="text-danger font-weight-bold small">Please verify data, before appending signatures.</p> 
                                </article>
                            </section>
                        }
                    </div>
                </div>
            </div>
            <div className="row d-flex float-right print-btn-container">
                {
                    operations.map(ops => (
                        <button 
                            key={ops}
                            onClick={ops.toLowerCase() === 'print' ? () => window.print() : () => props.hideFolio({
                                ...props.state,
                                modalType: "", // for F and B tables
                                buttonAction: "", // for other tables
                            }) }
                            className={`mx-2 btn ${ops.toLowerCase() === 'print' ? 'btn-success' : 'btn-secondary'}`}>
                            {ops}
                        </button>
                    ))
                }
            </div>
        </React.Fragment>
    )
}

export default Receipt