import { AllHTMLAttributes, useEffect } from "react";
import { HiSearchCircle } from "react-icons/hi"
import { useSelector } from "react-redux";
import fandbLoadingGif from "../assets/img/fandb-loading-gif.jpg"

import ChannelProLogo from "../assets/img/ChannelProLogo.jpeg";
interface GifInterface extends AllHTMLAttributes<any> {
    gifName: "fandb" | "" | "hotel-logo" | "search" | "channel-pro" | "company-details"
    width?: number
    height?: number
}

const Gifs = (props: GifInterface) => {
    const store = useSelector((store: any) => store)
    const { userDetails } = store;
    const { companysLogo, companysAddress, companysEmail } = userDetails.userDetails ? userDetails?.userDetails : "";
    // console.log("\n\t userDetails: ", userDetails)
    // console.log("\n\t companysLogo: ", companysLogo)
    return(
        <div>
            {
                props.gifName == "fandb" ?
                <img width={props.width ? props.width : 100} height={props.height ? props.height : 50} src={fandbLoadingGif} />
                :
                props.gifName == "hotel-logo" ?
                <img width={props.width || 120} height={props.height || 120} src={companysLogo || undefined} alt="Companys' logo" />
                // <img width={120} height={120} src={''} />
                :
                props.gifName == "search" ?
                <HiSearchCircle size={23} color="#f4f4f4" />
                :
                props.gifName == "channel-pro" ?
                <img width={150} height={150} src={ChannelProLogo} />
                :
                props.gifName == "company-details" ?
                <span style={{flexDirection: "column", width: "100%"}} className="companys-address-and-name justify-content-center align-items-center text-align-center d-flex">
                    <p style={{marginBottom: "-3px"}} className="text-align-center">{companysAddress}</p>
                    <p className="text-align-center">{companysEmail}</p>
                </span>
                :
                // input
                ''
            }
        </div>
    )
};

export default Gifs