import { yesterdaysFormDate } from "../../../../../assets/events/individualReservations";
import { showConfirmModalAction, showFormModalAction } from "../../../../../redux/actions/storeActions/appActionsUtilities";
import axiosHelper from "../../../../../utilities/axiosHelper";
import { customAlert } from "../../../../../utilities/customAlert";
import {  getAllSystemCharges, getAllSystemCredits, getFrontdeskDetails } from "../../../../../utilities/databaseHelpers";
import { dateMaker,  } from "../../../../../utilities/dateHelper";
import { departments, eventsTableHeader, groupDetailsCheckedInTableHeader } from "../tableData";
import { varyingDatesComparator, todaysDate, givenDateAndCurrentTime, tomorrowsDate, dateStringToDate } from "date-fran";
import { titledTextJoiner } from "../../../../../utilities/strings";

// console.log("\n\t dateFran-givenDateAndCurrentTime: ", givenDateAndCurrentTime())
// console.log("\n\t dateFran-todaysDate: ", todaysDate())


interface ChangeRoomExtensionInterface {
    rooms: any[]
    roomTypes: any[]
    currentRoomNumber?: number
    selectedRoomType: string
}
export const changeRoomExtension = (props: ChangeRoomExtensionInterface) => {
    const roomTypeDetailsFromServer = props.roomTypes.filter((roomType: any) => roomType.roomTypeName === props.selectedRoomType);
    const roomsUnderRoomType = props.rooms.filter((room: any) => (room.roomTypeId === roomTypeDetailsFromServer[0]._id) && (room.occupancyStatus !== "Occupied"))
    const availableRoomsToChangeTo = [];
    for(let room of roomsUnderRoomType){
        if((room.roomNumber !== props.currentRoomNumber) && (room.occupancyStatus === "Vacant")){
            availableRoomsToChangeTo.push(room.roomNumber);
        };
    };
    return availableRoomsToChangeTo;
};

interface GroupDetailsMakerInterface {
    reservationData: any
    frontdesksState: any
    setDetailsData: Function
};

export const groupDetailsMaker = (props: GroupDetailsMakerInterface) => {
    try {
        
        const individualDetailsInGroup = props.reservationData.individualDetailsInGroup;
        console.log("\n\t group-details-props.reservationData: ", props.reservationData)
        const tableData = [] as any;
        let individualDetailsInGroupCount = 1;
        individualDetailsInGroup.forEach((data: any, index: number) => {
            const actualData = data[0];
            // console.log("\n\t actualData: ", actualData)
            const refinedData = {
                "ID": individualDetailsInGroupCount,
                guestName: (actualData.firstname).concat(" ", actualData.lastname),
                guestPhone: actualData.phone,
                roomNumber: actualData.roomNumber,
                floorNumber: actualData.floorNumber,
                roomType: actualData.roomType,
                checkOutDate: actualData.departureDate,
                groupName: props.reservationData.groupName,
                checkInId: props.reservationData._id,
                _id: props.reservationData._id,
                individualCheckInDate: actualData.individualCheckInDate,
                individualCheckOutDate: actualData.individualCheckOutDate || actualData.departureDate,
                extendedStay: actualData.extendedStay || 0,
                changedRoomTo: actualData.changedRoomTo || "None"
            }
            tableData.push(refinedData);
            individualDetailsInGroupCount++
        });
        return props.setDetailsData({
            ...props.frontdesksState,
            reservationData: props.reservationData,
            showDetails:  true,
            dataToBeDetailed:  tableData,
            tableHeaderForDataToBeDetailed: [...groupDetailsCheckedInTableHeader],
            groupName: props.reservationData.groupName,
            footerArray: ["", "", "", "", "", "", "", "", "", "", ""],
            detailsDataTableTitle: `Group Details for ${props.reservationData.groupName}`
        });
    } catch (error) {
        console.log("\n\t groupDetailsMaker-error: ",)
    }
}

interface FrontdeskActionsHandlerInterface {
    pathname: string
    action: string
    reservationData: any
    dispatch: Function
    setFrontdesksState: Function
    frontdesksState: any
    // setGroupCheckInFormData: Function,
    setDetailsData: Function
    detailsStatus: boolean
};



export const handleFrontdeskActions = async (props: FrontdeskActionsHandlerInterface) => {
    console.log(`\n\t FrontdeskTable frontdeskActionsHandler-clicked-reservationData.......`, props.action);
    console.log("\n\t  props.frontdesksState.appModulesData: ", props.frontdesksState.appModulesData)
    props.frontdesksState.reservationData = props.reservationData;
    const { paymentMethod, membershipTypes } = props.frontdesksState.utilityData as any;
    const allSystemCredits = props.frontdesksState.allSystemCredits;
    const allSystemCharges = props.frontdesksState.allSystemCharges;
    console.log("\n\t props.action: ", props.action)
    if(props.action === "Check-in"){
        // console.log("\n\t Choice: frontdeskActionsHandler-check-in-reservationData: ", props.pathname, props.reservationData)
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        props.frontdesksState.previewData = true;
        if(props.pathname.includes('individual') || props.pathname.includes('history')){
            const paymentMethods = []as string[];
            paymentMethod.forEach((method: any) => paymentMethods.push(method.description));
            // console.log("\n\t paymentMethod: ", paymentMethod)
            const frontdeskChargeDescription: any = [];
            // const frontdeskCharges = await getAllSystemCharges();
            allSystemCharges.forEach((charge: any) => {
                frontdeskChargeDescription.push(charge.description)
            });
            return  props.setFrontdesksState({
                ...props.frontdesksState,
                confirmModalTitle: "Check guest in?",
                formModalTitle: "Deposit on Check-in",
                formFields: ["Deposit", "Payment Method"],
                formFieldPlaceholder: ["Amount being deposited on check-in"],
                formFieldTypes: ["number", 'select'],
                reservationData: props.reservationData,
                formModalSaveButtonName: "Check-in",
                serverSelectOption: {
                    chargeDescription: frontdeskChargeDescription.sort(),
                    paymentMethod: paymentMethods
                },
                btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
            });
        };
        const { groupName, totalNumberOfRooms, roomTypeAndNumberOfRooms } = props.reservationData;
        props.frontdesksState.formModalTitle = `Check ${groupName} Group in`;

        const numberOfTimesToLoop: any[] = [];
        let count = 0;
        while(count < totalNumberOfRooms){
            // console.log("\n\t while loop: ", numberOfTimesToLoop)
            numberOfTimesToLoop.push(count);
            count++
        };
        console.log("\n\t  props.frontdesksState.appModulesData---2: ", props.frontdesksState.appModulesData)
        return props.setFrontdesksState({
            ...props.frontdesksState,
            groupCheckInFormData: {
                ...props.reservationData,
                ...props.frontdesksState.appModulesData,
                numberOfTimesToLoop,
            }
        });

    }else if(props.action === "Cancel Reservation"){
        // props.setReservationData(props.reservationData);
        props.frontdesksState.formFields = ["Username", "Password"]
        props.frontdesksState.formFieldTypes = ["text", "password"];
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            reservationData: props.reservationData,
            formModalSaveButtonName: "Continue",
            confirmModalTitle: "Are you sure about cancelling this reservation?",
            formModalTitle: `Cancelling a reservation requires authorization`,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        });

    }else if(props.action === "Move In-House"){
        // props.setReservationData(props.reservationData);
        console.log("\n\t Move In-House: ", props.reservationData)
        console.log("\n\t Move In-House-utilityData: ", props.frontdesksState.utilityData)
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        const roomTypesForMoveInHouse = props.frontdesksState.utilityData.roomTypes;
        const roomTypeNamesForMoveInHouse = []as string[];
        roomTypesForMoveInHouse.filter((roomType: any) => roomTypeNamesForMoveInHouse.push(roomType.roomTypeName));
        // console.log("\n\t props.reservationData.credit - props.reservationData.debit: ", props.reservationData.credit - props.reservationData.debit)
        const systemMembershipTypesForMoveInHouse = []as string[];
        membershipTypes.forEach((type: any) => systemMembershipTypesForMoveInHouse.push(type.membershipType));
        props.setFrontdesksState({
            ...props.frontdesksState,
            reservationData: props.reservationData,
            formFields: ["Departure Date", "Room Type", "Room Number", "Membership Type", "Deposit"],
            formFieldTypes: ["date", "select", "select", "select", "text"],
            formModalSaveButtonName: "Move In-House",
            confirmModalTitle: `This action will check ${props.reservationData.accountName || props.reservationData.guestName} in. Are you sure about moving the guest?`,
            formModalTitle: `Moving ${props.reservationData.accountName || props.reservationData.guestName} in-house.`,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            readOnlyFields: [false, false, false, false, true],
            formFieldPlaceholder: ["", "", "", "",  props.reservationData.credit - props.reservationData.debit],
            serverSelectOption: {
                membershipType: systemMembershipTypesForMoveInHouse.sort(),
                roomType: roomTypeNamesForMoveInHouse.sort()
            },
            formInputs: {
                deposit: (props.reservationData.credit - props.reservationData.debit) || 0,
            },
        });

    }else if(props.action === "Cancel Event"){
        // props.setReservationData(props.reservationData);
        props.frontdesksState.formFields = ["Username", "Password"]
        props.frontdesksState.formFieldTypes = ["text", "password"];
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            reservationData: props.reservationData,
            formModalSaveButtonName: "Continue",
            confirmModalTitle: "Are you sure about cancelling this event?",
            formModalTitle: `Cancelling an event requires authorization`,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        });
    }else if(props.action === "Backtrack Guest"){
        // props.setReservationData(props.reservationData);
        props.frontdesksState.formFields = ["Username", "Password"]
        props.frontdesksState.formFieldTypes = ["text", "password"];
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            reservationData: props.reservationData,
            formModalSaveButtonName: "Continue",
            confirmModalTitle: props.pathname.includes("history") ?
             "Backtracking an already checked-out guest is fraudulent and affects settled transactions. An operational email will also be sent to ChannelPro and the operational manager. Would you still want to carry on with this action?"
             : "Before backtracking this guest, ensure that the guest's check-out date has not been exceeded as it will be impossible to bring the guest in-house. However, this operation is safe, if the guest's checkout date is later than today. Continue?",
            formModalTitle: `Backtracking A Guest From ${props.pathname.includes("history") ? "History (Check-Out)" : "In-ouse"}`,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        });
    }else if(props.action === "Extend Stay"){
        props.frontdesksState.modalFormEditFlag = true;
        
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));

        // console.log("\n\t Checkout date: ", (new Date(props.reservationData.checkOutDate)).toLocaleDateString())
        // console.log("\n\t Choice: frontdeskActionsHandler-Extend Stay: ", props.reservationData);
        return  props.setFrontdesksState({
            ...props.frontdesksState,
            confirmModalTitle: `Are you sure about extending ${props.reservationData.guestName || props.reservationData.groupName}'s stay?`,
            formModalTitle: `Extend ${props.reservationData.guestName || props.reservationData.groupName}'s stay`,
            formFields: ["Supposed Departure Date", "Extended Stay"],
            formFieldPlaceholder: ["", "The number of days to add"],
            formFieldTypes: ["string", "date"],
            readOnlyFields: [true, false],
            reservationData: props.reservationData,
            formModalSaveButtonName: "Extend",
            formInputs: {
                supposedDepartureDate: (givenDateAndCurrentTime(props.reservationData.checkOutDate.split("T")[0])).toLocaleDateString(),
            },
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        })
    }else if(props.action === "Edit Details"){
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));

        // console.log("\n\t Choice: frontdeskActionsHandler-Extend Stay: ", props.reservationData)
        const guestTitles = []as string[];
        props.frontdesksState.utilityData.guestTitles.forEach((title:any) => guestTitles.push(title.titleCode));
        // console.log("\n\t frontdesksState.detailsMembershipTypes: ", detailsMembershipTypes)
        const guest = `${props.reservationData.guestName}`.split(" ");
        const systemMembershipTypesForEditDetails = []as string[];
        membershipTypes.forEach((type: any) => systemMembershipTypesForEditDetails.push(type.membershipType));
        return  props.setFrontdesksState({
            ...props.frontdesksState,
            confirmModalTitle: `Are you sure about updating ${props.reservationData.guestName}'s details?`,
            formModalTitle: `Updating check-in details for ${props.reservationData.guestName}`,
            formFields: ["Title", "Firstname", "Lastname", "Email", "Phone", "Deposit", "Room Rate", "No Post", "Membership Type", "Total Cost"],
            formFieldPlaceholder: [guest[0], guest[1], guest[2], `${props.reservationData.email}`, "", `${props.reservationData.deposit}`, `${props.reservationData.roomRate}`,],
            formFieldTypes: ["select", "text", "text", "email", "tel", "number", "number", "select", "select", "number"],
            // readOnlyFields: [true, false],
            reservationData: props.reservationData,
            formModalSaveButtonName: "Save",
            serverSelectOption: {
                membershipType: systemMembershipTypesForEditDetails.sort(),
                title: guestTitles.sort(),
                noPost: ["True", "False"]
            },
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        })

    }else if(props.action === "Change Room"){
        // console.log("\n\t Change room: ", props.reservationData)
        // console.log("\n\t props.frontdesksState-appModulesData: ", props.frontdesksState.appModulesData)
        const { rooms, roomTypes } = props.frontdesksState.utilityData as any;
        props.frontdesksState.modalFormEditFlag = true;
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        
        props.frontdesksState.modalFormInstruction = "Ensure you report the reason to the concerned department to get the room fixed-up.";
        const currentRoomType = props.reservationData.roomType;
        const currentRoomNumber = props.reservationData.roomNumber;

        const roomTypeNames = []as string[]
        roomTypes.filter((roomType: any) => roomTypeNames.push(roomType.roomTypeName));
        const availableRoomsToChangeTo = changeRoomExtension({
            currentRoomNumber,
            rooms,
            roomTypes,
            selectedRoomType: currentRoomType
        });
        const systemMembershipTypesForChangeRoom = []as string[];
        membershipTypes.forEach((type: any) => systemMembershipTypesForChangeRoom.push(type.membershipType));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            readOnlyFields: [false, true, false],
            reservationData: props.reservationData,
            formModalTitle: `Change ${props.reservationData.guestName}'s room`,
            serverSelectOption: {
                newRoom: availableRoomsToChangeTo.sort(),
                roomType: roomTypeNames.sort(),
                membershipType: systemMembershipTypesForChangeRoom.sort()
            },
            confirmModalTitle: `Are you sure about changing ${props.reservationData.guestName}'s room?`,
            // formFields: ["Room Type", "Current Room", "Membership Type", "New Room", "Reason For Change"],
            formFields: ["Room Type", "Current Room", "New Room", "Reason For Change"],
            formFieldPlaceholder: ["", "", "Why are you changing the guests room?"],
            formFieldTypes: ["select", "text", "select", "textarea"],
            formInputs: {
                currentRoom: `${currentRoomType}-${props.reservationData.roomNumber}`,
            },
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            formModalSaveButtonName: "Change Room",
        })

    }else if(props.action === "Pay Balance"){
        console.log("\n\t Choice: frontdeskActionsHandler-pay balance-reservationData: ", props.pathname, props.reservationData)
        const balance = props.reservationData.balance;
        if(balance <= 0){
            return customAlert("error", "You can't pay balance for a non-owing guest.", 5000);
        }
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        // let { paymentMethod, frontdeskCharges } = props.frontdesksState.appModulesData[0] as any;
        // console.log("\n\t appModulesData: ", props.frontdesksState.appModulesData)
        // console.log("\n\t frontdeskCharges: ", frontdeskCharges)

        const paymentMethods: any = [];
        const frontdeskChargeCategoriesForPayBalance: any = [];
        paymentMethod && paymentMethod.forEach((method: any) => {
            paymentMethods.push(method.description)
        });
        // const frontdeskCharges = await getAllSystemCharges();
        allSystemCharges && allSystemCharges.forEach((charge: any) => {
            frontdeskChargeCategoriesForPayBalance.push(charge.description)
        });
        return props.setFrontdesksState({
            ...props.frontdesksState,
            // confirmModalTitle: `This action entails that the guest has payed you a total ${(props.reservationData.balance).toLocaleString()}. If you continue, this will return the guests outstanding balance to zero. Are you sure about this?`,
            confirmModalTitle: `This will return the guests outstanding balance to zero. Are you sure about this?`,
            serverSelectOption: {
                paymentMethod: paymentMethods.sort(), 
                chargeCategory: frontdeskChargeCategoriesForPayBalance.sort(),
            },
            formModalTitle: "Pay Balance",
            formFields: ["Payment Method"],
            formFieldPlaceholder: ["Outstanding balance before checkout"],
            formFieldTypes: ["select"],
            reservationData: props.reservationData,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            formModalSaveButtonName: "Make Payment"
        });
    }else if(props.action === "Check-Out"){
        console.log("\n\t Choice: frontdeskActionsHandler-check-out: ", props.pathname, props.reservationData)
        props.dispatch(showConfirmModalAction(true));
        props.dispatch(showFormModalAction(false));
        props.frontdesksState.reservationData = props.reservationData;
        
        if(props.detailsStatus || props.pathname.includes('individual')){
            if(props.pathname.includes('individual')){
                props.frontdesksState.confirmModalTitle = "Proceed with checking guest out?"
                props.frontdesksState.btnAction = `${props.action.toLowerCase().replaceAll(" ", "-")}`
            }else{
                props.frontdesksState.confirmModalTitle = `Proceed with checking this guest out from ${props.reservationData.groupName}?`
            }
            return props.setFrontdesksState({
                ...props.frontdesksState,
                btnAction:`${props.action.toLowerCase().replaceAll(" ", "-")}`,
            });
        };
        return props.setFrontdesksState({
            ...props.frontdesksState,
            btnAction:`${props.action.toLowerCase().replaceAll(" ", "-")}`,
            confirmModalTitle: `This operation would check everyone in ${props.reservationData.groupName} group out. If this is not what is intended, then click on 'Group Details', under 'Actions'; to check guests out, per-person. Otherwise, proceed?`
        });
    }else if(props.action === "Group Details"){
        return groupDetailsMaker({
            setDetailsData: props.setDetailsData,
            frontdesksState: props.frontdesksState,
            reservationData: props.reservationData
        })
    }else if (props.action === "Close Checking-in"){
        props.dispatch(showConfirmModalAction(true));
        props.dispatch(showFormModalAction(false));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            reservationData: props.reservationData,
            btnAction:`${props.action.toLowerCase().replaceAll(" ", "-")}`,
            confirmModalTitle: "Close Checking-in implies that you have inquired and confirmed that the remaining guests who are not checked-in yet, won't be coming anymore. Is this correct?"
        })
    }else if(props.action === "End Event"){
        console.log("\n\t End Event: ", props.reservationData);
        const endDate = givenDateAndCurrentTime(props.reservationData.endDate)
        const todaysDateExceedsEndDate = varyingDatesComparator(endDate, todaysDate())
        if(!todaysDateExceedsEndDate){
            props.dispatch(showConfirmModalAction(false));
            props.dispatch(showFormModalAction(false));
            return customAlert("error", "You can't end an event whose end date isn't past", 6000);
        }else{
            props.dispatch(showConfirmModalAction(true));
            props.dispatch(showFormModalAction(false));
            return props.setFrontdesksState({
                ...props.frontdesksState,
                reservationData: props.reservationData,
                btnAction:`${props.action.toLowerCase().replaceAll(" ", "-")}`,
                confirmModalTitle: "Ending an event implies that the designated end date of this event has been exceeded and as such, should be marked as a completed event. Is this correct?"
            })
        }
    }else if(props.action === "Add Event"){
        console.log("\n\t Add Event: ", props.reservationData);
        props.dispatch(showFormModalAction(true));
        const paymentMethodsForAddEvents = []as string[];
        paymentMethod.forEach((method: any) => paymentMethodsForAddEvents.push(method.description));
        // const minChargeAddEventDate = dateMaker(newMadeAddEventDate.getFullYear(), newMadeAddEventDate.getMonth(), newMadeAddEventDate.getDate());
        // const maxChargeAddEventDate = dateMaker(newMadeEventDate.getFullYear(), newMadeEventDate.getMonth(), newMadeEventDate.getDate());
        return props.setFrontdesksState({
            ...props.frontdesksState,
            confirmModalTitle: `Create event for ${props.reservationData.facilityName}?`,
            formModalTitle: `Create New Event For ${props.reservationData.facilityName}.`,
            formModalSaveButtonName: "Create",
            // readOnlyFields: [false, false, false, false, false, false, false],
            // minDate: minChargeAddEventDate,
            maxDate: yesterdaysFormDate(),
            reservationData: props.reservationData,
            formFieldPlaceholder: ["Group or Individual using the facility", "", "", "", "", props.reservationData.chargePerHour, "", ""],
            formFields: ["Entity", "Phone", "Deposit", "Payment Method", "Start Date", "End Date", "Total Charge", props.pathname.includes('backtrack') && "Backtrack Date", props.pathname.includes("backtrack") && "Username", props.pathname.includes("backtrack") && "Password"],
            formFieldTypes: ["text", "tel", 'number', "select", "date", "date", "number", "date", props.pathname.includes("backtrack") && "text", props.pathname.includes("backtrack") && "password"],
            serverSelectOption: {
                paymentMethod: paymentMethodsForAddEvents.sort()
            },
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        })
    }else if(props.action === "Update Event"){
        props.dispatch(showFormModalAction(true));
        const paymentMethodsForUpdateEvents = []as string[];
        paymentMethod.forEach((method: any) => paymentMethodsForUpdateEvents.push(method.description));
        console.log("\n\t Update Event: ", props.reservationData);
        props.dispatch(showFormModalAction(true));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            confirmModalTitle: `Update event for ${props.reservationData.facilityName}?`,
            formModalTitle: `Update Event For ${props.reservationData.facilityName}.`,
            formModalSaveButtonName: "Update",
            reservationData: props.reservationData,
            formFieldPlaceholder: [props.reservationData.guestDetails, props.reservationData.guestPhone, "", "", "", +props.reservationData.totalCharge, "", ""],
            formFields: ["Entity", "Phone", "Deposit", "Payment Method", "Start Date", "End Date", "Total Charge", props.pathname.includes("backtrack") && "Username", props.pathname.includes("backtrack") && "Password"],
            formFieldTypes: ["text", "tel", 'number', "select", "date", "date", "number", props.pathname.includes("backtrack") && "text", props.pathname.includes("backtrack") && "password"],
            serverSelectOption: {
                paymentMethod: paymentMethodsForUpdateEvents.sort()
            },
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        });
    }else if(props.action === "Add Charges"){
        console.log("\n\t Add charges: ", props.reservationData, props.pathname);
        let minChargeDate = "";
        let maxChargeDate = "";
        const frontdeskChargeCategories = []as string[];
        const { guestName, roomNumber } = props.reservationData;
        allSystemCharges && allSystemCharges.forEach((charge: any) => {
            frontdeskChargeCategories.push(charge.description)
        });
        props.dispatch(showFormModalAction(true));
        if(!props.pathname.includes('group')){
            const requiredDate = (props.pathname.includes("account") ? props.reservationData.dateCreated : props.reservationData.checkedInDate).split("T")[0].split("-");
            const newMadeDate = requiredDate ? givenDateAndCurrentTime(`${requiredDate[0]}-${requiredDate[1]}-${requiredDate[2]}`) : givenDateAndCurrentTime(props.pathname.includes("backtrack") ? props.reservationData.checkedInDate : props.reservationData.arrivalDate);
            const newMadeDateCheckoutDate = new Date(props.reservationData.checkOutDate);
            // console.log("\n\t Add charges-newMadeDate: ", newMadeDate)
            minChargeDate = dateMaker(newMadeDate.getFullYear(), newMadeDate.getMonth(), newMadeDate.getDate());
            maxChargeDate = dateMaker(newMadeDateCheckoutDate.getFullYear(), newMadeDateCheckoutDate.getMonth(), newMadeDateCheckoutDate.getDate());
        };
        return props.setFrontdesksState({
            ...props.frontdesksState,
            confirmModalTitle: `Have you confirmed that ${guestName || props.reservationData.accountName} owes this charge and that the room number is correct?`,
            formModalTitle: `Add Charges To ${guestName || props.reservationData.accountName}'s Bill (This operation requires authorization).`,
            formModalSaveButtonName: "Charge",
            readOnlyFields: [false, true, false, true],
            minDate: minChargeDate,
            maxDate: maxChargeDate,
            reservationData: props.reservationData,
            formFieldPlaceholder: ["", roomNumber, "", props.pathname.includes('group') && guestName],
            formFields: ["Amount", "Room Number", "Description", props.pathname.includes('group') && "Guest Name", props.pathname.includes('backtrack') && "Backtrack Date", props.pathname.includes("backtrack") && "Username", props.pathname.includes("backtrack") && "Password"],
            formFieldTypes: ["number", 'number', "select", props.pathname.includes('group') && "text", props.pathname.includes('backtrack') && "date", props.pathname.includes("backtrack") && "text", props.pathname.includes("backtrack") && "password"],
            serverSelectOption: {
                description: frontdeskChargeCategories.sort()
            },
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        });
    }else if(props.action === "Add Credits"){
        console.log("\n\t Add Credits: ", props.reservationData);
        const paymentMethodsForAddCredits = []as string[];
        paymentMethod.forEach((method: any) => paymentMethodsForAddCredits.push(method.description));
        const requiredMinCreditDate = (props.pathname.includes("account") ? props.reservationData.dateCreated : props.reservationData.checkedInDate).split("T")[0].split("-");
        const newMadeCreditDate = requiredMinCreditDate ? givenDateAndCurrentTime(`${requiredMinCreditDate[0]}-${requiredMinCreditDate[1]}-${requiredMinCreditDate[2]}`) : givenDateAndCurrentTime(props.pathname.includes("backtrack") ? props.reservationData.checkedInDate : props.reservationData.arrivalDate);
        
        const newMadeDateCheckoutCreditDate = new Date(props.reservationData.dateCreated || props.reservationData.checkOutDate);
        // console.log("\n\t newMadeCreditDate: ", newMadeCreditDate)
        // console.log("\n\t Add Credits-props.frontdesksState.allSystemCredits: ", props.frontdesksState.allSystemCredits);

        const frontdeskCreditCategoriesList: any = [];
        allSystemCredits && allSystemCredits.forEach((credit: any) => {
            frontdeskCreditCategoriesList.push(credit.description)
        });
        props.dispatch(showFormModalAction(true));
        const minCreditDate = dateMaker(newMadeCreditDate.getFullYear(), newMadeCreditDate.getMonth(), newMadeCreditDate.getDate());
        const maxCreditDate = dateMaker(newMadeDateCheckoutCreditDate.getFullYear(), newMadeDateCheckoutCreditDate.getMonth(), newMadeDateCheckoutCreditDate.getDate());
        
        return props.setFrontdesksState({
            ...props.frontdesksState,
            confirmModalTitle: `Performing this action entails that you're about adding the inputted amount as a credit to ${props.reservationData.guestName || props.reservationData.accountName}'s bill. Is this correct?`,
            formModalTitle: `Add Credits To ${props.reservationData.guestName || props.reservationData.accountName}'s Bill (This operation requires authorization).`,
            formModalSaveButtonName: "Credit",
            readOnlyFields: [false, true, false],
            minDate: minCreditDate,
            // previewData: true,
            formFields: ["Amount", "Room Number", "Description", "Payment Method", props.pathname.includes('backtrack') && "Backtrack Date", props.pathname.includes("backtrack") && "Username", props.pathname.includes("backtrack") && "Password"],
            maxDate: maxCreditDate,
            reservationData: props.reservationData,
            formFieldPlaceholder: ["", props.reservationData.roomNumber, "", "", ""],
            formFieldTypes: ["number", 'number', "select", "select", props.pathname.includes('backtrack') && "date", props.pathname.includes("backtrack") && "text", props.pathname.includes("backtrack") && "password"],
            serverSelectOption: {
                description: frontdeskCreditCategoriesList.sort(),
                paymentMethod: paymentMethodsForAddCredits.sort()
            },
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        })

    }else if(props.action === "Bill Details"){
        const bills = props.reservationData.bills;
        let summedTotalCharge = 0;
        let summedTotalBalance = 0;
        bills.forEach((bill: any) => {
            summedTotalCharge += bill.charges;
            summedTotalBalance += bill.credits;
        });
        const footerArray = ["", "", "", summedTotalCharge, summedTotalBalance];
        return props.setDetailsData({
            ...props.frontdesksState,
            showDetails:  true,
            dataToBeDetailed:  bills,
            tableHeaderForDataToBeDetailed: ["Description", "Location", "Date Created", "Charges", "Credits",],
            groupName: props.reservationData.groupName,
            footerArray,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            detailsDataTableTitle: `Bill Details for: ${props.reservationData.groupName || props.reservationData.guestName}`
        })
    }else if(props.action === "Message"){
        // console.log("\n\t props-action: ", props.action)
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        
        const actualDepts = departments.filter(item => item.toLowerCase() !== 'frontdesk')
        return props.setFrontdesksState({
            ...props.frontdesksState,
            confirmModalTitle: "Proceed with sending message?",
            formModalTitle: "Send Message",
            formModalSaveButtonName: "Send Message",
            formFields: ["Receipient", "Subject", "Message"],
            formFieldTypes: ["select", 'text', "textarea"],
            serverSelectOption: {
                receipient: actualDepts.sort()
            },
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
        })
    }else if(props.action === "create-pm-account"){
        console.log("\n\t props-action: ", props.action)
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        
        return props.setFrontdesksState({
            ...props.frontdesksState,
            confirmModalTitle: "Proceed With creating a New PM-Account?",
            formModalTitle: "Create A New PM-Account",
            formModalSaveButtonName: "Create Account",
            formFields: ["Account Name", "Username", "Password"],
            formFieldTypes: ['text', "text", "password"],
            formFieldPlaceholder: ['Enter the name of the account e.g Chairman'],
            btnAction: "create-pm-account"
        })
    }else if(props.action === "Print Bill"){
        console.log("\n\t Print bill: ", props.reservationData)
        return props.setDetailsData({
            ...props.frontdesksState,
            printGuestBill: true,
            printRegCard: true,
            dataToBeDetailed:  props.reservationData,
            tableHeaderForDataToBeDetailed: ["Description", "Location", "Date", "Total Charge", "Balance"],
            groupName: props.reservationData.groupName,
            detailsDataTableTitle: `Guest's Bill: ${props.reservationData.groupName || props.reservationData.guestName}`
        });
    }else if(props.action === "Reverse Charges"){
        console.log("\n\t Reverse Charges-reservationData: ", props.reservationData)
        props.frontdesksState.formFields = ["Charges", "Username", "Password"]
        props.frontdesksState.formFieldTypes = ["select", "text", "password"];
        const guestsBillCharge = props.reservationData.guestsBill;

        const actualBills = guestsBillCharge[0].bills;
        const billsToReverse = []as string[];
        const chargesAndFolioIds = []as string[];
        actualBills.forEach((bill:any) => {
            const { dateCreated, credits, charges, folioId, description } = bill; 
            if((charges > 0) && (credits === 0)){
                const data = `${description} ~ (${(givenDateAndCurrentTime(dateCreated.split("T")[0])as Date).toDateString()}) ~ ${charges.toLocaleString()}`;
                billsToReverse.push(data);
                chargesAndFolioIds.push(`${data}/${folioId}`)
            }
        })
        console.log("\n\t guestsBillCharge: ", guestsBillCharge)
        console.log("\n\t actualBills: ", actualBills)
        return props.setFrontdesksState({
            ...props.frontdesksState,
            formModalSaveButtonName: "Reverse",
            reservationData: props.reservationData,
            previewData: true,
            confirmModalTitle: "Reversing this charge implies that the guest was incorrectly charged and that the system should negate this charge. Are you sure about this?",
            formModalTitle: `Reverse Superfluous Charges for ${props.reservationData.guestName || props.reservationData.accountName}.`,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            serverSelectOption: {
                charges: billsToReverse.sort(),
                chargesAndFolioIds
            },
        });
    }else if(props.action === "Reverse Credits"){
        console.log("\n\t Reverse Credits-reservationData: ", props.reservationData)
        props.frontdesksState.formFields = ["Credits", "Username", "Password"]
        props.frontdesksState.formFieldTypes = ["select", "text", "password"];
        const guestsBillCredit = props.reservationData.guestsBill;

        const actualCreditBills = guestsBillCredit[0].bills;
        const creditBillsToReverse = []as string[];
        const creditsAndFolioIds = []as string[];
        actualCreditBills.forEach((bill:any) => {
            const { dateCreated, charges, folioId, credits, description } = bill; 
            if(credits > 0 && charges === 0){
                const data = `${description} ~ (${(givenDateAndCurrentTime(dateCreated.split("T")[0])as Date).toDateString()}) ~ ${credits.toLocaleString()}`;
                creditBillsToReverse.push(data);
                creditsAndFolioIds.push(`${data}/${folioId}`)
            }
        });
        console.log("\n\t guestsBillCredit: ", guestsBillCredit);
        console.log("\n\t actualCreditBills: ", actualCreditBills);
        return props.setFrontdesksState({
            ...props.frontdesksState,
            reservationData: props.reservationData,
            formModalSaveButtonName: "Reverse",
            confirmModalTitle: "Reversing this credit implies that the guest was incorrectly credited and that the system should negate this credit. Are you sure about this?",
            formModalTitle: `Reverse Superfluous Credits for ${props.reservationData.guestName || props.reservationData.accountName}.`,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            previewData: true,
            serverSelectOption: {
                credits: creditBillsToReverse.sort(),
                creditsAndFolioIds
            },
        });
    }else if(props.action === "Transfer Bill"){
        // console.log("\n\t Transfer Bill-reservationData: ", props.reservationData)
        // console.log("\n\t Transfer Bill-guestsInHouse: ", props.reservationData['guestsInHouse'])
        props.frontdesksState.formFields = ["Bills", "In-House Guest", "Username", "Password"]
        props.frontdesksState.formFieldTypes = ["select", "select", "text", "password"];
        const guestsBillCharge = props.reservationData.guestsBill;

        const actualBills = guestsBillCharge[0].bills;
        const billsToTransfer = []as string[];
        const folioBills = []as string[];
        actualBills.forEach((bill:any) => {
            const { dateCreated, credits, charges, folioId, description } = bill; 
            const data = `${description} ~ (${(givenDateAndCurrentTime(dateCreated.split("T")[0])as Date).toDateString()}) ~ ${(credits || charges).toLocaleString()}`;
            billsToTransfer.push(data);
            folioBills.push(`${data}/${folioId}`)
        });
        const guestsInHouse = []as string[];
        const inHouseGuests = props.reservationData['guestsInHouse']
        inHouseGuests.forEach((item:any) => guestsInHouse.push(item.guestName))
        // console.log("\n\t guestsBillCharge: ", guestsBillCharge)
        // console.log("\n\t guestsInHouse: ", guestsInHouse)
        return props.setFrontdesksState({
            ...props.frontdesksState,
            formModalSaveButtonName: "Transfer",
            reservationData: props.reservationData,
            confirmModalTitle: `Transferring this bill implies the removal of this bill from ${props.reservationData.accountName} to the bill of the selected in-house guest. Are you sure about this?`,
            formModalTitle: `Transfer Bills from ${props.reservationData.guestName || props.reservationData.accountName} PM account.`,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            serverSelectOption: {
                bills: billsToTransfer.sort(),
                "in-HouseGuest": guestsInHouse,
                folioBills
            },
        });
    }else if(props.action === "Transfer Balance"){
        console.log("\n\t Transfer Balance-reservationData: ", props.reservationData)
        console.log("\n\t Transfer Balance-guestsInHouse: ", props.reservationData['guestsInHouse'])
        
        props.frontdesksState.formFields = ["In-House Guest", "Amount", "Username", "Password"]
        props.frontdesksState.formFieldTypes = ["select", "number", "text", "password"];
       
        const guestsInHouse = []as string[];
        const inHouseGuests = props.reservationData['guestsInHouse']
        inHouseGuests.forEach((item:any) => guestsInHouse.push(item.guestName));
        console.log("\n\t guestsInHouse: ", guestsInHouse)
        return props.setFrontdesksState({
            ...props.frontdesksState,
            formModalSaveButtonName: "Transfer",
            reservationData: props.reservationData,
            formFieldPlaceholder: ["", "Enter zero to transfer the whole balance"],
            confirmModalTitle: `Transferring this amount will reduce ${props.reservationData.accountName} folio-balance by this value. Are you sure about this?`,
            formModalTitle: `Transfer ${props.reservationData.guestName || props.reservationData.accountName} PM account Balance.`,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            serverSelectOption: {
                "in-HouseGuest": guestsInHouse,
            },
        });
    }else if(props.action === "Guest Bill"){
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(false));
        // console.log("\n\t Guest bill: ", props.reservationData)
        return props.setDetailsData({
            ...props.frontdesksState,
            printGuestBill: true,
            printRegCard: true,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            dataToBeDetailed: {
                guestBillId: props.reservationData._id,
                roomNumber: props.reservationData.roomNumber,
                guestName: props.pathname.includes('pm-account') ? props.reservationData.accountName : props.reservationData.guestName,
                dateOfCreation: props.reservationData.dateCreated,
                folioCount: props.pathname.includes("pm-account") ? props.reservationData.folios.length : 0,
                departureDate: props.reservationData.checkOutDate,
                checkedInDate: props.reservationData.checkedInDate
            },
            tableHeaderForDataToBeDetailed: ["Description", "Location", "Date", "Total Charge", "Balance"],
            groupName: props.reservationData.groupName,
            detailsDataTableTitle: `Guest's Bill: ${props.reservationData.groupName || props.reservationData.guestName || props.reservationData.accountName}`
        });

    }else if(props.action === "Completed Events"){
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(false));
        console.log("\n\t Guest bill: ", props.reservationData)
        // guestsBill.data.filter((bill: any) => bill.roomNumber == props.reservationData.roomNumber)
        return props.setDetailsData({
            ...props.frontdesksState,
            showDetails: true,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            dataToBeDetailed:  props.reservationData.events,
            tableHeaderForDataToBeDetailed: eventsTableHeader.slice(0, 11),
            groupName: props.reservationData.groupName,
            detailsDataTableTitle: `Completed Events For: ${props.reservationData.facilityName}`
        });
    }else if(props.action === "Add Guest"){
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(true));
        // console.log("\n\t Guest bill: ", props.reservationData)
        props.frontdesksState.formFields = ["Title", "Firstname", "Lastname", "Guest Phone", "Room Number", "Arrival Date"]
        props.frontdesksState.formFieldTypes = ["select", "text", "text", "tel", "select", "date"];
        const guestTitles = []as string[];
        props.frontdesksState.utilityData.guestTitles.forEach((title:any) => guestTitles.push(title.titleCode));
        const { rooms, roomTypes } = props.frontdesksState.utilityData as any;
        const availableRoomsToChangeTo = []as any;
        rooms.forEach((room:any) => {
            if(room.houseKeepingStatus.toLowerCase() === 'clean' && room.occupancyStatus.toLowerCase() === 'vacant'){
                const roomTypeDetails = roomTypes.find((type:any) => type._id === room.roomTypeId);
                // console.log("\n\t roomTypeDetails: ", roomTypeDetails);
                availableRoomsToChangeTo.push(`${roomTypeDetails.roomTypeName}-${room.roomNumber}`)
            }
        });
        const requiredDate = (props.reservationData.arrivalDate).split("T")[0].split("-");
        const newMadeDate = givenDateAndCurrentTime(`${requiredDate[0]}-${requiredDate[1]}-${requiredDate[2]}`);
        const minChargeDate = dateMaker(newMadeDate.getFullYear(), newMadeDate.getMonth(), newMadeDate.getDate());
        const requiredCheckoutDate = (props.reservationData.checkOutDate).split("T")[0].split("-");
        const newMadeCheckOutDate = givenDateAndCurrentTime(`${requiredCheckoutDate[0]}-${requiredCheckoutDate[1]}-${requiredCheckoutDate[2]}`);
        const maxCheckOutDate = dateMaker(newMadeCheckOutDate.getFullYear(), newMadeCheckOutDate.getMonth(), newMadeCheckOutDate.getDate());
        return props.setDetailsData({
            ...props.frontdesksState,
            btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
            dataToBeDetailed:  props.reservationData.events,
            groupName: props.reservationData.groupName,
            formModalSaveButtonName: "Add",
            previewData: true,
            minDate: minChargeDate,
            maxDate: maxCheckOutDate,
            formModalTitle: `New Guest For ${props.reservationData.groupName} ${props.reservationData.groupName.toLowerCase().includes('group') ? "" : "Group"}.`,
            serverSelectOption: {
                title: guestTitles.sort(),
                roomNumber: availableRoomsToChangeTo.sort(),
            },
            confirmModalTitle: "This action will add the details of this guest among checked-in guests for this group. Continue?"
        });
    }else if(props.action === "Group Bill"){
        props.dispatch(showConfirmModalAction(false));
        props.dispatch(showFormModalAction(false));
        console.log("\n\t Group bill: ", props.reservationData)
        const groupBills = await axiosHelper({
            httpVerb: "get",
            routeName: `/audit-reports/guest-bills/group/${props.reservationData._id}`
        });
        console.log("\n\t server-Group bill: ", groupBills)
        return props.setDetailsData({
            ...props.frontdesksState,
            printGuestBill: true,
            printRegCard: true,
            dataToBeDetailed:  groupBills,
            tableHeaderForDataToBeDetailed: ["Description", "Location", "Date", "Total Charge", "Balance"],
            groupName: props.reservationData.groupName,
            detailsDataTableTitle: `Group Bill: ${props.reservationData.groupName || props.reservationData.guestName}`
        });
    }else if(props.action === "Assign To PM-Account"){
        console.log("\n\t Assign To PM-Account: ", props.reservationData)
        props.dispatch(showFormModalAction(false));
        if(props.reservationData.balance <= 0){
            props.dispatch(showConfirmModalAction(false));
            return customAlert("error", `You can't assign a ${props.reservationData.balance === 0 ? "zero-balance" : "non-owing"} guest to a PM-account. Check the guest out instead.`, 5000)
        }else{
            props.dispatch(showConfirmModalAction(true));
            return props.setFrontdesksState({
                ...props.frontdesksState,
                btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`,
                formInputs: {
                    guestName: props.reservationData.guestName, 
                    debit: props.reservationData.balance, 
                    checkinId: props.reservationData._id 
                },
                reservationData: props.reservationData,
                confirmModalTitle: "Assigning this guest to a PM-Account, entails that the guest should be  \
                checked-out and registered as a debtor with the indicated figure on the debit side. \
                Do you want to continue?"
            });
        }
    }else if(props.action === "Transfer Charges"){
        // console.log("\n\t Transfer Debt-reservationData: ", props.reservationData)
        const guestsBillTransfer = props.reservationData.guestsBill;
        const amountOwed = guestsBillTransfer[0].charges - guestsBillTransfer[0].credits;
        console.log("\n\t amountOwed: ", amountOwed)
        if(+amountOwed <= 0){
            props.dispatch(showConfirmModalAction(false));
            props.dispatch(showFormModalAction(false));
            return customAlert("error", "This guest is not owing. You can only transfer charges from a owing guest.", 5000)
        }else{
            props.dispatch(showConfirmModalAction(false));
            props.dispatch(showFormModalAction(true));
            const billsToTransfer = []as string[];
            const chargesAndFolioIds = []as string[];
            guestsBillTransfer[0].bills.forEach((bill:any) => {
                // console.log("\n\t bill: ", bill)
                const { dateCreated, credits, charges, folioId, description } = bill; 
                if((charges > 0) && (credits === 0)){
                    const data = `${description} - (${(givenDateAndCurrentTime(dateCreated.split("T")[0])as Date).toDateString()}) - ${charges.toLocaleString()}`;
                    chargesAndFolioIds.push(`${data}/${folioId}`)
                    billsToTransfer.push(data)
                }
            });
            const roomNumberOfOwingGuest = props.reservationData.roomNumber;
            const guestsInHouse = []as string[];
            props.frontdesksState.tableData.forEach((guest: any) => {
                if(roomNumberOfOwingGuest !== guest.roomNumber){
                    guestsInHouse.push(`${guest.guestName} - (Room: ${guest.roomNumber}) - (Balance: ${guest.balance})`)
                }
            });
            // console.log("\n\t allGuestBills: ", allGuestBills.data[0])
            // props.reservationData.folioIdOfCharge = folioIdOfCharge;
            return props.setFrontdesksState({
                ...props.frontdesksState,
                confirmModalTitle: `${guestsBillTransfer[0].guestName} owes ${Number(props.reservationData.balance).toLocaleString()} and you are about transferring the inpuuted amount to an in-house guest-bill. Is the paying-guest aware of this?`,
                formModalTitle: `Transferring one of ${props.reservationData.guestName}'s charges.`,
                formModalSaveButtonName: "Transfer Charge",
                formFields: ["Charge", "Paying Guest", "Username", "Password"],
                formFieldTypes: ["select", 'select', "text", "password"],
                previewData: true,
                reservationData: props.reservationData,
                formInputs: {
                    roomNumberOfOwingGuest,
                },
                serverSelectOption: {
                    payingGuest: guestsInHouse.sort(),
                    charge: billsToTransfer.sort(),
                    chargesAndFolioIds
                },
                btnAction: `${props.action.toLowerCase().replaceAll(" ", "-")}`
            });
        }
    }else if(props.action === "Room Billing"){
        props.dispatch(showFormModalAction(false));
        props.dispatch(showConfirmModalAction(true));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            continueButtonText: "Bill Room",
            // cancelButtonText: "Cancel",
            btnAction: props.action.toLowerCase().replaceAll(" ", "-"),
            showForm: false,
            dbIdOfDataToModify: props.reservationData._id, 
            confirmModalTitle: `This action will charge the guest for accommodation, \
            for the number of nights stayed. Please, ensure you have not \
            initiated this action before as this cannot be reversed.`, // to be copleted in component
        })
    }else if(props.action === "End Backtrack"){
        const checkoutDateExceedsTodaysDate = varyingDatesComparator(tomorrowsDate(), givenDateAndCurrentTime(props.reservationData.checkOutDate.split("T")[0]))
        // console.log("\n\t props.reservationData.checkOutDate: ", givenDateAndCurrentTime("2022-12-26"))
        if(checkoutDateExceedsTodaysDate){
            props.dispatch(showFormModalAction(false));
            return customAlert("error", "You can't end backtrack for this guest; because the guests' checkout date is not due. Please, close backtrack.", 6000)
        }else{
            props.dispatch(showFormModalAction(false));
            props.dispatch(showConfirmModalAction(true));
            return props.setFrontdesksState({
                ...props.frontdesksState,
                continueButtonText: "Proceed",
                // cancelButtonText: "Cancel",
                btnAction: props.action.toLowerCase().replaceAll(" ", "-"),
                showForm: false,
                dbIdOfDataToModify: props.reservationData._id, 
                confirmModalTitle: `Performing this action implies that the guest's bill has been successfully \
                observed and cerified to correctly contain all charges and credits of the guest. \
                If you end backtrack for this guest, You can find this guest at frontdesks' history`, // to be copleted in component
            })
        }
    }else if(props.action === "Cancel Backtrack"){
        props.dispatch(showFormModalAction(true));
        props.dispatch(showConfirmModalAction(false));
        props.frontdesksState.formFields = ["Username", "Password"]
        props.frontdesksState.formFieldTypes = ["text", "password"];
        props.frontdesksState.formModalTitle = `Cancelling a Backtrack Record Requires Authorization`;
        return props.setFrontdesksState({
            ...props.frontdesksState,
            continueButtonText: "Yes",
            cancelButtonText: "No",
            formModalSaveButtonName: "Proceed",
            btnAction: props.action.toLowerCase().replaceAll(" ", "-"),
            showForm: false,
            dbIdOfDataToModify: props.reservationData._id, 
            confirmModalTitle: `Cancelling a backtrack record implies that this record \
            will be deleted from the system, including financial transactions related to the guest. \
            An operational email will also be sent to ChannelPro and \
            the operational manager. Are you sure about this?`, // to be copleted in component
        })
    }else if(props.action === "Close Backtrack"){
        // console.log("\n\t props.reservationData: ", props.reservationData)
        props.dispatch(showFormModalAction(false));
        const checkOutDate = props.reservationData.checkOutDate
        const status = varyingDatesComparator(givenDateAndCurrentTime(checkOutDate.split("T")[0]), todaysDate());
        console.log("\n\t props.reservationData: ", props.reservationData)
        if(status){
            props.dispatch(showConfirmModalAction(false));
            customAlert("error", "You can't close backtrack for a guest whose departure date has been exceeded. Please, use the 'End Backtrack' instead.", 6000)
        }else{
            // console.log("\n\t varyingDatesComparator-status: ", status)
            props.dispatch(showConfirmModalAction(true));
            props.frontdesksState.formFields = ["Username", "Password"]
            props.frontdesksState.formFieldTypes = ["text", "password"];
        }
        return props.setFrontdesksState({
            ...props.frontdesksState,
            continueButtonText: "Yes",
            cancelButtonText: "No",
            formModalSaveButtonName: "Proceed",
            btnAction: props.action.toLowerCase().replaceAll(" ", "-"),
            showForm: false,
            dbIdOfDataToModify: props.reservationData._id, 
            confirmModalTitle: `Closing a backtrack record implies that this guest will not be seen as a backtrack guest but as an in-house guest (i.e will be moved in-house). Are you sure about this?`, // to be copleted in component
        });
    }else if(props.action === "Bill IHG"){
        props.dispatch(showFormModalAction(false));
        props.dispatch(showConfirmModalAction(true));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            continueButtonText: "Yes",
            cancelButtonText: "No",
            reservationData: props.reservationData,
            btnAction: props.action.toLowerCase().replaceAll(" ", "-"),
            dbIdOfDataToModify: props.reservationData._id, 
            formInputs: {noEndOfDay: props.reservationData["noEndOfDay"]},
            confirmModalTitle: `"Bill IHG (Bill In-House-Guests)": this action instructs HotelPro to check the bills of all in-house guests on: ${props.reservationData["noEndOfDay"]}, if any of these guests weren't bill, the system then bills such guest. Would you like to continue?`, // to be copleted in component
        })
    }else if(props.action === "Compute Transactions"){
        props.dispatch(showFormModalAction(false));
        props.dispatch(showConfirmModalAction(true));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            continueButtonText: "Yes",
            cancelButtonText: "No",
            reservationData: props.reservationData,
            btnAction: props.action.toLowerCase().replaceAll(" ", "-"),
            dbIdOfDataToModify: props.reservationData._id, 
            formInputs: {noEndOfDay: props.reservationData["noEndOfDay"]},
            confirmModalTitle: `This action checks if the financial transactions for: ${props.reservationData["noEndOfDay"]}, have been kept in records. If they haven't, HotelPro, then, records the transaction. Would you like to continue?`, // to be copleted in component
        })
    }else if(props.action === "Run EOD"){
        props.dispatch(showFormModalAction(false));
        props.dispatch(showConfirmModalAction(true));
        return props.setFrontdesksState({
            ...props.frontdesksState,
            continueButtonText: "Yes",
            cancelButtonText: "No",
            btnAction: props.action.toLowerCase().replaceAll(" ", "-"),
            dbIdOfDataToModify: props.reservationData._id, 
            formInputs: {noEndOfDay: props.reservationData["noEndOfDay"]},
            confirmModalTitle: `Run EOD for this day: ${props.reservationData["noEndOfDay"]}? Ensure that you've done the "Bill IHG" and "Compute Transactions" operation first.`, // to be copleted in component
        })

    }
};



export const frontdeskState = async() => {
    const frontdeskDetails = await getFrontdeskDetails();
    console.log("\n\t frontdeskDetails: ", frontdeskDetails)
    return frontdeskDetails
};


interface FrontdeskFormChangeInterface {
    frontdesksState: any
    field: string, 
    value: string
    setFrontdesksState: Function
    btnAction: string
}
export const frontdeskHandleFormChange = (props: FrontdeskFormChangeInterface) => {
    // console.log("\n\t handleFormChange: ", props.field, props.value);
    if(props.field.toLowerCase() === "room type"){
        const { rooms, roomTypes } = props.frontdesksState.utilityData as any;
        const availableRoomsToChangeTo = changeRoomExtension({
            rooms,
            roomTypes,
            selectedRoomType: props.value,
        });
        // console.log("\n\t availableRoomsToChangeTo: ", availableRoomsToChangeTo)
        props.frontdesksState.serverSelectOption = {
            ...props.frontdesksState.serverSelectOption,
            newRoom: availableRoomsToChangeTo,
            roomNumber: availableRoomsToChangeTo,
        }
    }if(props.field.toLowerCase().includes("paying guest")){
        // console.log("\n\t checkinIdOfPayingGuest: ", props.value)
        // props.frontdesksState.tableData.filter((data:any) => data.guestName ===)
        const guestsName = props.value.split("-")[0];
        const payingGuestCheckInData = props.frontdesksState.tableData.filter((data:any) => data.guestName === guestsName.trim());
        console.log("\n\t payingGuestCheckInData: ", payingGuestCheckInData)
        props.frontdesksState.formInputs["roomNumberOfPayingGuest"] = payingGuestCheckInData[0].roomNumber;
    }if(!props.field.toLocaleLowerCase().includes("total") && (props.field.toLocaleLowerCase().includes("bills") || props.field.toLocaleLowerCase().includes("charge") || props.field.toLocaleLowerCase().includes("credit"))){
        console.log("\n\t folioIdOfCharge: ", props.value);
        const chargeDetailsToBeTransferred = props.btnAction.includes("charges") ? 
        props.frontdesksState.serverSelectOption.chargesAndFolioIds.filter((data: any) => data.includes(props.value))
        :
        props.btnAction.includes("transfer-bill") ? 
        props.frontdesksState.serverSelectOption.folioBills.filter((data: any) => data.includes(props.value))
        :
        props.frontdesksState.serverSelectOption.creditsAndFolioIds.filter((data: any) => data.includes(props.value));
        // console.log("\n\t chargeDetailsToBeTransferred: ", chargeDetailsToBeTransferred)
        const folioIdOfCharge = chargeDetailsToBeTransferred[0].split("/")[1] 
        props.frontdesksState.formInputs[props.btnAction.includes('transfer-bill') ? "folioIdToTransfer" : "folioIdOfCharge"] = folioIdOfCharge;
    }
    props.frontdesksState.formInputs[titledTextJoiner(props.field)] = props.value;
    props.setFrontdesksState({...props.frontdesksState});
};