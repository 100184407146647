import React, { useEffect, useState } from 'react';
import Modules from '../containers/HomeDashboardModules';
import Sidebar from '../containers/Sidebar';

import { useSelector } from "react-redux";
import Footer from '../containers/Footer';
import WarningModal from '../containers/WarningModal';
interface Props {
    
}

const AdminsDashboard = (props: Props) => {
    const store = useSelector((store: any) => store);
    const { sidebarStatus, userDetails } = store;
    const { showSidebar } = sidebarStatus;
    const userDetailsData =  userDetails.userDetails;
    const licenseDetails = userDetailsData?.licenseDetails
    const [dashboardState, setDashboardState] = useState({
        warningModalMessage: "",
        showWarningModal: false
    })
    const warn = (licenseDetails: string) => {
        setDashboardState({
            ...dashboardState,
            warningModalMessage: licenseDetails,
            showWarningModal: true
        });
        const dashboard = document.querySelector(".dashboard-body");
        const warnMsg = <div></div>
        // dashboard?.insertBefore(warnMsg)
        // (dashboard as any)!.innerHTML = <WarningModal warningMessage="" />
        console.log("\n\t warn dashboard: ", dashboard)

    }
    console.log("\n\t licenseDetails: ", licenseDetails)
    // console.log("\n\t userDetailsData: ", userDetailsData)
    useEffect(() => {
        // if(licenseDetails){
        //     licenseDetails.toLowerCase().includes('okay') && 
        //     // setDashboardState({
        //     //     ...dashboardState,
        //     //     warningModalMessage: licenseDetails,
        //     // });
        //     warn(licenseDetails)
        // };
    }, []);
    // console.log("\n\t showWarningModal: ", dashboardState.showWarningModal)
    // console.log("\n\t props.routeName: ", window.location.origin.split("//")[1])

    return (
        <React.Fragment>
            {
                // dashboardState.showWarningModal ?
                // :
                <div className='dashboard-body'>
                    <div className="row mt-3"></div>
                    <div className="row mt-3"></div>
                    {
                        dashboardState.showWarningModal &&
                        <WarningModal

                            warningMessage={dashboardState.warningModalMessage}
                        />
                    }
                    <div className="mt-1">
                        <main style={{overflow: "hidden"}} className="row">
                            <div style={{}} className="d-flex col-md-2">
                                {
                                    showSidebar &&
                                    <Sidebar />
                                }
                            </div>
                            <div style={{flexDirection: "column", left: showSidebar ? "-15px" : "17px",}} className={`${showSidebar ? 'col-md-10' : 'col-md-12' } `}>
                                <Modules />
                                <Footer />
                            </div>
                            {/* {warn()} */}
                        </main>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default AdminsDashboard
