import React, { useEffect, useState, lazy, Suspense } from 'react'
import "../../../../styles/TableStyles.css";

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { handleRestaurantActions } from "./tableActions/tableActionsTwo";


import {  frontdeskHandleFormChange, frontdeskState, handleFrontdeskActions } from './tableActions/tableActionsOne';
import {
    frontdeskDashboardRouteChecker,
    paginationButtons, restaurantOrderDetailsTableHeader,
} from './tableData';
import colors from '../../../../assets/colors';
import { showConfirmModalAction, showFormModalAction, toggleFooterAction } from '../../../../redux/actions/storeActions/appActionsUtilities';
import { inputValidator } from '../../../../utilities/security';
import { customAlert } from '../../../../utilities/customAlert';
import { BsArrowRightCircle } from 'react-icons/bs';
import { getAllBacktrackGuest, getAllSystemCharges, getAllSystemCredits, getFAndBTableOrderData } from '../../../../utilities/databaseHelpers';
import FrontdeskTable, { actionsInvolvingGuestsBill, individualsCheckedInDefinitions } from './FrontdeskTable';
import { reportsAccountHandler } from './auditReports/reportsAccountHandler';
import axiosHelper from '../../../../utilities/axiosHelper';
import { backtrackTableActions } from './subTables/subTableHandleActions';
import FAndBPaymentTray from '../../restaurantData/FAndBPaymentTray';
import Modal from '../../Modal';
import FAndBTray from '../../restaurantData/FAndBTray';
import RegCard from '../../Forms/regCards/RegCard';
import Filter from '../../Filter';
import ModuleSummary from '../ModuleSummary/ModuleSummary';
// import { barRestaurantHelpers } from './tableActions/tableActionsTwo';
// import FAndBTable from './FAndBTable';
import { handleDataPagination } from './tableActions/tableActionsThree';
import FolioAndReceipt from '../../Forms/FolioAndReceipt';

const Details = lazy(() => import('./Details')) ;
const BacktrackSubTable = lazy(() => import('./subTables/BacktrackSubTable'));
interface Props {

}

// Profile", "Folios",
const BacktrackTable = (props: Props) => {
    const dispatch = useDispatch();

   
    const location = useLocation();
    const pathname = location.pathname;

    const [backtrackState, setBacktrackState] = useState({
        tableData: [] as any,
        guestBillType: "",
        utilityData: {}as any,
        allBacktrackGuest: []as any[],
        minDate: "",
        maxDate: "",
        showDetails: false,
        btnAction: "",
        dataToBeDetailed: [],
        tableHeaderForDataToBeDetailed: [],
        detailsDataTableTitle: "",
        paginationStartPoint: 0,
        paginationLength:  pathname.includes('room') ? 15 : 10,
        paginationEndPoint: pathname.includes('room') ? 15 : 10,
        paginatedTableData: [] as any,
        actualLengthOfTableData: 0,
        footerArray: [],
        printGuestBill: false,
        printRegCard: false,
        serverSelectOption: {} as any,
        formModalTitle: "",
        formModalSaveButtonName: "",
        formFields: [] as string[],
        formFieldTypes: [] as string[],
        formFieldPlaceholder: []as any[],
        confirmModalTitle: "",
        formInputs: {} as any,
        appModulesData: [] as any[], 
        readOnlyFields: [] as boolean[],
        defaultSelectOption: {}as any,
        guestBills: []as any[],
        orderMode: "new",
        paymentMethods: []as any[],
        allSystemCharges: []as any[],
        allSystemCredits:  []as any[],
        modalType: "",
        modalFormInstruction: "",
        orderDetails: [] as any,
        orderDetailsToModify: {} as any,
        continueButtonText: "",
        cancelButtonText: "",
        modalFormEditFlag: false,
        department: pathname.split("/")[2],
        autoCreatedPMAccounts: []as any[],
        fAndBDataToPreview: {} as any,
        fAndBDiscounts: []as any[],
        fAndBDiscountData: []as any[],
        placeholders: [] as string[],
        reservationData: {}as any,
        orderToUpdate: {} as any,
        loadingPrompt: "Please wait, backtrack data is being fetched...."
    });
    const endOfDayForBacktrack = () => {
        console.log("\n\t formModalStatus: ", formModalStatus)
        // props.backtrackState.formFields = ["Date", "Username", "Password"]
        // props.backtrackState.formFieldTypes = ["date", "text", "password"];
        dispatch(toggleFooterAction(true))
        dispatch(showFormModalAction(true));
        dispatch(showConfirmModalAction(false));
        // return props.setBacktrackState({
        //     ...props.backtrackState,
        //     maxDate: yesterdaysFormDate(),
        // })
    };
    const handlePagination = (btnName: string) => {
        handleDataPagination({
            paginationBtnName: btnName,
            state: backtrackState,
            stateHandler: setBacktrackState
        })
    };
    const [groupCheckInData, setGroupCheckInData] = useState({} as any);

    const [groupChackInFormData, setGroupCheckInFormData] = useState({} as any)


    const store = useSelector((store: any) => store);
    const { confirmModal, modalStatus, sidebarStatus, modulesData } = store
    const { showSidebar } = sidebarStatus;
    const { showConfirm } = confirmModal;
    const { showFormModal } = modalStatus;
    const formModalStatus: boolean = showFormModal ? showFormModal.modalStatus : false;
    // console.log("\n\t backtrackState.tableData: ",  backtrackState.tableData);

    const handleFormChange = (field: string, value: string) => {
        // console.log("\n\t handleFormChange: ", field, value);
        frontdeskHandleFormChange({
            field,
            frontdesksState: backtrackState,
            setFrontdesksState: setBacktrackState,
            value,
            btnAction: backtrackState.btnAction
        })
    };

    // console.log("\n\t backtrackState.formInputs: ", backtrackState.formInputs)

    const backtrackActionsHandler = async (action: string, reservationData: any, modalType?: string) => {
        console.log("\n\t backtrackState: ", backtrackState);
        if (action.toLowerCase().includes('reg. card') || action.toLowerCase().includes('reg card')) {
            return setBacktrackState({
                ...backtrackState,
                printRegCard: true,
                reservationData
            });
        };
        if(actionsInvolvingGuestsBill.includes(action.toLowerCase())){
            // If you're going to call a backend-service and dispatch a frontdesk state effect,
            // first dispatch the frontdesk state effect; before calling the backend service. This explains why
            // there's a dispatxh call here, before the axios-call
            dispatch(showConfirmModalAction(false));
            dispatch(showFormModalAction(true));
            const guestbillResponse = await axiosHelper({
                httpVerb: "get",
                routeName: `/audit-reports/get-${pathname.includes('pm-account') ? 'pm-account' : 'individual'}-guestbill/${reservationData._id}`
            });
            console.log("\n\t guestbillResponse: ", guestbillResponse)
            reservationData["guestsBill"] = guestbillResponse.data
        }
        if(pathname.includes("account")){
            reportsAccountHandler({
                action,
                dataToModify: reservationData,
                dispatch,
                pathname,
                state: backtrackState,
                stateHandler: setBacktrackState,
                appModulesData: backtrackState.appModulesData,
                allSystemCredits: backtrackState.allSystemCredits
            })
        }else if(!pathname.includes("restaurant") && !pathname.includes("bar")){
            handleFrontdeskActions({
                pathname,
                action,
                dispatch,
                reservationData,
                setDetailsData: setBacktrackState,
                frontdesksState: backtrackState,
                setFrontdesksState: setBacktrackState,
                // setGroupCheckInFormData,
                detailsStatus: backtrackState.showDetails
            })
        }else{
            // console.log("\n\t FAndB action: ", action, reservationData, modalType);
            // console.log("\n\t backtrackState: ", backtrackState);
            handleRestaurantActions({
                modalType: action,
                department: backtrackState.department,
                pointOfSalesData: backtrackState.tableData,
                action,
                dispatch, 
                setState: setBacktrackState,
                state: backtrackState,
                orderToUpdate: typeof(reservationData) !== "string" ? reservationData : modalType,
                paymentMethods: backtrackState.paymentMethods,
                pathname
            })
        }
    };
    const modulesState = useSelector((state:any) => state.modulesData);
    useEffect(() => {
        (async () => {
            // const {orderDataDetails} = await barRestaurantHelpers({dispatch, pathname});
            let allBacktrackGuest = []as any[];
            if(!pathname.includes("guests/individual") && !pathname.includes("guests/group")){
                allBacktrackGuest = await getAllBacktrackGuest();
            };
            const [response, utilityData, allSystemCharges, allSystemCredits] = await Promise.all([
                axiosHelper({   // table data for frontdesk sidebar menus
                    routeName: pathname,
                    httpVerb: "get"
                }),
                frontdeskState(),
                getAllSystemCharges(),
                getAllSystemCredits()
            ]);
            console.log("\n\t modulesState: ", modulesState);
            backtrackState.appModulesData = modulesState.modulesData ? modulesState.modulesData[0] : [];
            backtrackState.allSystemCharges = allSystemCharges;
            backtrackState.allSystemCredits = allSystemCredits;
            console.log("\n\t Effect-allSystemCredits: ", allSystemCredits);
            backtrackState.utilityData = utilityData;
            if(backtrackState.appModulesData.length === 0){
                if( (pathname.includes("bar") || pathname.includes("restaurant"))){
                    const fAndBTableOrderData = await Promise.all([
                        getFAndBTableOrderData(),
                    ]);
                    backtrackState.appModulesData = fAndBTableOrderData;
                }else if(!pathname.includes("bar") && !pathname.includes("restaurant")){
                    backtrackState.appModulesData = modulesState.modulesData ? modulesState.modulesData[0] : [];
                    // backtrackState.allSystemCredits = modulesState.modulesData ? modulesState.modulesData[1] : [];
                    // console.log("\n\t modulesState: ",modulesState)
                    // const requiredFAndBDiscounts = []as string[];
                };
            }
            console.log("\n\t response: ", response)
            setBacktrackState({
                ...backtrackState,
                tableData: response.data ? pathname.includes("bar") ? response.data.barOrders : pathname.includes("restaurant") ? response.data.sales : Object.values(response.data) : [],
                paginatedTableData: pathname.includes("bar") ? response.data.barOrders.slice(backtrackState.paginationStartPoint, backtrackState.paginationEndPoint) : pathname.includes("restaurant") ? response.data.sales.slice(backtrackState.paginationStartPoint, backtrackState.paginationEndPoint) : (Object.values(response.data)).slice(backtrackState.paginationStartPoint, backtrackState.paginationEndPoint),
                allBacktrackGuest,
                // fAndBDiscounts: requiredFAndBDiscounts,
                // fAndBDiscountData: fAndBDiscounts as any,
                loadingPrompt: "",  // after loading the required data hide the loading text
                actualLengthOfTableData: pathname.includes("bar") ? (Object.values(response.data.barOrders)).length : pathname.includes("restaurant") ? (Object.values(response.data.sales)).length : (Object.values(response.data)).length,
            });
        })();
        
        if(pathname.toLowerCase().includes('no-eod')){
            endOfDayForBacktrack()
        }
    }, [pathname]);
    // backtrackState.showDetails, backtrackState.printRegCard, backtrackState.appModulesData
    const toggleModals = () => {
        const validation = inputValidator(backtrackState.formInputs, Object.keys(backtrackState.formInputs).length, Object.keys(backtrackState.formInputs));
        if (typeof (validation) === "string") {
            return customAlert("error", validation as string);
        };
        dispatch(showFormModalAction(false));
        dispatch(showConfirmModalAction(true));
    };
    // console.log("\n\t tableData: ", backtrackState.tableData)
    // console.log("\n\t backtrackState.appModulesData: ", backtrackState.appModulesData)
    const updateRoutes = ["compute-reports", "compute-transactions", "bill-ihg", "pay-balance", "facilities/facility", "cancel-event", "close-backtrack", "modify-order", "reverse-charges", "reverse-credits", "cancel-order", "sign-consent-folio", "bill-backtrack-guest", "room-billing", "cancel-backtrack", "end-backtrack", "add-charges", "add-credits"]
    const handleFolioToggle = () => {
        setBacktrackState({
            ...backtrackState,
            btnAction: "",
            printGuestBill: false,
            printRegCard: false
        })
        dispatch(toggleFooterAction(true));
    };
    const splittedPathname = pathname.split("backtrack/");
    const printRegCardArray = ["guests/individual", "arrivals/group"]
    const setPrintRegCard = () => {
        return setBacktrackState({
            ...backtrackState,
            printRegCard: backtrackState.printGuestBill || printRegCardArray.includes(splittedPathname[1]) ? !backtrackState.printRegCard : false
        })
    };
    const handleAction = () => {
        backtrackTableActions({
            backtrackState,
            pathname,
            updateRoutes
        })
    };
    const setShowRowDetails= () => {
        setBacktrackState({
            ...backtrackState,
            showDetails: !backtrackState.showDetails
        })
    }
    return (
        <React.Fragment>
            {
                formModalStatus && 
                backtrackState.modalType.includes("folio") || backtrackState.modalType.includes("receipt") ?
                <FolioAndReceipt 
                    pathname={pathname}
                    type={backtrackState.modalType.includes("consent") ? "consent" : backtrackState.modalType.includes("payment") ? "payment" : "receipt"}
                    hideFolio={setBacktrackState}
                    folioReceiptData={backtrackState.orderDetails}
                    dateCreated={backtrackState.orderToUpdate.dateCreated}
                    generatedBy={backtrackState.orderToUpdate.teller || backtrackState.orderToUpdate.generatedBy}
                    state={backtrackState}
                    receiptType="Restaurant"
                />
                :
                <React.Fragment>
                    {
                        !backtrackState.printRegCard ?
                        <div style={{ width: '80vw',}} className={`${showSidebar ? 'm-0' : 'm-5'}`}>
                            {
                                formModalStatus && backtrackState.modalType === "tray" ?
                                <div style={{width: "100vw"}} className='row d-flex'>
                                    <FAndBTray 
                                        setState={setBacktrackState}
                                        state={backtrackState}
                                        formInput={backtrackState.formInputs}
                                        orderDetails={backtrackState.orderDetails}
                                        pathname={pathname}
                                        orderMode={backtrackState.orderMode === "new" ? "new" : "modify"}
                                        orderDetailsToModify={backtrackState.orderDetailsToModify}
                                    />
                                </div>
                                :
                                formModalStatus && backtrackState.modalType === "pay-balance" &&
                                <FAndBPaymentTray 
                                    paymentMethods={backtrackState.paymentMethods} 
                                    formFields={backtrackState.formFields}
                                    formFieldTypes={backtrackState.formFieldTypes}
                                    formModalTitle={backtrackState.formModalTitle}
                                    onChange={handleFormChange}
                                    submitToServer={toggleModals}
                                    placeholders={backtrackState.placeholders}
                                    fAndBDiscounts={backtrackState.fAndBDiscounts}
                                    orderTotal={backtrackState.orderToUpdate.balance}
                                    fAndBDiscountData={backtrackState.fAndBDiscountData}
                                    order={backtrackState.orderToUpdate}
                                />
                                
                            }
                            {
                                formModalStatus &&
                                <Modal
                                    type={"form"}
                                    formFields={backtrackState.formFields || undefined}
                                    formFieldType={backtrackState.formFieldTypes || undefined}
                                    placeholders={backtrackState.formFieldPlaceholder || undefined}
                                    onChange={handleFormChange}
                                    numberOfFields={backtrackState.formFields && backtrackState.formFields.length as number || 0}
                                    title={backtrackState.formModalTitle}
                                    handleAction={toggleModals}
                                    formInput={backtrackState.formInputs}
                                    stopFormModalSubmission={true}
                                    formModalSaveButtonName={backtrackState.formModalSaveButtonName}
                                    serverSelectOption={backtrackState.serverSelectOption}
                                    pathname={pathname}
                                    minDate={backtrackState.minDate}
                                    maxDate={backtrackState.maxDate}
                                    // radioOptions={backtrackState.radioOptions}
                                    readOnly={backtrackState.readOnlyFields}
                                    editFlag={backtrackState.modalFormEditFlag}
                                    groupCheckInFormData={groupChackInFormData}
                                    optionalFormText={backtrackState.modalFormInstruction}
                                    continueButtonText={backtrackState.continueButtonText}
                                    cancelButtonText={backtrackState.cancelButtonText}
                                    // defaultSelectOption={backtrackState.defaultSelectOption}
                                    setGroupCheckInData={pathname.includes("arrivals/group") ? setGroupCheckInData : () => ""}
                                    // setPrintRegCard={setPrintRegCard}
                                />
                            }
                            {
                                showConfirm &&
                                <Modal
                                    type="confirm"
                                    pathname={pathname}
                                    onChange={() => ""}
                                    // setPrintRegCard={() => ""}
                                    numberOfFields={0}
                                    handleAction={handleAction}
                                    title={backtrackState.confirmModalTitle}
                                    continueButtonText={backtrackState.continueButtonText}
                                    cancelButtonText={backtrackState.cancelButtonText}
                                    setGroupCheckInData={() => undefined}
                                    previewData={(!pathname.includes("restaurant") && !pathname.includes("bar")) ? false : true}
                                    fAndBData={(!pathname.includes("restaurant")  && !pathname.includes("bar")) ? "" : backtrackState.fAndBDataToPreview}
                                />
                            }
                            {
                                pathname === "/backtrack" &&
                                <ModuleSummary pathname={`${pathname}`} dashboardName="Backtrack" />
                            }
                            <div style={{marginLeft: ".15rem"}} className={`${showSidebar ? 'm-3' : 'm-0'}`} >
                                {
                                    !backtrackState.showDetails ?
                                    <div className="">
                                        {
                                            // !pathname.toLowerCase().includes('end-of-day') &&
                                            <React.Fragment>
                                                <h4 className="mt-5">{frontdeskDashboardRouteChecker(pathname)}</h4>
                                                <article style={{justifyContent: "space-between", flexDirection: "column", width: "100%"}} className="d-flex align-items-center ">
                                                    <p style={{ fontFamily: "serif" }}>
                                                        Total {frontdeskDashboardRouteChecker(pathname)}: {backtrackState.tableData && Object.values(backtrackState.tableData).length}
                                                    </p>
                                                    <section style={{width: "100%", justifyContent: "space-between"}} className='frontdesk-top-buttons d-flex px-3'>
                                                        {
                                                            (pathname.includes("bar") || pathname.includes("restaurant")) &&
                                                            <Link 
                                                                to="#" 
                                                                onClick={() => backtrackActionsHandler("tray", "", 'make-order')} 
                                                                className="btn button bg bg-" 
                                                                style={{backgroundColor: colors.sideNav, color: colors.appYellow, fontWeight: "bold"}}
                                                                data-toggle="modal" 
                                                                data-target="#restaurantTray">
                                                                Open Table
                                                            </Link>
                                                        }
                                                        <article style={{width: pathname.includes("logs") || pathname.includes("status") || pathname.includes("company") ? "100%" : undefined}} className={document.querySelector('.settings-add-button') ? 'row justify-content-center' : undefined}>
                                                            <Filter 
                                                                tableData={backtrackState.tableData}
                                                                componentsState={backtrackState}
                                                                componentsStateSetter={setBacktrackState}
                                                                paginationEndPoint={backtrackState.paginationEndPoint}
                                                                paginationStartPoint={backtrackState.paginationStartPoint}
                                                            />
                                                        </article>
                                                        <button 
                                                            data-toggle="modal"
                                                            data-target="#modal"
                                                            onClick={() => backtrackActionsHandler('Message', backtrackState.reservationData)}
                                                            className="btn btn-info font-weight-bold">
                                                            Message
                                                        </button>
                                                    </section>
                                                </article>
                                            </React.Fragment>

                                        }
                                            <p className="animate__animated animate__headShake animate__infinite font-weight-bold text-success">{backtrackState.loadingPrompt}</p>
                                            <Suspense fallback={backtrackState.loadingPrompt}>
                                                <BacktrackSubTable
                                                    backtrackActionsHandler={backtrackActionsHandler}
                                                    backtrackState={backtrackState}
                                                    pathname={pathname}
                                                />
                                            </Suspense>
                                            {
                                                !backtrackState.loadingPrompt &&
                                                <span className="mx-3 btn-container float-right">
                                                    {
                                                        backtrackState.tableData && backtrackState.tableData.length > 10 &&
                                                        paginationButtons.slice(
                                                            (backtrackState.paginationStartPoint <= 0) ? 1 
                                                            :
                                                            0, (backtrackState.paginationEndPoint >= backtrackState.actualLengthOfTableData) ? 1
                                                            : undefined
                                                        ).map(btn => (
                                                            <button 
                                                                onClick={e => handlePagination(btn)}
                                                                key={btn}
                                                                className={`btn ${btn.toLowerCase() === 'next' ? 'btn-primary' : 'btn-secondary'} mx-2 mb-4`}>
                                                                {btn}
                                                            </button>
                                                        ))
                                                    }
                                                </span>
                                            }
                                            <div className="managers-flash-notes">
                                                {
                                                    (pathname.includes('arrivals') || pathname.includes('departures') || pathname.includes('guest-in-house')) &&
                                                    <section className="print-btn-container">
                                                        <button onClick={() => window.print()} className="btn btn-secondary">
                                                            Print
                                                        </button>
                                                    </section>
                                                }
                                                {
                                                    (pathname.includes('account') || pathname.includes('bills') || pathname.includes('guest')) &&
                                                    <section>
                                                        <p className="mx-2 font-weight-bold" style={{textDecoration: "underline", fontSize: "16px"}}>Definitions:</p>
                                                        {
                                                            Object.keys(individualsCheckedInDefinitions).slice(
                                                                pathname.includes("account") ? 2 : 0,
                                                                pathname.includes("account") ? undefined : 2,
                                                            ).map((variable) => (
                                                                <article key={variable} className="individualsCheckedInDefinitions">
                                                                    <p style={{fontSize: "14px"}} className="d-flex align-items-center">
                                                                        <span><BsArrowRightCircle /></span>
                                                                        <span className="mx-2 mt-1 font-weight-bold">{variable}:</span> 
                                                                        <span className="mt-1">{(individualsCheckedInDefinitions as any)[variable]}</span>
                                                                    </p>
                                                                </article>
                                                            ))
                                                        }
                                                    </section>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <Suspense fallback={backtrackState.loadingPrompt}>
                                            <Details
                                                className='mt-5'
                                                tableData={(pathname.includes("restaurant") || pathname.includes("bar")) ? [...backtrackState.orderToUpdate['orderDetails']] : backtrackState.dataToBeDetailed}
                                                setShowRowDetails={setShowRowDetails}
                                                tableHeader={(pathname.includes("restaurant") || pathname.includes("bar")) ? restaurantOrderDetailsTableHeader : backtrackState.tableHeaderForDataToBeDetailed}
                                                showRowDetails={backtrackState.showDetails}
                                                tableTitle={ backtrackState.detailsDataTableTitle}
                                                actionsHandler={(pathname.includes("restaurant") || pathname.includes("bar")) ? () => undefined : backtrackActionsHandler}
                                                footerArray={(pathname.includes("restaurant") || pathname.includes("bar")) ? [...backtrackState.footerArray] : [backtrackState.footerArray]}
                                                // stateSetter={setBacktrackState}
                                            />
                                        </Suspense>
                                }
                            </div>
                        </div>
                            :
                        <div>
                            {
                                backtrackState.printGuestBill ?
                                <FolioAndReceipt 
                                    pathname={pathname}
                                    type={(backtrackState.guestBillType.includes("individual") || pathname.includes('individual')) ? "guestbill" : "groupBill"}
                                    state={backtrackState}
                                    hideFolio={handleFolioToggle}
                                    folioReceiptData={backtrackState.dataToBeDetailed}
                                    guestBillTitle={backtrackState.detailsDataTableTitle}
                                />
                                :
                                <RegCard
                                    reservationDetails={backtrackState.reservationData}
                                    groupCheckInData={groupCheckInData}
                                    setPrintRegCard={setPrintRegCard}
                                    // setGroupCheckInData={setGroupCheckInData}
                                />
                            }
                        </div>
                }
                </React.Fragment>
            }
        </React.Fragment>

    )
};


export default BacktrackTable

