import { givenDateAndCurrentTime } from "date-fran";
import { settingsNonHiddenOptions } from "../components/containers/Modules/ModuleSidebar/SettingsSidebar";


export const stringJoiner = (string: string[] | string, joiningCharacter: any) => {
    let actualString = "";
    if(typeof(string) === "string"){
        for(let char of string){
            if(char === " "){
                actualString.concat(`${joiningCharacter}`);
            }else{
                actualString.concat(char);
            }
        }
    }

    return actualString;
}


export const titleCase = (word: string | string[]) => {
    // console.log("\n\t word: ", word)
    let desiredString = "";
    if(typeof(word) === "string"){
        const trimmedWord = word.trim();
        // console.log("\n\t trimmedWord[0]: ", trimmedWord[0])
        if(trimmedWord[0]){
            let ww = trimmedWord[0].toUpperCase()
            let charIndex = 0;
            for(let char of trimmedWord.substring(1)){
                if(char === " "){
                    ww.concat(char, trimmedWord[charIndex+1].toUpperCase());
                    charIndex += 2;
                }else{
                    // console.log("\n\t desired-string ww: ", char, trimmedWord[charIndex], ww)
                    ww += char;
                    charIndex++;
                }
            };
            desiredString += ww;
        }
    };
    return desiredString
}


export const multilineMessage = (message1: string, message2: string) => {
    const message = `<p>${message1} \n ${message2}</p>`
    return message
}

export const titledTextJoiner = (textString: string) => {
    try {
        const firstChar = textString[0].toLowerCase();
        const otherChars = textString.slice(1).replaceAll(" ", "");
        // console.log("\n\t firstChar+otherChars: ", firstChar+otherChars)
        return firstChar+otherChars;
    } catch (error: any) {
        // console.log("\n\t titledTextJoiner error: ", error.message)
        return ""
    }
}

export const tableDataCleaner = (dataName: string, dataObject: any, pathname?: string) => {
    // console.log("\n\t tableDataCleaner: ", dataName, dataObject)
    try {
        if(!pathname && !dataName.toLowerCase().includes('record') && !dataName.toLowerCase().includes('sales') && !dataName.toLowerCase().includes('shift') && !dataName.toLowerCase().includes('by') && dataName.toLowerCase().includes("date")){
            // console.log("\n\t Date: ", dataName);
            let dataValue = dataObject[titledTextJoiner(dataName)]
            dataValue = dataValue ? 
                        dataValue : 
                        dataName.toLowerCase().includes('checked-in') || dataName.toLowerCase().includes('check-in') ? 
                        dataObject['checkedInDate'] ? dataObject['checkedInDate'] : dataObject['individualCheckInDate'] : 
                        dataName.toLowerCase().includes('check-out') && !dataName.toLowerCase().includes('individual') ? 
                        dataObject['checkOutDate'] : 
                        dataName.toLowerCase().includes('check-out') && dataName.toLowerCase().includes('individual') ? 
                        dataObject['individualCheckOutDate'] : 
                        dataObject['dateCreated'];
                        
            // console.log("\n\t dataValue: ", dataValue.split("T"));
            // console.log("\n\t typeof-dataValue: ", typeof(dataValue));
            const datePart = givenDateAndCurrentTime(dataValue.split("T")[0]).toDateString();
            // console.log("\n\t datePart.concat(, timePart): ", datePart.concat(" ", dataValue.split("T")[1]));
            return datePart.concat(" ", timeHelper(dataValue.split("T")[1]))
        }else if(dataName.toLowerCase().includes("guest (group) name") || dataName.toLowerCase().includes("guest name") || dataName.toLowerCase() === "fullname"){
            // console.log("\n\t Fullname: ", dataName.toLowerCase(), dataObject)
            let fullname = "";
            if((dataName === "groupName" || dataName.toLowerCase().includes('group')) && dataObject['groupName']){
                return dataObject['groupName'];
            }else if(dataName.toLowerCase() === "fullname" && dataObject['fullname']){
                return dataObject['fullname'];
            }
            // console.log("\n\t Fullname-1: ", fullname)
            if(!fullname){
                fullname = dataObject['firstName'] ? dataObject['firstName'].concat(" ",  dataObject['lastName']) : dataName.toLowerCase() === 'guest name' ? dataObject['guestName'] : fullname;
            }
            return fullname;
        }else if(dataName.toLowerCase() === ("rate + breakfast")){
            const rate = dataObject['rate']
            const breakfast = dataObject['breakfast']
            return (+rate + +breakfast).toLocaleString()
        }else if(dataName.toLowerCase() === "number of rooms"){
            const numberOfRooms = dataObject['totalNumberOfRooms'] ? dataObject['totalNumberOfRooms'] : dataObject['numberOfRooms'] ? dataObject['numberOfRooms'] : 1;
            return numberOfRooms
        }else if(dataName.toLowerCase().includes("cost") || dataName.toLowerCase().includes("rate")){
            let cost = 0;
            try {
                cost = (dataObject[titledTextJoiner(dataName)]).toLocaleString()
            } catch (error) {
                cost = dataObject['totalCharge'].toLocaleString()
            }
            return cost
        }else if(dataName.toLowerCase().includes("adult") || dataName.toLowerCase().includes("number of adults")){
            return dataObject[titledTextJoiner("adult")] || dataObject[titledTextJoiner("numberOfAdult")]
        }else if(dataName.toLowerCase().includes("checked-in by")){
            return dataObject['checkedInBy']
        }else if(dataName.toLowerCase() === "required deposit"){
            return dataObject["requiredDepositAtCheckIn"].toLocaleString();
        }else if(dataName.toLowerCase() === "variety name"){
            return dataObject["categoryVarietyName"] || dataObject["varietyName"];
        }else if(dataName.toLowerCase() === "vat"){
            let charge = 0
            try {
                charge = dataObject["vat"].toLocaleString();
            } catch (error) {
                charge = dataObject['vatCharge'].toLocaleString();
            }
            return charge
        }else if(dataName.toLowerCase().includes("creditor/") || dataName.toLowerCase() === "depositor"){
            return dataObject["creditorOrDebtorsName"];
        }else if(dataName.toLowerCase() === "number of guests checked-in"){
            return dataObject["numberOfGuestsCheckedIn"];
        }else if(dataName.toLowerCase() === "number of guests checked-out"){
            return dataObject["numberOfGuestsCheckedOut"];
        }else if(dataName.toLowerCase().includes("paid at check-in")){
            return dataObject['amountPaidAtCheckIn'].toLocaleString()
        }else if(dataName.toLowerCase().includes("paid before check-out")){
            return dataObject['amountPaidBeforeCheckOut'].toLocaleString()
        }else if(dataName.toLowerCase().includes("occupancy")){
            return dataObject['occupancyStatus'].toLocaleString()
        }else if(dataName.toLowerCase() === "s/n"){
            const idKey = Object.keys(dataObject).find(key => !key.toLowerCase().includes("blockedrooms") && !key.toLowerCase().includes("related") && !key.toLowerCase().includes("_id") && key.toLowerCase().includes("id"))
            return dataObject[idKey as string]
        }else if(dataName.toLowerCase() === "username"){
            const username = dataObject['userName']
            return username
        }else if(dataName.toLowerCase() === "reservation code"){
            return dataObject['groupReservationCode']
        }else if(dataName === "Room Type/Number Of Rooms"){
            const roomTypeAndNumberOfRooms = dataObject['roomTypeAndNumberOfRooms']
            // console.log("\n\t roomTypeAndNumberOfRooms: ", roomTypeAndNumberOfRooms)
            let roomTypes = ""
            let numberOfRooms = ""
            let count = 0;
            for(let object of roomTypeAndNumberOfRooms){
                roomTypes += count === 0 ? object.roomTypeName : ", ".concat(object.roomTypeName);
                numberOfRooms += count === 0 ? object.numberOfRooms : ", ".concat(object.numberOfRooms);
                count++
            }
            return roomTypes.concat("/ ", numberOfRooms)
        }else{
            let required = ""
            if(dataName.toLowerCase().includes('code')){
                required = (dataObject[titledTextJoiner(dataName)])
            }else{
                // console.log("\n\t titledTextJoiner(dataName): ", titledTextJoiner(dataName), dataObject[titledTextJoiner(dataName)])
                required = dataName.toLowerCase().includes('check-in') ? (dataObject[titledTextJoiner('recordDate')]).toLocaleString() : (dataObject[titledTextJoiner(dataName)]).toLocaleString() || "--"
            };
            // console.log("\n\t required: ", required);
            return required
        }
    } catch (error) {
        return ""
    }
}

export const buttonTitle = (pathname: string) => {
    let check = false;
    let splittedPathname = pathname.split("/");
    let buttonName = "";
    buttonName = splittedPathname[3] || splittedPathname[2];
    // console.log("\n\t buttonName: ", buttonName)
    if(pathname.includes('settings')){
        check = settingsNonHiddenOptions.includes(buttonName)
    }
    const firstChar = buttonName[0].toUpperCase();
    let requiredName = ""
    let index = 0
    for(let char of buttonName.slice(1)){
        if(buttonName[index] === "-"){
            requiredName +=  "-"+buttonName[index + 1].toUpperCase();
        }else{
            requiredName += char
        }
        index++
    }
    return firstChar + requiredName.replaceAll("-", " ");
}

/**
 * Takes a word in Pascal case and return the required word in normal form
 * @param word the pascal-cased-string to be transformed
 */
export const pascalCaseSeparator = (word: string) => {
    const titleCasedWord = titleCase(word);
    // console.log("\n\t Word: ", word, titleCasedWord)
    let requiredWord = "";
    let count = 0;
    for(let char of titleCasedWord){
        if(count !== 0){
            // console.log("\n\t char: ", char)
            if(char === char.toUpperCase()){
                requiredWord += " " + char;
            }else{
                requiredWord += char;
            }
        }else{
            requiredWord += char.toUpperCase()
        }
        count ++
    }
    // console.log("\n\t requiredWord: ", requiredWord)
    return requiredWord;
}

export const convertToCamelCase = (word: string) => {
    const firstChar = word[0].toLowerCase();
    let otherChars = word.slice(1);
    let count = 0;
    let requiredWord = ""
    for(let char of otherChars){
        if(char === " "){
            requiredWord += otherChars[count+1].toUpperCase();
        }else{
            if(otherChars[count-1] !== " "){
                requiredWord += char
            }
        }
        count ++
    }
    // console.log("\n\t requiredWord: ", requiredWord)
    return firstChar + requiredWord;
}

const timeHelper = (timeString: string) => {
    const sections = timeString.split(":");
    // console.log("\n\t Time-string sections: ", sections);
    const hour = sections[0];
    if(+hour < 12){
        return hour.concat(":", sections[1], " ", "AM")
    }
    return hour.concat(":", sections[1], " ", "PM")
}



export const removeItemFromArray = (array: string[], index: number) => {
    const firstPart = array.slice(0, index)
    const secondPart = array.slice(index+1)
    const requiredArray = [...firstPart, ...secondPart];
    const choice = index == 0 ? array : requiredArray;
    return choice;
}