import { showConfirmModalAction, showFormModalAction } from "../../../redux/actions/storeActions/appActionsUtilities";
import { customAlert } from "../../../utilities/customAlert";
import { inputValidator } from "../../../utilities/security";

export const handleTrayBtnClick = (action: string, dispatch: Function, restaurantState: any, setRestaurantState: Function, setState: Function, state: any, pathname: string) => {
    if(action.toLowerCase().includes('cancel')){
        dispatch(showFormModalAction(false));
        window.location.reload();
    }else if(action.toLowerCase().includes('add persons')){
        const inputValidation = inputValidator(restaurantState.restaurantStateInputs, Object.keys(restaurantState.restaurantStateInputs).length, Object.keys(restaurantState.restaurantStateInputs))
        if(typeof(inputValidation) === "string") return customAlert("error", inputValidation, 5000)
        console.log("\n\t restaurantState.checkedInData: ", restaurantState.checkedInData)
        
        setRestaurantState({
            ...restaurantState,
            showTray: false,
        });
    }else if(action.toLowerCase() === "preview"){
        console.log("\n\t Preview-action", restaurantState.tableOrder)
        const individualTotals = fAndBDiscountAction({
            fAndBDiscount: restaurantState.chosenFAndBDiscount,
            fAndBTrayState: restaurantState,
            tableOrder: restaurantState.tableOrder
        })
        // console.log("\n\t Preview-action-restaurantState: ", restaurantState)
        // console.log("\n\t Preview-action-state: ", state)
        const orders = {}as any;
        const personKeys = Object.keys(restaurantState.tableOrder)
        Object.keys(restaurantState.tableOrder).map((orderKey: any, index: number) => {
            orders[`${personKeys[index]}`] = [personKeys[index], [...restaurantState.tableOrder[orderKey]], individualTotals[index]]
        });
        let cummulativeTotal = 0;
        individualTotals.map(total => cummulativeTotal += total)
        const orderDetails = {
            numberOfPersonsOnTable: restaurantState.restaurantStateInputs['numberOfPersonsOnTable'],
            tableNumber: restaurantState.restaurantStateInputs['tableNumber'],
            order: restaurantState.tableOrder,
            total: cummulativeTotal,
            discountCode: restaurantState.restaurantStateInputs['discountCode'],
            backtrackDate: pathname.includes("backtrack") ? restaurantState.restaurantStateInputs.backtrackDate : undefined
        }
        orders['Total'] = ["Total", [], cummulativeTotal || 0];
        orders['Discount'] = ["Discount", [], restaurantState.chosenFAndBDiscount ? restaurantState.chosenFAndBDiscount : "NONE",];
        // orders["orderDetails"] = orderDetails;
        if(pathname.includes('backtrack')){
            dispatch(showConfirmModalAction(true));
            dispatch(showFormModalAction(false));
            console.log("\n\t state.orderMode: ", state.orderMode);
            state.btnAction = state.orderMode === "new" ? "make-order" : "modify-order";
        }
        return setState({
            ...state,
            modalType: "confirm",
            fAndBDataToPreview: orders,
            orderDetails
        })
    }
};

export const setTableItemsFromOrderToModify = (restaurantState: any, setRestaurantState: Function, orderDetailsToModify: any) => {
    // console.log("\n\t OrderToModify['orderDetails']: ", orderDetailsToModify['orderDetails'])
    const total = orderDetailsToModify['totalCharge'];
    setRestaurantState({
        ...restaurantState,
        tableOrder : [
            ...orderDetailsToModify['orderDetails']
        ],
        restaurantStateInputs: {
            ...restaurantState.restaurantStateInputs,
            total
        },
        showOrdersToModify: true // to hide the showOrdersToModify button
    })
};

export const setTableItemsFromSearch = (restaurantState: any, setRestaurantState: Function, personNumber: number, selectedItem: string) => {
    
    const varietyMenu = (document as any).querySelector('.menu-item').value;
    const quantity = (restaurantState.restaurantStateInputs as any)['quantity']
    let cost = 0;
    restaurantState.varietyMenus.find((item: any) => {
        if(item.varietyMenu === varietyMenu) {
            return cost = item.cost;
        }
    })
    const chosenItem = {
        category: restaurantState.categoryName,
        item: varietyMenu,
        quantity: quantity ? quantity : 1,
        cost
    };
    
    // console.log("\n\t restaurantState.categoryName: ", restaurantState.categoryName)
    // console.log("\n\t restaurantState.chosenItem: ", chosenItem)

    if(chosenItem.category){
        const personsPreviousOrders = restaurantState.tableOrder[`Person-${personNumber+1}`];
        console.log("\n\t personsPreviousOrders: ", personsPreviousOrders)
        restaurantState.tableOrder[`Person-${personNumber+1}`] = [...personsPreviousOrders, {...chosenItem}]
        const orderTotalPerPerson = computeOrderTotals(restaurantState.tableOrder);
        varietyMenu && setRestaurantState({
            ...restaurantState,
            showDiscount: restaurantState.showDiscount ? restaurantState.showDiscount : true,
            restaurantStateInputs: {
                ...restaurantState.restaurantStateInputs,
            },
            orderTotalPerPerson
        });
    }
    (document as any).querySelector('.major-group').value = "";
    (document as any).querySelector('.family-group').value = "";
    (document as any).querySelector('.quantity').value = "";
    (document as any).querySelector('.menu-item').value = ""
};

export const setRestaurantTableItems = (restaurantState: any, setRestaurantState: Function, personNumber: number) => {
    
    const varietyMenu = (document as any).querySelector('.menu-item').value;
    const quantity = (restaurantState.restaurantStateInputs as any)['quantity']
    let cost = 0;
    restaurantState.varietyMenus.find((item: any) => {
        if(item.varietyMenu === varietyMenu) {
            return cost = item.cost;
        }
    })
    const chosenItem = {
        category: restaurantState.categoryName,
        item: varietyMenu,
        quantity: quantity ? quantity : 1,
        cost
    };
    
    // console.log("\n\t restaurantState.categoryName: ", restaurantState.categoryName)
    // console.log("\n\t restaurantState.chosenItem: ", chosenItem)

    if(chosenItem.category){
        const personsPreviousOrders = restaurantState.tableOrder[`Person-${personNumber+1}`];
        console.log("\n\t personsPreviousOrders: ", personsPreviousOrders)
        restaurantState.tableOrder[`Person-${personNumber+1}`] = [...personsPreviousOrders, {...chosenItem}]
        const orderTotalPerPerson = computeOrderTotals(restaurantState.tableOrder);
        varietyMenu && setRestaurantState({
            ...restaurantState,
            showDiscount: restaurantState.showDiscount ? restaurantState.showDiscount : true,
            restaurantStateInputs: {
                ...restaurantState.restaurantStateInputs,
            },
            orderTotalPerPerson
        });
    }
    (document as any).querySelector('.major-group').value = "";
    (document as any).querySelector('.family-group').value = "";
    (document as any).querySelector('.quantity').value = "";
    (document as any).querySelector('.menu-item').value = ""
};

export const modifyItemOnTray = (personCount: number, itemsIndex: number, restaurantState: any, setRestaurantState: Function, action: string) => {
    console.log("\n\t action-orderMode: ", action, restaurantState.orderMode)

    const desiredOrderAfterModification = [] as any;
    let personsOrderToModify = restaurantState.tableOrder[`Person-${personCount+1}`];
    const ordersNotBeingModified = {} as any;
    Object.keys(restaurantState.tableOrder).map((personKey: any) => {
        if(personKey != `Person-${personCount+1}`){
            ordersNotBeingModified[personKey] = restaurantState.tableOrder[personKey];
        }
    });
    // console.log("\n\t ordersNotBeingModified-1: ", ordersNotBeingModified)
    if(action == 'delete-item'){
        (personsOrderToModify as any[]).filter((item, index) => {
            if(index !== itemsIndex){
                desiredOrderAfterModification.push(item)
            }
        });
        // console.log("\n\t desiredOrderAfterModification-1: ", desiredOrderAfterModification)
        personsOrderToModify = desiredOrderAfterModification
    }else if(action == 'add-quantity'){
        const actualOrderToAddQuantity = personsOrderToModify[itemsIndex];
        const newQuantity = String(+actualOrderToAddQuantity.quantity + 1);
        actualOrderToAddQuantity.quantity = newQuantity;
        const otherOrders = personsOrderToModify.filter((item:any, index: number) => index != itemsIndex)
        personsOrderToModify = [actualOrderToAddQuantity, ...otherOrders];
        // console.log("\n\t actualOrderToAddQuantity: ", actualOrderToAddQuantity)
    }else if(action == 'minus-quantity'){
        const actualOrderToMinusQuantity = personsOrderToModify[itemsIndex];
        const newQuantity = String(+actualOrderToMinusQuantity.quantity - 1);
        actualOrderToMinusQuantity.quantity = newQuantity;
        const otherOrders = personsOrderToModify.filter((item:any, index: number) => index != itemsIndex)
        personsOrderToModify = [...otherOrders, actualOrderToMinusQuantity];
        //  console.log("\n\t actualOrderToMinusQuantity: ", actualOrderToMinusQuantity)
    }else if(action == 'change-item-left'){
        const actualOrderToChangeItemLeft = personsOrderToModify[itemsIndex];
        personsOrderToModify = switchItemLeftOrRight({
            actualOrderToChangeItemRightOrLeft: actualOrderToChangeItemLeft,
            itemsIndex,
            personsOrderToModify,
            restaurantState,
            type: "left"
        });
        // change-item-left console.log("\n\t actualOrderToChangeItemLeft: ", actualOrderToChangeItemLeft)
    }else if(action == 'change-item-right'){
        const actualOrderToChangeItemRight = personsOrderToModify[itemsIndex];
        personsOrderToModify = switchItemLeftOrRight({
            actualOrderToChangeItemRightOrLeft: actualOrderToChangeItemRight,
            itemsIndex,
            personsOrderToModify,
            restaurantState,
            type: "right"
        })
    }
    // console.log("\n\t desiredOrderAfterModification: ", desiredOrderAfterModification);
    // console.log("\n\t personsOrderToModify-1: ", personsOrderToModify)
    ordersNotBeingModified[`Person-${personCount+1}`] = personsOrderToModify ? personsOrderToModify : [];
    const requiredModifiedOrder = {
        ...ordersNotBeingModified,
    };
    // console.log("\n\t requiredModifiedOrder: ", requiredModifiedOrder)
    const orderTotalPerPerson = fAndBDiscountAction({
        fAndBTrayState: restaurantState,
        fAndBDiscount: restaurantState.chosenFAndBDiscount,
        tableOrder: requiredModifiedOrder
    });
    setRestaurantState({
        ...restaurantState,
        tableOrder: requiredModifiedOrder,
        orderTotalPerPerson,
    });
    // console.log("\n\t orderTotalPerPerson: ", orderTotalPerPerson)
};

export const computeOrderTotals = (requiredModifiedOrder: any) => {
    const keys = Object.keys(requiredModifiedOrder).sort();
    // console.log("\n\t Keys: ", keys)
    let count = 0;
    let totals = [] as number[];
    while(count < keys.length){
        let totalPerIndividual = 0;
        for(let items of requiredModifiedOrder[keys[count]]){
            totalPerIndividual += (+items.quantity*items.cost)
        }
        totals.push(totalPerIndividual)
        count++
    };
    
    return totals
};



interface OrderItemSwitchInterface {
    actualOrderToChangeItemRightOrLeft: any
    restaurantState: any
    personsOrderToModify: any[]
    itemsIndex: number
    type: "left" | "right"
}

const switchItemLeftOrRight = (props: OrderItemSwitchInterface) => {
    try {
        
        const { actualOrderToChangeItemRightOrLeft, itemsIndex, restaurantState } = props;
        const restaurantCategoryVarieties = restaurantState.categoryVarieties;
        const restaurantVarietyMenus = restaurantState.varietyMenus;

        const varietyMenuInOrder = actualOrderToChangeItemRightOrLeft.item;
        const detailsOfMenuInRestaurantVarietyMenus = restaurantVarietyMenus.find((item:any) => item.varietyMenu == varietyMenuInOrder)
        const categoryVarietyContainingDetails = restaurantCategoryVarieties.find((item:any) => detailsOfMenuInRestaurantVarietyMenus.inventoryVarietyDetails == item._id)
        const allVarietyMenusWithinCategory = restaurantVarietyMenus.filter((item:any) => item.inventoryVarietyDetails == categoryVarietyContainingDetails._id)
        // const categoryVarietyContainingVarietyMenuInOrder = restaurantCategoryvarieties.find((categoryVariety: any) => )

        const fAndBVarietyMenus = allVarietyMenusWithinCategory.map((item:any) => item.varietyMenu);
    
        const indexOfVarietyMenuInOrder = fAndBVarietyMenus.indexOf(varietyMenuInOrder);
        let itemInRightPositionOfVarietyMenuInOrder = "" as any;
        let itemInLeftPositionOfVarietyMenuInOrder = "" as any;
        if(props.type == "right"){
            itemInRightPositionOfVarietyMenuInOrder = (indexOfVarietyMenuInOrder + 1) <= (fAndBVarietyMenus.length-1) ? fAndBVarietyMenus[indexOfVarietyMenuInOrder + 1] : fAndBVarietyMenus[fAndBVarietyMenus.length-1];
            actualOrderToChangeItemRightOrLeft.item = itemInRightPositionOfVarietyMenuInOrder;
        }else{
            itemInLeftPositionOfVarietyMenuInOrder = (indexOfVarietyMenuInOrder - 1) >= 0 ? fAndBVarietyMenus[indexOfVarietyMenuInOrder - 1] : fAndBVarietyMenus[0]
            actualOrderToChangeItemRightOrLeft.item = itemInLeftPositionOfVarietyMenuInOrder;
        }
        let itemsCost = 0
        restaurantState.varietyMenus.find((item: any) => {
            if((props.type == "right") && (item.varietyMenu === itemInRightPositionOfVarietyMenuInOrder)) {
                return itemsCost = item.cost;
            }else{
                if((props.type == "left") && (item.varietyMenu === itemInLeftPositionOfVarietyMenuInOrder)) {
                    return itemsCost = item.cost;
                }
            }
        })
        actualOrderToChangeItemRightOrLeft.cost = itemsCost;
    
        const otherOrders = props.personsOrderToModify.filter((item:any, index: number) => index != itemsIndex)
        return props.personsOrderToModify = [...otherOrders, actualOrderToChangeItemRightOrLeft];
    } catch (error) {
        
    }
}


interface DiscountInterface {
    fAndBTrayState: any
    fAndBDiscount: string
    tableOrder: []
}
export const fAndBDiscountAction = (props: DiscountInterface) => {
    try {
        const chosenDiscountDetails = props.fAndBTrayState.fAndBDiscount.find((item: any) => item.description === props.fAndBDiscount)
        const discountValue = chosenDiscountDetails ? chosenDiscountDetails.discount : 0;
        let newOrderTotalPerPersonDueDiscount = []as number[];
        const orderTotals = computeOrderTotals(props.tableOrder)
        orderTotals.forEach((value:number) => {
            const discountedTotalPerPerson = value*(1-discountValue/100);
            newOrderTotalPerPersonDueDiscount.push(discountedTotalPerPerson);
        })
        console.log("\n\t newOrderTotalPerPersonDueDiscount: ", newOrderTotalPerPersonDueDiscount)
        return newOrderTotalPerPersonDueDiscount;
    } catch (error) {
        console.log(error)
        return []
    }
}