import { Route, Routes, useNavigate } from "react-router-dom";

import HomeScreen from "../components/screens/HomeScreen";
import Logout from "../components/containers/Logout";
import { useEffect } from "react";
import { useSelector } from "react-redux";

interface Props {
    
}

const Unprotected = (props: Props) => {
    const store = useSelector((store: any) => store);
    const { userDetails } = store;
    const { userType } =  userDetails.userDetails ? userDetails.userDetails : "";
    const navigate = useNavigate();
    useEffect(() => {
        // !userType && navigate("/") 
    }, [])
    return (
        <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/logout" element={<Logout />} />
        </Routes>
    )
}

export default Unprotected;
