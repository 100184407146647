import { customAlert } from "./customAlert";
import { clearStorageItem } from "./localStorage";

import { pascalCaseSeparator } from "./strings";

export const denyAccess = (navigate: Function) => {
    customAlert("warning", "You've been denied access. Please login in again.", 5000)
    setTimeout(async () => {
        await clearStorageItem();
        navigate("/")
    }, 5200);
};



/**
 * 
 * @param inputData data for which its values are to be validated
 * @param numberOfFields the number of important fields required to be validated
 * @returns string if input validation fails or boolean if otherwise
 */
export const inputValidator = (inputData: object, numberOfFields: number, fieldNames: string[]) => {
    // console.log("\n\t inputValidator: ", inputData)
    // console.log("\n\t inputValidator-fieldNames: ", fieldNames, numberOfFields)

    const keys = Object.keys(inputData);
    // console.log("\n\t inputValidator-keys: ", keys)
    if(keys.length === 0){
        return `Invalid entry. Required field(s) cannot be empty.`
    }else if(keys.length !== numberOfFields){
        for(let field of fieldNames){
            if(!Object.keys(inputData).includes(field)){
                return `Invalid entry. ${pascalCaseSeparator(field)} cannot be empty.`
            }
        }
    }
    const values = Object.values(inputData);
    let index = 0
    for(let value of values){
        if(value === undefined || value.length === 0){
            return `${pascalCaseSeparator(keys[index])} cannot be empty`
        }
        index++
    }
    return true
}