import React from 'react'
import { useLocation } from 'react-router-dom';
import ModuleSummary from '../ModuleSummary/ModuleSummary';
import AuditReportsTable from '../Tables/auditReports/AuditReportsTable'
import EndOfDay from '../Tables/auditReports/endoOfDayComponents/EndOfDay';
import DailySales from '../Tables/DailySales';

interface Props {
    summaryHeader: any[]
    tableData: []
};

const AuditReportsDashboard = (props: Props) => {
    const location = useLocation();
    const pathname = location.pathname;
    console.log("\n\t Reports-pathname: ", pathname)
    return (
        <div className={`${(pathname.includes('transaction') || pathname.includes('flash')) ? "ml-5" : "ml-1"}`}>
            {
                pathname ===  "/audit-reports" ?
                <ModuleSummary dashboardName="Reports"  />
                :
                // pathname.includes('daily') && !pathname.includes('transactions') && !pathname.includes('logs') ?
                // <DailySales salesRoute={
                //     pathname.includes('bar') ? `/records/${pathname}`
                //     :
                //     "/records/restaurant-daily-sales"
                // } />
                // :
                pathname.includes('end') ?
                <EndOfDay  />
                :
                <AuditReportsTable />
            }
        </div>
    )
}

export default AuditReportsDashboard
