import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useLocation } from 'react-router';
import colors from '../../../../../assets/colors';
import "../../../../../styles/ReportsStyles.css";

import { BsArrowRightCircle } from "react-icons/bs";
import Gifs from '../../../../Gifs';
import { managersFlashRecord } from '../tableActions/tableActionsThree';
import axiosHelper from '../../../../../utilities/axiosHelper';
import { titleCase } from '../../../../../utilities/strings';

interface Props {
    dataToDetail: any
};

const ManagersFlashReport = (props: Props) => {
    const store = useSelector((store: any) => store);
    const pathname = useLocation().pathname;
    const { sidebarStatus } = store;
    const { showSidebar } = sidebarStatus;

    // const location = useLocation();
    const subRecord = ["Day", "Month", "Year"]
    const [managersFlashState, setManagersflashState] = useState({
        rowVariables: []as string[],
        referenceDate: "",
        lastYearVariables: [] as string[],
        thisYearVariables: [] as string[],
        paginatedTableData: [],
        paginationStartPoint: 0,
        paginationEndPoint: 10,
        paginationLenght: 10,
        loadingPrompt: `Please wait; HotelPro is fetching ${titleCase(pathname.split("/")[2])} data...`,
        actualLengthOfTableData: 0
    })
    const definitions = {
        "Available rooms": "Signifies the number of rooms available for reservation.",
        "Dirty rooms": "Signifies the number of rooms checked-out from, but requires clean-up.",

    };
    useEffect(() => {
        (async () => {
            setManagersflashState({
                ...managersFlashState,
                loadingPrompt: `Please wait; HotelPro is fetching ${titleCase(pathname.split("/")[2])} data...`,
            });
            // console.log("\n\t dataToDetail: ", props.dataToDetail)
            const dataToDetail = props.dataToDetail;
            const recordResponse = await axiosHelper({
                httpVerb: "get",
                routeName: `${pathname}/${dataToDetail.recordDate}`
            });
            // console.log("\n\t recordResponse: ", recordResponse)
            const data = recordResponse ? recordResponse.data : {data: []}
            const managersFlashRecordData =  (pathname.includes('market') || pathname.includes('room')) ? data : managersFlashRecord(data);
            // console.log("\n\t managersFlashRecordData: ", managersFlashRecordData)
           if(managersFlashRecordData.length > 0){ 
                const data = managersFlashRecordData;
                // console.log("\n\t Data: ", data)
                setManagersflashState({
                    ...managersFlashState,
                    rowVariables: data[0].variables,
                    referenceDate: new Date(data[0].referenceDate).toDateString(),
                    lastYearVariables: data[0].lastYearsArray,
                    thisYearVariables: data[0].thisYearsArray,
                    loadingPrompt: ""
                });
            };
        })();
    }, []);
    // console.log("\n\t managersFlashState: ", managersFlashState )
    return (
        <React.Fragment>
            {
                managersFlashState.loadingPrompt ?
                <div style={{height: "100vh", width: "100vw", marginLeft: "-4rem", flexDirection: "column"}} className='bg-black d-flex justify-content-center align-items-center'>
                    <Gifs width={150} height={150} gifName='fandb' />
                    {/* <p className='font-italic animate__animated animate__headShake animate__infinite'>{recordsState.loadingText}</p> */}
                    <span className="font-weight-bolder small text-success animate__animated animate__pulse animate__infinite">{managersFlashState.loadingPrompt}</span>
                </div>
                :
                <div className="managers-flash-table-container" style={{
                                                    // overflow: "hidden", 
                                                    marginLeft: showSidebar ? "-25px" : "2rem", 
                                                    width: "80vw"
                                                }}>
                    <span className="managers-flash-header">
                        <span className='text-center'>
                            <Gifs gifName='hotel-logo' />
                        </span>
                        <h4 className="text-center mt-2">{pathname.includes('market') || pathname.includes('room') ? `${pathname.includes('market') ? "Market" : "Room-type"} Segmentation` : "Managers' Flash"}</h4>
                        <h5 style={{fontSize: "15px"}} className="text-center font-weight-bold">{managersFlashState.referenceDate && `Reference Date: ${managersFlashState.referenceDate}`}</h5>
                        <hr />
                    </span>
                    <div >
                        <table className="managers-flash-table-table table bg-light table-striped table-hover table-bordered">
                            <thead style={{backgroundColor: colors.sideNav}} className="text-white">
                                <tr>
                                    <th>S/N</th>
                                    <th  className="text-center">Variables</th>
                                    <th className="text-center" colSpan={3}>
                                        <span>This Year</span>
                                        <span style={{borderTopWidth: "2px", borderTopColor: "grey", borderTopStyle: "solid", width: "100%", marginTop: "5px"}} className="hr d-flex mb-3"></span>
                                        <span className="row mt-2">
                                            {
                                                subRecord.map(rec => (
                                                    <span  style={{display: "flex", width: "33.09%"}} className="text-center justify-content-center" key={rec}>{rec}</span>
                                                ))
                                            }
                                        </span>
                                    </th>
                                    
                                    <th className="text-center last-year-column" colSpan={3}>
                                        <span>Last Year</span>
                                        <span style={{borderTopWidth: "2px", borderTopColor: "grey", borderTopStyle: "solid", width: "100%", marginTop: "5px"}} className="hr d-flex mb-3"></span>
                                        <span className="row mt-2">
                                            {
                                                subRecord.map(rec => (
                                                    <span style={{display: "flex", width: "33.09%"}} className="text-center justify-content-center" key={rec}>{rec}</span>
                                                ))
                                            }
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {
                                managersFlashState.paginatedTableData &&
                                <tbody className='managers-flash-body'>
                                    {
                                        managersFlashState.rowVariables.length > 0 && managersFlashState.rowVariables.map((variable, index) => (
                                            <tr key={variable}>
                                                <td style={{width: "20px", textAlign: "center"}}>{index+1}</td>
                                                <th style={{width: "300px", }} colSpan={1}>{variable}</th>
                                                {
                                                    managersFlashState.thisYearVariables[index] !== undefined && (managersFlashState.thisYearVariables[index] as any).map((vars: number, tvsIndex: number) => (
                                                        <td width={"12%"} className="text-center" key={tvsIndex}>{variable.toLowerCase().includes('occupancy') ? `${vars.toFixed(2)}%` : variable.toLowerCase().includes('revenue') ? vars.toLocaleString() :  vars}</td>
                                                    ))   
                                                }
                                                {
                                                    managersFlashState.lastYearVariables[index] !== undefined && (managersFlashState.lastYearVariables[index] as any).map((vars: number, lvsIndex: number) => (
                                                        <td width={"12%"} className="text-center" key={lvsIndex}>{variable.toLowerCase().includes('occupancy') ? `${vars.toFixed(2)}%` : variable.toLowerCase().includes('revenue') ? vars.toLocaleString() : vars}</td>
                                                    ))   
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            }
                        </table>
                    </div>
                    {
                        !pathname.includes('market') &&
                        <div className="managers-flash-notes">
                            <section>
                                <p className="mx-2 font-weight-bold" style={{textDecoration: "underline", fontSize: "16px"}}>Definitions:</p>
                                {
                                    Object.keys(definitions).map((variable) => (
                                        <article key={variable} className="definitions">
                                            <p style={{fontSize: "14px"}} className="d-flex align-items-center">
                                                <span><BsArrowRightCircle /></span>
                                                <span className="mx-2 mt-1 font-weight-bold">{variable}:</span> 
                                                <span className="mt-1">{(definitions as any)[variable]}</span>
                                            </p>
                                        </article>
                                    ))
                                }
                            </section>
                        </div>
                    }
                    <section style={{justifyContent: "space-between"}} className="row">
                        <span className="mx-3 print-btn-container">
                            <button className="btn btn-secondary text-center" onClick={e => window.print()}>
                                Print 
                            </button>
                        </span>
                    </section>
                </div>
            }
        </React.Fragment>
    )
};

export default ManagersFlashReport;
