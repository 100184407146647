import axiosHelper from "../../../../utilities/axiosHelper";

export const houseKeepingStatus = ["Clean", "Dirty", "Out-of-order"];


export const serverSelectOptionsForSettings = (settingsState: any) => {
    return {
        groupName: settingsState.groups, 
        roomType: settingsState.roomTypes,
        roomFloor: settingsState.roomFloor, 
        houseKeepingStatus, 
        staff: settingsState.staffs,
        shiftName: settingsState.shiftName,
        occupancyStatus: ["Vacant", "Occupied"], 
        status: ["Open", "Closed"], 
        receipients: ["In-House Guests", "All Guests", "F&B Staffs", "Frontdesk Staffs", "Administrative Staffs"], 
        blockStatus: ["Blocked", "Opened"], 
        workStatus: ["On-leave", "Suspended", "Active"],
        majorGroup: settingsState.inventoryCategory, 
        familyGroup: settingsState.inventoryCategoryVarieties, 
        department: departments.slice(0, 7).sort(), 
        outOfOrderReason: ["Heater", "A/C fault", "Beddings", "Furnitures", "POP Issues", "Door Malfunction", "Electrical faults", "Electronics", "Plumbing faults", "NONE"].sort(),
        companyName: settingsState.allCompanies, 
        licenseDuration: settingsState.licenseDuration, 
        warningDaysCount: settingsState.warningDaysCount,
        role: settingsState.userRoles.sort(),
        visibilityStatus: ["Visible", "Hidden"]
    }
}

interface FieldSelectorInterface {
    
}
export const formFieldsSelector = (pathname: string, btnAction?: string) => {
    const subPath = pathname.split("/").length > 3 ? pathname.split("/")[3] : pathname;
    // console.log("\n\t formFieldsSelector-Sub Path: ", subPath);
    // console.log("\n\t formFieldsSelector btnAction: ", btnAction);

    if(subPath.includes('credits') || subPath.includes('charge')){
        return [[`${subPath.includes('credits') ? "Credit" : "Charge"} Code`, "Description", !subPath.includes('credits') ? "Category": null].slice(0, pathname.includes('credits') ? 2 : undefined), ["number", "textarea", !subPath.includes('credits') &&  "radio"], [], [], ["Taxable", "Non-taxable"]] 
    }else if(subPath.includes('discount')){
        return [["Discount", "Description"], ["number", "textarea"]] 
    }else if(pathname.includes("settings") && btnAction && btnAction.includes('bulk-email')){
        return [["Receipients", "Mail Subject", "Message"], ["select", "text", "textarea"], ["", "What's the mail title(subject)", ""]] 
    }else if(subPath.includes('taxes')){
        return [["VAT", "Service Charge", "Consumption Charge"], ["number", "number", "number"], ["Enter VAT charge in percentge", "Enter service charge in percentge", "Enter consumption charge in percentge"]] 
    }else if(subPath.includes('payment')){
        return [["Payment Method Code", "Description"], ["number", "textarea"], ["Enter payment method", "Describe this payment method"]] 
    }else if(subPath.includes('major-group')){
        return [["Major Group"], ["text"], ["Food"]] 
    }else if(subPath === 'family-group'){
        return [["Major Group", "Family Group"], ["select", "text"], ["Food"]] 
    }else if(subPath.includes('menu-items')){
        return [["Family Group", "Menu Item", "Cost", "Visibility Status"], ["select", "text", "number", "select"], [""]] 
    }else if(subPath.includes('groups')){
        return [["Group Name", "Group Email", "Group Code", "Credit Limit"], ["text", "email", "text", "number"]] 
    }else if(subPath.includes('time')){
        return [["Time Name", "Start Time", "End Time"], ["select", "time", "time"], [], ["Breakfast", "Lunch", "Dinner"]] 
    }else if(subPath.includes('hotel-date')){
        return [["Date"], ["date"], [], []] 
    }else if(subPath.includes('shift-timing')){
        return [["Shift Name", "Start Time", "End Time"], ["text", "time", "time"], [], []] 
    }else if(subPath.includes('shifts')){
        return [["Staff", "Shift Name", "Shift Date",], ["select", "select", "date",], [], []] 
    }else if(subPath.includes('room-and-rates')){
        return [
            ["Room Type", "Room Floor", "Room Number", "Breakfast"], // form fields
            ["select", "select", "number", "number"], // field types
            [], // placeholders
            [], // select options
           ] 
    }else if(subPath.includes('room-type')){
        return [["Room Type Name", "Required Deposit", "Room Type Rate", "Double Rate", ], ["text", "number", "number", "number"], ["", "Required deposit at check-in"]] 
    }else if(subPath.includes('floor')){
        return [["Floor Number",], ["text"]] 
    }else if(subPath.includes('communications')){
        return [["Department", "Email", "Password"], ["select", 'email', 'password'], ["text"]] 
    }else if(subPath.includes('location')){
        return [["Table Location",], ["text"], ["Bar"]] 
    }else if(pathname.includes('licenses')){
        return [["Company Name", "Clients Domain", "License Start Date", "License End Date", "Warning Days Count"], ["select", "text", "date", "date", "select"], ["Bar"],] 
    }else if(pathname.includes('facilities')){
        return [["Facility Name"], ["text"], ["Gym"],] 
    }else if(pathname.includes('companies')){
        return [
            ["Company Name", "Address", "Email", "Primary Phone", "Secondary Phone", "Website Address", "Slogan", "Logo"], 
            ["text", "text", "email", "tel", "tel", "text", "text", "file"], ["BON Hotel"]
        ] 
    }else if(subPath.includes('group-rate')){
        return [["Group Name", "Room Type", "Room Rate", "Breakfast"], ["select", "select", "number", "number"], []] 
    }else if(subPath.includes('title')){
        return [["Title Code", "Description"], ["text", "textarea",], []] 
    }else if(subPath.includes('bar-rates')){
        return [
            ["Occupancy Percentage", "Room Type", "Current Rate", "New Rate"], 
            ["number", "select", "number", "number",], ["Percentage at which this bar-rate would be initiated.", "", "", "Enter new rate for this bar-rate"], [], 
        ] 
    }else if(subPath.includes('source')){
        return [["Source Code", "Description"], ["text", "textarea",], []] 
    }else if(subPath.includes('membership-type')){
        return [["Membership Type", "Discount"], ["text", "number"], ] 
    }else if(pathname.includes('account')){
        return [["Account Name"], ['text'], ['Enter the name of the account e.g Chairman']]
    }else if(subPath.includes('room-status')){
        return [["Occupancy Status", "Block Status", "House Keeping Status","Out Of Order Reason"], ["select", "select", "select", "select"] ] 
    }else if(subPath.includes('f-and-b-tables')){
        console.log("\n\t btnAction: ", btnAction)
        return [["Table Number", "Department", "Maximum Persons On Table", btnAction && btnAction.includes("edit") ? "Status" : ""], ["number", "select", "number", "select"],  ] 
    }else if(subPath.includes('users')){
        return [
            ["Firstname", "Lastname", "Email", "Username", "Phone", "Work Status", "Role", "Password", "Confirm Password"], // fields
            ["text", "text", "email", "text", "tel", "select", "select", "password", "password"], // types
            [], // placeholders
            [],
            [], // radio options
        ] 
    }
};

export const reservationsdashboardRouteChecker = (pathname: string) => {
    if(pathname.toLowerCase().includes('future')){
        return pathname.includes("individual") ? "Future Arrivals (Indivdual)" : "Future Arrivals (Group)"
    }
    if(pathname.toLowerCase().includes('individual')){
        return "Individual Reservations"
    }if(pathname.toLowerCase().includes('room')){
        return "Rooms"
    }if(pathname.toLowerCase().includes('group')){
        return "Group Reservations"
    }if(pathname.toLowerCase().includes('profiles')){
        return "Guest Profiles"
    }if(pathname.toLowerCase().includes('receipts')){
        return "Receipts"
    }if(pathname.toLowerCase().includes('calendar')){
        return "Reservation Calendar"
    }else{
        return "Reservations"
    } 
};

export const frontdeskDashboardRouteChecker = (pathname: string) => {
    if(pathname.toLowerCase().includes('checked-in/group')){
        return "Groups Checked-in"
    }if(pathname.toLowerCase().includes('checked-in/individual')){
        return "Individuals Checked-in"
    }if(pathname.toLowerCase().includes('checked-out')){
        return "Checked-out"
    }if(pathname.toLowerCase().includes('room-status')){
        return "Rooms Summary (By Status)"
    }if(pathname.toLowerCase().includes('room-type-summary')){
        return "Room Summary (By Type)"
    }if(pathname.toLowerCase().includes('arrivals/group')){
        return "Expected Group Arrivals"
    }if(pathname.toLowerCase().includes('arrivals/individual')){
        return "Expected Individual Arrivals"
    }if(pathname.toLowerCase().includes('history')){
        return "Check-out History"
    }if(pathname.toLowerCase().includes('departures/individual')){
        return "Departures (Individuals)"
    }if(pathname.toLowerCase().includes('departures/group')){
        return "Departures (Group)"
    }if(pathname.toLowerCase().includes('security')){
        return "Security &  Night Auditors' Report"
    }if(pathname.toLowerCase().includes('guest-in-house/group')){
        return "Guest In-House (Group)"
    }if(pathname.toLowerCase().includes('pm-account')){
        return "PM-Accounts"
    }if(pathname.toLowerCase().includes('bills')){
        return pathname.includes('individual') ? "Guest Bills (Individual)" : "Guest Bills (Group)"
    }if(pathname.toLowerCase().includes('backtrack/guests')){
        return pathname.includes('individual') ? "Bactrack Guests (Individual)" : "Bactrack Guests (Group)"
    }if(pathname.toLowerCase().includes('facilities')){
        return pathname.includes("event") ? "Events" : "Facilities"
    }if(pathname.toLowerCase().includes('backtrack') && (pathname.toLowerCase().includes('restaurant') || pathname.toLowerCase().includes('bar'))){
        return pathname.includes('restaurant') ? "Bactrack Bills (Restaurant)" : 
        pathname.includes('pool') ? "Bactrack Bills (Pool-Bar)" : 
        "Bactrack Bills (Bar)"
    }if(pathname.includes("no-eod")){
        return "End of Day (Backtrack)";
    }else{
        return "Expected Arrivals";
    } 
};


export const rowActionFunctions = {
    postAction: async (pathname: string, settingsInput: any) => {
        console.log("\n\t postAction: ", settingsInput)
        const serverResponse = await axiosHelper({
            routeName: `${pathname}/add`, 
            httpVerb: "post", 
            dataToPostEditOrDelete: settingsInput
        });
        console.log("\n\t Error type: ", serverResponse)
        return serverResponse;
    },
    deleteAction: async (pathname: string, id: string) => {
        console.log("\n\t Now deleting....");
        const serverResponse = await axiosHelper({
            routeName: `${pathname}/delete/${id}`, 
            httpVerb: "delete", 
        });
        console.log("\n\t Error type: ", serverResponse)
        return serverResponse;
    },
    updateAction: async (pathname: string, id: string, dataToEdit: any) => {
        console.log("\n\t updateAction-id: ", id, dataToEdit);
        // return
        const serverResponse = await axiosHelper({
            routeName: `${pathname}/update/${id}`, 
            httpVerb: "put", 
            dataToPostEditOrDelete: dataToEdit
        });
        return serverResponse;
    },
   
};


export const resrvationTableHeader = [
    "S/N", "Reservation Type", 
    "Date Created", "Created By", 
    "Actions"
];

export const frontdeskTableHeader = [
    "S/N", "Guest (Group) Name", "Room Number", "Number of Rooms", "Arrival Date",	"Departure Date", "Nights",
];

export const barTableHeader = [
    "S/N", "Item Code",	"Item Name", "Category",	"Cost Price",	"Selling Price",	
    "Quantity", "Location"
];

export const poolBarTableHeader = [
    "S/N", "Item Code",	"Item Name", "Category",	"Cost Price",	"Selling Price",	
    "Quantity", "Location"
];

export const storeTableHeader = [
    "S/N", "Item Code",	"Item Name", "Category",	"Cost Price",	"Selling Price",	
    "Quantity", "Location"
];

// export const res = [
//     "S/N", "Item Code",	"Item Name", "Category",	"Cost Price",	"Selling Price",	
//     "Quantity", "Location"
// ];

export const departments = ["Administrative", "Restaurant", "Inner-Bar", "Pool-Bar",  "Kitchen", "Frontdesk", "House Keeping", "Store",  "Maintenance"];

// ======================================= Reservations ======================================
export const individualReservationTableHeader = ["S/N", "Fullname", "Arrival Date", "Departure Date", "Traces", "Membership Type",  "Adults", "Nights", "Room Type", "Room Rate", "Room Number", "Floor", "Total Cost", "Deposit", "Date Created", "Created By", "Date Updated", "Updated By", "Actions"];
export const hotelOccupancyTableHeader = ["S/N", "Fullname", "Arrival Date", "Departure Date", "Membership Type",  "Adults", "Nights", "Traces", "Room Type", "Room Rate", "Room Number", "in-House Status", "Total Cost", "Deposit", "Date Created"];

export const groupReservationTableHeader = ["S/N", "Group Name", "Reservation Code", "Arrival Date", "Departure Date", "Nights", "Room Type/Number Of Rooms", "Number Of Guests", "Total Cost", "Date Created", "Created By", "Date Updated", "Updated By", "Actions"];
export const reservationCalendarTableHeader = ["S/N", "Month/Year", "Number Of Guests", "Number Of Rooms",  "Actions"];

export const roomStatusTableHeader = ["S/N", "Room Number", "Occupancy Status", "Rate", "Room Floor", "Room Type Name", "Block Status", "House Keeping Status", "Out Of Order Reason", "Actions"];
export const roomTypeSummaryTableHeader = ["S/N", "Room Type", "Number of Rooms", "Rooms Occupied", "Rooms Vacant", "Out Of Order", "Dirty Rooms", "Clean Rooms"];

export const generalDetailsReservationTableHeader = ["S/N", "Reservation Type", "Date Created", "Created By"];
export const guestsProfileTableHeader = ["S/N", "Title", "Fullname",  "Email", "Phone", "Nationality", "Membership Type", "Membership Number", "Last Checkout Date", "Date Created", "Actions"];

// ========================================= Settings ===================================
export const chargeCategoryTableHeader = ["S/N", "Charge Code", "Category", "Description", "Date Created", "Date Updated", "Created By", "Updated By", "Actions"];
export const creditCategoryTableHeader = ["S/N", "Credit Code", "Description", "Date Created", "Date Updated", "Created By", "Updated By", "Actions"];

export const fAndBDiscountTableHeader = ["S/N", "Discount", "Description", "Date Created", "Date Updated", "Created By", "Updated By", "Actions"];
export const notiificationTableHeader = ["S/N", "Department", "Email",  "Date Created", "Date Updated", "Created By", "Actions"];
export const fAndBTImeSettingsTableHeader = ["S/N", "Time Name", "Start Time", "End Time",  "Date Created", "Date Updated", "Created By", "Actions"];
export const shiftSettingsTableHeader = ["S/N", "Shift Name", "Shift Date", "Staff", "Status",  "Date Created", "Date Updated", "Created By", "Updated By", "Actions"];
export const shiftTimingTableHeader = ["S/N", "Shift Name", "Start Time", "End Time",  "Date Created", "Date Updated", "Created By", "Updated By", "Actions"];



export const fAndBVirtualRoomsTableHeader = ["S/N", "Room Code", "Description", "Date Created", "Date Updated", "Created By", "Actions"];
export const roomRateTableHeader = ["S/N", "Room Number", "Occupancy Status", "Rate", "Double Rate", "Breakfast","Rate + Breakfast" ,"Room Floor","Room Type Name","House Keeping Status", "Date Created", "Date Updated", "Created By", "Actions"];
export const roomTypeTableHeader = ["S/N", "Room Type Name", "Required Deposit", "Room Type Rate", "Room Type Double Rate", "Date Created", "Date Updated", "Created By", "Actions"];
export const hotelDateTableHeader = ["S/N", "Hotel Date",  "Date Updated", "Updated By", "Actions"];

export const roomFloorTableHeader = ["S/N", "Floor Number", "Created By", "Date Created", "Date Updated",  "Actions"];
export const taxesTableHeader = ["S/N", "Vat", "Service Charge", "Consumption Charge", "Date Created", "Date Updated", "Created By", "Actions"];
export const paymentMethodTableHeader = ["S/N", "Payment Method Code", "Description", "Date Created", "Date Updated", "Created By", "Actions"];
export const pmAccountTableHeader = ["S/N", "Account Name", "Credit", "Debit", "Number Of Transactions", "Auto Created", "Date Created", "Created By", "Date Updated", "Updated By", "Actions"]

export const userLogsTableHeader = ["S/N", "User Fullname", "User Type", "Operation", "Date Created"];
export const usersTableHeader = ["S/N", "Fullname", "User Type", "Phone", "Email", "Username", "Status", "Work Status", "Last Seen", "Created By", "Date Created", "Actions"];

export const groupsTableHeader = ["S/N", "Group Name", "Group Email", "Group Code", "Credit Limit", "Date Created", "Date Updated", "Created By", "Actions"];
export const groupsRateTableHeader = ["S/N", "Group Name", "Room Type", "Room Rate", "Breakfast", "Date Created", "Date Updated", "Created By", "Actions"];
export const tableManagementTableHeader = ["S/N", "Table Number", "Department", "Max Cover", "Status", "Date Created", "Date Updated", "Created By", "Actions"];
export const inventoryManagementTableHeader = ["S/N", "Major Group", "Date Created", "Date Updated", "Created By", "Actions"];
export const inventoryCategoryVarietyTableHeader = ["S/N", "Major Group", "Family Group", "Date Created", "Date Updated", "Created By", "Actions"];
export const inventoryVarietyMenuTableHeader = ["S/N", "Major Group", "Family Group", "Menu Item", "Cost", "Visibility Status", "Date Created", "Date Updated", "Created By", "Actions"];

export const fAndBTablesTableHeader = ["S/N", "Table Number", "Department", "Status", "Maximum Persons On Table", "Date Created", "Date Updated", "Created By", "Updated By", "Actions"];
export const facilitiesTableHeader = ["S/N", "Facility Name", "Total Revenue", "Last Used", "Status", "Actions"];
export const eventsTableHeader = ["S/N", "Description", "Guest Details", "Guest Phone", "Start Date", "End Date", "Deposit", "Total Charge", "Date Created", "Date Updated", "Ended By", "Actions"];

export const companiesTableHeader = ["S/N", "Company Name", "Address", "Email", "Primary Phone", "Secondary Phone", "Website", "Logo", "Slogan", "Date Created", "Date Updated", "Actions"];
export const licensesTableHeader = ["S/N", "Company Name", "Clients Domain", "License Start Date", "License End Date", "Warning Days Count", "Signed License", "Status", "Date Created", "Created By", "Date Updated", "Actions"];

export const titleTableHeader = ["S/N", "Title Code", "Description", "Created By", "Date Created", "Date Updated", "Actions"];
export const sourceTableHeader = ["S/N", "Source Code", "Description", "Date Created", "Date Updated", "Created By", "Actions"];
export const membershipTypeTableHeader = ["S/N", "Membership Type", "Discount", "Date Created", "Date Updated", "Created By", "Actions"];

//========================================= Reports ================================================
export const groupGuestLedgerTableHeader = ["S/N", "Group Name", "Checked-in Date", "Check-out Date", "Nights", "Room Type/Number Of Rooms", "Number Of Guests Checked-in", "Expected Number Of Guests", "Actions"];
export const groupGuestBillsTableHeader = [...groupGuestLedgerTableHeader.slice(0, 8), "Total Charge", "Balance", "Actions"];

export const individualGuestLedgerTableHeader = ["S/N", "Guest Name", "Room Number", "Checked-in Date", "Departure Date", "Actions"];
export const individualGuestBillsTableHeader = [...individualGuestLedgerTableHeader.slice(0, 5), "Charges", "Credits", "Actions"];

export const depositsTableHeader = ["S/N",  "Date Created",  "Depositor", "Amount", "Description", "Teller", "Payment Method", "Receipt Number"];
export const financialTransactionsTableHeader = ["S/N", "Date",  "Creditor/ Debtor", "Debit", "Credit", "Transaction Code", "Description", "Debit Credit Id", "Net Charge", "VAT", "Service Charge", "Consumption Charge","Teller", "Receipt Number", "Payment Method", ];
export const foliosTableHeader = ["S/N", "Date",  "Guest Details", "Debit", "Credit", "Transaction Code", "Description", "Net Charge", "VAT", "Service Charge", "Consumption Charge", "Teller"];

export const securityReportForIndividualGuestInHouseTableHeader = ["S/N", "Guest Name", "Room Type", "Nights", "Adults", "Nationality", "Passport Number", "Checked-in Date", "Check-out Date"];
export const individualGuestInHouseTableHeader = ["S/N", "Guest Name", "Room Type", "Room Number", "Floor Number", "Nights", "Adults", "Checked-in Date", "Check-out Date", "Extended Stay", "Changed Room To", "Checked-in By"];

export const groupGuestInHouseTableHeader = ["S/N", "Group Name", "Fullname", "Guest Phone", "Room Type", "Room Number", "Checked-in Date", "Check-out Date", "Extended Stay", "Changed Room To",];

export const auditReceiptsTableHeader = ["S/N", "Receipt Number", "Description", "Debit Credit Id", "Receipients Name", "Receipients Phone", "Total Cost", "Amount Paid", "Payment Method", "Date Generated", "Generated By"];
export const receiptsTableHeader = ["S/N", "Date Generated", "Receipt Number", "Description", "Receipients Name", "Receipients Phone", "Total Cost", "Amount Paid",  "Guest Type", "Generated By", "Payment Method", "Actions"];

export const indiviudalCityLedgerTableHeader = ["S/N", "Group Name", "Arrival Date", "Nights", "Expected Number Of Guests", "Number of Guests Checked-in", "Number of Guests Checked-out", "Room Type/Number Of Rooms", "Check-out Date", "Checked-In By", "Total Cost", "Balance", "Actions"];
export const groupCityLedgerTableHeader = ["S/N", "Group Name", "Arrival Date", "Nights", "Expected Number Of Guests", "Number of Guests Checked-in", "Number of Guests Checked-out", "Room Type/Number Of Rooms", "Check-out Date", "Checked-In By", "Total Cost", "Balance", "Actions"];

export const guestLedgerDetailsTableHeader = ["S/N", "Transaction Date", "Charge Code", "Charge Description", "Transaction Cost", "Teller", "Actions"];

export const trialBalanceTableHeader = ["S/N", "Room Number", "Name", "Arr. Date", "Departure Date", "Persons", "Room Charges", "Other Charges", "Credit", "Balance", "Rate", "Payment Method"];

//===================================== Frontdesk =================================================
export const individualsCheckedInTableHeader = ["S/N", "Guest Name", "Room Type", "Room Number", "Room Rate", "Nights", "Adults", "Checked-in Date", "Check-out Date", "Extended Stay", "No Post", "Changed Room To", "Checked-in By", "Membership Type", "Total Cost", "Amount Paid At Check-in", "Amount Paid Before Check-Out", "Balance", "Actions"];
export const individualsCityLedgerTableHeader = ["S/N", "Guest Name", "Room Type", "Room Number", "Room Rate", "Nights", "Checked-in Date", "Check-out Date",  "Total Cost", "Amount Paid At Check-in", "Balance", "Actions"];

export const individualsDepartureTableHeader = ["S/N", "Guest Name", "Room Number", "Room Rate", "Nights", "Adults", "Checked-in Date", "Check-out Date", "Changed Room To", "Membership Type", "Total Cost", "Balance", "Actions"];

export const individualsHistoryTableHeader = ["S/N", "Guest Name", "Room Type", "Room Number", "Room Rate", "Nights", "Adults", "Checked-in Date", "Check-out Date", "Extended Stay", "Changed Room To", "Membership Type", "Total Cost", "Amount Paid At Check-in", "Amount Paid Before Check-Out", "Balance", "Actions"];

export const backtrackEndOfDayTableHeader = ["S/N", "No End Of Day", "Financial Transactions", "Folios", "Receipts", "Actions"];

export const individualsCheckoutHistoryTableHeader = ["S/N", "Title", "Guest Name", "Guest Phone", "Room Type", "Room Number", "Room Rate", "Nights", "Adults", "Checked-in Date", "Check-out Date", "Extended Stay", "Changed Room To", "Balance", "Checked-in By", "Actions"];

export const expectedIndividualArrivalsTableHeader = ["S/N", "Guest Name", "Phone Number", "Room Number", "Arrival Date", "Departure Date", "Nights", "Room Type", "Room Rate", "Number Of Adults", "Membership Type", "Total Cost", "Deposit", "Date Created", "Created By", "Actions"];
export const expectedIndividualCheckOutTableHeader = ["S/N", "Guest Name", "Guest Phone", "Membership Type", "Arrival Date", "Departure Date", "Nights", "Room Number", "Room Type", "Number Of Adults", "Extended Stay", "Changed Room To", "Balance", "Actions"];
export const expectedGroupCheckOutTableHeader = ["S/N", "Group Name", "Checked-in Date",  "Nights", "Room Type/Number Of Rooms", "Number Of Guests Checked-in", "Expected Number Of Guests", "Checked-in By", "Actions"];


export const generalGroupsCheckedInTableHeader = ["S/N", "Group Name", "Reservation Code", "Checked-in Date", "Check-out Date", "Nights", "Room Type/Number Of Rooms", "Number Of Guests Checked-in", "Expected Number Of Guests", "Total Cost", "Checked-in By", "Actions"];

export const groupDetailsCheckedInTableHeader = ["S/N", "Guest Name", "Guest Phone", "Room Type", "Room Number", "Floor Number", "Changed Room To", "Individual Check-in Date",  "Extended Stay", "Individual Check-out Date"];
export const checkedOutTableHeader = ["S/N", "Guest Name", "Guest Phone", "Room Number", "Room Rate", "Nights", "Check-in date", "Check-out Date", "Checked-out By", "Extended Stay", "Total Cost", "Actions"];


//========================================= Restaurant ==============================================
export const restaurantTableHeader = [ "S/N", "Description", "Table Number", "Number Of Persons On Table", "Total Cost", "Discount", "Order Status", "Balance", "Consent Signature", "Teller", "Receipt Number", "Date Created", "Actions"]
export const shiftSalesTableHeader = [ "S/N", "Date Created", "Table Number", "Number Of Persons On Table", "Total Cost", "Discount", "Order Status", "Balance",  "Room Number", "Receipt Number",]

export const restaurantOrderDetailsTableHeader = [ "S/N", "Item", "Quantity", "Cost", "Total"]
// export const shiftSalestOrderDetailsTableHeader = [ "S/N", "Item", "Quantity", "Cost", "Total"]

export const restaurantFolioTableHeader = ["S/N", "Guest Details", "Location", "Charge", "Credit", "VAT", "Service Charge", "Consumption Charge", "Consent Signature", "Payment Signature", "Teller", "Date Created", "Date Updated", "Actions"]
export const endOfShiftTableHeader = ["S/N", "Teller", "Shift Date", "Number Of Sales", "Total", "Balance", "Actions"]

export const guestLedgerFoliosTableHeader = ["S/N", "Location", "Total Cost", "VAT", "Service Charge", "Consumption Charge", "Teller", "Date Created"]
export const dailySalesTableHeader = ["S/N", "Sales Date", "Number Of Sales", "Total", "Balance", "Actions"]

//=========================================== Store =======================================================
export const storeSectionTableHeader = ["S/N", "Section Code", "Description", "Date Created", "Created By", "Updated By", "Date Updated", "Actions"]
export const storeItemsTableHeader = ["S/N", "Item Name", "Section", "Price Per-unit", "Quantity", "Restock Limit", "Total Worth", "Date Created", "Created By", "Updated By", "Date Updated", "Last Stock Date", "Signature Of Acquisition", "Actions"]
export const storeOutletsTableHeader = ["S/N", "Outlet", "Section", "Item Name", "Price Per-unit", "Quantity Stocked", "Total Worth", "Last Stock Date", "Stocked By", "Collected By"]
export const storeRecordsTableHeader = ["S/N", "Items Name", "Section", "Price Per-unit", "Quantity", "Date Created", "Created By", "Dat Updated", "Actions"]

export const paginationButtons = ['Previous', 'Next'];


