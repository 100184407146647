import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EndOfDayResults from './EndOfDayResults';

interface Props {
    serverResponse: Object | string
}


const EndOfDayComputations = (props: Props) => {
    const [endOfDayState, setEndOfDayState] = useState({
        runningTimeWarning: "",
        messages: [
            "Starting up modules...",
            'Checking Reservations...',
            'Checking Frontdesk...',
            'Checking Restaurant...',
            'Hotel-Pro will be done soon. Keep your computer on...',
            'Computing Transactions...',
            'Computing Trial-Balance...',
            'Computing Managers Flash...',
            'Hotel-Pro is summarising. Keep your computer on...',
            'Finishing up...',
            "",
            "",
            "",
        ],
        processMessage: 'End of day is starting. HotelPro is gathering data...',
        index: 0,
        showEndOfDayResults: false
    });
    useEffect(() => {
        console.log("\n\t useEffect-EndOfDayComputations-endOfDayResponse......", props.serverResponse);
        const interval = setInterval(() => {
            setEndOfDayState({
                ...endOfDayState,
                processMessage: endOfDayState.index >= 12 ? "Cleaning Reports..." : endOfDayState.messages[endOfDayState.index as any],
                index: endOfDayState.index+1
            });
        }, 10000);
        const rr = typeof(props.serverResponse) === "object" ? Object.keys(props.serverResponse) : props.serverResponse;
        if(endOfDayState.index >= 6 && (rr || rr.length > 0)) {
            console.log("\n\t useEffect-EndOfDayComputations-endOfDayResponse-2......", props.serverResponse);
            setEndOfDayState({
                ...endOfDayState,
                showEndOfDayResults: true
            });
        }
        return () => clearInterval(interval)
    }, [endOfDayState.index, props.serverResponse]);
    // console.log("\n\t EndOfDayComputations-props.serverResponse: ", props.serverResponse && props.serverResponse)
    // console.log("\n\t showEndOfDayResults: ", showEndOfDayResults)
    return (
        <div style={{height: !endOfDayState.showEndOfDayResults ? "90vh" : "auto", justifyContent: "center"}} className="d-flex ">
            {
                !endOfDayState.showEndOfDayResults ?
                <section className="d-flex row justify-content-center align-items">
                    {
                        endOfDayState.processMessage.includes('starting') ?
                        <article style={{flexDirection: "column", display: "flex"}}>
                            <img src={require("../../../../../../assets/img/end-of-day3.gif")} />
                            <span className="animate__animated animate__pulse animate__infinite text-center text-white loading-text">{endOfDayState.processMessage !== undefined ? endOfDayState.processMessage : endOfDayState.messages[-1]}</span>
                            <span className="animate__animated animate__pulse animate__infinite text-center text-white loading-text">Keep your computer on.</span>
                        </article>
                        :
                        <article className="mt-4" style={{flexDirection: "column", display: "flex"}}>
                            {
                                (endOfDayState.index as any === 5) || (endOfDayState.index as any === 9)  ?
                                <img src={require("../../../../../../assets/img/end-of-day.gif")} />
                                :
                                <img className="mt-5" src={require("../../../../../../assets/img/end-of-day2.gif")} />
                            }
                            <span className="animate__animated animate__headShake animate__infinite text-center text-white loading-text">{endOfDayState.processMessage !== undefined ? endOfDayState.processMessage : endOfDayState.messages[-1]}</span>
                        </article>
                    }
                </section>
                :
                <section className="d-flex row justify-content-center align-items">
                    <EndOfDayResults
                        serverResponse={props.serverResponse}
                    />
                </section>
            }
        </div>
    )
}


export default EndOfDayComputations