import React, { useEffect, useState } from 'react';
import './App.css';

import Unprotected from './routes/Unprotected';
import Protected from './routes/Protected';
import Header from './components/containers/Header';

import { useLocation, useNavigate } from "react-router-dom";
import userDetailsAction from './redux/actions/storeActions/userDetailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { showConfirmModalAction, showFormModalAction, showProcessingGifAction, toggleFooterAction, toggleNavbarAction, toggleSidebarAction } from './redux/actions/storeActions/appActionsUtilities';
import { getUserDetails } from './utilities/databaseHelpers';
import { UserDetails } from './types';
import { denyAccess } from './utilities/security';

interface Props {

}


const App = () => {
    const appLocation = useLocation();
    const navigate = useNavigate();
    const pathname = appLocation.pathname;
    const dispatch = useDispatch();
    const store = useSelector((store: any) => store)
    const { userDetails, sidebarStatus, navbarStatus } = store;
    const { showSidebar } = sidebarStatus;
    const { showNavbar } = navbarStatus;
    const userDetailsData = userDetails?.userDetails;

    // console.log("\n\t userDetails-App.tsx: ", userDetails);
    useEffect(() => {
        // on-load of the app fill the store with default data
        !pathname.toLowerCase().includes("reg-card") && dispatch(toggleSidebarAction(true));
        dispatch(showProcessingGifAction(false));
        dispatch(showFormModalAction(false));
        dispatch(showConfirmModalAction(false));
        dispatch(toggleFooterAction(true));
        dispatch(toggleNavbarAction(true));   // show navbar
        pathname !== "/" && "/logout" && (async() => {
            if(!userDetailsData){
                const userDetails = await getUserDetails();
                // console.log("\n\t userDetails-App.tsx: ", userDetails);
                // dispatch the users' details upon successful login
                const {licenseDetails, shiftStatus, hotelDate, userType, email, companysAddress, companysEmail, firstName, lastName, userName, companysLogo} = userDetails as UserDetails
                // security action
                userType ?
                dispatch(userDetailsAction({userName, hotelDate, shiftStatus, companysEmail, companysAddress, userType, firstName, lastName, licenseDetails, email, companysLogo}))
                :
                denyAccess(navigate) // provision for server error where userType is undefined
            };
           
        })();
    }, []);
    return(
        <React.Fragment>
            {
                // userType &&
                !pathname.includes("end-of-day") && !pathname.includes("reg-card") && pathname !== "/" && pathname !== "/logout" &&
                <section style={{marginLeft: showSidebar ? "10rem" : 0}}>
                    <Header />
                </section>
            }
            {
                // userType &&
                <Protected />
            }
            <Unprotected />
        </React.Fragment>
    )
}
export default App;
