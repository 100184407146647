import React, { lazy, Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Route, Routes, RouteObject, useRoutes, Router, useLocation, useNavigate } from "react-router-dom";
import Component404 from '../components/containers/Modules/Tables/404';

import AdminsDashboard from "../components/screens/AdminsDashboard";
// const Modules  =  lazy(() => import("../components/screens/Modules"));

import Modules from "../components/screens/Modules";


interface Props {
    
}

const ProtectedRoutes = (props: Props) => {
    const store = useSelector((store: any) => store);
    const { userDetails } = store;
    // const userData =  userDetails.userDetails ? userDetails.userDetails : "";
    const navigate = useNavigate();

    // const path = useLocation();
    // console.log("\n\t Protected routes: ", props);
    // console.log("\n\t ProtectedRoutes-userType: ", userType)
    useEffect(() => {
        // !userType && navigate("/")
    }, [])
    return (
        // <Suspense fallback={<div>HotelPro</div>}>
            <Routes>
                <Route path="/dashboard" element={<AdminsDashboard />} />
                {/* ================ Reservations ======================= */}
                <Route path="/reservations" element={<Modules />} />
                <Route path="/reservations/calendar" element={<Modules />} />
                <Route path="/reservations/receipts" element={<Modules />} />
                <Route path="/reservations/new-reservation/individual" element={<Modules />} />
                <Route path="/reservations/schedules/future-individual-arrivals" element={<Modules />} />
                <Route path="/reservations/schedules/future-group-arrivals" element={<Modules />} />
                <Route path="/reservations/schedules/future-occupancies" element={<Modules />} />

                <Route path="/reservations/new-reservation/group" element={<Modules />} />
                <Route path="/reservations/update-individual-reservations/:id" element={<Modules />} />
                <Route path="/reservations/update-group-reservations/:id" element={<Modules />} />

                <Route path="/reservations/reservation-table/individual" element={<Modules />} />
                <Route path="/reservations/reservation-table/group" element={<Modules />} />
                <Route path="/reservations/room-status" element={<Modules />} />
                <Route path="/reservations/profiles" element={<Modules />} />
                {/*=========================== Settings ================================  */}
                <Route path="/settings" element={<Modules />} />
                <Route path="/settings/charges/frontdesk-charges" element={<Modules />} />
                <Route path="/settings/credits/frontdesk-credits" element={<Modules />} />

                <Route path="/settings/charges/taxes" element={<Modules />} />
                <Route path="/settings/charges/f-and-b-discounts" element={<Modules />} />
                <Route path="/settings/charges/f-and-b-charges" element={<Modules />} />
                <Route path="/settings/credits/f-and-b-credits" element={<Modules />} />

                <Route path="/settings/charges/f-and-b-virtual-rooms" element={<Modules />} />
                <Route path="/settings/charges/payment-method" element={<Modules />} />

                <Route path="/settings/communications" element={<Modules />} />

                <Route path="/settings/inventory-management/major-group" element={<Modules />} />
                <Route path="/settings/inventory-management/family-group" element={<Modules />} />
                <Route path="/settings/inventory-management/f-and-b-time" element={<Modules />} />
                <Route path="/settings/inventory-management/menu-items" element={<Modules />} />
                <Route path="/settings/inventory-management/f-and-b-tables" element={<Modules />} />

                <Route path="/settings/inventory-management/add-pool-bar-menu-item" element={<Modules />} />

                <Route path="/settings/room-settings/room-floor" element={<Modules />} />
                <Route path="/settings/room-settings/room-type" element={<Modules />} />
                <Route path="/settings/room-settings/room-and-rates" element={<Modules />} />          
                <Route path="/settings/room-settings/bar-rates" element={<Modules />} />          
                <Route path="/settings/room-settings/room-status" element={<Modules />} />          
                <Route path="/settings/pm-account/checked-out" element={<Modules />} />
                <Route path="/settings/pm-account/in-house" element={<Modules />} />
                

                <Route path="/settings/user-management/users" element={<Modules />} />     
                <Route path="/settings/user-management/shifts" element={<Modules />} />     
                <Route path="/settings/user-management/shift-timing" element={<Modules />} />     
                <Route path="/settings/user-activities/user-logs" element={<Modules />} />     

                <Route path="/settings/group-management/groups" element={<Modules />} />     
                <Route path="/settings/group-management/group-rate" element={<Modules />} />     
                <Route path="/settings/group-management/membership-type" element={<Modules />} />     

                <Route path="/settings/table-management/table-listing" element={<Modules />} />     
                <Route path="/settings/table-management/table-location" element={<Modules />} />     
                <Route path="/settings/companies" element={<Modules />} />     
                <Route path="/settings/other-setups/title" element={<Modules />} />     
                <Route path="/settings/other-setups/hotel-date" element={<Modules />} />     
                <Route path="/settings/other-setups/source" element={<Modules />} />   
                <Route path="/settings/licenses" element={<Modules />} />
                <Route path="/settings/facilities" element={<Modules />} />
                {/*===================== Reports ========================================  */}
                <Route path="/audit-reports" element={<Modules />} />     
                <Route path="/audit-reports/guest-ledger/group" element={<Modules />} />     
                <Route path="/audit-reports/guest-ledger/individual" element={<Modules />} />     
                <Route path="/audit-reports/guest-in-house/group" element={<Modules />} />     
                <Route path="/audit-reports/guest-in-house/individual" element={<Modules />} />     

                <Route path="/audit-reports/financial-transactions" element={<Modules />} />     
                <Route path="/audit-reports/previous-month-transactions" element={<Modules />} />     
                <Route path="/audit-reports/current-month-transactions" element={<Modules />} />     
                <Route path="/audit-reports/managers-flash" element={<Modules />} />     
                
                <Route path="/audit-reports/deposits" element={<Modules />} />     

                <Route path="/audit-reports/city-ledger/individual" element={<Modules />} />     
                <Route path="/audit-reports/credit-balance/individual" element={<Modules />} />     

                <Route path="/audit-reports/city-ledger/group" element={<Modules />} />     

                <Route path="/audit-reports/pm-account" element={<Modules />} />

                <Route path="/audit-reports/end-of-day" element={<Modules />} />
                <Route path="/audit-reports/trial-balance" element={<Modules />} />     
                <Route path="/audit-reports/rate-variance" element={<Modules />} />     
                <Route path="/audit-reports/room-discrepancy" element={<Modules />} />     
                <Route path="/audit-reports/reversals" element={<Modules />} />     
                <Route path="/audit-reports/aging-analysis" element={<Modules />} />     
                <Route path="/audit-reports/guest-in-house" element={<Modules />} />     
                <Route path="/audit-reports/receipts" element={<Modules />} />     
                <Route path="/audit-reports/history" element={<Modules />} />     
                <Route path="/audit-reports/guest-bills/individual" element={<Modules />} />     
                <Route path="/audit-reports/guest-bills/group" element={<Modules />} />   
                <Route path="/audit-reports/records" element={<Modules />} />   
                
                <Route path="/audit-reports/restaurant-shift-sales" element={<Modules />} />
                <Route path="/audit-reports/restaurant-daily-sales" element={<Modules />} />


                <Route path="/audit-reports/bar-shift-sales" element={<Modules />} />
                <Route path="/audit-reports/bar-daily-sales" element={<Modules />} />

                <Route path="/audit-reports/pool-bar-shift-sales" element={<Modules />} />
                <Route path="/audit-reports/pool-bar-daily-sales" element={<Modules />} />


                <Route path="/audit-reports/daily-logs" element={<Modules />} />
                <Route path="/audit-reports/checkout-history" element={<Modules />} />
                {/* ================================ Records ============================ */}
                {/* <Route path="/records" element={<Modules />} /> */}
                <Route path="/records/financial-transactions" element={<Modules />} />
                <Route path="/records/city-ledger" element={<Modules />} />
                <Route path="/records/managers'-flash" element={<Modules />} />
                <Route path="/records/trial-balance" element={<Modules />} />
                <Route path="/records/receipts" element={<Modules />} />
                <Route path="/records/charges" element={<Modules />} />
                <Route path="/records/credits" element={<Modules />} />
                <Route path="/records/folios" element={<Modules />} />
                <Route path="/records/market-count-segmentation" element={<Modules />} />
                <Route path="/records/market-worth-segmentation" element={<Modules />} />
                <Route path="/records/room-type-count-segmentation" element={<Modules />} />
                <Route path="/records/room-type-worth-segmentation" element={<Modules />} />

                <Route path="/records/deposits" element={<Modules />} />
                <Route path="/records/group-in-house" element={<Modules />} />
                <Route path="/records/individual-in-house" element={<Modules />} />

                <Route path="/records/bar-daily-sales" element={<Modules />} />
                <Route path="/records/pool-bar-daily-sales" element={<Modules />} />
                <Route path="/records/restaurant-daily-sales" element={<Modules />} />
                <Route path="/records/daily-logs" element={<Modules />} />
                <Route path="/records/cashiers-summary-report" element={<Modules />} />
                <Route path="/records/cashiers-breakdown-report" element={<Modules />} />
                {/* ======================= Frontdesk ================================= */}
                <Route path="/frontdesk" element={<Modules />} />
                <Route path="/frontdesk/checked-out/group" element={<Modules />} />
                <Route path="/frontdesk/checked-out/individual" element={<Modules />} />

                <Route path="/frontdesk/security-&-auditor-report" element={<Modules />} />
                <Route path="/frontdesk/security-&-auditor-report" element={<Modules />} />
                <Route path="/frontdesk/guest-bills/individual" element={<Modules />} />
                <Route path="/frontdesk/guest-bills/group" element={<Modules />} />


                <Route path="/frontdesk/checked-in/group" element={<Modules />} />
                <Route path="/frontdesk/checked-in/individual" element={<Modules />} />

                <Route path="/frontdesk/arrivals/individual/print-reg-card/:guestId" element={<Modules />} />
                <Route path="/frontdesk/departures/group" element={<Modules />} />
                <Route path="/frontdesk/departures/individual" element={<Modules />} />

                <Route path="/frontdesk/room-details/room-status" element={<Modules />} />
                <Route path="/frontdesk/room-details/room-type-summary" element={<Modules />} />

                <Route path="/frontdesk/history" element={<Modules />} />

                <Route path="/frontdesk/arrivals/group" element={<Modules />} />
                <Route path="/frontdesk/arrivals/individual" element={<Modules />} />
                <Route path="/frontdesk/pm-account/checked-out" element={<Modules />} />
                <Route path="/frontdesk/pm-account/in-house" element={<Modules />} />

                <Route path="/frontdesk/facilities/facility" element={<Modules />} />
                <Route path="/frontdesk/facilities/events" element={<Modules />} />
                <Route path="/frontdesk/end-of-day" element={<Modules />} />

                {/* ============================== House Keeping =================================== */}
                <Route path="/house-keeping/" element={<Modules />} />
                <Route path="/house-keeping/room-status" element={<Modules />} />
                <Route path="/house-keeping/room-type-summary" element={<Modules />} />

                {/* ===================== Restaurant ====================================== */}
                <Route path="/restaurant" element={<Modules />} />
                <Route path="/restaurant/receipts" element={<Modules />} />
                <Route path="/restaurant/point-of-sales" element={<Modules />} />
                <Route path="/restaurant/folios" element={<Modules />} />
                <Route path="/restaurant/tables" element={<Modules />} />
                <Route path="/restaurant/backtrack" element={<Modules />} />
                <Route path="/restaurant/daily-sales" element={<Modules />} />
                <Route path="/restaurant/shift-sales" element={<Modules />} />
                <Route path="/restaurant/stocks" element={<Modules />} />
                {/* ========================== Bar ================================= */}
                <Route path="/bar" element={<Modules />} />
                <Route path="/bar/point-of-sales" element={<Modules />} />
                <Route path="/bar/folios" element={<Modules />} />
                <Route path="/bar/receipts" element={<Modules />} />
                <Route path="/bar/tables" element={<Modules />} />
                <Route path="/bar/daily-sales" element={<Modules />} />
                <Route path="/bar/shift-sales" element={<Modules />} />
                <Route path="/bar/stocks" element={<Modules />} />
                {/* ============================= Pool Bar ================================= */}
                <Route path="/pool-bar" element={<Modules />} />
                <Route path="/pool-bar/point-of-sales" element={<Modules />} />
                <Route path="/pool-bar/receipts" element={<Modules />} />
                <Route path="/pool-bar/folios" element={<Modules />} />
                <Route path="/pool-bar/stocks" element={<Modules />} />
                <Route path="/pool-bar/tables" element={<Modules />} />
                <Route path="/pool-bar/daily-sales" element={<Modules />} />
                <Route path="/pool-bar/shift-sales" element={<Modules />} />
                {/* ============================== Store ======================================= */}
                <Route path="/store" element={<Modules />} />
                <Route path="/store/sections" element={<Modules />} />
                <Route path="/store/records/outlets" element={<Modules />} />
                <Route path="/store/records/items" element={<Modules />} />
                <Route path="/store/items" element={<Modules />} />
                <Route path="/store/logs" element={<Modules />} />
                <Route path="/store/outlets" element={<Modules />} />
                {/* ================================================================================== */}
                {/* =================== Backtrack ==================================================== */}
                <Route path='/backtrack' element={<Modules/>} />
                <Route path='/backtrack/accommodation/individual' element={<Modules/>} />
                <Route path='/backtrack/accommodation/group' element={<Modules/>} />

                <Route path='/backtrack/guests/individual' element={<Modules/>} />
                <Route path='/backtrack/guests/group' element={<Modules/>} />
                <Route path='/backtrack/pm-account' element={<Modules/>} />

                <Route path='/backtrack/restaurant' element={<Modules/>} />
                <Route path='/backtrack/bar' element={<Modules/>} />
                <Route path='/backtrack/pool-bar' element={<Modules/>} />
                <Route path='/backtrack/no-eod' element={<Modules/>} />
                <Route path="/backtrack/facilities/facility" element={<Modules />} />
                <Route path="/backtrack/facilities/events" element={<Modules />} />
                {/* ========================== Statistics ==================================================== */}
                <Route path='/statistics' element={<Modules/>} />

                {/* =========================================== */}
                <Route path='*' element={<Component404 />} />
            </Routes>
        // </Suspense>
    )
}

export default ProtectedRoutes

