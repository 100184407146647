

export const setStorageItem =  (key: string, value: string | string[]) => {
    try {
        if(typeof(value) === "string"){
            localStorage.setItem(key, value);
        }else if(typeof(value) === "object"){
            console.log("\n\t setStorageItem: ")
            localStorage.setItem(key, JSON.stringify(value));
        }
    } catch (error) {
        return error
    }

}

export const getStorageItem =  async (key: string) => {
    try {
        const storeItem = localStorage.getItem(key);
        return storeItem;
    } catch (error) {
        
    }

}

export const clearStorageItem = async () => {
    try {
        localStorage.clear()
    } catch (error) {
        
    }

}