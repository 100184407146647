import React from 'react';
import { Link } from 'react-router-dom';
import Forms from "./Forms/Forms";
import { FaEnvelope, FaLock } from "react-icons/fa";
import colors from '../../assets/colors';

import "../../styles/LoginStyles.css";
import Gifs from '../Gifs';

interface Props {
    handleInputChange: Function
    handleSignIn: Function
    loginDetails: string[],
    loginMessage: string
    enterKeyAction: Function
    hideLoginButton: boolean
};


const Login = (props: Props) => {
    return (
        <div style={{width: "100%", height: "100vh", justifyContent: "center", backgroundColor: "#fff"}} className="login-box animate__animated animate__bounce box_shadow">
            <div className='bg-dar'>
                <div style={{borderColor: "#fff", height: "100vh"}} className="bg-dar home_body card py-4">
                    <span className='row d-flex justify-content-center'>
                        <Gifs style={{alignItems: "center", width: "100%"}} className="row d-flex" gifName='channel-pro' />
                    </span>
                    <div className="card-header text-center mt-1">
                        <Link style={{textDecoration: "none"}} to="#" className="text-success h1 title">
                            <b>Hotel</b>Pro <span style={{fontSize: "20px"}}>({process.env.REACT_APP_VERSION_NUMBER})</span>
                        </Link>
                    </div>
                    <div className="card-bod mx-3">
                        <span>
                            <p className="login-box-msg font-weight-bold text-center text-success">Welcome!</p>
                            <p className={`text-center text-success login-box-msg font-weight-bold animate__animated animate__swing animate__infinite ${props.loginMessage.includes('expired') && 'text-danger'}`}>{props.loginMessage}</p>
                        </span>
                        <section className='px-5'>
                            <Forms
                                fields={props.loginDetails}
                                types={["text", "password"]}
                                icon={[<FaEnvelope  size={23} color={"ghostwhite"} />, <FaLock color={"ghostwhite"} size={23}/>]}
                                onChange={props.handleInputChange}
                                enterKeyAction={props.enterKeyAction}
                            />
                        </section>
                        <div style={{width: "100%", alignSelf: "center"}} className="justify-content-center d-flex">
                        {
                            !props.hideLoginButton ?
                                <button
                                    id='login-button'
                                    onClick={() => props.handleSignIn()}
                                    type="button" 
                                    style={{width: "70%", justifyContent: "center", borderColor: "#fff"}}
                                    className={`login-button row d-flex btn mt-2 mb-3 `}>
                                        Login   
                                </button>
                            :
                            <span className='animate__animated animate__flash animate__infinite animate__slower 5s text text-white text-center' style={{fontSize: "20px", }}>Please wait.... Your credentials are being checked!</span>
                        }
                        </div>
                        <p style={{color: colors.sideNav}} className="mt-1 text-white">
                            <b>N.B:</b><br/>
                            Please contact your IT administrator, <br />
                            if there are issues with logging-in
                        </p>
                    </div>
                    <Link to="#" style={{fontSize: "15px"}} className="ml-3 mt-3 text-white">License and data policy.</Link>
                    <p className="ml-3 mt-3 text-white">&copy; Channel-Pro Property {new Date().getFullYear()}.</p>
                </div>
            </div>
        </div>
    )
}

export default Login;
